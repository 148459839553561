<template>
  <svg viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icons/Hotel/Double-Bed-24x24-Line" transform="translate(1.000000, 1.000000)">
        <rect id="Rectangle-4-Copy-3" x="0" y="0" width="24" height="24"></rect>
        <path
          id="Page-1"
          d="M21,7 C21,6.45 20.55,6 20,6 L4,6 C3.45,6 3,6.45 3,7 L3,11 L21,11 L21,7 Z M4.09,12.59 C4.23,13.13 4.56,13.57 5,13.8 C5.23,13.93 5.48,14 5.75,14 L9.25,14 C9.89,14 10.46,13.6 10.76,13 C10.91,12.71 11,12.36 11,12 L4,12 C4,12.21 4.03,12.41 4.09,12.59 L4.09,12.59 Z M5,18 L19,18 L19,14.88 C18.76,14.96 18.51,15 18.25,15 L14.75,15 C13.55,15 12.54,14.16 12.16,13 C12.06,12.69 12,12.35 12,12 C12,12.35 11.94,12.69 11.84,13 C11.46,14.16 10.45,15 9.25,15 L5.75,15 C5.49,15 5.24,14.96 5,14.88 L5,18 Z M13.24,13 C13.54,13.6 14.11,14 14.75,14 L18.25,14 C18.52,14 18.77,13.93 19,13.8 C19.44,13.57 19.77,13.13 19.91,12.59 C19.97,12.41 20,12.21 20,12 L13,12 C13,12.36 13.09,12.71 13.24,13 L13.24,13 Z M21,12 C21,12.93 20.61,13.76 20,14.31 L20,18 C20,18.55 19.55,19 19,19 L5,19 C4.45,19 4,18.55 4,18 L4,14.31 C3.39,13.76 3,12.93 3,12 C2.45,12 2,11.55 2,11 L2,7 C2,5.9 2.9,5 4,5 L20,5 C21.1,5 22,5.9 22,7 L22,11 C22,11.55 21.55,12 21,12 L21,12 Z"
          fill="currentColor"
          transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) "
        ></path>
      </g>
    </g>
  </svg>
</template>
