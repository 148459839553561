<template>
  <section class="tw-py-6 md:tw-py-8">
    <div class="tw-w-full tw-justify-between tw-px-4 sm:tw-px-8 lg:tw-px-0 tw-mx-auto tw-flex tw-flex-col">
      <transition-group class="carousel tw-w-vw tw-overflow-hidden" tag="div" name="swipe">
        <div
          v-for="(app, index) in apps"
          :key="index"
          class="md:tw-gap-8 tw-gap-4 tw-flex tw-items-center lg:tw-min-w-[980px] lg:tw-mx-[calc((100vw-980px)/2)] md:tw-min-w-[768px] md:tw-mx-[calc((100vw-768px)/2)] tw-min-w-[330px] tw-mx-[calc((100vw-330px)/2)] tw-m-auto"
        >
          <div
            class="tw-min-w-[80px] tw-w-auto md:tw-w-1/3 lg:tw-w-2/5 tw-order-1 md:tw-order-0 tw-flex tw-justify-end"
          >
            <img
              :src="app.image"
              :alt="$t(app.title)"
              class="tw-w-auto tw-h-[70px] md:tw-h-[136px]"
              :class="{ 'mirror-image': index === 0 }"
            />
          </div>
          <div class="tw-w-auto md:tw-w-2/3 lg:tw-w-3/5 tw-flex tw-flex-col tw-mr-3 tw-order-1">
            <p
              class="tw-text-[10px] sm:tw-text-lg lg:tw-text-2xl md:tw-max-w-[85%] lg:tw-w-full"
              v-html="$t(app.title)"
            />
            <p
              class="tw-text-[10px] sm:tw-text-lg lg:tw-text-2xl md:tw-max-w-[85%] lg:tw-w-full"
              v-html="$t(app.text)"
            />
          </div>
        </div>
      </transition-group>
    </div>

    <div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-full tw-mt-4 md:tw-mt-6">
      <button
        v-for="(_, index) in apps"
        :key="index"
        class="tw-w-[10px] tw-mx-[5px] tw-h-[10px] tw-rounded-full"
        :class="current === index ? 'tw-bg-blue-800' : 'tw-bg-gray-300'"
      />
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import TouchEvent from '@/utils/touchEvents.js'

const HotelConnect = new URL('/src/assets/img/studio-plus-landing/hotel-connect.svg', import.meta.url)
const VisitorsProfile = new URL('/src/assets/img/studio-plus-landing/visitors-profile.svg', import.meta.url)

const apps = ref([
  {
    image: VisitorsProfile,
    title: 'studio_plus_new_feature_1_header',
    text: 'studio_plus_new_feature_1_text',
  },
  {
    image: HotelConnect,
    title: 'studio_plus_new_feature_2_header',
    text: 'studio_plus_new_feature_2_text',
  },
])
const touchEvent = ref(null)
const current = ref(null)
const timer = ref('')

const previous = () => {
  current.value = current.value === 0 ? 1 : current.value - 1
  const element = apps.value.pop()
  apps.value.unshift(element)
}

const next = () => {
  if (current.value === null) {
    current.value = 0
  } else {
    current.value = current.value === 1 ? 0 : current.value + 1
    const element = apps.value.shift()
    apps.value.push(element)
  }
}

const autoplay = () => {
  next()
  timer.value = setTimeout(autoplay, 5000)
}

const handleSwipe = event => {
  if (!touchEvent.value) {
    return
  }

  touchEvent.value?.setEndEvent(event)
  if (touchEvent.value?.isSwipeRight()) {
    previous()
  } else if (touchEvent.value?.isSwipeLeft()) {
    next()
  }
  touchEvent.value = null
}

onMounted(() => {
  autoplay()
  window.addEventListener('touchstart', event => {
    touchEvent.value = new TouchEvent(event)
  })

  window.addEventListener('touchend', handleSwipe)
})

onUnmounted(() => {
  clearInterval(timer)
  window.removeEventListener('touchend', handleSwipe)
  window.removeEventListener('touchstart')
})
</script>
<style scoped>
.carousel {
  @apply tw-flex tw-justify-start tw-items-center tw-overflow-hidden;
  @media (max-width: theme('screens.md')) {
    width: auto;
  }
}
.carousel img {
  @media (max-width: theme('screens.sm')) {
    transform: scaleX(-1);
  }
}
.mirror-image {
  transform: scaleX(-1);
}
</style>
