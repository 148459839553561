<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
    <g id="Outer_Bounds" data-name="Outer Bounds"><rect class="cls-1" width="72" height="72" /></g>
    <g id="Elements">
      <path class="cls-2" d="M60,36,40,56,26,42,12,56v9a1,1,0,0,0,1,1H59a1,1,0,0,0,1-1Z" />
      <path class="cls-3" d="M40,56,26,42V66H59a1,1,0,0,0,1-1V36Z" />
      <path class="cls-4" d="M40,56V66H59a1,1,0,0,0,1-1V36Z" />
      <path
        class="cls-5"
        d="M60,32a4,4,0,0,0-4,4,3.89,3.89,0,0,0,.32,1.56L41.56,52.32a4,4,0,0,0-3.12,0L26,39.88,13.56,52.32A3.89,3.89,0,0,0,12,52a4,4,0,1,0,4,4,3.89,3.89,0,0,0-.32-1.56L26,44.12,36.32,54.44A3.89,3.89,0,0,0,36,56a4,4,0,0,0,8,0,3.89,3.89,0,0,0-.32-1.56L58.44,39.68A3.89,3.89,0,0,0,60,40a4,4,0,0,0,0-8Z"
      />
      <circle class="cls-6" cx="26" cy="24.5" r="15.5" />
      <path
        class="cls-5"
        d="M26,9A15.5,15.5,0,1,1,10.5,24.5,15.51,15.51,0,0,1,26,9m0-3A18.5,18.5,0,1,0,44.5,24.5,18.5,18.5,0,0,0,26,6Z"
      />
      <path
        class="cls-5"
        d="M32.5,28.5A5.51,5.51,0,0,0,27,23H25a2.5,2.5,0,0,1,0-5h5.5a1.5,1.5,0,0,0,0-3h-3V13.5a1.5,1.5,0,0,0-3,0V15A5.5,5.5,0,0,0,25,26h2a2.5,2.5,0,0,1,0,5H21.5a1.5,1.5,0,0,0,0,3h3v1.5a1.5,1.5,0,0,0,3,0V34A5.5,5.5,0,0,0,32.5,28.5Z"
      />
    </g>
  </svg>
</template>
<style scoped>
.cls-1 {
  fill: none;
}
.cls-2 {
  fill: #c94a30;
}
.cls-3 {
  fill: #d67763;
}
.cls-4 {
  fill: #e4a497;
}
.cls-5 {
  fill: #293339;
}
.cls-6 {
  fill: #f6ab3f;
}
</style>
