import { CAMPAIGN_MODES } from '@/utils/rateConnectUtils'

const DATE_LOCALE_OPTIONS = {
  month: 'long',
  day: '2-digit',
}

const EN_US_LOCALE = 'en-US'
const EXPLANATION_METRICS = ['cpc']

export function convertDateToFormattedString(date, locale = EN_US_LOCALE, options = DATE_LOCALE_OPTIONS) {
  return date instanceof Date ? date.toLocaleDateString(locale, options) : ''
}
export const splineChartOptions = {
  chart: {
    backgroundColor: null,
    type: 'spline',
    scrollablePlotArea: {
      minWidth: 700,
      scrollPositionX: 1,
    },
    plotBorderWidth: 0,
    alignTicks: false,
    style: {
      fontFamily: `SourceSansPro, Sans-Serif, "Sans Serif"`,
    },
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    visible: false,
    gridLineWidth: 1,
    tickLength: 1,
    ordinal: false,
    lineWidth: 1,
    categories: {
      formatter() {
        return this.value
      },
    },
    crosshair: {
      color: '#C4E3F4',
      dashStyle: 'solid',
    },
    labels: {
      style: {
        color: '#697379',
        fontSize: '14px',
      },
    },
  },
  yAxis: {
    visible: true,
    gridLineWidth: 1,
    tickLength: 0,
    lineWidth: 1,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    spline: {
      lineWidth: 3,
      marker: {
        fillColor: 'white',
        lineColor: '#368CDC',
        lineWidth: 3,
        radius: 3,
        symbol: 'circle',
      },
      states: {
        hover: {
          enabled: false,
        },
      },
    },
  },
  tooltip: {
    backgroundColor: null,
    borderWidth: 0,
    crosshairs: true,
    shadow: false,
    useHTML: true,
    hideDelay: 0,
    shared: true,
    padding: 0,
    formatter: function () {
      const html = ({ date, series }) => `
      <div style="
        background-color: white;
        padding: 12px;
        box-shadow: 0 1px 4px 0 rgba(41, 51, 57, 0.5);
        border-radius: 4px;
        font-size: 14px;
      ">
          <div style="
            color: #37454d;
            font-weight: 600;
            padding-bottom: 4px;
          ">
            ${date}
          </div>
          ${series}
        </div>`

      const series = ({
        translationKey,
        value,
        color,
        columnIndicator,
        classNames,
        explanationNeeded,
      }) => `<div style="height: 100%;
        margin-bottom: 2px; width: 100%;">
            ${
              classNames.includes('highlighted')
                ? `<span style="${
                    columnIndicator
                      ? `padding-left: 5px; color: #0a76da; border-left: 5px solid ${color}`
                      : 'color: #0a76da; border: 0;'
                  }">${translationKey} ${value}</span>`
                : ''
            }
            ${
              classNames.includes('warning')
                ? `<span style="${
                    columnIndicator
                      ? `padding-left: 5px; color: #f48f00; border-left: 5px solid ${color}`
                      : 'color: #f48f00; border: 0;'
                  }">${translationKey} ${value}</span>`
                : ''
            }
            ${
              !classNames.includes('warning') && !classNames.includes('highlighted')
                ? `<span  style="${
                    columnIndicator ? `padding-left: 5px; border-left: 5px solid ${color}` : ''
                  }">${translationKey} ${value}</span>`
                : ''
            }
            ${explanationNeeded ? `<span style="color: #69a9e4;">*</span>` : ''}
          </div>`

      let renderedSeries = ''
      for (let i = 0; i < this.points.length; i++) {
        let point = this.points[i]
        const {
          internalName,
          highlighted,
          warningThreshold,
          translationKey,
          columnIndicator,
          currency = 'USD',
        } = point.series.userOptions
        const campaignType = point.series.xAxis.userOptions.campaignTypes[point.point.x]
        const explanationNeeded = campaignType === CAMPAIGN_MODES.BOTH && EXPLANATION_METRICS.includes(internalName)

        let value = point.y
        let classNames = ''

        switch (internalName) {
          case 'cpc':
          case 'avgBookingRevenue':
          case 'bookingRevenue':
          case 'commissionCost':
          case 'costs':
          case 'bookingRefundedRevenue':
            value = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency,
              maximumFractionDigits: 2,
            }).format(value)
            break
          case 'clickThroughRate':
          case 'click2BookRatio':
          case 'cpa':
            value = new Intl.NumberFormat('en-US', {
              style: 'percent',
              maximumFractionDigits: 1,
            }).format(value)
            break
          case 'unavailabilityScore':
            value =
              point.y === -0.1
                ? '-'
                : new Intl.NumberFormat('en-US', {
                    style: 'percent',
                  }).format(value)
            break
          case 'cpaLevel':
            value = `${value}%`
            break
          default:
            value = new Intl.NumberFormat('en-US').format(value)
        }

        classNames += highlighted ? 'highlighted' : ''
        classNames += point.y >= warningThreshold ? 'warning' : ''

        renderedSeries += series({
          translationKey: translationKey,
          value,
          color: point.color,
          columnIndicator,
          classNames,
          explanationNeeded,
        })
      }

      return html({
        date: this.x,
        series: renderedSeries,
      })
    },
  },
}

export const columnChartOptions = {
  chart: {
    backgroundColor: null,
    type: 'column',
    scrollablePlotArea: {
      minWidth: 700,
      scrollPositionX: 1,
    },
    plotBorderWidth: 0,
    alignTicks: false,
    style: {
      fontFamily: `SourceSansPro, Sans-Serif, "Sans Serif"`,
    },
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    visible: true,
    gridLineWidth: 0,
    tickLength: 0,
    ordinal: false,
    lineColor: '#697379',
    lineWidth: 1,
    categories: {
      formatter() {
        return this.value
      },
    },
    crosshair: {
      color: '#F3F9FD',
      dashStyle: 'solid',
    },
    labels: {
      style: {
        color: '#697379',
        fontSize: '14px',
      },
    },
  },
  yAxis: {
    visible: true,
    gridLineWidth: 1,
    gridLineColor: '#B4CFE7',
    tickLength: 0,
    lineColor: '#368CDC',
    lineWidth: 1,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    column: {
      stacking: 'normal',
    },
  },
  tooltip: {
    backgroundColor: null,
    borderWidth: 0,
    crosshairs: true,
    shadow: false,
    useHTML: true,
    hideDelay: 0,
    shared: true,
    padding: 0,
    formatter: function () {
      const html = ({ date, series }) => `
      <div style="
        background-color: white;
        padding: 12px;
        box-shadow: 0 1px 4px 0 rgba(41, 51, 57, 0.5);
        border-radius: 4px;
        font-size: 14px;
        width: auto;
      ">
          <div style="
            color: #37454d;
            font-weight: 600;
            padding-bottom: 4px;
          ">
            ${date}
          </div>
          ${series}
        </div>`

      const series = ({
        translationKey,
        value,
        color,
        columnIndicator,
        classNames,
        explanationNeeded,
      }) => `<div style="height: 100%;
      margin-bottom: 2px; width: 100%;">
          ${
            classNames.includes('highlighted')
              ? `<span style="${
                  columnIndicator
                    ? `padding-left: 5px; color: #0a76da; border-left: 5px solid ${color}`
                    : 'color: #0a76da; border: 0;'
                }">${translationKey} ${value}</span>`
              : ''
          }
          ${
            classNames.includes('warning')
              ? `<span style="${
                  columnIndicator
                    ? `padding-left: 5px; color: #f48f00; border-left: 5px solid ${color}`
                    : 'color: #f48f00; border: 0;'
                }">${translationKey} ${value}</span>`
              : ''
          }
          ${
            !classNames.includes('warning') && !classNames.includes('highlighted')
              ? `<span  style="${
                  columnIndicator ? `padding-left: 5px; border-left: 5px solid ${color}` : ''
                }">${translationKey} ${value}</span>`
              : ''
          }
          ${explanationNeeded ? `<span style="color: #69a9e4;">*</span>` : ''}
        </div>`

      let renderedSeries = ''
      for (let i = 0; i < this.points.length; i++) {
        let point = this.points[i]
        const {
          internalName,
          highlighted,
          warningThreshold,
          translationKey,
          columnIndicator,
          currency = 'USD',
        } = point.series.userOptions
        const campaignType = point.series.xAxis.userOptions.campaignTypes[point.point.x]
        const explanationNeeded = campaignType === CAMPAIGN_MODES.BOTH && EXPLANATION_METRICS.includes(internalName)

        let value = point.y
        let classNames = ''
        switch (internalName) {
          case 'cpc':
          case 'avgBookingRevenue':
          case 'bookingRevenue':
          case 'commissionCost':
          case 'costs':
          case 'bookingRefundedRevenue':
            value = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency,
              maximumFractionDigits: 2,
            }).format(value)
            break
          case 'clickThroughRate':
          case 'click2BookRatio':
          case 'cpa':
            value = new Intl.NumberFormat('en-US', {
              style: 'percent',
              maximumFractionDigits: 1,
            }).format(value)
            break
          case 'unavailabilityScore':
            value =
              point.y === -0.1
                ? '-'
                : new Intl.NumberFormat('en-US', {
                    style: 'percent',
                  }).format(value)
            break
          case 'cpaLevel':
            value = `${value}%`
            break
          default:
            value = new Intl.NumberFormat('en-US').format(value)
        }

        classNames += highlighted ? 'highlighted' : ''
        classNames += point.y >= warningThreshold ? 'warning' : ''

        renderedSeries += series({
          translationKey: translationKey,
          value,
          color: point.color,
          columnIndicator,
          classNames,
          explanationNeeded,
        })
      }

      return html({
        date: this.x,
        series: renderedSeries,
      })
    },
  },
}

/**
 * @export
 * Add a datapoint on hover
 * @param {*} data value
 * @param {string} translationKey
 * @param {string} internalName used to discern the formatting in Tooltip
 * @param {object} options extra arguments
 * @returns {object} config to add only a datapoint on hover
 */
export function addHoverDatapoint(data, translationKey, internalName, options = {}) {
  return {
    data,
    translationKey,
    internalName,
    lineColor: 'transparent',
    marker: {
      enabled: false,
      fillColor: 'transparent',
      states: {
        hover: {
          enabled: false,
        },
      },
    },
    ...options,
  }
}

/**
 *
 *
 * @export
 * Add visible series to a graph
 * @param {*} data value
 * @param {string} translationKey
 * @param {string} internalName discern the formatting in Tooltip
 * @returns {object} config to add series
 */
export function addSeries(data, translationKey, internalName, options = {}, currency) {
  return {
    data,
    translationKey,
    internalName,
    highlighted: true,
    color: '#368CDC',
    ...options,
    currency,
  }
}
