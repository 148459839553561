import { store } from '@/store'

export default async function (to, from, next) {
  try {
    await store.dispatch('session/restoreStudioSession', { from, to })
    const userEmail = store.state.session.userEmail

    if (userEmail.split('@')[1] !== 'trivago.com' && userEmail !== 'aniadanilec@gmail.com') {
      return next({ name: 'home' })
    }

    return next()
  } catch {
    return next({ name: 'getstarted' })
  }
}
