import { computed, ref } from 'vue'

const currentStep = ref(1)
const selectedCampaignType = ref(null)
const selectedCommissionLevel = ref(null)
const selectedBudget = ref(0)

export function useSetup() {
  const isSelectedCampaignTypeCPA = computed(() => {
    if (!selectedCampaignType.value) return null
    return selectedCampaignType.value === 'netCpa' || selectedCampaignType.value === 'cpa'
  })

  const resetSetup = () => {
    currentStep.value = 1
    selectedCampaignType.value = null
    selectedCommissionLevel.value = null
    selectedBudget.value = 0
  }

  return {
    currentStep,
    selectedCampaignType,
    isSelectedCampaignTypeCPA,
    selectedCommissionLevel,
    selectedBudget,
    resetSetup,
  }
}
