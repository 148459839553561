<template>
  <div class="tw-flex tw-py-2 tw-text-gray-700">
    <p class="tw-mr-4 tw-text-sm">
      <span class="tw-font-bold">{{ $t('app_hotel_details_check_in') }}{{ ':' }}</span>
      {{ checkin }}
    </p>
    <p class=" tw-text-sm">
      <span class="tw-font-bold">{{ $t('app_hotel_details_check_out') }}{{ ':' }}</span>
      {{ checkout }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    checkin: {
      type: String,
      default: '0',
    },
    checkout: {
      type: String,
      default: '0',
    },
  },
}
</script>
