export const languages = [
  { value: 'de-DE', displayText: 'Deutsch', default: true },
  { value: 'el-GR', displayText: 'Ελληνικά', default: true },
  { value: 'en-GB', displayText: 'English (United Kingdom)', default: false },
  { value: 'en-US', displayText: 'English (United States)', default: true },
  { value: 'es-ES', displayText: 'Español (España)', default: false },
  { value: 'es-MX', displayText: 'Español (México)', default: true },
  { value: 'fr-FR', displayText: 'Français', default: true },
  { value: 'it-IT', displayText: 'Italiano', default: true },
  { value: 'ja-JP', displayText: '日本語', default: true },
  { value: 'nl-NL', displayText: 'Nederlands', default: true },
  { value: 'pl-PL', displayText: 'Polski', default: true },
  { value: 'pt-BR', displayText: 'Português (Brasil)', default: false },
  { value: 'pt-PT', displayText: 'Português (Portugal)', default: true },
  { value: 'ru-RU', displayText: 'Pусский', default: true },
  { value: 'tr-TR', displayText: 'Türkçe', default: true },
]

const getLanguageCodes = () => languages.map(item => item.value)

export const languageCodes = getLanguageCodes()
