<template>
  <g id="Final-Homepage-Dashboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="tBS---Home-FL---Desktop---No-Data-1" transform="translate(-530.000000, -793.000000)">
      <g id="Group-8" transform="translate(94.000000, 632.000000)">
        <g id="Group-14" transform="translate(318.000000, 70.000000)">
          <g id="Icons/Interface/Invisible-24x24-Line" transform="translate(118.000000, 91.000000)">
            <rect id="Rectangle-4-Copy-95" x="0" y="0" width="64" height="64"></rect>
            <g id="Page-1" transform="translate(6.000795, 9.333600)" fill="#0A1121">
              <path
                id="Fill-1"
                d="M52.83994,23.6 C50.13694,30.9066667 39.32494,36 26.49894,36 C24.06094,36 21.67594,35.8133333 19.42344,35.44 L20.37744,34.48 L21.78194,33.0666667 L22.36494,32.48 C23.63694,33.04 25.04144,33.3333333 26.49894,33.3333333 C32.35544,33.3333333 37.09894,28.56 37.09894,22.6666667 C37.09894,21.2 36.80744,19.7866667 36.25094,18.5066667 L38.23844,16.5066667 C39.21894,18.3466667 39.74894,20.4533333 39.74894,22.6666667 C39.74894,26.48 38.15894,29.9466667 35.58844,32.3733333 C43.08794,30.72 48.73244,27.0933333 50.34894,22.6666667 C49.10344,19.2533333 45.41994,16.2933333 40.35844,14.3733333 L42.37244,12.3466667 C47.59294,14.5066667 51.38244,17.8133333 52.83994,21.7333333 C53.05194,22.3466667 53.05194,22.9866667 52.83994,23.6"
              ></path>
              <path
                id="Fill-3"
                d="M31.217,12.2666667 L31.217,12.2666667 L30.634,12.8533333 L30.6075,12.8533333 C29.362,12.2933333 27.9575,12 26.5,12 C20.6435,12 15.9,16.7733333 15.9,22.6666667 C15.9,24.1333333 16.1915,25.5466667 16.748,26.8 L16.748,26.8266667 L14.7605,28.8266667 C13.78,26.9866667 13.25,24.88 13.25,22.6666667 C13.25,18.8533333 14.84,15.3866667 17.4105,12.96 C9.911,14.6133333 4.2665,18.24 2.65,22.6666667 C3.8955,26.08 7.579,29.04 12.6405,30.96 L10.62385,32.9866667 C5.406,30.8266667 1.6165,27.52 0.159,23.6 C-0.053,22.9866667 -0.053,22.3466667 0.159,21.7333333 C2.862,14.4266667 13.674,9.33333333 26.5,9.33333333 C28.938,9.33333333 31.323,9.52 33.5755,9.89333333 L32.6215,10.8533333 L31.217,12.2666667 Z"
              ></path>
              <path
                id="Fill-5"
                d="M21.225175,22.32 C21.384175,19.6533333 23.504175,17.52 26.154175,17.36 L21.225175,22.32 Z"
              ></path>
              <path
                id="Fill-7"
                d="M26.84344,27.9736 L31.77244,23.0136 C31.61344,25.6802667 29.49344,27.8136 26.84344,27.9736"
              ></path>
              <path
                id="Fill-9"
                d="M6.22697,44.9333333 C5.98847,45.2 5.64397,45.3333333 5.29947,45.3333333 C4.95497,45.3333333 4.61047,45.2 4.37197,44.9333333 C3.84197,44.4 3.84197,43.5733333 4.37197,43.0666667 L46.77197,0.4 C47.01047,0.133333333 47.35497,0 47.69947,0 C48.04397,0 48.38847,0.133333333 48.62697,0.4 C49.15697,0.906666667 49.15697,1.73333333 48.62697,2.26666667 L6.22697,44.9333333 Z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</template>
