<template>
  <div
    v-if="isClickable"
    class="hover:tw-cursor-pointer tw-flex tw-w-full tw-py-2 tw-border-b tw-border-gray-300 last:tw-border-none tw-items-center tw-justify-between"
    @click="() => onSelect(accommodation)"
  >
    <div class="tw-flex">
      <div class="tw-h-[64px] tw-overflow-hidden tw-w-[64px] tw-rounded tw-mr-3">
        <img :src="getMainImage(accommodation)" :alt="accommodation.name" height="64" width="64" class="hotel-image" />
      </div>
      <div>
        <p class="tw-font-bold tw-text-sm">{{ accommodation.name }}</p>
        <p class="tw-text-sm">{{ accommodation.address }}</p>
        <p class="tw-text-sm">{{ accommodation.location }}</p>
      </div>
    </div>
    <div class="tw-pr-3">
      <input type="checkbox" :id="accommodation.id" :checked="isChecked(accommodation.id)" />
    </div>
  </div>
  <div
    v-else
    class="tw-flex tw-w-full tw-py-2 tw-border-b tw-border-gray-300 last:tw-border-none tw-items-center tw-justify-between"
  >
    <div class="tw-flex">
      <div class="tw-h-[64px] tw-overflow-hidden tw-w-[64px] tw-rounded tw-mr-3">
        <img :src="getMainImage(accommodation)" :alt="accommodation.name" height="64" width="64" class="hotel-image" />
      </div>
      <div>
        <p class="tw-font-bold tw-text-sm">{{ accommodation.name }}</p>
        <p class="tw-text-sm">{{ accommodation.address }}</p>
        <p class="tw-text-sm">{{ accommodation.location }}</p>
      </div>
    </div>
    <div
      class="hover:tw-cursor-pointer tw-p-[34px] tw-my-[-34px] tw-ml-[-34px] tw-mr-[-22px]"
      @click="() => onSelect(accommodation)"
    >
      <input type="checkbox" :id="accommodation.id" :checked="isChecked(accommodation.id)" />
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  values: Object,
  accommodation: Object,
  isClickable: Boolean,
})

const emit = defineEmits(['onSelect'])
const onSelect = value => emit('onSelect', value)

const getMainImage = accommodation => {
  const isUrl = Object.keys(accommodation).includes('imageUrl')
  const isImage = Object.keys(accommodation).includes('image')
  let imageValue
  if (isImage) {
    imageValue = accommodation?.image.length > 0 ? accommodation.image : null
  } else if (isUrl) {
    imageValue = accommodation.imageUrl.length > 0 ? accommodation.imageUrl : null
  }
  if (imageValue) {
    return `https://imgcy.trivago.com/c_fill,d_dummy.jpeg,e_sharpen:60,f_auto,h_64,q_auto,w_64/${imageValue}`
  } else {
    return 'https://imgcy.trivago.com/c_fill,d_dummy.jpeg,e_sharpen:60,f_auto,h_64,q_auto,w_64/dummy.jpeg'
  }
}

const isChecked = id => {
  return props.values.find(item => item.id === id)
}
</script>
