<template>
  <a v-if="href" v-bind="$attrs" :href="href" target="_blank" rel="noopener noreferrer" :data-testid="dataTestId">
    <slot />
  </a>
  <router-link v-else v-bind="$attrs" :to="to">
    <slot />
  </router-link>
</template>

<script>
export default {
  name: 'StdioBaseLink',
  props: {
    href: {
      type: String,
      default: '',
    },
    to: {
      type: [Object, String],
      default: () => ({}),
    },
    dataTestId: {
      type: String,
      default: '',
    },
  },
}
</script>
