<template>
  <svg viewBox="0 0 14 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path
      pid="0"
      d="M4.667 11c-.299 0-.598-.12-.825-.358l-3.5-3.667A1.25 1.25 0 010 6.111c0-.313.114-.626.342-.864a1.128 1.128 0 011.65 0L4.667 8.05 12.008.358a1.128 1.128 0 011.65 0c.228.239.342.552.342.864 0 .313-.114.626-.342.865l-8.166 8.555a1.137 1.137 0 01-.825.358"
      _fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>
