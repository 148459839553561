<template>
  <BaseButton
    :size="size"
    :data-testid="dataTestId"
    class="tw-font-bold tw-border tw-border-red-700 tw-text-white tw-bg-red-700"
    v-bind="$attrs"
  >
    <slot />
  </BaseButton>
</template>

<script>
import BaseButton from './BaseButton.vue'
export default {
  components: {
    BaseButton,
  },
  props: {
    size: {
      type: String,
      default: 'big',
    },
    dataTestId: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
button:disabled {
  @apply tw-bg-red-200 tw-border-red-200;
}
@media not all and (hover: none) and (pointer: fine) {
  button:hover {
    background-color: theme('colors.red.800');
    border-color: theme('colors.red.800');
  }
}
</style>
