<template>
  <g id="RC-FL---Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="RC-FL-Settings-Campaing-Type-1-5" transform="translate(-338.000000, -458.000000)">
      <g id="Group-9" transform="translate(306.000000, 192.000000)">
        <g id="Group-10" transform="translate(32.000000, 128.000000)">
          <g id="Icons/Travel/Global-24x24-Line" transform="translate(0.000000, 138.000000)">
            <rect id="Rectangle-4-Copy-47" x="0" y="0" width="24" height="24"></rect>
            <path
              id="Page-1"
              d="M13.07,3.07 L14,4 L15,5 L17,6 L18,8 L18,9 L17,10 L16,11 L14,11 L13,10 L12,9 L12,8 L14,7 L13,5 L12.339,3.017 C12.226,3.013 12.114,3 12,3 C7.04,3 3,7.04 3,12 C3,14.94 4.42,17.55 6.6,19.19 L6,18 L7,17 L8,18 L8,17 L7,15 L11,11 L13,10 L11,12 L11,13 L12,14 L13,13 L15,15 L15,16 L14,18 L13,18 L13,17 L12,17 L12,18 L13,19 L12,20 L8,20 L8.12,20.12 C9.3,20.68 10.61,21 12,21 C16.96,21 21,16.96 21,12 C21,7.403 17.527,3.602 13.07,3.07 M12,22 C11.22,22 10.46,21.91 9.74,21.74 C8.87,21.54 8.04,21.23 7.27,20.81 C4.14,19.13 2,15.81 2,12 C2,6.48 6.48,2 12,2 C17.52,2 22,6.48 22,12 C22,17.52 17.52,22 12,22"
              fill="#007CC2"
              transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) "
            ></path>
          </g>
        </g>
      </g>
    </g>
  </g>
</template>
