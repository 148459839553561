<template>
  <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g _fill="#37454D">
      <path
        pid="0"
        d="M20.5 6H17V5c0-1.1-.9-2-2-2H9c-1.1 0-2 .9-2 2v1H3.5c-.3 0-.5.2-.5.5s.2.5.5.5h17.1c.2 0 .4-.2.4-.5s-.2-.5-.5-.5zM8 6V5c0-.5.5-1 1-1h6c.5 0 1 .5 1 1v1H8zm11 2H5c-.3 0-.6.1-.8.4-.2.2-.2.5-.2.8l2 12c.1.4.5.8 1 .8h10c.5 0 .9-.4 1-.8l2-12c0-.3 0-.6-.2-.8-.2-.3-.5-.4-.8-.4zm-2 13H7L5 9h14l-2 12z"
      />
      <path
        pid="1"
        d="M9 19.5c.3 0 .5-.3.5-.6l-1-8c0-.3-.3-.5-.6-.4-.3 0-.5.3-.4.6l1 8c0 .2.3.4.5.4zm3 0c.3 0 .5-.2.5-.5v-8c0-.3-.2-.5-.5-.5s-.5.2-.5.5v8c0 .3.2.5.5.5zm2.9 0c.1 0 .1 0 0 0 .3 0 .5-.2.6-.4l1-8c0-.3-.2-.5-.4-.6s-.5.2-.6.4l-1 8c0 .3.2.6.4.6z"
      />
    </g>
  </svg>
</template>
