import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import DashboardService from '@/services/DashboardService'

export default function useGuestRatings(selectedAccommodationId) {
  const { isLoading, data } = useQuery({
    queryKey: ['guestRatings', selectedAccommodationId],
    queryFn: ({ queryKey }) => DashboardService.fetchRateIndex({ accommodationId: queryKey[1] }),
  })

  const accommodationRating = computed(
    () => data?.value?.data?.data?.getAccommodationReviewRatings?.formattedRating ?? null
  )
  const advertiserRatings = computed(
    () => data?.value?.data?.data?.getAccommodationReviewRatings?.advertiserRatings ?? []
  )
  const numberOfReviews = computed(() => data?.value?.data?.data?.getAccommodationReviewRatings?.ratingCount ?? null)
  const propertyHasData = computed(() => numberOfReviews.value > 0 && accommodationRating.value)

  return { isLoading, accommodationRating, numberOfReviews, propertyHasData, advertiserRatings }
}
