<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 2.5C17.247 2.5 21.5 6.753 21.5 12C21.5 17.247 17.247 21.5 12 21.5C6.753 21.5 2.5 17.247 2.5 12C2.5 6.753 6.753 2.5 12 2.5Z"
    stroke="#171717"
    stroke-linecap="round"
  />
  <path d="M12 11V17" stroke="#171717" stroke-width="2" stroke-linecap="round" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13 7C13 7.552 12.552 8 12 8C11.448 8 11 7.552 11 7C11 6.448 11.448 6 12 6C12.552 6 13 6.448 13 7Z"
    fill="#171717"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 2.5C17.247 2.5 21.5 6.753 21.5 12C21.5 17.247 17.247 21.5 12 21.5C6.753 21.5 2.5 17.247 2.5 12C2.5 6.753 6.753 2.5 12 2.5Z"
    stroke="#171717"
    stroke-linecap="round"
  />
  <path d="M12 11V17" stroke="#171717" stroke-width="2" stroke-linecap="round" />
</template>
