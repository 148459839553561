<template>
  <div class="tw-relative">
    <BaseCard
      v-if="isMenuOpen"
      v-click-outside="onClickaway"
      no-padding
      class="tw-absolute tw-z-[10] tw-flex tw-min-w-[300px] xs:tw-min-w-[340px] tw-mx-[10px] menu-card-mobile tw-rounded-xl tw-right-0"
    >
      <div class="tw-p-[15px] tw-w-full">
        <ul>
          <li
            class="tw-items-center tw-text-sm tw-justify-between tw-py-1.5 tw-px-3 hover:tw-cursor-pointer hover:tw-bg-gray-200 active:tw-bg-gray-200 tw-rounded tw-font-bold tw-mb-2 tw-w-full tw-flex"
            :class="{ selected: selectedTab === 'products' }"
            @click="toggleTab('products')"
            @keydown.enter="toggleTab('products')"
          >
            {{ $t('navigation_menu_our_solutions') }}
            <BaseIcon
              icon-name="chevron-right"
              :height="18"
              :width="24"
              class="hover:tw-fill-blue-800 arrow-dropdown tw-fill-gray-800 hover:tw-fill-blue-800"
            >
              <ChevronRight />
            </BaseIcon>
          </li>
          <ul v-if="selectedTab === 'products'" class="tw-ml-6 tw-mb-2">
            <li v-for="item in productLinks" :key="item.id">
              <a
                class="hover:tw-no-underline tw-w-full tw-py-1 tw-px-1 hover:tw-text-gray-800 tw-flex tw-items-center tw-justify-between hover:tw-bg-blue-100 tw-text-sm hover:tw-rounded"
                :href="item.newPage ? getSupportCenterUrl(locale.value, item.url) : item.url"
                :target="item.newPage ? '_blank' : ''"
              >
                <span v-html="$t(item.label)" />
              </a>
            </li>
          </ul>
          <li
            class="tw-font-bold tw-items-center tw-text-sm tw-justify-between tw-py-1.5 tw-px-3 hover:tw-cursor-pointer hover:tw-cursor-pointer hover:tw-bg-gray-200 tw-rounded tw-w-full tw-flex"
            :class="{ selected: selectedTab === 'support' }"
            @click="toggleTab('support')"
            @keydown.enter="toggleTab('support')"
          >
            {{ $t('navigation_menu_support_center') }}
            <BaseIcon
              icon-name="chevron-right"
              :height="18"
              :width="24"
              class="hover:tw-fill-blue-800 arrow-dropdown tw-fill-gray-800 hover:tw-fill-blue-800"
            >
              <ChevronRight />
            </BaseIcon>
          </li>
          <ul v-if="selectedTab === 'support'" class="tw-ml-6 tw-mb-2">
            <li v-for="item in supportLinks" :key="item.id">
              <a
                class="hover:tw-no-underline tw-w-full tw-py-1 tw-px-1 hover:tw-text-gray-800 tw-flex tw-items-center hover:tw-bg-blue-100 tw-text-sm hover:tw-rounded"
                :href="item.newPage ? getSupportCenterUrl(locale.value, item.url) : item.url"
                :target="item.newPage ? '_blank' : ''"
              >
                <span class="tw-mr-2" v-html="$t(item.label)" />
                <External v-if="item.withIcon" />
              </a>
            </li>
          </ul>
        </ul>

        <MainButton
          size="big"
          no-padding
          class="tw-flex tw-w-full tw-text-center tw-mt-2 tw-justify-center"
          @click="() => onAuthorize('signup')"
          @keydown.enter="() => onAuthorize('signup')"
        >
          {{ $t('tbs_mp_log_in_cta') }}
        </MainButton>
      </div>
    </BaseCard>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue'
import { getSupportCenterUrl } from '@/utils/Utility.js'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import appAnalytics from '@/utils/tracking'

import External from '@/components/icons/External.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'

defineProps({
  supportLinks: Array,
  productLinks: Array,
  isMenuOpen: Boolean,
  onClickaway: Function,
})
const selectedTab = ref('')
const store = useStore()
const route = useRoute()
const locale = computed(() => store.state.locale.language)

const toggleTab = name => {
  if (name === selectedTab.value) {
    selectedTab.value = ''
  } else {
    selectedTab.value = name
  }
}
const authorize = authData => store.dispatch('session/authorize', authData)
const onAuthorize = async (type = 'login') => {
  appAnalytics.track(type === 'login' ? appAnalytics.events.BUTTON_CLICKED : appAnalytics.events.BUTTON_CLICKED, {
    button: type,
    property_id: null,
  })
  await authorize({
    type,
    stateParams: {
      redirectFrom: route.query.redirectFrom,
    },
    locale: locale.value.language,
  })
}
</script>
<style scoped>
.arrow-dropdown {
  top: 1px;
  bottom: 0;
  height: 100%;
  right: 0;
  pointer-events: none;
  transform: translateY(0%) rotate(90deg);
}
.selected .arrow-dropdown {
  transform: translateY(0%) rotate(-90deg);
  @apply tw-fill-blue-700;
}
.selected {
  @apply tw-bg-white tw-mb-0;
}
.menu-card-mobile {
  top: 10px;
}
</style>
