<template>
  <div
    class="tw-flex-col tw-flex tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-mr-auto tw-ml-auto tw-min-h-screen-minus-header-and-footer tw-px-4"
  >
    <div class="tw-mt-[40px]">
      <div class="tw-flex tw-items-center tw-py-2">
        <VisitorsProfileIcon class="tw-mr-1" />
        <h4 class="tw-text-xl tw-font-bold">{{ $t('app.visitorsProfile.name') }}</h4>
        <UiTooltip tooltip-position="top" :label="$t('vp_tooltip')" class="tw-cursor-pointer tw-ml-1">
          <BaseIcon
            :height="16"
            :width="16"
            class="tw-fill-none tw-stroke-gray-800 tw-h-[22px] tw-w-[22px] tw-ml-1"
            icon-name="information-outline"
          >
            <InformationOutline />
          </BaseIcon>
        </UiTooltip>
      </div>
      <p class="tw-flex tw-w-full tw-text-base tw-font-normal">
        {{ $t('vp_tag_line') }}
      </p>
    </div>
    <LaunchpadLoader
      v-if="isRequiredDataFetching"
      class="tw-w-full tw-flex tw-justify-center tw-items-center tw-flex-1"
    />
    <div v-else-if="isRequiredDataError" class="tw-flex tw-justify-center tw-items-center tw-flex-1">
      <ErrorModal
        :allow-retry="false"
        app-name="Visitors Profile"
        :use-app-name="true"
        header-key="onboarding.appLaunchError.header"
        content-key="onboarding.appLaunchError.text"
        retry-key="onboarding.appLaunchError.retryButton"
      />
    </div>
    <div
      v-else
      class="tw-flex-col tw-flex tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-mr-auto tw-ml-auto tw-my-9"
    >
      <AppMenuNew :menuItems="menuItems" class="tw-mb-4" />
      <router-view :key="selectedAccommodationId" />
    </div>
  </div>
</template>

<script setup>
import { computed, watch, ref } from 'vue'
import { useRouter } from 'vue-router'

import AppMenuNew from '@/components/appMenu/AppMenuNew.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import ErrorModal from '@/components/dashboard/ErrorModal.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import VisitorsProfileIcon from '@/components/icons/VisitorsProfileIcon.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import { selectedAccommodationId } from '@/layouts/queries'
import { useStudioPlusCampaign } from '@/components/payment/queries'

const router = useRouter()
const {
  studioPlusCampaign,
  isFetching: isStudioPlusCampaignFetching,
  isExpiredSubscription,
  isError: isStudioPlusCampaignError,
} = useStudioPlusCampaign(selectedAccommodationId)

const isRequiredDataFetching = computed(() => isStudioPlusCampaignFetching.value)
const isRequiredDataError = computed(() => isStudioPlusCampaignError.value)

const isMobile = document.body.clientWidth >= 500 ? ref(false) : ref(true)
const isUserAllowedAccess = computed(() => {
  if (studioPlusCampaign.value && !isExpiredSubscription.value && !isMobile.value) return true
  return false
})

watch(isRequiredDataFetching, newVal => {
  if (newVal === false && isUserAllowedAccess.value === false && !isRequiredDataError.value) {
    router.replace('/')
  }
})

const menuItems = [
  {
    label: 'vp_stay_header',
    route: '/visitors-profile/stay-duration',
    testId: 'visitors-profile-stay-duration',
  },
  {
    label: 'vp_time_header',
    route: '/visitors-profile/search-window',
    testId: 'visitors-profile-search-window',
  },
  {
    label: 'vp_countries_header',
    route: '/visitors-profile/search-countries',
    testId: 'visitors-profile-search-countries',
  },
]
</script>
