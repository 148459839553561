<template>
  <div class="tw-my-6">
    <div>
      <div class="required-info tw-text-xs tw-text-gray-700 tw-mb-1" v-html="$t('required_information')" />
      <div>
        <BaseSelect
          id="'select-rc-pause-reason'"
          :placeholder="$t('rc_pause_flow_menu_prompt')"
          required
          groupedOptions
          :options="reasons"
          display-text-key="label"
          :label="$t('rc_pause_flow_pause_question')"
          @onChange="e => selectReason(e)"
          labelRequiresTranslation
          :value="selectedReason"
        />
      </div>
    </div>
  </div>
  <div v-if="showHint && selectedReasonObject">
    <SimpleSmartHints v-if="isSimpleHint" :id="selectedReasonObject?.id" />
    <ListSmartHints v-if="isListHint" :id="selectedReasonObject?.id" />
    <UpsellSmartHints
      v-if="isUpsellHint"
      :id="selectedReasonObject?.id"
      @toggleChangeCampaignModal="toggleChangeCampaignModal"
    />
    <div v-if="selectedReasonObject?.id === 11">
      <p class="tw-font-bold tw-mb-1" v-html="$t('reason_textbox')" />
      <textarea
        class="tw-outline-none tw-w-full tw-border tw-border-gray-500 tw-rounded-md tw-p-2 tw-max-h-[150px]"
        :placeholder="$t('rc_pause_flow_other_text_prompt')"
        @input="updateOtherExplanation($event.target.value)"
        :ref="otherExplanation"
        style="height: 56px"
      />
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import BaseSelect from '@/components/BaseSelect.vue'
import SimpleSmartHints from './smartHints/SimpleSmartHints.vue'
import ListSmartHints from './smartHints/ListSmartHints.vue'
import UpsellSmartHints from './smartHints/UpsellSmartHints.vue'

const props = defineProps({
  reasons: Array,
  selectedReason: [String, null],
  selectedReasonObject: [Object, null],
  showHint: Boolean,
  isItemNetCPAEligible: Boolean,
  currentCampaignType: String,
  otherExplanation: String,
})

const listIds = [3, 5, 9, 10]
const simpleIds = [0, 2]
const upsellIds = [1, 4, 6, 7, 8]
const emit = defineEmits(['selectReason', 'toggleChangeCampaignModal', 'updateOtherExplanation'])
const toggleChangeCampaignModal = () => emit('toggleChangeCampaignModal')

const updateOtherExplanation = text => emit('updateOtherExplanation', text)
const selectReason = reason => emit('selectReason', reason)

const isEligibleForChange = computed(() => {
  return props.currentCampaignType === 'cpc' && props.isItemNetCPAEligible
})

const isSimpleHint = computed(() => simpleIds.includes(props.selectedReasonObject?.id))
const isListHint = computed(
  () =>
    (upsellIds.includes(props.selectedReasonObject.id) && !isEligibleForChange.value) ||
    listIds.includes(props.selectedReasonObject.id)
)
const isUpsellHint = computed(() => upsellIds.includes(props.selectedReasonObject?.id) && isEligibleForChange.value)
</script>
<style scoped>
.required-info :deep(span) {
  @apply tw-text-red-700;
}
p :deep(span) {
  color: theme('colors.red.600');
}
</style>
