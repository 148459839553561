<template>
  <section>
    <div
      class="tw-flex tw-flex-col tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-screen-md sm:tw-px-8 tw-px-4 lg:tw-px-0 tw-mx-auto tw-pb-8"
    >
      <div class="tw-flex tw-flex-col tw-items-center md:tw-items-start">
        <img :src="StudioPlusLogo" alt="rate-connect-logo" height="28" class="tw-mb-4" />
        <h1
          class="tw-font-bold tw-text-black tw-text-[28px] lg:tw-text-[40px] tw-leading-[35px] lg:tw-leading-[50px] tw-text-center md:tw-text-left"
        >
          {{ $t('studio_plus_mp_subheader') }}
        </h1>
      </div>
    </div>

    <!-- Web Version -->
    <div
      class="tw-flex tw-flex-col tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-screen-md sm:tw-px-8 tw-px-4 lg:tw-px-0 tw-mx-auto"
    >
      <div class="md:tw-flex tw-hidden tw-flex-wrap tw-w-full tw-text-gray-800 tw-gap-6">
        <div
          v-for="(benefit, index) in webBenefits"
          :key="index"
          class="tw-bg-white tw-flex tw-items-center tw-w-[calc(50%-12px)] tw-rounded-lg tw-px-4 tw-py-6"
        >
          <div class="tw-min-w-[75px] tw-mr-2">
            <img
              :src="benefit.image"
              :alt="$t(benefit.title)"
              class="tw-mx-auto"
              :class="index === 2 || index === 3 ? 'tw-h-[70px]' : 'tw-h-[81px]'"
            />
          </div>
          <div class="tw-flex tw-flex-col">
            <p class="tw-font-bold tw-text-2xl tw-mb-2 tw-text-blue-700">
              {{ $t(benefit.title) }}
            </p>
            <p class="tw-text-lg">{{ $t(benefit.text) }}</p>
          </div>
        </div>
      </div>
      <p class="tw-text-lg tw-mt-12 md:tw-flex tw-hidden">
        {{ $t('studio_plus_info_text') }}
      </p>
    </div>

    <!-- Mobile Version -->
    <div
      class="tw-w-full tw-flex md:tw-hidden tw-justify-between tw-text-gray-800 tw-flex-col md:tw-flex-row tw-w-auto"
    >
      <transition-group
        class="carousel-mobile tw-w-vw tw-overflow-hidden"
        tag="div"
        name="swipe"
        :class="{ 'tw-ml-0 md:tw-ml-8': isInitialSlide }"
      >
        <div
          v-for="(benefit, index) in benefits"
          :key="index"
          class="tw-bg-white tw-rounded-lg tw-flex tw-mx-4 tw-my-0 tw-p-2.5 tw-gap-1 tw-min-w-[300px] xs:tw-min-w-[330px] tw-min-h-[85px] tw-flex tw-items-center"
        >
          <div class="tw-min-w-[75px]">
            <img :src="benefit.image" :alt="$t(benefit.title)" class="tw-h-[70px] tw-w-[74px]" />
          </div>
          <div>
            <p class="tw-font-bold tw-text-base tw-mb-2 tw-text-blue-700">
              {{ $t(benefit.title) }}
            </p>
            <p class="tw-text-sm">{{ $t(benefit.text) }}</p>
          </div>
        </div>
      </transition-group>

      <div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-full tw-mt-5">
        <button
          v-for="(_, index) in benefits"
          :key="index"
          class="tw-w-[10px] tw-mx-[5px] tw-h-[10px] tw-rounded-full tw-mb-6"
          :class="current === index ? 'tw-bg-blue-800' : 'tw-bg-gray-300'"
        />
      </div>
      <p class="tw-px-4 tw-text-sm">
        {{ $t('studio_plus_info_text') }}
      </p>
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import TouchEvent from '@/utils/touchEvents.js'

const StudioPlusLogo = new URL('/src/assets/img/landing/landing_studio_plus.svg', import.meta.url)

const benefitOne = new URL('/src/assets/img/studio-plus-landing/lightbulb.svg', import.meta.url)
const benefitTwo = new URL('/src/assets/img/studio-plus-landing/sp-benefit-two.svg', import.meta.url)
const benefitThree = new URL('/src/assets/img/studio-plus-landing/sp-benefit-three.svg', import.meta.url)
const benefitFour = new URL('/src/assets/img/studio-plus-landing/sp-benefit-four.svg', import.meta.url)

const webBenefits = ref([
  {
    title: 'studio_plus_mp_benefit_1_header',
    text: 'studio_plus_mp_benefit_1_text',
    image: benefitOne,
  },
  {
    title: 'studio_plus_mp_benefit_2_header',
    text: 'studio_plus_mp_benefit_2_text',
    image: benefitTwo,
  },
  {
    title: 'studio_plus_mp_benefit_3_header',
    text: 'studio_plus_mp_benefit_3_text',
    image: benefitThree,
  },
  {
    title: 'studio_plus_mp_benefit_3_header',
    text: 'studio_plus_mp_benefit_4_text',
    image: benefitFour,
  },
])
const benefits = ref([
  {
    title: 'studio_plus_mp_benefit_1_header',
    text: 'studio_plus_mp_benefit_1_text',
    image: benefitOne,
  },
  {
    title: 'studio_plus_mp_benefit_2_header',
    text: 'studio_plus_mp_benefit_2_text',
    image: benefitTwo,
  },
  {
    title: 'studio_plus_mp_benefit_3_header',
    text: 'studio_plus_mp_benefit_3_text',
    image: benefitThree,
  },
  {
    title: 'studio_plus_mp_benefit_4_header',
    text: 'studio_plus_mp_benefit_4_text',
    image: benefitFour,
  },
])

const touchEvent = ref(null)
const current = ref(null)
const timer = ref('')
const isInitialSlide = ref(true)

watch(current, newCurrent => {
  if (newCurrent === 1 && isInitialSlide.value) {
    isInitialSlide.value = false
  }
})
const previous = () => {
  current.value = current.value === 0 ? 3 : current.value - 1
  const element = benefits.value.pop()
  benefits.value.unshift(element)
}

const next = () => {
  if (current.value === null) {
    current.value = 0
  } else {
    current.value = current.value === 3 ? 0 : current.value + 1
    const element = benefits.value.shift()
    benefits.value.push(element)
  }
}

const autoplay = () => {
  next()
  timer.value = setTimeout(autoplay, 5000)
}

const handleSwipe = event => {
  if (!touchEvent.value) {
    return
  }

  touchEvent.value?.setEndEvent(event)
  if (touchEvent.value?.isSwipeRight()) {
    previous()
  } else if (touchEvent.value?.isSwipeLeft()) {
    next()
  }
  touchEvent.value = null
}

onMounted(() => {
  autoplay()
  window.addEventListener('touchstart', event => {
    touchEvent.value = new TouchEvent(event)
  })

  window.addEventListener('touchend', handleSwipe)
})

onUnmounted(() => {
  clearInterval(timer)
  window.removeEventListener('touchend', handleSwipe)
  window.removeEventListener('touchstart')
})
</script>
<style scoped>
.carousel-mobile {
  @apply tw-flex tw-justify-start tw-items-center tw-overflow-hidden;
  @media (max-width: theme('screens.md')) {
    width: auto;
  }
}
</style>
