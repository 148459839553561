<template>
  <div class="auth-callback">
    <EllipsisLoader
      :text="$t('pages.oauthCallback.loadText')"
      :text-class="'ellipsis-loader__text--light'"
      class="ellipsis-loader--launched-app"
    />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'

import EllipsisLoader from '@/components/EllipsisLoader.vue'

const store = useStore()

onMounted(() => {
  store.dispatch('session/authenticate')
})
</script>
