<template>
  <g id="Homepage" stroke="white" stroke-width="1" fill="white" fill-rule="evenodd">
    <g id="tBS---Home-FL---Desktop" transform="translate(-197.000000, -775.000000)" fill="#fff" fill-rule="nonzero">
      <g id="Group-8" transform="translate(96.000000, 628.000000)">
        <g id="Group-16" transform="translate(0.000000, 75.000000)">
          <g id="Group-11-Copy-3" transform="translate(20.000000, 20.000000)">
            <g id="arrow" transform="translate(78.000000, 49.000000)">
              <path
                id="Path"
                d="M10.4270833,6.71254041 L3.98505729,6.71254041 L6.45181771,4.51966158 C6.58098437,4.40483381 6.58598958,4.21405697 6.46295833,4.09350288 C6.34008854,3.97309949 6.13568229,3.96827733 6.00635417,4.08310509 L3.18922917,6.58761624 C3.06732812,6.70153985 3,6.85283523 3,7.01392562 C3,7.17486533 3.06732812,7.3263114 3.19488021,7.44520787 L6.00651562,9.94459546 C6.069,10.000201 6.14908333,10.0277778 6.22916667,10.0277778 C6.31441667,10.0277778 6.39966667,9.99643372 6.46311979,9.93419767 C6.58615104,9.81364358 6.58114583,9.62301743 6.45197917,9.50818967 L3.97488542,7.31531084 L10.4270833,7.31531084 C10.6053333,7.31531084 10.75,7.18029026 10.75,7.01392562 C10.75,6.84756098 10.6053333,6.71254041 10.4270833,6.71254041 L10.4270833,6.71254041 Z"
                transform="translate(6.875000, 7.013889) scale(1, -1) rotate(-270.000000) translate(-6.875000, -7.013889) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</template>
