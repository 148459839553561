<template>
  <div class="tw-mx-4 lg:tw-mx-0 tw-relative tw-mb-6">
    <div v-if="isLoading" class="tw-rounded-xl tw-animate-pulse tw-bg-gray-300 tw-h-[658px]"></div>
    <div v-else-if="isError">
      <p class="tw-text-center tw-text-xl">{{ $t('onboarding.appLaunchError.header') }}</p>
    </div>
    <BiddingModeOverview v-else />
    <FullScreenLoader v-show="!isLoading && isFetching" />
  </div>
</template>
<script setup>
import BiddingModeOverview from '@/components/rateConnect/biddingGoals/BiddingModeOverview.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import { useCampaigns } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const { isLoading, isFetching, isError } = useCampaigns(selectedAccommodationId)
</script>
