<template>
  <path
    d="M10.3633 3.95728L2.25653 18.017C2.08938 18.3177 2.00094 18.6587 2.00001 19.0059C1.99907 19.3531 2.08567 19.6946 2.25119 19.9963C2.41671 20.2979 2.65538 20.5493 2.94346 20.7254C3.23153 20.9015 3.55897 20.9962 3.8932 21H20.1068C20.441 20.9962 20.7685 20.9015 21.0565 20.7254C21.3446 20.5493 21.5833 20.2979 21.7488 19.9963C21.9143 19.6946 22.0009 19.3531 22 19.0059C21.9991 18.6587 21.9106 18.3177 21.7435 18.017L13.6367 3.95728C13.466 3.66505 13.2258 3.42345 12.9391 3.25577C12.6524 3.08809 12.329 3 12 3C11.671 3 11.3476 3.08809 11.0609 3.25577C10.7742 3.42345 10.534 3.66505 10.3633 3.95728Z"
    fill="#FFCC31"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.8084 2.82417C11.1709 2.61215 11.5814 2.5 12 2.5C12.4186 2.5 12.8291 2.61215 13.1916 2.82417C13.5539 3.03611 13.8553 3.34015 14.0685 3.70517L14.0698 3.70752L22.1806 17.7741C22.3892 18.1494 22.4988 18.5736 22.5 19.0046C22.5012 19.4355 22.3937 19.8603 22.1872 20.2368C21.9805 20.6134 21.6813 20.9295 21.3173 21.152C20.9532 21.3746 20.5378 21.4951 20.1125 21.5L20.1068 21.5L3.88749 21.5C3.46222 21.4951 3.04679 21.3746 2.68268 21.152C2.31872 20.9295 2.01947 20.6134 1.81284 20.2368C1.60627 19.8603 1.49885 19.4355 1.50001 19.0046C1.50117 18.5736 1.61088 18.1494 1.8195 17.7741L1.82332 17.7672L9.93017 3.70752L9.93154 3.70517C10.1447 3.34015 10.4461 3.03611 10.8084 2.82417ZM12 3.5C11.7606 3.5 11.5242 3.56403 11.3133 3.68736C11.1027 3.81057 10.9238 3.98935 10.7958 4.20829L10.7951 4.20939L2.69178 18.2631C2.56725 18.4885 2.50071 18.745 2.50001 19.0072C2.4993 19.2708 2.56508 19.5289 2.68955 19.7557C2.81396 19.9825 2.99205 20.1691 3.20424 20.2988C3.41551 20.428 3.654 20.4968 3.89629 20.5H20.1037C20.346 20.4968 20.5845 20.428 20.7958 20.2988C21.008 20.1691 21.186 19.9825 21.3105 19.7557C21.4349 19.5289 21.5007 19.2708 21.5 19.0072C21.4993 18.745 21.4328 18.4885 21.3082 18.2632L13.2049 4.20939L13.2042 4.20829C13.0762 3.98935 12.8973 3.81057 12.6867 3.68736C12.4758 3.56403 12.2394 3.5 12 3.5Z"
    fill="#FFCC31"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11 17C11 16.448 11.448 16 12 16C12.552 16 13 16.448 13 17C13 17.552 12.552 18 12 18C11.448 18 11 17.552 11 17Z"
    fill="#171717"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 14C11.4477 14 11 13.5523 11 13V9C11 8.44772 11.4477 8 12 8C12.5523 8 13 8.44772 13 9V13C13 13.5523 12.5523 14 12 14Z"
    fill="#171717"
  />
</template>
