<template>
  <div class="tw-mx-4 lg:tw-mx-0">
    <div class="tw-my-4">
      <ProfileInformation :user="userDetails" :loading="isUserDetailsLoading" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import ProfileInformation from '@/components/settings/profileSettings/ProfileInformation.vue'
import { useUserDetails } from '@/layouts/queries'

const store = useStore()
const userId = computed(() => store.state.session?.userId)
const { userDetails, isUserDetailsLoading } = useUserDetails(userId)
</script>
