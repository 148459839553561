<template>
  <div class="tw-px-2">
    <svg v-if="marks && marks.length > 0" class="tw-w-full tw-h-4">
      <text v-for="(mark, i) in marks" :key="i" class="tw-font-bold" :x="getValuePercentage(mark.value)" y="14">
        {{ mark.label }}
      </text>
    </svg>
  </div>
  <div class="tw-relative tw-mt-2">
    <input
      id="custom-slider"
      type="range"
      ref="inputRef"
      :min="min"
      :value="value"
      :max="max"
      @input="onInput"
      class="tw-absolute"
      :class="{ 'hide-thumb': hideThumb }"
    />
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'

const props = defineProps({
  min: {
    type: Number,
    required: true,
    default: 0,
  },
  max: {
    type: Number,
    required: true,
    default: 100,
  },
  value: {
    type: Number,
    required: true,
    default: 0,
  },
  marks: {
    type: Array,
    required: false,
    default: () => [],
  },
  hideThumb: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits(['onInput'])

const onInput = e => emit('onInput', Number(e.target.value))

const getValuePercentage = val => {
  const percentage = ((val - props.min) / (props.max - props.min)) * 100
  return `${percentage - 4}%`
}

const inputRef = ref()

watchEffect(() => {
  if (inputRef.value?.style) {
    const threshold = ((props.value - props.min) / (props.max - props.min)) * 100
    inputRef.value.style.background = `linear-gradient(to right, #007cc2 0%, #007cc2 ${threshold}%, #f3f4f5 ${threshold}%, #f3f4f5 100%)`
  }
})
</script>

<style scoped>
#custom-slider {
  background: linear-gradient(to right, #007cc2 0%, #007cc2 50%, #f3f4f5 50%, #f3f4f5 100%);
  border: none;
  border-radius: 4px;
  height: 10px;
  width: 100%;
  outline: none;
  -webkit-appearance: none;
}
#custom-slider.hide-thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
}
</style>
