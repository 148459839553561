<template>
  <BaseCard no-padding class="tw-p-5">
    <MetricViewHeader
      :selectedTimeFrame="selectedTimeFrame"
      @onSelectTimeFrame="selectTimeFrame"
      :title="$t('vp_top_countries')"
      :titleTooltipLabel="$t('vp_top_countries_tooltip')"
    />
    <Notification :iconType="isWaitingMode ? 'info' : 'bulb'">
      {{
        isWaitingMode
          ? $t('vp_countries_table_empty_state')
          : $t('vp_top_country_tip', { TopCountry: mostPopularCountry })
      }}
    </Notification>
    <CountriesGraph
      class="tw-mt-9"
      :selectedTimeFrame="selectedTimeFrame"
      :isLoading="isVisitorsProfileCountriesLoading"
      :isError="isVisitorsProfileCountriesError"
      :visitorsProfileCountries="countriesWithoutZeros"
      :isWaitingMode="isWaitingMode"
    />
  </BaseCard>
  <BaseCard class="tw-mt-12" v-if="!isWaitingMode">
    <MetricViewHeaderTitle :tooltipLabel="$t('vp_all_countries_tooltip')">{{
      $t('vp_all_countries')
    }}</MetricViewHeaderTitle>
    <p class="tw-mt-2">
      {{ $t('vp_all_countries_table_info') }}
    </p>
    <CountriesTable
      class="tw-mt-9"
      :visitorsProfileCountries="countriesWithoutZeros"
      :selectedTimeFrame="selectedTimeFrame"
      :isLoading="isVisitorsProfileCountriesLoading"
      :isError="isVisitorsProfileCountriesError"
    />
  </BaseCard>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import MetricViewHeader from '@/components/visitorsProfile/common/MetricViewHeader.vue'
import Notification from '@/components/visitorsProfile/common/Notification.vue'
import CountriesGraph from '@/components/visitorsProfile/countries/CountriesGraph.vue'
import CountriesTable from '@/components/visitorsProfile/countries/CountriesTable.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import MetricViewHeaderTitle from '@/components/visitorsProfile/common/MetricViewHeaderTitle.vue'

import { useVisitorsProfileCountries } from '@/components/visitorsProfile/queries'
import { selectedAccommodationId } from '@/layouts/queries'

import appAnalytics from '@/utils/tracking'

const { t } = useI18n()
const selectedTimeFrame = ref('30')

const selectTimeFrame = timeFrame => {
  if (selectedTimeFrame.value !== timeFrame) {
    selectedTimeFrame.value = timeFrame
    appAnalytics.track(appAnalytics.events.VP_TIMEFRAME_CHANGED, {
      tabName: 'Countries',
      timeFrame: timeFrame,
      accommodationId: selectedAccommodationId.value,
    })
  }
}

const { isVisitorsProfileCountriesLoading, isVisitorsProfileCountriesError, visitorsProfileCountries } =
  useVisitorsProfileCountries(selectedAccommodationId, selectedTimeFrame)

const isWaitingMode = computed(() => {
  return (
    visitorsProfileCountries.value &&
    (visitorsProfileCountries.value.length === 0 || Object.keys(visitorsProfileCountries.value).length === 0)
  )
})

const countries = computed(() =>
  !isVisitorsProfileCountriesLoading.value || visitorsProfileCountries.value
    ? Object.keys(visitorsProfileCountries.value).map(key => {
        return {
          locale: t(`country_${key.toLowerCase()}`),
          share: visitorsProfileCountries.value[key],
        }
      })
    : []
)

const sortedCountries = computed(() => {
  const sorted = [...countries.value]?.sort(function (a, b) {
    if (a.share !== b.share) {
      return b.share - a.share
    }
    if (a.locale === b.locale) {
      return 0
    } else {
      return a.locale > b.locale ? 1 : -1
    }
  })
  return sorted
})

const countriesWithoutZeros = computed(() => {
  return sortedCountries.value.filter(item => item.share > 0)
})

const mostPopularCountry = computed(() => {
  return sortedCountries.value[0]?.locale
})
</script>
