<template>
  <div :id="containerId"></div>
</template>

<script setup>
import { watch, computed, nextTick } from 'vue'
import Highcharts from 'highcharts'
import { useStore } from 'vuex'
import * as Sentry from '@sentry/vue'

import { splineChartOptions } from './chartUtils'
import { convertDateToFormattedString } from '@/utils/Utility.js'

const store = useStore()
const props = defineProps({
  containerId: {
    type: String,
    required: true,
  },
  seriesData: {
    type: Array,
    default() {
      return []
    },
  },
  studioPlus: {
    type: Number,
    default: null,
  },
  isMobile: {
    type: Boolean,
    required: true,
  },
  impressions: {
    type: Boolean,
    default: false,
  },
  maxValue: {
    type: Number,
    required: false,
  },
})

const localization = computed(() => store.state.locale.language)

const drawChart = (containerId, data, studioPlus, isMobile, impressions, maxValue) => {
  if (!containerId || !data) return
  try {
    // without nextTick, highcharts may use the container before it exists in the DOM, it is important!
    nextTick(() => {
      // if for some reason the container is not yet mounted, go back.
      if (!document.getElementById(containerId)) return
      Highcharts.chart(containerId, {
        ...splineChartOptions,
        yAxis: {
          ...splineChartOptions.yAxis,
          // pass biggest value if impressions are turned off
          max: impressions ? null : maxValue,
        },
        xAxis: {
          visible: true,
          gridLineWidth: 1,
          tickLength: 0,
          ordinal: false,
          type: 'datetime',
          gridLineColor: '#C4E3F4',
          lineColor: '#C4E3F4',
          tickColor: '#C4E3F4',
          crosshair: {
            color: '#C4E3F4',
            dashStyle: 'solid',
          },
          labels: {
            useHTML: true,
            formatter: function () {
              const label = convertDateToFormattedString(new Date(this.value), localization.value, {
                month: 'short',
                day: '2-digit',
              })
              return `<span>${label}</span>`
            },
            style: {
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            },
            rotation: isMobile ? -45 : 0,
            align: 'center',
          },
          plotLines: [
            {
              color: '#000',
              width: 2,
              zIndex: 1,
              value: studioPlus,
              label: {
                useHTML: true,
                align: 'center',
                x: -7,
                y: 4,
                formatter: function () {
                  return `<div style="width: 0;height: 0;border-top: 5px solid transparent; border-bottom: 5px solid transparent; border-left: 10px solid #000;"/>`
                },
              },
            },
          ],
        },
        tooltip: {
          useHTML: true,
          hideDelay: 0,
          shared: true,

          formatter: function () {
            const html = ({ date, series }) => `<div>
                  <div>${date}</div>
                  ${series}
                </div>`

            const series = ({ name, value }) => `<div>
                  <span>${name}: </span><span>${value}</span>
                </div>`

            let aggregationType
            let renderedSeries = ''
            for (let i = 0; i < this.points.length; i++) {
              let point = this.points[i]
              aggregationType = point.series.userOptions.aggregationType
              if (point.series.userOptions.internalName === 'clickThroughRate') {
                renderedSeries += series({
                  name: localization.value === 'fr-FR' ? `${point.series.name} ` : `${point.series.name}`,
                  internalName: point.series.userOptions.internalName,
                  value:
                    localization.value === 'tr-TR'
                      ? `%${Intl.NumberFormat(localization.value).format(point.y).toString()}`
                      : `${Intl.NumberFormat(localization.value).format(point.y).toString()}%`,
                })
              } else {
                renderedSeries += series({
                  name: point.series.name,
                  internalName: point.series.userOptions.internalName,
                  value: Intl.NumberFormat(localization.value).format(point.y),
                })
              }
            }

            if (aggregationType === 'weekly') {
              const startDate = convertDateToFormattedString(new Date(this.x), localization.value)
              const endDate = convertDateToFormattedString(
                new Date(this.x + 6 * 24 * 60 * 60 * 1000),
                localization.value
              )
              return html({
                date: `${startDate} - ${endDate}`,
                series: renderedSeries,
              })
            } else {
              const date = convertDateToFormattedString(new Date(this.x), localization.value)
              return html({
                date,
                series: renderedSeries,
              })
            }
          },
        },
        series: data,
      })
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

watch(
  () => props,
  newProps => {
    if (newProps.seriesData?.length > 0 && newProps.containerId) {
      drawChart(
        newProps.containerId,
        newProps.seriesData,
        props.studioPlus,
        props.isMobile,
        props.impressions,
        props.maxValue
      )
    }
  },
  { deep: true, immediate: true }
)
watch(localization, (currentValue, oldValue) => {
  if (currentValue !== oldValue) {
    drawChart(props.containerId, props.data, props.studioPlus, props.isMobile, props.impressions, props.maxValue)
  }
})
</script>
