<template>
  <path d="M18.9343 9.03946L10.2764 17.6974" stroke="#0088D9" stroke-width="2" stroke-linecap="round" />
  <path d="M10.2763 17.6974L6.5658 13.9868" stroke="#0088D9" stroke-width="2" stroke-linecap="round" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.75 1C19.2397 1 24.5 6.26029 24.5 12.75C24.5 19.2397 19.2397 24.5 12.75 24.5C6.26029 24.5 1 19.2397 1 12.75C1 6.26029 6.26029 1 12.75 1Z"
    stroke="#0088D9"
    stroke-width="2"
    stroke-linecap="round"
  />
</template>
