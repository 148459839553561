<template>
  <div class="tw-text-sm tw-p-4">
    <h4 class="tw-font-bold tw-text-base">{{ $t('app_description_need_help') }}</h4>
    <p>{{ $t('app_description_guideline_header') }}</p>
    <p class="tw-my-4">
      <strong>{{ $t('app_description_guideline_title_1') }}</strong> {{ $t('app_description_guideline_content_1') }}
    </p>
    <p class="tw-mb-4">
      <strong>{{ $t('app_description_guideline_title_2') }}</strong> {{ $t('app_description_guideline_content_2') }}
    </p>
    <p class="tw-mb-4">
      <strong>{{ $t('app_description_guideline_title_3') }}</strong> {{ $t('app_description_guideline_content_3') }}
    </p>
    <p class="tw-mb-4">
      <strong>{{ $t('app_description_guideline_title_4') }}</strong> {{ $t('app_description_guideline_content_4') }}
    </p>
    <p class="tw-mb-4">
      <strong>{{ $t('app_description_guideline_title_5') }}</strong> {{ $t('app_description_guideline_content_5') }}
    </p>
  </div>
</template>
