<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1283 17.038C14.8274 15.8966 14.1614 14.8875 13.234 14.1677C12.3065 13.4479 11.1695 13.0576 9.99971 13.0576C8.82993 13.0576 7.69292 13.4479 6.76545 14.1677C5.83798 14.8875 5.17202 15.8966 4.87109 17.038"
      stroke="#0A1121"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19 10C19 -2 1 -2 1 10C1 22 19 22 19 10Z"
      stroke="#0A1121"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.99837 10.8111C11.9368 10.8111 13.0279 9.71076 13.0279 7.7534C13.0279 5.79605 11.9368 4.69434 9.99837 4.69434C8.05991 4.69434 6.97021 5.79605 6.97021 7.7534C6.97021 9.71076 8.05991 10.8111 9.99837 10.8111Z"
      stroke="#0A1121"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
