<template>
  <div>
    <PerformanceHeader />
    <BaseCard noPadding>
      <div class="tw-p-5 tw-pb-6">
        <PerformanceFilters
          :aggregationType="aggregationType"
          :startDate="startDate"
          :endDate="endDate"
          :periodType="periodType"
          :impressions="impressions"
          @changeAggregationType="changeAggregationType"
          @changeStartDate="changeStartDate"
          @changeEndDate="changeEndDate"
          @changeImpressions="changeImpressions"
          @changePeriodType="changePeriodType"
        />
        <PerformanceChartLoadingSkeleton v-show="isFetching" />
        <PerformanceChart
          v-if="!isFetching"
          :data="performanceDataFormattedForChart"
          :aggregation-type="aggregationType"
          :impressions="impressions"
          :studio-plus="studioPlusTimestamp"
          :is-mobile="isMobile"
        />
      </div>
      <div class="tw-p-5 tw-border-t-2">
        <PerformanceChartLegendsLoadingSkeleton v-if="isFetching" :studioPlusUser="studioPlusCampaign" />
        <PerformanceChartLegends v-else :studioPlusUser="studioPlusCampaign" />
      </div>
    </BaseCard>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

import PerformanceHeader from '@/components/profilePerformance/performance/PerformanceHeader.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import PerformanceFilters from '@/components/profilePerformance/performance/PerformanceFilters.vue'
import PerformanceChartLegends from './PerformanceChartLegends.vue'
import PerformanceChartLegendsLoadingSkeleton from '@/components/loadingSkeletons/PerformanceChartLegendsLoadingSkeleton.vue'
import PerformanceChart from './PerformanceChart.vue'
import PerformanceChartLoadingSkeleton from '@/components/loadingSkeletons/PerformanceChartLoadingSkeleton.vue'
import { selectedAccommodationId } from '@/layouts/queries'
import { useStudioPlusCampaign } from '@/components/payment/queries'
import { usePerformanceData } from '@/components/profilePerformance/queries/performance.js'

const ONE_DAY = 1
const NINETY_DAYS_PERIOD = 90
const NINETY_ONE_DAYS = NINETY_DAYS_PERIOD + ONE_DAY
const TODAY = new Date()

const dateToVueFormat = date => {
  return new Date(date).toISOString().slice(0, 10)
}
const aggregationType = ref('daily')
const periodType = ref(NINETY_DAYS_PERIOD)
const impressions = ref(true)
const startDate = ref(dateToVueFormat(new Date().setDate(TODAY.getDate() - NINETY_ONE_DAYS)))
const endDate = ref(dateToVueFormat(new Date().setDate(TODAY.getDate() - ONE_DAY)))

const changeImpressions = value => {
  impressions.value = value
}
const changeAggregationType = type => {
  aggregationType.value = type
}

const changeStartDate = newStartDate => {
  var date = new Date()
  date = date - 1000 * 60 * 60 * 24 * 2

  if (new Date(newStartDate.value).getTime() > new Date(date).getTime()) {
    startDate.value = new Date(date).toISOString().split('T')[0]
  } else {
    startDate.value = newStartDate
  }
}

const changeEndDate = newEndDate => {
  var date = new Date()
  date = date - 1000 * 60 * 60 * 24
  if (new Date(newEndDate).getTime() > new Date(date).getTime()) {
    endDate.value = new Date(date).toISOString().split('T')[0]
  } else {
    endDate.value = newEndDate
  }
}

const changePeriodType = type => {
  periodType.value = Number(type)
  // periodType 0 means it's custom dates, so no need to calculate start and end date, the user has to select it manually.
  if (periodType.value == 0) return
  const newStartDate = new Date()
  newStartDate.setDate(newStartDate.getDate() - (periodType.value + 1))
  startDate.value = dateToVueFormat(newStartDate)
  const newEndDate = new Date()
  newEndDate.setDate(newEndDate.getDate() - 1)
  endDate.value = dateToVueFormat(newEndDate)
}

const isMobile = document.body.clientWidth >= 500 ? ref(false) : ref(true)
const { studioPlusCampaign } = useStudioPlusCampaign(selectedAccommodationId)
const studioPlusTimestamp = computed(() => {
  const dayDate = studioPlusCampaign !== null ? studioPlusCampaign.value?.createdAt.split('@')[0] : null
  return studioPlusCampaign !== null ? Math.floor(new Date(dayDate).getTime()) : null
})

const { performanceDataFormattedForChart, isFetching } = usePerformanceData({
  accommodationId: selectedAccommodationId,
  aggregationType,
  startDate,
  endDate,
})
</script>
