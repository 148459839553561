<template>
  <g id="RC-FL---Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="RC-FL-Bidding-Goals-Enabled" transform="translate(-538.000000, -738.000000)">
      <g id="Group-13" transform="translate(96.000000, 294.000000)">
        <g id="info-24x24" transform="translate(442.000000, 444.000000)">
          <rect id="Rectangle-4-Copy-90" x="0" y="0" width="24" height="24"></rect>
          <path
            id="Combined-Shape"
            d="M12,19 C10.896,19 10,18.104 10,17 C10,15.896 10.896,15 12,15 C13.104,15 14,15.896 14,17 C14,18.104 13.104,19 12,19 M13,13 L11,13 C10.448,13 10,12.552 10,12 L10,6 C10,5.448 10.448,5 11,5 L13,5 C13.552,5 14,5.448 14,6 L14,12 C14,12.552 13.552,13 13,13"
            fill="#007CC2"
          ></path>
        </g>
      </g>
    </g>
  </g>
</template>
