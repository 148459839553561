<template>
  <button
    :type="type"
    :class="sizeClass"
    :data-testid="dataTestId"
    class="tw-text-center tw-cursor-pointer tw-transition-colors tw-duration-300"
    v-bind="$attrs"
  >
    <slot />
  </button>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  type: {
    type: String,
    default: 'button',
  },
  size: {
    type: String,
    default: 'big',
  },
  dataTestId: {
    type: String,
    default: '',
  },
})
const sizeClass = computed(() => {
  if (props.size === 'small') return 'tw-px-3 tw-py-0.5 tw-text-xs tw-rounded line-height-150'
  if (props.size === 'medium') return 'tw-px-4 tw-py-2 tw-text-sm tw-rounded-lg line-height-180'
  else return 'tw-px-6 tw-py-2 tw-text-base tw-rounded-lg line-height-260'
})
</script>

<style scoped>
button:disabled {
  pointer-events: none;
}
button:focus {
  @apply tw-outline-0;
}
.line-height-150 {
  line-height: 1.5;
}
.line-height-180 {
  line-height: 18px;
}
.line-height-260 {
  line-height: 26px;
}
</style>
