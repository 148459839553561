<template>
  <div class="tw-mx-4 lg:tw-mx-0">
    <div v-if="initialDataLoading" class="list-item tw-animate-pulse tw-bg-gray-300 tw-h-[658px]"></div>
    <div v-else-if="requiredDataHasErrors">
      <p class="tw-text-center tw-text-xl">{{ $t('onboarding.appLaunchError.header') }}</p>
    </div>
    <div v-else class="list-item tw-pt-6 tw-pb-8 tw-bg-white">
      <CampaignTypeContent class="tw-px-6" @toggleChangeCampaignModal="toggleChangeCampaignModal" />
      <div class="divider" />
      <ConnectivityProvider class="tw-px-6" />
      <div class="divider" />
      <CampaignStatus @openPauseModal="togglePauseCampaign" />
      <div class="divider" />
      <Billing class="tw-px-6" />
    </div>
    <FullScreenLoader v-show="requiredDataIsBeingRefetched" />
    <PauseModal
      :isOpen="isPauseCampaignOpen"
      @closeModal="togglePauseCampaign(isPauseCampaignOpen ? 'close' : 'open')"
      @toggleChangeCampaignModal="toggleChangeCampaignModal"
    />
    <ChangeCampaignModal ref="changeCampaignModalRef" />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

import ChangeCampaignModal from '@/components/rateConnect/settings/wizards/ChangeCampaignModal.vue'
import PauseModal from '@/components/rateConnect/pauseFlow/PauseModal.vue'
import CampaignTypeContent from '@/components/rateConnect/settings/CampaignTypeContent.vue'
import ConnectivityProvider from '@/components/rateConnect/settings/ConnectivityProvider.vue'
import CampaignStatus from '@/components/rateConnect/settings/CampaignStatus.vue'
import Billing from '@/components/rateConnect/settings/Billing.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'

import { useChangeCampaign } from '@/components/rateConnect/settings/wizards/useChangeCampaign'
import { useStartUp, useCampaigns, usePartners } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const changeCampaignModalRef = ref(null)
const isPauseCampaignOpen = ref(false)

const { source } = useChangeCampaign()

const toggleChangeCampaignModal = startSource => {
  source.value = startSource
  changeCampaignModalRef.value?.showModal()
}

const togglePauseCampaign = action => {
  if (action === 'open') {
    isPauseCampaignOpen.value = true
  }
  if (action === 'close') {
    isPauseCampaignOpen.value = false
  }
}

const {
  isLoading: isStartUpLoading,
  isFetching: isStartUpFetching,
  isError: isStartUpError,
} = useStartUp(selectedAccommodationId)
const {
  isLoading: isCampaignsLoading,
  isFetching: isCampaignsFetching,
  isError: isCampaignsError,
} = useCampaigns(selectedAccommodationId)
const {
  isLoading: isPartnersLoading,
  isFetching: isPartnersFetching,
  isError: isPartnersError,
} = usePartners(selectedAccommodationId)

const initialDataLoading = computed(() => isStartUpLoading.value || isCampaignsLoading.value || isPartnersLoading.value)
const requiredDataHasErrors = computed(() => isStartUpError.value || isCampaignsError.value || isPartnersError.value)
const requiredDataIsBeingRefetched = computed(
  () => !initialDataLoading.value && (isStartUpFetching.value || isCampaignsFetching.value || isPartnersFetching.value)
)
</script>
<style scoped>
.list-item {
  border-radius: 12px;
  box-shadow:
    0 2px 4px 0 rgba(63, 87, 189, 0.1),
    0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
.divider {
  height: 1px;
  width: 100%;
  background-color: theme('colors.gray.300');
}
</style>
