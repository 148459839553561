<template>
  <CustomModal
    :is-modal-open="isOpen"
    @toggleModal="close"
    overlay="dark"
    :with-header="true"
    :with-footer="true"
    :custom-classes="'tw-w-[100vw] md:tw-max-w-5xl md:tw-rounded-md tw-rounded-none tw-relative'"
  >
    <template #modalHeader>
      <p class="tw-text-xl tw-text-gray-700">
        {{ isNewLanguageDescription ? $t('app_description_create_title') : $t('app_description_edit_description') }}
      </p>
    </template>
    <div class="md:tw-flex">
      <div class="tw-flex tw-flex-col tw-w-full">
        <ExistingDescriptionModalHeader v-if="description" :description="description" class="tw-mb-4" />
        <NewDescriptionModalHeader
          v-else
          class="tw-mb-4"
          :language-selected="newLanguageSelected"
          @onLanguageSelectedChange="onLanguageSelectedChange"
        />
        <div v-if="description || (!description && newLanguageSelected)" class="tw-flex tw-flex-col tw-w-full">
          <label for="new-description" class="tw-font-bold tw-text-sm">{{
            $t('app_description_text_box_label')
          }}</label>
          <textarea
            id="new-description"
            :value="newDescription"
            @input="updateNewDescription"
            class="tw-text-sm tw-p-2 tw-border tw-border-gray-300 tw-h-80 tw-resize-none"
          />
          <div v-if="!isNewTextValid" class="tw-text-sm tw-text-gray-700 tw-mt-2">
            <span>
              {{
                isCountryValidatedByCharacters
                  ? $t('app_description_minimum_length_2')
                  : $t('app_description_minimum_length')
              }}
            </span>
            <span class="tw-inline-flex tw-relative tw-w-[100px] tw-h-2 tw-bg-gray-300 tw-rounded tw-ml-2">
              <span
                class="tw-absolute tw-top-0 tw-left-0 tw-h-full tw-rounded tw-bg-gray-800"
                :style="{ width: progressPercentage + '%' }"
              ></span>
            </span>
          </div>
        </div>
      </div>
      <EditDescriptionGuidelines />
    </div>

    <template #modalFooter>
      <div class="tw-w-full tw-flex tw-justify-end tw-gap-4">
        <GhostButton
          v-if="isNewLanguageDescription || (description && description.status === 'draft')"
          size="medium"
          :disabled="!isNewTextValid"
          @click="submitDescription('draft')"
          @keydown.enter="submitDescription('draft')"
        >
          {{ $t('app_description_save_draft') }}
        </GhostButton>
        <MainButton
          size="medium"
          :disabled="!isNewTextValid"
          @click="submitDescription('free')"
          @keydown.enter="submitDescription('free')"
          >{{ $t('app_description_publish_now') }}</MainButton
        >
      </div>
    </template>
    <FullScreenLoader v-show="isUpdatingPropertyDescription" />
  </CustomModal>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

import CustomModal from '@/components/base/CustomModal.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import ExistingDescriptionModalHeader from '@/components/propertyDetails/propertyDescription/ExistingDescriptionModalHeader.vue'
import NewDescriptionModalHeader from '@/components/propertyDetails/propertyDescription/NewDescriptionModalHeader.vue'
import EditDescriptionGuidelines from '@/components/propertyDetails/propertyDescription/EditDescriptionGuidelines.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import { usePropertyDescriptionMutation } from '@/components/propertyDetails/queries'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
    default: false,
  },
  close: {
    type: Function,
    required: false,
    default: () => {},
  },
  description: {
    type: Object,
    default: null,
  },
})

const emit = defineEmits(['onDescriptionSubmitSuccess'])

const { displayNotification } = useToastNotifications()
const { updatePropertyDescription, isUpdatingPropertyDescription } = usePropertyDescriptionMutation()

const minLength = 100
const minLengthByCharacters = 300
const validateByCharacters = ['AR', 'CN', 'JP', 'KO', 'TH', 'ZH']
const newDescription = ref(props.description?.text ?? '')
const newLanguageSelected = ref(null)

const isNewLanguageDescription = computed(() => {
  return props.description === null
})
const isCountryValidatedByCharacters = computed(() => {
  if (!props.description && newLanguageSelected.value)
    return validateByCharacters.includes(newLanguageSelected.value.toUpperCase())
  return validateByCharacters.includes(props.description.language_tag?.toUpperCase())
})
const numberOfCharacters = computed(() => {
  return newDescription.value?.replace(/\s/g, '').length ?? 0
})
const numberOfWords = computed(() => {
  return newDescription.value?.trim?.().split(/\s+/).length ?? 0
})
const descriptionLength = computed(() => {
  return isCountryValidatedByCharacters.value ? numberOfCharacters.value : numberOfWords.value
})
const progressPercentage = computed(() => {
  const min = isCountryValidatedByCharacters.value ? minLengthByCharacters : minLength
  const completion = (descriptionLength.value / min) * 100
  return completion < 0 ? 0 : completion
})
const isNewTextValid = computed(() => {
  return newDescription.value && newDescription.value.length && progressPercentage.value >= 100
})

const onLanguageSelectedChange = e => {
  newLanguageSelected.value = e
}
const updateNewDescription = newValue => {
  newDescription.value = newValue?.target?.value
}
const submitDescription = async (status = 'free') => {
  if (!isNewTextValid.value) return

  const language = props.description ? props.description?.language_tag : newLanguageSelected.value
  const body = {
    status,
    text: newDescription.value,
  }
  updatePropertyDescription(
    { accommodationId: selectedAccommodationId.value, language, body },
    {
      onSuccess: () => {
        emit('onDescriptionSubmitSuccess')
        newDescription.value = ''
      },
      onError: () => {
        displayNotification({
          message: 'save_error',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}

watch(
  () => props.description,
  newVal => {
    newDescription.value = newVal ? newVal?.text : ''
  }
)
</script>
