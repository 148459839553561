<template>
  <CustomModal
    class="remove-user-modal"
    :is-modal-open="props.isOpen"
    @toggleModal="props.closeModal"
    @onClickAway="props.closeModal"
    :overlay="'dark'"
    :with-header="true"
    :with-footer="true"
    :custom-classes="'tw-w-[100vw] md:tw-max-w-[400px] md:tw-w-[400px] tw-h-screen md:tw-h-auto md:tw-rounded-md tw-rounded-none'"
  >
    <template #modalHeader>
      <p class="tw-text-xl tw-text-gray-700">
        {{ isInvitationRemoval ? $t('invite_user_cancel_header') : $t('form.info.title_remove_user') }}
      </p>
    </template>

    <div class="tw-flex tw-flex-col tw-my-4" v-if="isInvitationRemoval">
      <p>{{ $t('invite_user_cancel_confirm_text') }}</p>
    </div>
    <div class="tw-flex tw-flex-col tw-my-4" v-else>
      <i18n-t tag="p" keypath="form.messages.confirm_remove">
        <template>
          <strong>{{ userEmail }}</strong>
        </template>
      </i18n-t>
    </div>

    <template #modalFooter>
      <div class="tw-w-full tw-flex tw-justify-end tw-gap-4">
        <GhostButton size="medium" class="tw-px-9" @click="closeModal">{{ $t('form.actions.cancel') }}</GhostButton>
        <DangerButton size="medium" class="tw-px-9" @click="remove">
          <span v-if="loading"><RCInlineLoader color="#fff" /></span>
          <span v-else>{{
            isInvitationRemoval ? $t('invite_user_cancel_cta') : $t('form.messages.default_confirm')
          }}</span>
        </DangerButton>
      </div>
    </template>
  </CustomModal>
</template>
<script setup>
import CustomModal from '@/components/base/CustomModal.vue'
import DangerButton from '@/components/base/buttons/DangerButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'

const props = defineProps({
  isOpen: Boolean,
  closeModal: Function,
  loading: Boolean,
  userEmail: String,
  isInvitationRemoval: Boolean,
})

const emit = defineEmits(['removeUser'])
const remove = () => emit('removeUser', props.userEmail)
</script>
