<template>
  <BaseCard>
    <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-text-gray-800">
      <div class="tw-flex tw-justify-between tw-w-full tw-flex-col">
        <div>
          <p class="tw-text-xl tw-font-bold">{{ $t('form.info.title_invite_user') }}</p>
        </div>
        <div
          class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-mt-4 tw-justify-between"
          :class="error ? 'tw-items-center' : 'tw-items-end'"
        >
          <div class="tw-flex">
            <BaseTextField
              :label="$t('form.labels.email_address')"
              :placeholder="$t('form.placeholder.email')"
              name="email"
              aria-label="email"
              type="email"
              class="lg:tw-w-[300px] tw-w-full lg:tw-mr-2 tw-mb-1 lg:tw-mb-auto"
              :error="error ? $t(error) : false"
              :value="emailValue"
              @onChange="e => setEmail(e)"
            />
            <BaseSelect
              id="'select-user-role'"
              :label="$t('form.labels.role')"
              class="lg:tw-w-[300px] tw-w-full lg:tw-mr-2 tw-mb-1 lg:tw-mb-auto"
              :value="userRole"
              :options="availableUserRoles"
              display-text-key="displayText"
              @onChange="e => setUserRole(e)"
            />
          </div>
          <MainButton size="medium" class="tw-w-full lg:tw-w-auto" @click="() => submitInvitation()">
            <span v-if="isSaving"><RCInlineLoader color="#fff" /></span>
            <span v-else>{{ $t('form.actions.invite') }}</span>
          </MainButton>
        </div>
        <div class="tw-flex tw-items-center tw-mt-6">
          <BaseIcon icon-name="information-outline" class="tw-fill-none tw-stroke-gray-800 tw-mr-1 tw-mt-[2px]">
            <InformationOutline />
          </BaseIcon>
          <p class="tw-text-sm">{{ $t('form.info.description.invite_user') }}</p>
        </div>
      </div>
    </div>
  </BaseCard>
</template>
<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import BaseIcon from '@/components/BaseIcon.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseTextField from '@/components/base/inputs/BaseTextField.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import BaseSelect from '@/components/BaseSelect.vue'

import { validate, required, emailAddress } from '@/utils/propertyDetailsHelpers.js'
import {
  useInviteUserMutation,
  useOrganisationUsers,
  useUsersInvitations,
} from '@/components/settings/teamSettings/queries'
import useToastNotifications from '@/components/notifications/useToastNotifications'

const store = useStore()
const { t } = useI18n()

const { inviteUser } = useInviteUserMutation()
const currentOrganisation = computed(() => store.state.session.selectedOrganisation)
const myOrganisationId = computed(() => (currentOrganisation.value ? currentOrganisation.value.id : false))
const { userOrganisationData } = useOrganisationUsers(myOrganisationId)
const { usersInvitations } = useUsersInvitations(myOrganisationId)

const error = ref(false)
const emailValue = ref('')
const userRole = ref('normal')

const availableUserRoles = computed(() => [
  {
    displayText: t('form.labels.user_role.admin'),
    value: 'admin',
  },
  { displayText: t('form.labels.user_role.normal'), value: 'normal' },
])
const isSaving = ref(false)

const setUserRole = role => {
  userRole.value = role
}
const langauge = computed(() => store.state.locale?.language)

const setEmail = inputValue => {
  const validationError = validate(inputValue, [required, emailAddress])
  error.value = validationError !== undefined ? validationError : false
  emailValue.value = inputValue
}

const isEmailAdded = () => {
  let isAdded = false
  userOrganisationData.value.forEach(item => {
    if (item.email === emailValue.value) {
      isAdded = true
    }
  })
  usersInvitations.value.forEach(item => {
    if (item.email === emailValue.value) {
      isAdded = true
    }
  })
  return isAdded
}
const { displayNotification } = useToastNotifications()
const submitInvitation = () => {
  const isEmail = isEmailAdded()
  if (isEmail) {
    emailValue.value = ''
    displayNotification({
      message: 'invite_user_error_notification',
      isTranslationKey: true,
      isHtml: false,
      type: 'error',
    })
    return
  }
  if (error.value) return

  // enables loader on the button
  isSaving.value = true

  inviteUser(
    {
      data: { invitedEmail: emailValue.value, language: langauge.value, role: userRole.value },
    },
    {
      onSuccess: () => {
        displayNotification({
          message: 'form.messages.success.invitation',
          isTranslationKey: true,
          isHtml: false,
          type: 'success',
        })
        isSaving.value = false
        emailValue.value = ''
      },
      onError: () => {
        isSaving.value = false
        displayNotification({
          message: 'form.messages.error.unknown',
          isTranslationKey: true,
          isHtml: false,
          type: 'error',
        })
      },
    }
  )
}
</script>
