<template>
  <g id="Top-Navigation-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="TN-Default-No-PRO" transform="translate(-1420.000000, -23.000000)">
      <g id="Group-2" transform="translate(1259.000000, 16.000000)">
        <g id="dots" transform="translate(161.041580, 7.018711)">
          <circle id="Oval" cx="1.995842" cy="1.995842" r="1.995842"></circle>
          <circle id="Oval-Copy-30" cx="1.995842" cy="8.98128898" r="1.995842"></circle>
          <circle id="Oval-Copy-31" cx="1.995842" cy="15.966736" r="1.995842"></circle>
        </g>
      </g>
    </g>
  </g>
</template>
