<template>
  <div :class="{ 'tw-min-h-[630px]': !isWaitingMode }">
    <div v-if="isLoading" class="tw-h-full tw-bg-gray-300 tw-animate-pulse tw-rounded-lg" />
    <div v-else-if="isError" class="tw-h-full" />
    <div v-else>
      <BarChart
        container="vp-countries-container"
        :series="dataFormattedForGraph"
        :categories="columnsFormattedForGraph"
        :isWaitingMode="isWaitingMode"
        :class="{ 'tw-h-[550px] tw-pb-4 tw-border-b-2 tw-border-b-gray-200': !isWaitingMode }"
      />
      <div class="tw-pl-4 tw-mt-9 tw-flex tw-items-center tw-gap-2" v-if="!isWaitingMode">
        <span class="tw-w-4 tw-h-4 tw-rounded-full tw-bg-graph-blue tw-inline-block" />
        <span>{{ $t('vp_countries_graph_info') }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'

import BarChart from '@/components/visitorsProfile/common/BarChart.vue'

import { selectedAccommodationId } from '@/layouts/queries'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const props = defineProps({
  selectedTimeFrame: {
    type: String,
    required: true,
    default: '30',
  },
  isLoading: Boolean,
  isError: Boolean,
  visitorsProfileCountries: Object,
  isWaitingMode: Boolean,
})

const columnsFormattedForGraph = computed(() => {
  if (!props.visitorsProfileCountries || !selectedAccommodationId.value || props.isWaitingMode)
    return [t('vp_country_1'), t('vp_country_2'), t('vp_country_3'), t('vp_country_4'), t('vp_country_5')]

  const graphData = props.visitorsProfileCountries?.slice(0, 5)

  const categories = graphData.map(item => item.locale)

  return categories
})

const dataFormattedForGraph = computed(() => {
  if (!props.visitorsProfileCountries || !selectedAccommodationId.value || props.isWaitingMode)
    return [{ data: [0, 0, 0, 0, 0] }]

  const graphData = props.visitorsProfileCountries?.slice(0, 5)

  const simplifiedSeries = graphData.map(item => item.share)
  return [{ data: simplifiedSeries }]
})
</script>
