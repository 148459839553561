import axios from 'axios'
import BaseService from './BaseService.js'

const countriesUrl = `${import.meta.env.VITE_COUNTRIES_API}`

class CountriesService extends BaseService {
  constructor() {
    super({
      baseUrl: countriesUrl,
    })
  }

  search({ searchTerm }) {
    return axios({
      url: `${countriesUrl}/resources/cities/UK`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
        'X-Requested-With': 'XMLHttpRequest',
      },
      params: { type: 'json', searchTerm },
    })
  }

  fetchCountriesCodes() {
    return axios({
      url: `${countriesUrl}/resources/countries/en`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
  }

  validatePhoneNumber({ locale, phone }) {
    return axios({
      url: `${countriesUrl}/validation/phone/${locale}/${phone}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }
}

export default new CountriesService()
