import { createApp } from 'vue'
import VueTippy from 'vue-tippy'
import Hotjar from 'vue-hotjar'
import * as Sentry from '@sentry/vue'
import { VueQueryPlugin, QueryClient } from '@tanstack/vue-query'
import App from './App.vue'
import router from './router'
import { initI18n } from './plugins/i18n'
import segment from './plugins/segment'
import axios from 'axios'

import clickOutside from './plugins/click-outside'
import { store } from './store'

import './assets/main.css'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

const app = createApp(App)
Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  publishRelease: import.meta.env.MODE === 'production',
  environment: import.meta.env.VITE_BRANCH_NAME || 'production',
  release: `studio-client::${import.meta.env.VITE_BRANCH_NAME || 'production'}::untagged`,
  tracesSampleRate: 1.0,
})

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

app.config.globalProperties.$axios = axios

app.use(router)
app.use(store)
app.use(initI18n())
app.use(segment)
app.use(VueTippy)
app.use(VueQueryPlugin, { queryClient })
app.use(Hotjar, {
  id: import.meta.env.VITE_HOTJAR_KEY,
  isProduction: import.meta.env.VITE_BRANCH_NAME === 'production',
})
app.directive('click-outside', clickOutside)
store.$router = router
store.$sentry = Sentry

app.mount('#app')
