<template>
  <section class="lg:tw-my-16 tw-mt-16 tw-mb-8">
    <div class="lg:tw-max-w-screen-landing md:tw-max-w-screen-md tw-px-8 lg:tw-px-0 tw-mx-auto tw-flex tw-flex-col">
      <div class="tw-items-center tw-mb-[53px] tw-hidden md:tw-flex">
        <img
          src="@/assets/img/landing/landing-bulb.svg"
          alt="trivago business studio hero image"
          class="lg:tw-h-[124px] lg:tw-w-[104px] md:tw-h-[100px] md:tw-w-[83px] md:tw-mb-[6px] tw-w-[50px] tw-h-[60px]"
        />
        <div class="tw-ml-6">
          <h1
            class="lg:tw-text-[44px] md:tw-text-[32px] lg:tw-leading-[44px] md:tw-leading-[32px] tw-font-bold tw-text-blue-800 tw-text-xl tw-mb-6"
            v-html="$t('tbs_mp_tbs_subtext')"
          />
          <p class="tw-text-black tw-text-sm md:tw-text-base lg:tw-text-2xl">{{ $t('tbs_mp_key_message') }}</p>
        </div>
      </div>
      <div class="tw-items-center tw-mb-[53px] md:tw-hidden tw-flex tw-flex-col">
        <div class="tw-flex tw-items-center tw-mb-6 tw-justify-start tw-mr-auto">
          <img
            src="@/assets/img/landing/landing-bulb.svg"
            alt="trivago business studio hero image"
            class="md:tw-mb-[6px] tw-w-[50px] tw-h-[50px] tw-mr-2"
          />
          <h1
            class="lg:tw-text-[44px] md:tw-text-[32px] tw-font-bold tw-text-blue-800 tw-text-xl tw-mb-[-4px]"
            v-html="$t('tbs_mp_tbs_subtext')"
          />
        </div>

        <p class="tw-text-black tw-text-sm md:tw-text-base lg:tw-text-2xl">{{ $t('tbs_mp_key_message') }}</p>
      </div>

      <div class="tw-hidden lg:tw-flex tw-flex-col md:tw-flex-row">
        <div
          class="tw-bg-white tw-rounded-xl tw-relative tw-w-[316px] tw-mx-auto first:tw-ml-0 last:tw-mr-0"
          v-for="(card, index) in cardsWeb"
          :key="index"
        >
          <span
            class="tw-absolute tw-px-2 tw-text-sm tw-text-white tw-font-bold tw-bg-red-800 tw-rounded-tl-xl tw-rounded-br-xl"
            >{{ $t('tbs_mp_free_tag').toUpperCase() }}</span
          >

          <div class="tw-p-5 tw-pt-8 tw-flex tw-flex-col tw-h-full">
            <p class="tw-font-bold tw-text-2xl tw-pb-6 tw-grow">{{ $t(card.title) }}</p>
            <div class="tw-flex tw-gap-4 tw-items-end">
              <img :src="card.image" :alt="$t(card.title)" height="93" width="109" class="tw-mx-auto" />
              <p class="tw-font-bold tw-text-sm">{{ $t(card.description) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile Version -->
    <div class="lg:tw-hidden tw-flex tw-flex-col md:tw-flex-row tw-w-auto">
      <transition-group class="carousel-mobile tw-w-vw tw-overflow-hidden" tag="div" name="swipe">
        <div
          class="tw-bg-white tw-min-w-[300px] xs:tw-min-w-[336px] tw-min-h-[100%] tw-mx-[15px] tw-rounded-xl tw-relative"
          v-for="(card, index) in cards"
          :key="`key_${index}`"
        >
          <span
            class="tw-absolute tw-px-2 tw-text-sm tw-text-white tw-font-bold tw-bg-red-800 tw-rounded-tl-xl tw-rounded-br-xl"
            >{{ $t('tbs_mp_free_tag').toUpperCase() }}</span
          >
          <div class="tw-p-6 tw-pt-8 tw-flex tw-flex-col tw-h-full">
            <p class="tw-font-bold tw-text-2xl tw-pb-6 tw-grow">{{ $t(card.title) }}</p>
            <div class="tw-flex tw-gap-4 tw-items-end">
              <img :src="card.image" :alt="$t(card.title)" class="tw-mx-auto md:tw-mb-[2px]" />

              <p class="tw-text-gray-800 tw-text-sm tw-flex-1">{{ $t(card.description) }}</p>
            </div>
          </div>
        </div>
      </transition-group>
    </div>

    <div
      class="lg:tw-hidden tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-full tw-mt-8 tw-max-w-screen-md tw-px-8 tw-mx-auto"
    >
      <button
        v-for="(_, index) in cards"
        :key="index"
        class="tw-w-[12px] tw-mx-[5px] tw-h-[12px] tw-rounded-full tw-mb-6"
        :class="current === index ? 'tw-bg-blue-800' : 'tw-bg-gray-300'"
      />
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

import TouchEvent from '@/utils/touchEvents.js'

const CardPropertyDetails = new URL('/src/assets/img/landing/landing_property_details.svg', import.meta.url)
const CardImageGallery = new URL('/src/assets/img/landing/landing_image_gallery.svg', import.meta.url)
const CardEssentialAnalytics = new URL('/src/assets/img/landing/landing_essential_analytics.svg', import.meta.url)

const cardsWeb = ref([
  {
    id: 'propertyDetails',
    title: 'tbs_mp_feature_1_header',
    description: 'tbs_mp_feature_1_subtext',
    image: CardPropertyDetails,
  },
  {
    id: 'imageGallery',
    title: 'tbs_mp_feature_2_header',
    description: 'tbs_mp_feature_2_subtext',
    image: CardImageGallery,
  },
  {
    id: 'essentialAnalytics',
    title: 'tbs_mp_feature_3_header',
    description: 'tbs_mp_feature_3_subtext',
    image: CardEssentialAnalytics,
  },
])
const cards = ref([
  {
    id: 'propertyDetails',
    title: 'tbs_mp_feature_1_header',
    description: 'tbs_mp_feature_1_subtext',
    image: CardPropertyDetails,
  },
  {
    id: 'imageGallery',
    title: 'tbs_mp_feature_2_header',
    description: 'tbs_mp_feature_2_subtext',
    image: CardImageGallery,
  },
  {
    id: 'essentialAnalytics',
    title: 'tbs_mp_feature_3_header',
    description: 'tbs_mp_feature_3_subtext',
    image: CardEssentialAnalytics,
  },
])

const touchEvent = ref(null)
const current = ref(1)
const timer = ref('')

const previous = () => {
  current.value = current.value === 0 ? 2 : current.value - 1
  const element = cards.value.pop()
  cards.value.unshift(element)
}

const next = () => {
  current.value = current.value === 2 ? 0 : current.value + 1
  const element = cards.value.shift()
  cards.value.push(element)
}

const autoplay = () => {
  next()
  timer.value = setTimeout(autoplay, 5000)
}

const handleSwipe = event => {
  if (!touchEvent.value) {
    return
  }

  touchEvent.value?.setEndEvent(event)
  if (touchEvent.value?.isSwipeRight()) {
    previous()
  } else if (touchEvent.value?.isSwipeLeft()) {
    next()
  }
  touchEvent.value = null
}

onMounted(() => {
  autoplay()
  window.addEventListener('touchstart', event => {
    touchEvent.value = new TouchEvent(event)
  })

  window.addEventListener('touchend', handleSwipe)
})

onUnmounted(() => {
  clearInterval(timer)
  window.removeEventListener('touchend', handleSwipe)
  window.removeEventListener('touchstart')
})
</script>
<style scoped>
.carousel-mobile {
  @apply tw-flex tw-justify-center tw-items-center tw-overflow-hidden;
  @media (max-width: theme('screens.md')) {
    width: auto;
  }
}
</style>
