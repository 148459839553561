<template>
  <div class="modal-dropdown">
    <button
      class="modal-dropdown-button tw-block tw-w-full tw-text-left tw-relative tw-text-sm tw-bg-blue-800 tw-text-white tw-p-3 tw-font-bold tw-rounded focus:tw-outline-none"
      @click="toggle"
      @keydown.enter="toggle"
    >
      {{ selected }}
      <div
        :class="{
          'down-triangle': !isOpen,
          'up-triangle': isOpen,
        }"
        class="tw-h-2 tw-w-2 tw-absolute tw-right-0 tw-top-0"
      />
    </button>
    <div v-show="isOpen">
      <slot :toggle="toggle" />
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'

export default {
  props: {
    selected: {
      type: String,
      required: true,
    },
  },
  setup() {
    const isOpen = ref(false)

    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    return {
      isOpen,
      toggle,
    }
  },
}
</script>
<style scoped>
.modal-dropdown-button::after {
  content: '';
  width: '20px';
  height: '20px';
}
.down-triangle {
  @apply tw-border-l-4;
  @apply tw-border-r-4;
  @apply tw-border-t-4;

  top: 22px;
  right: 16px;

  border-left-color: theme('colors.transparent');
  border-right-color: theme('colors.transparent');
  border-top-color: theme('colors.white');
}

.up-triangle {
  @apply tw-border-l-4;
  @apply tw-border-r-4;
  @apply tw-border-t-4;

  top: 16px;
  right: 16px;

  border-left-color: theme('colors.transparent');
  border-right-color: theme('colors.transparent');
  border-top-color: theme('colors.white');
  transform: rotate(180deg);
}
</style>
