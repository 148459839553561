<template>
  <CustomModal
    class="delete-property-image-modal"
    :is-modal-open="isOpen"
    @toggleModal="emit('closeModal')"
    @onClickAway="emit('closeModal')"
    :overlay="'dark'"
    :with-header="true"
    :with-footer="true"
    custom-classes="tw-h-screen md:tw-h-auto tw-w-[100vw] md:tw-max-w-[636px] md:tw-w-[636px] md:tw-rounded-md tw-rounded-none"
  >
    <template #modalHeader>
      <p class="tw-text-xl tw-text-gray-700">
        {{ selectedIds.length > 1 ? $t('app_images_delete_images') : $t('app_images_delete_image') }}
      </p>
    </template>

    <div class="tw-mt-2 tw-flex tw-py-11">
      {{
        selectedIds.length > 1
          ? $t('app_images_delete_images_confirm', { count: selectedIds.length })
          : $t('app_images_delete_image_confirm')
      }}
    </div>

    <template #modalFooter>
      <div class="tw-w-full tw-flex tw-justify-end">
        <GhostButton size="medium" class="tw-px-9 tw-mr-2" @click="emit('closeModal')">{{
          selectedIds.length > 1 ? $t('app_images_dont_delete_images') : $t('app_images_dont_delete_image')
        }}</GhostButton>
        <DangerButton size="medium" class="tw-px-9" @click="emit('deleteImages')">
          <span v-if="!isDeleting">{{
            selectedIds.length > 1 ? $t('app_images_yes_delete_images') : $t('app_images_yes_delete_image')
          }}</span>
          <span v-if="isDeleting">
            <RCInlineLoader color="#fff" />
          </span>
        </DangerButton>
      </div>
    </template>
  </CustomModal>
</template>

<script setup>
import CustomModal from '@/components/base/CustomModal.vue'
import DangerButton from '@/components/base/buttons/DangerButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'

const emit = defineEmits(['deleteImages', 'closeModal'])

defineProps({
  selectedIds: Array,
  isOpen: Boolean,
  isDeleting: Boolean,
})
</script>

<style scoped>
.delete-property-image-modal {
  z-index: 100;
}
</style>
