<template>
  <div>
    <div class="tw-pb-0">
      <AuthMenu pageName="rate-connect" isLanding @onRegisterClick="onAuthorize('login')" />
      <ProductPageHero pageName="rate-connect" class="tw-pt-16" @onLoginClick="onAuthorize('login')" />
      <RateConnectBenefits class="md:tw-my-4" />
      <RateConnectCampaign class="tw-bg-white" />
      <RateConnectGuide class="tw-bg-white" />
      <RateConnectStartCampaign class="tw-my-10 lg:tw-my-14" @onLoginClick="onAuthorize('login')" />
      <ProductPageFaq pageName="rate-connect" class="tw-bg-white" />
      <RateConnectReviews />
    </div>
    <ThePageFooter isLanding />
    <TheCookieNotice />
  </div>
</template>
<script setup>
import { ref, computed, onMounted, onUnmounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import appAnalytics from '@/utils/tracking'
import AuthMenu from '@/components/landingPage/AuthMenu.vue'
import ProductPageHero from '@/components/productPage/ProductPageHero.vue'
import RateConnectBenefits from '@/components/productPage/rateConnect/RateConnectBenefits.vue'
import RateConnectCampaign from '@/components/productPage/rateConnect/RateConnectCampaign.vue'
import RateConnectGuide from '@/components/productPage/rateConnect/RateConnectGuide.vue'
import RateConnectStartCampaign from '@/components/productPage/rateConnect/RateConnectStartCampaign.vue'
import ProductPageFaq from '@/components/productPage/ProductPageFaq.vue'
import RateConnectReviews from '@/components/productPage/rateConnect/RateConnectReviews.vue'

import ThePageFooter from '@/components/page/ThePageFooter.vue'
import TheCookieNotice from '@/components/legal/TheCookieNotice.vue'

const store = useStore()
const route = useRoute()

const windowWidth = ref(window.innerWidth)
const locale = computed(() => store.state.locale)
onMounted(() => {
  nextTick(() => {
    window.addEventListener('resize', onResize)
  })
})
onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})

const onResize = () => {
  windowWidth.value = window.innerWidth
}
const authorize = authData => store.dispatch('session/authorize', authData)
const onAuthorize = async (type = 'login') => {
  appAnalytics.track(type === 'login' ? appAnalytics.events.BUTTON_CLICKED : appAnalytics.events.BUTTON_CLICKED, {
    button: type,
    property_id: null,
  })
  await authorize({
    type,
    stateParams: {
      redirectFrom: route.query.redirectFrom,
    },
    locale: locale.value.language,
  })
}
</script>
