<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16 19H8C7.449 19 7 18.551 7 18C7 16.535 8.057 15.316 9.447 15.056C10.142 15.637 11.024 16 12 16C12.976 16 13.858 15.637 14.553 15.056C15.943 15.316 17 16.535 17 18C17 18.551 16.551 19 16 19ZM9 12C9 10.346 10.346 9 12 9C13.654 9 15 10.346 15 12C15 12.781 14.693 13.486 14.203 14.021C13.654 14.618 12.874 15 12 15C11.126 15 10.346 14.618 9.797 14.021C9.307 13.486 9 12.781 9 12ZM15.314 14.239C15.747 13.6 16 12.83 16 12C16 9.791 14.209 8 12 8C9.791 8 8 9.791 8 12C8 12.83 8.253 13.6 8.686 14.239C7.125 14.785 6 16.254 6 18C6 19.104 6.896 20 8 20H16C17.104 20 18 19.104 18 18C18 16.254 16.875 14.785 15.314 14.239Z"
    fill="#0A1121"
  />
  <mask id="mask0_3406_243" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="13" y="5" width="9" height="10">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2614 5H22.0001V15H13.2614V5Z" fill="white" />
  </mask>
  <g mask="url(#mask0_3406_243)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.6144 10.064C19.8534 9.593 20.0004 9.066 20.0004 8.5C20.0004 6.567 18.4334 5 16.5004 5C15.0344 5 13.7834 5.902 13.2614 7.181C13.5884 7.266 13.8994 7.382 14.1964 7.529C14.5764 6.631 15.4664 6 16.5004 6C17.8784 6 19.0004 7.122 19.0004 8.5C19.0004 9.066 18.8034 9.583 18.4844 10C18.1014 10.501 17.5394 10.858 16.8884 10.96V10.961C16.9564 11.28 16.9914 11.611 16.9954 11.95C17.7404 11.844 18.4014 11.496 18.9194 11H19.0004C20.1024 11 21.0004 11.897 21.0004 13C21.0004 13.551 20.5514 14 20.0004 14H16.9694C17.3514 14.284 17.6874 14.621 17.9744 15H20.0004C21.1044 15 22.0004 14.105 22.0004 13C22.0004 11.554 20.9764 10.348 19.6144 10.064Z"
      fill="#0A1121"
    />
  </g>
  <mask id="mask1_3406_243" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="2" y="5" width="9" height="10">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 5H10.7387V15H2V5Z" fill="white" />
  </mask>
  <g mask="url(#mask1_3406_243)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.38576 10.064C4.14676 9.593 3.99976 9.066 3.99976 8.5C3.99976 6.567 5.56676 5 7.49976 5C8.96576 5 10.2168 5.902 10.7388 7.181C10.4118 7.266 10.1008 7.382 9.80376 7.529C9.42376 6.631 8.53376 6 7.49976 6C6.12176 6 4.99976 7.122 4.99976 8.5C4.99976 9.066 5.19676 9.583 5.51576 10C5.89876 10.501 6.46076 10.858 7.11176 10.96V10.961C7.04376 11.28 7.00876 11.611 7.00476 11.95C6.25976 11.844 5.59876 11.496 5.08076 11H4.99976C3.89776 11 2.99976 11.897 2.99976 13C2.99976 13.551 3.44876 14 3.99976 14H7.03076C6.64876 14.284 6.31276 14.621 6.02576 15H3.99976C2.89576 15 1.99976 14.105 1.99976 13C1.99976 11.554 3.02376 10.348 4.38576 10.064Z"
      fill="#0A1121"
    />
  </g>
</template>
