<template>
  <section class="tw-max-w-4xl tw-py-8 tw-px-4 tw-text-justify tw-mx-auto">
    <h1
      class="tw-border-b tw-border-gray-300 tw-pb-5 tw-mb-8 tw-text-xl tw-font-bold tw-text-center"
      v-html="$t('privacy_policy.heading')"
    ></h1>
    <p v-html="$t('privacy_policy.content')"></p>
    <p v-html="$t('privacy_policy.policies')"></p>
    <p v-if="$t('privacy_policy.last_updated')" v-html="$t('privacy_policy.last_updated')"></p>
  </section>
</template>

<script></script>

<style scoped>
section :deep(p) {
  margin-bottom: 16px;
}
section :deep(a) {
  color: #007bff;
}
section :deep(h3) {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}
</style>
