<template>
  <div class="stepper-grid md:tw-max-w-2xl tw-mx-auto">
    <button
      class="step-circle-1 tw-relative tw-w-full tw-flex tw-justify-center"
      :disabled="currentStep === 1 || currentStep === 3"
      @click="stepAction(1)"
    >
      <div
        class="number number-1 tw-flex tw-justify-center tw-items-center tw-w-6 tw-h-6"
        :class="currentStep >= 1 ? 'tw-bg-blue-700' : 'tw-bg-white'"
      >
        <span class="tw-text-sm tw-font-bold" :class="currentStep >= 1 ? 'tw-text-white' : 'tw-text-blue-800'">1</span>
      </div>
    </button>
    <p class="step-name-1 tw-text-gray-800 tw-text-xs">{{ $t('rc_setup_stepper_campaign_type') }}</p>

    <button class="step-circle-2 tw-relative tw-w-full tw-flex tw-justify-center" @click="stepAction(2)">
      <div
        class="number number-2 tw-flex tw-justify-center tw-items-center tw-w-6 tw-h-6"
        :class="currentStep >= 2 ? 'tw-bg-blue-700' : 'tw-bg-white'"
      >
        <span class="tw-text-sm tw-font-bold" :class="currentStep >= 2 ? 'tw-text-white' : 'tw-text-blue-800'">2</span>
      </div>
    </button>
    <p class="step-name-2 tw-text-gray-800 tw-text-xs">{{ $t('rc_setup_stepper_commission') }}</p>

    <button
      class="step-circle-3 tw-relative tw-w-full tw-flex tw-justify-center"
      :disabled="currentStep === 3 || currentStep === 1"
      @click="stepAction(3)"
    >
      <div
        class="number number-3 tw-flex tw-justify-center tw-items-center tw-w-6 tw-h-6"
        :class="currentStep >= 3 ? 'tw-bg-blue-700' : 'tw-bg-white'"
      >
        <span class="tw-text-sm tw-font-bold" :class="currentStep >= 3 ? 'tw-text-white' : 'tw-text-blue-800'">3</span>
      </div>
    </button>
    <p class="step-name-3 tw-text-gray-800 tw-text-xs">{{ $t('rc_setup_stepper_billing') }}</p>
  </div>
</template>
<script setup>
const props = defineProps({
  currentStep: {
    type: Number,
    default: 1,
  },
})
const emit = defineEmits(['onNext', 'onBack'])

const stepAction = step => {
  if (props.currentStep > step) emit('onBack')
  if (props.currentStep < step) emit('onNext')
}
</script>
<style scoped>
.stepper-grid {
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 0.5fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'step-circle-1 gray-line-1 step-circle-2 gray-line-2 step-circle-3'
    'step-name-1 . step-name-2 . step-name-3';
  align-items: center;
  justify-items: center;
}
.number {
  border-radius: 100%;
  width: 24px;
  height: 24px;
  box-shadow: 0 2px 4px 0 rgba(63, 87, 189, 0.1);
}
p {
  margin-top: 5px;
}
.step-circle-1 {
  grid-area: step-circle-1;
}
.step-circle-2 {
  grid-area: step-circle-2;
}
.step-circle-3 {
  grid-area: step-circle-3;
}
.step-name-1 {
  grid-area: step-name-1;
  text-wrap: nowrap;
}
.step-name-2 {
  grid-area: step-name-2;
  text-wrap: nowrap;
}
.step-name-3 {
  grid-area: step-name-3;
  text-wrap: nowrap;
}
.gray-line-1 {
  grid-area: gray-line-1;
}
.gray-line-2 {
  grid-area: gray-line-2;
}
.number-1::after,
.number-2::after {
  content: '';
  position: absolute;
  width: 75%;
  height: 1px;
  top: 50%;
  left: calc(50% + 20px);
  z-index: -1;
  background-color: theme('colors.blue.300');
}
.number-2::before,
.number-3::before {
  content: '';
  position: absolute;
  width: 75%;
  height: 1px;
  top: 50%;
  right: calc(50% + 20px);
  overflow: hidden;
  z-index: -1;
  background-color: theme('colors.blue.300');
}
.gray-line {
  background-color: theme('colors.blue.300');
  height: 1px;
  width: 100%;
}
</style>
