<template>
  <div>
    <FreeBookingLinkHeader />
    <div class="kpi-grid">
      <BaseCard class="tw-min-h-[260px] tw-flex tw-flex-col tw-gap-4">
        <div>
          <p class="tw-font-bold tw-mb-4">{{ $t('fbl_status') }}</p>
          <div class="tw-flex tw-items-center">
            <span
              aria-hidden="true"
              class="tw-h-3 tw-w-3 tw-rounded-full tw-mr-2"
              :class="hasFreeLinks ? 'tw-bg-green-700' : 'tw-bg-orange-700'"
            ></span>
            <p class="tw-text-sm">{{ hasFreeLinks ? $t('fbl_active') : $t('fbl_pending') }}</p>
          </div>
        </div>
        <div v-if="directRatesPartnerId" class="tw-mb-5">
          <p class="tw-mb-2">{{ $t('fbl_connectivity_provider') }}</p>
          <img
            :src="`https://imgcy.trivago.com/e_trim,f_auto,q_auto/partnerlogos-s3/${directRatesPartnerId}`"
            alt="partner logo"
            height="30"
            class="partner-logo"
          />
        </div>
        <FreeBookingLinkActivateButton v-if="!hasFreeLinks && optInEligible" />
      </BaseCard>
      <BaseCard class="tw-min-h-[260px]">
        <div>
          <p class="tw-mb-2 tw-font-bold">{{ $t('fbl_clicks_header') }}</p>
          <ConversionFunnelKPIValue />
          <p class="tw-text-sm tw-text-gray-700">{{ $t('fbl_clicks_info') }}</p>
          <img
            src="@/assets/img/rate-connect/no-data.svg"
            alt="No data available"
            class="tw-my-4 tw-mx-auto no-data-img"
            height="78"
          />
          <hr class="tw-h-px tw-bg-blue-800" />
          <p class="tw-text-sm tw-text-gray-700">{{ $t('fbl_data_message') }}</p>
        </div>
      </BaseCard>
      <BaseCard v-if="hasFreeLinks" class="kpi-bookings-section">
        <p class="tw-mb-2 tw-font-bold">{{ $t('fbl_bookings_header') }}</p>
        <ConversionFunnelKPIValue />
        <p class="tw-text-sm tw-text-gray-700">{{ $t('fbl_bookings_info') }}</p>
        <img
          src="@/assets/img/rate-connect/no-data.svg"
          alt="No data available"
          class="tw-my-4 tw-mx-auto no-data-img"
          height="78"
        />
        <hr class="tw-h-px tw-bg-blue-800" />
        <p class="tw-text-sm tw-text-gray-700">{{ $t('fbl_data_message') }}</p>
      </BaseCard>
      <BaseCard v-else class="tw-py-7 tw-flex tw-flex-row">
        <div class="tw-flex tw-flex-col tw-gap-4 tw-w-full md:tw-w-3/5">
          <h3 class="tw-text-xl tw-leading-[21px]" v-html="$t('activate_booking_link_header')" />
          <img class="tw-flex md:tw-hidden tw-max-w-[250px] tw-m-auto" :src="imageURL" :alt="$t('fbl_learn_more')" />
          <div>
            <p
              v-for="(text, index) in freeLinksBenefits"
              :key="index"
              class="tw-text-gray-800 tw-leading-[27px] tw-text-xs"
              v-html="$t(text)"
            />
          </div>
          <GhostButton class="tw-self-start" size="big" @click="redirectToFaq">{{ $t('fbl_learn_more') }}</GhostButton>
        </div>
        <div class="tw-hidden md:tw-flex tw-w-2/5 tw-my-auto">
          <img :src="imageURL" :alt="$t('fbl_learn_more')" class="tw-w-fit" />
        </div>
      </BaseCard>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import FreeBookingLinkHeader from './FreeBookingLinkHeader.vue'
import FreeBookingLinkActivateButton from './FreeBookingLinkActivateButton.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import ConversionFunnelKPIValue from '@/components/profilePerformance/conversionFunnel/ConversionFunnelKPIValue.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import useRateConnect from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const freeLinksBenefits = ref(['fbl_benefit_1', 'fbl_benefit_2', 'fbl_benefit_3', 'fbl_benefit_4'])
const supportUrls = ref({
  EN: 'https://trivago.my.site.com/supportcenter/s/topic/0TO69000000wxjGGAQ/free-booking-links?language=en_US',
  BR: 'https://trivago.my.site.com/supportcenter/s/topic/0TO69000000wxjGGAQ/free-booking-links?language=pt_BR',
  DE: 'https://trivago.my.site.com/supportcenter/s/topic/0TO69000000wxjGGAQ/free-booking-links?language=de',
  ES: 'https://trivago.my.site.com/supportcenter/s/topic/0TO69000000wxjGGAQ/free-booking-links?language=es',
  FR: 'https://trivago.my.site.com/supportcenter/s/topic/0TO69000000wxjGGAQ/free-booking-links?language=fr',
  GR: 'https://trivago.my.site.com/supportcenter/s/topic/0TO69000000wxjGGAQ/free-booking-links?language=el',
  IT: 'https://trivago.my.site.com/supportcenter/s/topic/0TO69000000wxjGGAQ/free-booking-links?language=it',
  JP: 'https://trivago.my.site.com/supportcenter/s/topic/0TO69000000wxjGGAQ/free-booking-links?language=ja',
  MX: 'https://trivago.my.site.com/supportcenter/s/topic/0TO69000000wxjGGAQ/free-booking-links?language=es_MX',
  NL: 'https://trivago.my.site.com/supportcenter/s/topic/0TO69000000wxjGGAQ/free-booking-links?language=nl_NL',
  PL: 'https://trivago.my.site.com/supportcenter/s/topic/0TO69000000wxjGGAQ/free-booking-links?language=pl',
  PT: 'https://trivago.my.site.com/supportcenter/s/topic/0TO69000000wxjGGAQ/free-booking-links?language=pt_PT',
  RU: 'https://trivago.my.site.com/supportcenter/s/topic/0TO69000000wxjGGAQ/free-booking-links?language=ru',
  TR: 'https://trivago.my.site.com/supportcenter/s/topic/0TO69000000wxjGGAQ/free-booking-links?language=tr',
})
const images = ref({
  EN: new URL(`@/assets/img/free-booking-links/FBL_EN.png`, import.meta.url),
  BR: new URL(`@/assets/img/free-booking-links/FBL_BR.png`, import.meta.url),
  DE: new URL(`@/assets/img/free-booking-links/FBL_DE.png`, import.meta.url),
  ES: new URL(`@/assets/img/free-booking-links/FBL_ES.png`, import.meta.url),
  FR: new URL(`@/assets/img/free-booking-links/FBL_FR.png`, import.meta.url),
  GR: new URL(`@/assets/img/free-booking-links/FBL_GR.png`, import.meta.url),
  IT: new URL(`@/assets/img/free-booking-links/FBL_IT.png`, import.meta.url),
  MX: new URL(`@/assets/img/free-booking-links/FBL_MX.png`, import.meta.url),
  PL: new URL(`@/assets/img/free-booking-links/FBL_PL.png`, import.meta.url),
  PT: new URL(`@/assets/img/free-booking-links/FBL_PT.png`, import.meta.url),
  TR: new URL(`@/assets/img/free-booking-links/FBL_TR.png`, import.meta.url),
})

const { hotelierData } = useRateConnect(selectedAccommodationId)
const directRatesPartnerId = computed(() => hotelierData?.value?.directRatesPartner)
const hasFreeLinks = computed(() => hotelierData?.value?.hasFreeLinks)
const optInEligible = computed(() => hotelierData?.value?.optInEligible)
const locale = computed(() => store.state.locale.language)
const imageURL = computed(() => {
  const skippedLanguages = ['JP', 'RU', 'NL']
  const country = locale.value.split('-')[1].toUpperCase() || 'EN'
  if (skippedLanguages.includes(country) || country === 'GB' || country === 'US') {
    return images.value['EN']
  } else {
    return images.value[country]
  }
})

const redirectToFaq = () => {
  const country = locale.value.split('-')[1].toUpperCase() || 'EN'
  if (country === 'GB' || country === 'US') {
    return window.open(supportUrls.value['EN'], '_blank')
  } else {
    return window.open(supportUrls.value[country], '_blank')
  }
}
</script>

<style scoped>
.kpi-grid {
  display: grid;
  grid-template-columns: 1.1fr 1.1fr 2fr;
  gap: 1rem;
}

@media (max-width: theme('screens.md')) {
  .kpi-grid {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: theme('screens.lg')) {
  .kpi-grid {
    grid-template-columns: 1fr 1fr;
  }
  .kpi-grid div:last-child {
    grid-column: 1/3;
  }
}

.no-data-img {
  height: 78px !important;
}

.partner-logo {
  height: 30px;
}
</style>
