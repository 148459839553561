const DATE_LOCALE_OPTIONS = {
  month: 'long',
  day: '2-digit',
}

const LOCALE = localStorage.getItem('stdio_locale_language') || 'en-US'

export function convertDateToFormattedString(date, locale = LOCALE, options = DATE_LOCALE_OPTIONS) {
  return date instanceof Date ? date.toLocaleDateString(locale, options) : ''
}

export const areaChartOptions = {
  chart: {
    backgroundColor: null,
    type: 'area',
    height: '100px',
    margin: 0,
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  title: {
    text: null,
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    visible: false,
  },
}

export const splineChartOptions = {
  chart: {
    backgroundColor: null,
    type: 'spline',
    plotBorderWidth: 0,
    alignTicks: false,
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    title: false,
    gridLineWidth: 1,
    tickLength: 0,
    gridLineColor: '#C4E3F4',
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    spline: {
      marker: {
        symbol: 'circle',
        radius: 4,
        lineWidth: 1,
      },
    },
  },
}
