<template>
  <svg id="Artwork" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1415.1164 400.0157">
    <defs></defs>
    <rect class="cls-1" x="418.3797" y="92.9301" width="67.7905" height="205.2202" rx="3.1903" ry="3.1903" />
    <path
      class="cls-2"
      d="m660.4436,94.9992l-40.4143,107.6497c-.2325.6194-1.1082.6206-1.3425.002l-40.7762-107.6603c-.4697-1.2401-1.6575-2.0603-2.9835-2.0603h-67.1437c-2.2643,0-3.8079,2.293-2.9559,4.3908l80.7572,198.8395c.4886,1.203,1.6575,1.9898,2.9559,1.9898h59.0518c1.2966,0,2.4642-.7847,2.954-1.9853l81.1154-198.8395c.8561-2.0985-.6876-4.3954-2.954-4.3954h-65.2773c-1.3294,0-2.5195.8244-2.9868,2.069Z"
    />
    <path
      class="cls-3"
      d="m1304.356,89.8176c-64.0841,0-110.7604,45.1944-110.7604,105.9458s46.6764,105.9437,110.7604,105.9437c63.3446,0,110.7604-45.5641,110.7604-105.9437,0-60.7514-47.0461-105.9458-110.7604-105.9458Zm0,154.1021c-25.1883,0-41.8587-19.2646-41.8587-48.1562s16.6703-48.1583,41.8587-48.1583c24.8186,0,41.8587,19.2625,41.8587,48.1583,0,28.524-17.0401,48.1562-41.8587,48.1562Z"
    />
    <path
      class="cls-1"
      d="m395.9274,91.945c-1.73-.6338-4.001-1.1395-6.4216-1.5169-3.9331-.6133-45.5889-5.2999-68.7709,25.7915-.4108.5509-1.2868.2569-1.2868-.4304v-16.2989s-.0003.0001-.0003.0001h.0003v-3.37c0-1.762-1.4284-3.1904-3.1904-3.1904h-61.4108c-1.762,0-3.1904,1.4284-3.1904,3.1904v198.8395c0,1.762,1.4284,3.1904,3.1904,3.1904h61.4109c1.762,0,3.1904-1.4284,3.1904-3.1904v-73.8284c0-55.1953,25.558-68.9246,50.7484-68.9246,2.9641,0,11.5797-.017,23.75,3.3523,2.0256.5608,4.0316-.9663,4.0316-3.068v-57.558c0-1.3251-.8065-2.5324-2.0507-2.9882Z"
    />
    <path
      class="cls-1"
      d="m452.275,0c-21.4164,0-38.7784,16.7347-38.7784,37.3764s17.362,37.3785,38.7784,37.3785,38.7784-16.7347,38.7784-37.3785S473.6914,0,452.275,0Z"
    />
    <path
      class="cls-2"
      d="m941.8061,92.9301h-61.4104c-1.762,0-3.1904,1.4284-3.1904,3.1904v19.554c0,.7649-1.0294.9951-1.3588.3048-22.1721-46.4623-153.5257-37.4304-153.5257,79.5484,0,109.363,112.224,128.8398,153.6304,83.0698.4443-.4912,1.2568-.1898,1.2568.4725v15.8899c0,1.762,1.4283,3.1903,3.1903,3.1903h61.4078c1.762,0,3.1903-1.4283,3.1903-3.1903V96.1204c0-1.762-1.4283-3.1903-3.1903-3.1903Zm-107.1631,150.7664c-27.0493,0-45.9026-18.8533-45.9026-47.9507s18.8533-48.3639,45.9026-48.3639c27.4597,0,45.9016,19.6737,45.9016,48.3639s-18.4419,47.9507-45.9016,47.9507Z"
    />
    <path
      class="cls-3"
      d="m1173.7137,92.9301h-61.4103c-1.762,0-3.1904,1.4284-3.1904,3.1904v19.5516c-.0001.7654-1.0324.9955-1.3599.3037-22.2718-47.046-144.5961-35.2478-144.5961,73.6386,0,106.7526,111.5045,115.5382,144.755,84.4827.4554-.4253,1.1968-.0947,1.1968.5284v9.6085h-.0074c-.8436,45.366-57.6729,64.9961-86.5455,30.541-1.0338-1.2336-2.8223-1.5085-4.1737-.6343l-51.7483,33.4783c-1.5516,1.0038-1.9197,3.1044-.8284,4.5957,66.0321,90.236,210.027,44.3441,211.0933-67.773h.0052V96.1205c0-1.762-1.4284-3.1904-3.1904-3.1904Zm-101.2772,140.0219c-24.4489,0-41.4889-17.0402-41.4889-43.3375s17.04-43.7134,41.4889-43.7134c24.8186,0,41.4868,17.7815,41.4868,43.7134s-16.6682,43.3375-41.4868,43.3375Z"
    />
    <path
      class="cls-1"
      d="m230.3047,185.8392h-61.4946c-1.6359,0-3.0456,1.3377-3.1738,2.9686-1.9853,25.2469-23.0869,45.1171-48.8428,45.1171-30.9024,0-43.3981-26.2411-43.4004-26.2454-5.3323-10.0485-5.6118-19.7601-5.6056-21.8602.0008-.2599.1431-.4927.3706-.6184l17.5953-9.7228h0l111.1797-61.4839c1.6934-.9363,2.7441-2.7185,2.7441-4.6531V43.2476c0-2.4296-2.6076-3.9675-4.7339-2.7919l-126.0962,69.7209c-.478.2644-1.0645-.0815-1.0645-.6279V5.6555c0-2.5289-2.8019-4.0525-4.9246-2.6779L2.4268,42.1133c-1.5132.98-2.4268,2.66-2.4268,4.4628v139.2653c.499,64.0769,52.6001,115.8657,116.7935,115.8657,63.0732,0,114.4609-49.9995,116.708-112.5251.0655-1.8212-1.3744-3.3428-3.1968-3.3428Z"
    />
  </svg>
</template>
<style scoped>
.cls-1 {
  fill: #e32851;
}
.cls-1,
.cls-2,
.cls-3 {
  stroke-width: 0px;
}
.cls-2 {
  fill: #ff932c;
}
.cls-3 {
  fill: #0088d9;
}
</style>
