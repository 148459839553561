export const sepaCountries = [
  {
    country_code: 'AUT',
  },
  {
    country_code: 'AND',
  },
  {
    country_code: 'BEL',
  },
  {
    country_code: 'BGR',
  },
  {
    country_code: 'HRV',
  },
  {
    country_code: 'CYP',
  },
  {
    country_code: 'CZE',
  },
  {
    country_code: 'DNK',
  },
  {
    country_code: 'EST',
  },
  {
    country_code: 'FIN',
  },
  {
    country_code: 'FRA',
  },
  {
    country_code: 'DEU',
  },
  {
    country_code: 'GIB',
  },
  {
    country_code: 'GRC',
  },
  {
    country_code: 'HUN',
  },
  {
    country_code: 'IRL',
  },
  {
    country_code: 'ISL',
  },
  {
    country_code: 'ITA',
  },
  {
    country_code: 'LIE',
  },
  {
    country_code: 'LVA',
  },
  {
    country_code: 'LTU',
  },
  {
    country_code: 'LUX',
  },
  {
    country_code: 'MCO',
  },
  {
    country_code: 'MLT',
  },
  {
    country_code: 'NLD',
  },
  {
    country_code: 'NOR',
  },
  {
    country_code: 'POL',
  },
  {
    country_code: 'PRT',
  },
  {
    country_code: 'ROU',
  },
  {
    country_code: 'SVK',
  },
  {
    country_code: 'SMR',
  },
  {
    country_code: 'SVN',
  },
  {
    country_code: 'SWE',
  },
  {
    country_code: 'CHE',
  },
  {
    country_code: 'GBR',
  },
  {
    country_code: 'VAT',
  },
  {
    country_code: 'ESP',
  },
]
