<template>
  <div class="tw-relative tw-h-full sml:tw-h-[208px] tw-w-full sml:tw-w-[231px] tw-mx-1">
    <Checkbox
      v-if="!isMainImage && customProperties.isDeletable"
      :name="`select${id}`"
      label=""
      :value="isSelected"
      class="image-checkbox"
      @onChange="$emit('selectItem', $event, id)"
    />
    <div v-if="!isMainImage && customProperties.isLowQuality" class="tw-absolute tw-left-[10px] tw-bottom-[10px]">
      <UiTooltip tooltip-position="top" :label="$t('app_images_low_quality_hint')" class="tw-w-6">
        <BaseIcon icon-name="excalamation-mark" :height="24" :width="24">
          <WarningTriangle />
        </BaseIcon>
      </UiTooltip>
    </div>
    <div v-if="!isMainImage" class="tw-absolute tw-right-[15px] tw-bottom-[10px]">
      <div class="hover:tw-cursor-pointer" @keydown.enter="emit('toggleMenu', id)" @click="emit('toggleMenu', id)">
        <BaseIcon icon-name="chevron-right" :height="24" :width="24" class="tw-rotate-90 tw-fill-white">
          <ChevronRight />
        </BaseIcon>
      </div>
      <BaseCard v-if="openId === id" no-padding class="tw-z-10 tw-absolute tw-right-[-15px] tw-w-[231px]">
        <div class="tw-w-full tw-w-flex tw-flex-row">
          <ul>
            <li
              v-if="customProperties.isMainImageable"
              class="tw-p-2 hover:tw-cursor-pointer hover:tw-bg-blue-200 tw-rounded-t-xl"
              @keydown.enter="() => mainImage(id)"
              @click="() => mainImage(id)"
            >
              {{ $t('app_images_set_as_main_image') }}
            </li>
            <hr />
            <li
              v-if="customProperties.isDeletable"
              class="tw-p-2 hover:tw-cursor-pointer hover:tw-bg-blue-200 tw-rounded-b-xl"
              @click="() => openDeleteModal(id)"
              @keydown.enter="() => openDeleteModal(id)"
            >
              {{ $t('delete') }}
            </li>
          </ul>
        </div>
      </BaseCard>
    </div>
    <div v-if="isMainImage" class="main-image-badge tw-text-sm tw-text-white tw-absolute tw-top-[10px] tw-right-[10px]">
      {{ $t('app_images_main_image') }}
    </div>
    <div
      v-if="isUpdating === id || (deleteItemId.length > 0 && deleteItemId.includes(id))"
      class="loader-container tw-absolute tw-top-0 tw-bottom-0"
    >
      <LaunchpadLoader class="tw-min-h-[208px] tw-flex tw-items-center tw-justify-center" />
    </div>
    <div @click="() => openImagesGallery(id)" @keydown.enter="() => openImagesGallery(id)">
      <img
        referrerPolicy="strict-origin-when-cross-origin"
        :src="imageUrl"
        alt="hotel-image"
        class="hover:tw-cursor-zoom-in tw-rounded-xl"
      />
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Checkbox from '@/components/base/inputs/Checkbox.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import WarningTriangle from '@/components/icons/WarningTriangle.vue'
import { useSetMainImage } from '@/components/propertyDetails/queries/index.js'
import { usePropertyImages } from '@/components/propertyDetails/propertyImages/usePropertyImages.js'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'

const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { isUpdating, setUpdatedImage, setDeletingIds, deleteItemId } = usePropertyImages()
const store = useStore()
const emit = defineEmits(['toggleDeleteModal', 'openImageGallery', 'setOpenImage', 'toggleMenu'])
const props = defineProps({
  images: Array,
  openId: {
    type: [Number, String, null],
    default: 0,
  },
  id: {
    type: [String, Number],
    default: '',
  },
  imageUrl: {
    type: String,
    default: '',
  },
  image: {
    Object,
    required: true,
  },
  isMainImage: {
    type: Boolean,
    default: false,
  },
  context: {
    type: String,
    default: '',
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
  customProperties: {
    type: Object,
    default: () => {},
  },
})

const { setMainImage } = useSetMainImage()

const { displayNotification } = useToastNotifications()

const mainImage = imageId => {
  setUpdatedImage(imageId)
  setMainImage(
    {
      accommodationId: selectedAccommodationId.value,
      imageId,
      imagePath: props.image?.imagePath,
      organisationId: selectedOrganisationId.value,
    },
    {
      onSuccess: data => {
        if (data.status === 200) {
          setUpdatedImage(false)
          displayNotification({
            message: 'app_images_successfully_set_main_image',
            isTranslationKey: true,
            isHtml: false,
            type: 'success',
          })
          emit('toggleMenu', imageId)
        }
      },
      onError: () => {
        setUpdatedImage(false)
        displayNotification({
          message: 'something_went_wrong',
          isTranslationKey: true,
          isHtml: false,
          type: 'error',
        })
      },
    }
  )
}
const openDeleteModal = imageId => {
  emit('toggleDeleteModal')
  emit('toggleMenu', imageId)
  setDeletingIds(imageId)
}
const openImagesGallery = imageId => {
  const mainImage = props.images.find(item => item.imageId === imageId)
  emit('openImageGallery')
  emit('setOpenImage', mainImage)
}
</script>
<style scoped>
.loader-container {
  width: 231px;
  background-color: rgba(255, 255, 255, 0.8);
}
.main-image-badge {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  padding: 3px 7px;
}
.image-box {
  @apply tw-rounded-xl;
  background-size: cover;
  background-position: center;
  box-sizing: content-box;
}
.image-checkbox {
  position: absolute;
  top: 15px;
  left: 15px;
}
</style>
