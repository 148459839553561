import { computed, ref } from 'vue'

const currentStep = ref(1)
const selectedCampaignType = ref(null)
const selectedCommissionLevel = ref(null)
const selectedBudget = ref(0)
const selectedNewPartner = ref(null)
const source = ref(null)

export function useChangeCampaign() {
  const isSelectedCampaignTypeCPA = computed(() => {
    if (!selectedCampaignType.value) return null
    return selectedCampaignType.value === 'netCpa' || selectedCampaignType.value === 'cpa'
  })

  const resetFlow = () => {
    currentStep.value = 1
    selectedCampaignType.value = null
    selectedCommissionLevel.value = null
    selectedBudget.value = 0
    source.value = null
    selectedNewPartner.value = null
  }

  return {
    currentStep,
    selectedCampaignType,
    isSelectedCampaignTypeCPA,
    selectedCommissionLevel,
    selectedBudget,
    selectedNewPartner,
    source,
    resetFlow,
  }
}
