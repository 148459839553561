<template>
  <BaseCard
    :class="selected ? 'tw-bg-blue-100' : 'tw-bg-white'"
    class="md:tw-flex md:tw-items-center md:tw-gap-2 tw-border tw-rounded-xl tw-p-5"
  >
    <div class="tw-flex tw-items-center tw-grow">
      <img :src="organisationLogo" alt="organisation icon" />
      <div class="tw-ml-4 tw-grow tw-flex tw-flex-col tw-overflow-hidden">
        <p class="tw-text-sm tw-font-bold">{{ organisationDetails.name }}</p>
        <span class="tw-text-gray-700 tw-text-xs tw-whitespace-nowrap tw-text-ellipsis">{{
          organisationDetails.id
        }}</span>
      </div>
    </div>
    <div v-if="!selected" class="tw-mt-6 md:tw-mt-0 md:tw-flex">
      <GhostButton
        class="tw-w-full tw-mb-3 md:tw-mb-0 md:tw-mr-4 md:tw-min-w-[120px]"
        size="medium"
        @click.stop="emit('onOrganisationRemoveClick', organisationDetails)"
        >{{ $t('pages.organizationSwitcher.cta.leave') }}</GhostButton
      >
      <MainButton
        size="medium"
        class="tw-w-full md:tw-min-w-[120px]"
        @click.stop="emit('onOrganisationSelectClick', organisationDetails)"
      >
        {{ $t('pages.organizationSwitcher.cta.select') }}
      </MainButton>
    </div>
  </BaseCard>
</template>

<script setup>
import { computed } from 'vue'

import MainButton from '@/components/base/buttons/MainButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'

const props = defineProps({
  organisationDetails: {
    required: true,
    type: Object,
  },
  selected: {
    required: false,
    type: Boolean,
  },
})

const emit = defineEmits(['onOrganisationSelectClick', 'onOrganisationRemoveClick'])

const organisationLogo = computed(() =>
  !props.selected ? '/home/organization-logo.svg' : '/home/organization-logo-selected.svg'
)
</script>
