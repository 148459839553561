import { reactive, toRefs } from 'vue'

const state = reactive({
  allFilters: '',
})

export function useRateInsights() {
  const setFilters = filters => {
    state.allFilters = filters
  }

  return {
    ...toRefs(state),

    setFilters,
  }
}
