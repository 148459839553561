// Validation
export const latinChars = value => (value && /[a-zA-Z-9]/g.test(value) === false ? 'error_latin_chars' : false)

export const noSpecialChars = value => {
  const pattern = new RegExp('[?~[\\]%(){}]', 'g')
  return value && pattern.test(value) ? 'error_special_chars' : false
}

export const maximal4CapitalizedLetters = value => {
  return value && /[A-Z]{5,}/g.test(value) ? 'capital_letter_alert' : false
}

export const required = value => (value === null || value.length === 0 ? 'error_field_required' : false)

export const emailAddress = value => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
  return value && pattern.test(value) ? false : 'error_invalid_email'
}

export const minLength = value => (value && (value.length < 7 || value.length > 150) ? 'error_url_value_length' : false)

export const url = value => {
  var pattern = new RegExp(
    '^' + // protocol identifier
      '(?:(?:https?)://)?' +
      '(?:' + // IP address exclusion
      // private & local networks
      '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
      '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
      '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' + // IP address dotted notation octets
      // excludes loopback network 0.0.0.0
      // excludes reserved space >= 224.0.0.0
      // excludes network & broacast addresses
      // (first & last IP address of each class)
      '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
      '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
      '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
      '|' + // host name
      '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' + // domain name
      '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*' + // TLD identifier
      '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))' +
      ')' + // port number
      '(?::\\d{2,5})?' + // resource path
      '(?:[/?#]\\S*)?' +
      '$',
    'i'
  )

  return value && !pattern.test(value) ? 'err_11' : false
}

export const validate = (value, fns = []) => {
  for (let i = 0; i < fns.length; i++) {
    const fn = fns[i]
    const error = fn(value)
    if (error !== false && error.length > 0) {
      return error
    }
  }
}

export function defaultCosts(currency = 'EUR', timeUnit = 'hour') {
  return {
    amount: 1,
    currency,
    timeUnit,
    timeAmount: 1,
  }
}

// Images
const MAIN_IMAGE = 'MAIN_IMAGE'
const HOTELIER = 'HOTELIER'
const PARTNER = 'PARTNER'

export const CONTEXT_TYPES = {
  MAIN_IMAGE,
  HOTELIER,
  PARTNER,
}

const ACTIVE = 'ACTIVE'
const UPLOADING = 'UPLOADING'
const POLLING = 'POLLING'

export const STATUS = {
  ACTIVE,
  UPLOADING,
  POLLING,
}

export function prepareNewImagesCollection(items = []) {
  const mainImage = items.find(item => item.isMainImage)
  const mainImageId = mainImage?.imageId.split('-').pop()
  items = items.filter(element => element.imageId !== mainImageId)
  items = items.map(item => ({
    ...item,
    customProperties: {
      isMainImageable: true,
      isDeletable: true,
      isLowQuality: item.width < 700 || item.height < 700,
    },
  }))

  return items
}

export function getNewMainImage(items = []) {
  return items.filter(item => item.isMainImage)
}

export function generateGUID() {
  var d = new Date().getTime()
  var d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16
    if (d > 0) {
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export function getItemsByIds(ids = [], items = []) {
  return items.filter(o => ids.indexOf(o.id) !== -1)
}
