<template>
  <div>
    <div class="tw-relative tw-overflow-x-auto tw-w-full">
      <table class="tw-w-full tw-text-sm tw-text-right tw-text-gray-800">
        <thead class="tw-hidden md:tw-table-header-group tw-text-xs tw-text-gray-700 tw-bg-white">
          <tr class="tw-border-b-1 tw-px-6 tw-py-5">
            <th
              v-for="column in newColumns"
              :key="column.id"
              scope="col"
              class="tw-px-6 tw-py-6 tw-relative tw-border-r-0 tw-border-l-0 tw-text-right table-header tw-p"
              :class="{ 'tw-text-blue-800': column.id === kpiHighlight }"
              @click="sortTable(column.id)"
              @keydown.enter="sortTable(column.id)"
            >
              <UiTooltip :label="getHint(column)" tooltip-position="top" theme="customlightwithpadding">
                <span class="tw-flex tw-items-center tw-relative tw-justify-end">
                  {{ $t(column.header) }}
                  <div v-if="column.header.length > 1 && column.sortable" class="tw-relative">
                    <BaseIcon
                      :height="10"
                      :width="11"
                      class="tw-ml-2 tw-text-gray-100"
                      icon-name="table-sort-arrows"
                      :icon-color="
                        sortColumn === column.id ? (column.id === kpiHighlight ? '#69A9E4' : '#AFB3BC') : '#AFB3BC'
                      "
                    >
                      <TableSortArrows />
                    </BaseIcon>
                    <BaseIcon
                      :height="4"
                      :width="11"
                      class="tw-ml-2"
                      icon-name="table-sort-arrow-up"
                      :icon-color="
                        sortColumn === column.id ? (column.id === kpiHighlight ? '#C4E3F4' : '#007CC2') : '#007CC2'
                      "
                      :class="!ascending ? 'tw-rotate-180 tw-absolute bottom-sort-arrow' : 'tw-absolute top-sort-arrow'"
                    >
                      <TableSortArrowUp />
                    </BaseIcon>
                  </div>
                </span>
              </UiTooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(row, index) in getData()" :key="index">
            <tr
              class="tw-text-gray-800 tw-bg-white table-row tw-px-6 tw-py-5"
              :class="{
                'expanded-table-row': index === expandRowIndex,
                'mobile-full-view': expandMobileElement == index,
                'extra-info-row': row.extraInfo !== undefined && haveExtraInfo,
              }"
              @click="toggleExpandRow(index, row)"
              @keydown.enter="toggleExpandRow(index, row)"
            >
              <td class="md:tw-hidden tw-w-full tw-py-2.5 tw-px-4 tw-border-0">
                <div class="tw-w-full tw-flex tw-justify-between">
                  <span
                    v-for="col in mobileHeaderColumns"
                    :key="col.id"
                    :class="{ 'tw-items-end': col.id === 'status' }"
                    class="tw-flex tw-flex-col tw-items-start"
                  >
                    <p class="tw-font-bold tw-uppercase">{{ $t(col.header) }}</p>
                    <span
                      class="tw-font-normal"
                      :class="col.id === 'status' ? `${row[col.id]}-badge badge` : 'tw-text-blue-800 tw-font'"
                    >
                      {{ col.id === 'bookingId' ? row[col.id] : $t(bookingStatusTranslationsMap[row[col.id]].status) }}
                    </span>
                  </span>
                </div>
              </td>
              <td
                v-for="column in newColumns"
                :key="column.id"
                scope="row"
                class="cell tw-px-6 tw-py-4 tw-font-medium tw-whitespace-nowrap tw-border-0 tw-relative"
                :class="getClass(column.id, row, column.className)"
              >
                <div>
                  <UiTooltip
                    v-if="column.id === 'bookingId'"
                    :label="row['bookingId'] !== null && row['bookingId'].length > 10 ? row['bookingId'] : null"
                    tooltip-position="top"
                    theme="customlightwithpadding"
                  >
                    <span class="tw-flex tw-justify-end">
                      <p class="md:tw-hidden tw-font-bold">{{ $t(column.header) }}</p>
                      <span class="badge">
                        {{ row[column.id] ? trim(row[column.id], 20) : '-' }}
                      </span>
                    </span>
                  </UiTooltip>
                  <UiTooltip
                    v-else-if="column.id === 'status'"
                    :label="$t(bookingStatusTranslationsMap[row[column.id]].tooltip)"
                    tooltip-position="top"
                    class="tw-hidden md:tw-flex"
                    theme="customlightwithpadding"
                  >
                    <span class="badge" :class="`${row[column.id]}-badge`">
                      {{ $t(bookingStatusTranslationsMap[row[column.id]].status) }}
                    </span>
                    <div v-if="haveExtraInfo && row.extraInfo" class="tw-flex">
                      <div
                        class="tw-absolute tw-top-3 tw-mt-1 tw-ml-2 tw-border tw-border-gray-500 tw-rounded-md focus:tw-outline-none tw-w-[27px] tw-h-[27px]"
                      >
                        <BaseIcon
                          :height="26"
                          :width="23"
                          icon-name="button-arrow"
                          class="tw-mt-[2px]"
                          :class="{ 'tw-rotate-180': expandRowIndex !== index }"
                        >
                          <ButtonArrow />
                        </BaseIcon>
                      </div>
                    </div>
                  </UiTooltip>
                  <UiTooltip
                    v-else-if="column.id === 'market'"
                    :label="$t(`locale_${row[column.id]}`)"
                    tooltip-position="top"
                    theme="customlightwithpadding"
                  >
                    <span v-if="column.id === 'market'">
                      <p class="md:tw-hidden">{{ $t(column.header) }}</p>
                      <p v-if="row[column.id] !== '**'" class="tw-flex tw-items-center tw-justify-end">
                        <span
                          class="flag tw-w-[20px] tw-h-[12px] tw-mr-1 flagImage"
                          :class="`flag-${row[column.id].toLowerCase()}`"
                        />
                        <span>{{ $t(`locale_${row[column.id]}`) }}</span>
                      </p>
                      <p v-else>{{ $t('rc_bookings_list_unavailable') }}</p>
                    </span>
                  </UiTooltip>
                  <UiTooltip
                    v-else-if="column.id === 'bookingValue.amountCampaignCurrency'"
                    :label="`${$t('rc_bookings_list_original_value', {
                      amount: `${(getRowValue('bookingValue.localCurrency', row), 'currency')}${$n(
                        getRowValue('bookingValue.amountLocalCurrency', row),
                        'currency'
                      )},
                      `,
                    })}`"
                    theme="customlightwithpadding"
                    tooltip-position="top"
                  >
                    <span
                      ><p class="md:tw-hidden">{{ $t(column.header) }}</p>
                      {{ formatTableCell(getRowValue(column.id, row), column.colType) }}</span
                    >
                  </UiTooltip>
                  <UiTooltip
                    v-else-if="column.id === 'bookingValue.commissionCost'"
                    theme="customlightwithpadding"
                    :label="`${$t('rc_list_bookings_commission', {
                      value: formatTableCell(row['cpaLevel'], 'percentage'),
                    })}
                      `"
                    tooltip-position="top"
                  >
                    <span>
                      <p class="md:tw-hidden">{{ $t(column.header) }}</p>
                      {{ formatTableCell(getRowValue(column.id, row), column.colType) }}</span
                    >
                  </UiTooltip>
                  <UiTooltip v-else theme="customlightwithpadding" tooltip-position="top">
                    <span v-if="column.id === 'stayDate'">
                      <p class="md:tw-hidden">{{ $t(column.header) }}</p>
                      {{ formatTableCell(getRowValue('stayDate.arrivalDate', row), 'dateshort') }}
                      {{ '-' }}
                      {{ formatTableCell(getRowValue('stayDate.departureDate', row), 'dateshort') }}
                    </span>
                    <span v-else
                      ><p class="md:tw-hidden">{{ $t(column.header) }}</p>
                      {{ formatTableCell(getRowValue(column.id, row), column.colType) }}
                    </span>
                  </UiTooltip>
                </div>
              </td>
            </tr>
            <tr
              v-if="expandRowIndex === index"
              :key="`key_${index}`"
              class="hover:tw-bg-blue-100 tw-bg-blue-100 bottom-shadow"
            >
              <td :colspan="newColumns.length" class="tw-border-0">
                <div class="tw-flex tw-flex-row tw-w-full tw-justify-end">
                  <div v-for="col in additionalInfoColumns" :key="col.id" class="tw-ml-2">
                    <span class="tw-font-bold">{{ $t(col.header) }}: </span>
                    <span>{{ formatTableCell(row[col.id], col.colType) }}</span>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div class="table-footer tw-flex tw-justify-between tw-text-sm tw-items-center tw-px-6 tw-py-5">
      <div />
      <MainButtonOutline
        size="small"
        :disabled="loadedElements === tableRows.length"
        @click="loadMore()"
        @keydown.enter="loadMore()"
      >
        {{ $t('show_more') }}
      </MainButtonOutline>
      <div />
    </div>
  </div>
</template>
<script setup>
import { computed, ref, onMounted, nextTick, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'

import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import MainButtonOutline from '@/components/base/buttons/MainButtonOutline.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import TableSortArrows from '@/components/icons/TableSortArrows.vue'
import TableSortArrowUp from '@/components/icons/TableSortArrowUp.vue'
import ButtonArrow from '@/components/icons/ButtonArrow.vue'
import { CURRENCIES } from '@/constants/currencies.js'

const { t, n } = useI18n()
const props = defineProps({
  columns: {
    type: Array,
    default: () => [],
  },
  data: {
    type: Array,
    default: () => [],
  },
  haveExtraInfo: {
    type: Boolean,
    default: false,
  },
  currency: {
    type: String,
    required: true,
    default: '',
  },
  language: {
    type: String,
    required: true,
    default: '',
  },
})

const kpiHighlight = ref('bookingDate')
const windowWidth = ref(window.innerWidth)
const loadedElements = ref(props.data?.length < 6 ? props.data?.length : 6)
const ascending = ref(false)
const sortColumn = ref('bookingDate')
const additionalInfoColumns = ref(props.columns.filter(column => column.additional))
const tableRows = ref(props.data)
const expandRowIndex = ref(null)
const expandMobileElement = ref(null)
const bookingStatusTranslationsMap = ref({
  completed: {
    tooltip: 'rc_completed_stay_tooltip_pay_per_stay',
    status: 'rc_completed_stay_pay_per_stay',
  },
  cancelled: {
    tooltip: 'rc_canceled_booking_tooltip_pay_per_stay',
    status: 'rc_canceled_booking_pay_per_stay',
  },
  confirmed: {
    tooltip: 'rc_confirmed_booking_tooltip_pay_per_stay',
    status: 'rc_confirmed_booking_pay_per_stay',
  },
})

const newColumns = computed(() => {
  return windowWidth.value > 768
    ? props.columns.filter(column => !column.mobileHeader)
    : props.columns.filter(column => !column.additional)
})
const mobileHeaderColumns = computed(() => props.columns.filter(column => column.mobileHeader))
const currencySymbol = computed(() => CURRENCIES[props.currency])

onMounted(() => {
  nextTick(() => {
    window.addEventListener('resize', onResize)
  })
})

onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})

const onResize = () => {
  windowWidth.value = window.innerWidth
}
const trim = (value, trimLength) => {
  const shouldTrim = value && value.length > trimLength
  return shouldTrim ? value.substring(0, trimLength - 3) + '...' : value
}
const getRowValue = (key, row) => {
  if (key.includes('.')) {
    return key.split('.').reduce((acc, item) => {
      return acc[item]
    }, row)
  } else {
    return row[key] === undefined ? 0 : row[key]
  }
}

const getClass = (columnId, row, className) => {
  let classes = [`${'bookingLog'}_${className}`]
  if (columnId === kpiHighlight.value) {
    classes.push('md:tw-text-blue-700')
  }
  if (row.extraInfo !== undefined && props.haveExtraInfo) {
    classes.push('extra-info')
  }
  return classes.toString().replaceAll(',', ' ')
}

const getData = () => {
  return tableRows.value.slice(0, loadedElements.value)
}
const loadMore = () => {
  if (tableRows.value.length > loadedElements.value) {
    loadedElements.value = loadedElements.value + 10
    getData()
  }
}
const formatTableCell = (value, type) => {
  if (type === 'percentage') {
    return n(value / 100, 'percent')
  } else if (type === 'number') {
    return n(value, 'decimal')
  } else if (type === 'currency') {
    return `${currencySymbol.value}${n(value, 'currency')}`
  } else if (type === 'date' && value !== undefined) {
    value = value === 0 ? '-' : new Date(value).toLocaleString(props.language, { timeZone: 'UTC' })
    return value
  } else if (type === 'dateshort' && value !== undefined) {
    return value === null ? '' : value
  } else {
    return value
  }
}
const sortTable = col => {
  if (sortColumn.value === col) {
    ascending.value = !ascending.value
  } else {
    ascending.value = true
    sortColumn.value = col
  }

  var ascendingVar = ascending.value

  tableRows.value.sort(function (a, b) {
    if (col === 'date') {
      if (a[col].start > b[col].start) {
        return ascendingVar ? 1 : -1
      } else if (a[col].start < b[col].start) {
        return ascendingVar ? -1 : 1
      }
    } else {
      if (a[col] > b[col]) {
        return ascendingVar ? 1 : -1
      } else if (a[col] < b[col]) {
        return ascendingVar ? -1 : 1
      }
    }
    return 0
  })
}
const toggleExpandRow = (rowIndex, row) => {
  if (row.extraInfo && props.haveExtraInfo) {
    if (expandRowIndex.value === rowIndex) {
      expandRowIndex.value = null
    } else {
      expandRowIndex.value = rowIndex
    }
  }
}
const getHint = column => {
  return column?.hint === undefined ? '' : t(column.hint)
}
</script>
<style scoped>
table thead th,
table tbody td {
  font-size: 14px;
}
table thead th:last-child {
  padding-right: 45px;
  @media (max-width: theme('screens.md')) {
    padding-right: 16px;
  }
}
.table-header:hover {
  cursor: pointer;
}
.icon-fill {
  fill: theme('colors.blue.800');
}
.table-footer {
  display: grid;
  grid-template-columns: 4fr 1fr 4fr;
  gap: 1rem;
  @media (max-width: theme('screens.md')) {
    display: flex;
    justify-content: center;
  }
}
.top-sort-arrow {
  right: 0;
  top: -1px;
}
.table-row:hover {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
  transform: scale(1);
  cursor: pointer;
  @media (max-width: theme('screens.md')) {
    box-shadow: none;
  }
}
.bottom-sort-arrow {
  right: 0;
  top: 6px;
}
.table-row {
  @media (max-width: theme('screens.md')) {
    flex: 1 0 auto;
    box-sizing: border-box;
    flex-wrap: wrap;
    margin-bottom: 8px;
    padding-bottom: 16px;
    display: flex;
    padding-left: 0;
    padding-right: 0;
  }
}
.bottom-shadow {
  box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.22);
  transform: scale(1);
  @apply tw-pr-11;
}
.table-row td:first-child {
  @media (max-width: theme('screens.md')) {
    padding-left: 16px;
    background-color: theme('colors.blue.100');
    font-weight: bold;
  }
}
.expanded-table-row {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
}
.cell span {
  @media (max-width: theme('screens.md')) {
    display: flex;
    width: auto;
    justify-content: space-between;
  }
}
.badge {
  @apply tw-rounded-md;
  @apply tw-text-xs;
  @apply tw-px-3;
  @apply tw-py-[0.15rem];
  @apply tw-relative;
  line-height: 1.43rem;
  @media (max-width: theme('screens.md')) {
    padding: 0 12px;
  }
}
.cancelled-badge {
  @apply tw-text-orange-700;
  @apply tw-bg-orange-300;
}
.completed-badge {
  @apply tw-text-blue-800;
  @apply tw-bg-blue-300;
}
.cell {
  @media (max-width: theme('screens.md')) {
    width: 100%;
  }
}
.confirmed-badge {
  @apply tw-text-green-600;
  @apply tw-bg-green-200;
}

tr:not(.mobile-full-view) .bookingLog_col--clicks,
tr:not(.mobile-full-view) .bookingLog_col--cpa,
tr:not(.mobile-full-view) .bookingLog_col--cpc,
tr:not(.mobile-full-view) .bookingLog_col--costs,
tr:not(.mobile-full-view) .bookingLog_col--unavailability,
tr:not(.extra-info-row) .bookingLog_col--cancelledBookings,
tr:not(.extra-info-row) .bookingLog_col--bookingRefundedRevenue {
  @media (max-width: theme('screens.md')) {
    display: none;
  }
}
.flag {
  left: 1.6rem;
  background-size: 20px auto;
  top: 1.6rem;
  background-position: 0 -30px;
}
.flagImage {
  background-image: url(https://imgcy.trivago.com/q_auto/v1/hardcodedimages/imagemap_all_flags_21@2x.png);

  background-repeat: no-repeat;
}
.flag-at {
  background-position: 0 -810px;
}
.flag-de {
  background-position: 0 0;
}
.flag-en,
.flag-uk {
  background-position: 0 -30px;
  height: 11px;
}
.flag-es {
  background-position: 0 -60px;
}
.flag-fr {
  background-position: 0 -90px;
}
.flag-se {
  background-position: 0 -120px;
}
.flag-pl {
  background-position: 0 -150px;
}
.flag-ru {
  background-position: 0 -180px;
}
.flag-it {
  background-position: 0 -210px;
}
.flag-gr {
  background-position: 0 -240px;
}
.flag-nl {
  background-position: 0 -270px;
}
.flag-rs {
  background-position: 0 -300px;
}
.flag-tr {
  background-position: 0 -330px;
}
.flag-ro {
  background-position: 0 -360px;
}
.flag-ar {
  background-position: 0 -390px;
}
.flag-ie {
  background-position: 0 -870px;
}
.flag-id {
  background-position: 0 -1290px;
}
.flag-hu {
  background-position: 0 -900px;
}
.flag-br {
  background-position: 0 -450px;
}
.flag-zh,
.flag-cn {
  background-position: 0 -480px;
}
.flag-us {
  background-position: 0 -510px;
}
.flag-fi {
  background-position: 0 -540px;
}
.flag-jp {
  background-position: 0 -570px;
}
.flag-mx {
  background-position: 0 -600px;
}
.flag-pt {
  background-position: 0 -630px;
}
.flag-cs,
.flag-cz {
  background-position: 0 -840px;
}
.flag-bg {
  background-position: 0 -420px;
}
.flag-da,
.flag-dk {
  background-position: 0 -690px;
}
.flag-no {
  background-position: 0 -720px;
}
.flag-be {
  background-position: 0 -750px;
}
.flag-so,
.flag-si {
  background-position: 0 -780px;
}
.flag-ch {
  background-position: 0 -930px;
}
.flag-ca {
  background-position: 0 -990px;
  height: 12px;
}
.flag-nz {
  background-position: 0 -1050px;
}
.flag-au {
  background-position: 0 -960px;
  height: 12px;
}
.flag-co {
  background-position: 0 -1200px;
  height: 12px;
}
.flag-ko,
.flag-kr {
  background-position: 0 -1020px;
}
.flag-cl {
  background-position: 0 -1170px;
  height: 12px;
}
.flag-sg {
  background-position: 0 -1140px;
}
.flag-in {
  background-position: 0 -1080px;
}
.flag-hk {
  background-position: 0 -1110px;
}
.flag-ae {
  background-position: 0 -1320px;
}
.flag-ms,
.flag-my {
  background-position: 0 -1230px;
}
.flag-th {
  background-position: 0 -1260px;
}
.flag-he,
.flag-il {
  background-position: 0 -1350px;
}
.flag-za {
  background-position: 0 -1380px;
}
.flag-tw {
  background-position: 0 -1410px;
}
.flag-ph {
  background-position: 0 -1470px;
}
.flag-world_wide {
  background-position: 0 -1514px;
}
.flag-vn {
  background-position: 0 -1440px;
}
.flag-aa,
.flag-shabaka {
  background-position: 0 -1530px;
}
.flag-hr {
  background-position: 0 -1560px;
}
.flag-sk {
  background-position: 0 -1590px;
}
.flag-pe {
  background-position: 0 -1620px;
}
.flag-ec {
  background-position: 0 -1650px;
}
.flag-uy {
  background-position: 0 -1680px;
}
.flag-ng {
  background-position: 0 -1710px;
}
</style>
