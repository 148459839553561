<template>
  <BaseCard no-padding>
    <div class="progress tw-py-4 tw-flex tw-flex-col">
      <ul class="tw-flex tw-justify-between">
        <li
          v-for="step in steps"
          :key="step.name"
          :class="getClass(step)"
          class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-1/3 tw-relative"
        >
          <span class="dot" />
          <span class="step-name tw-uppercase tw-font-bold tw-text-[10px] tw-text-gray-500">{{ $t(step.label) }}</span>
        </li>
      </ul>
    </div>
  </BaseCard>
</template>

<script setup>
import BaseCard from '@/components/dashboard/BaseCard.vue'

const props = defineProps({
  currentStep: {
    type: Number,
    required: true,
    default: 0,
  },
})

const steps = [
  { id: 1, name: 'userDetails', label: 'tbs_registration_stepper_2' },
  { id: 2, name: 'addProperty', label: 'tbs_registration_stepper_1' },
  { id: 3, name: 'home', label: 'tbs_registration_stepper_3' },
]

const getClass = step => {
  if (step.id === props.currentStep) {
    return 'active'
  } else if (step.id < props.currentStep) {
    return 'completed'
  }
}
</script>
<style scoped>
li .dot {
  display: block;
  height: 6px;
  width: 6px;
  @apply tw-bg-blue-800 tw-rounded-lg;
  z-index: 3;
  margin-bottom: 9px;
}
li.completed .dot,
li.active .dot {
  @apply tw-bg-gray-100;
}
li.active .step-name,
li.completed .step-name {
  @apply tw-text-blue-800;
}
.progress li:first-child {
  justify-content: flex-start;
  transform: translateX(-15%);
  z-index: 1;
}
.progress li:last-child {
  justify-content: flex-end;
  transform: translateX(25%);
}
li .step-name {
  @media (max-width: theme('screens.md')) {
    max-width: 60px;
    text-align: center;
  }
}
.progress li:nth-child(2):before {
  content: '';
  width: 115%;
  margin: auto;
  @apply tw-h-[6px] tw-mb-2.5 tw-absolute;
  left: -65%;
  top: 0;
  background: theme('colors.gray.300');
  background: linear-gradient(to left, theme('colors.gray.300') 50%, theme('colors.blue.800') 50%) right;
  background-size: 200%;
  transition: 0.5s ease-out;
  z-index: 0;
}
.progress li.active:nth-child(2):before {
  background-position: left;
}
.progress li:last-child:before {
  content: '';
  width: 125%;
  margin: auto;
  @apply tw-h-[6px] tw-mb-2.5 tw-absolute;
  background: theme('colors.gray.300');
  background: linear-gradient(to left, theme('colors.gray.300') 50%, theme('colors.blue.800') 50%) right;
  background-size: 200%;
  transition: 0.5s ease-out;
  left: -75%;
  top: 0;
  z-index: 0;
}
.progress li.active:last-child:before {
  background-position: left;
}
</style>
