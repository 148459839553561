<template>
  <div class="radio-button-container tw-w-1/4 tw-relative">
    <div class="tw-text-gray-700 tw-font-bold tw-text-sm tw-flex tw-flex-col">
      <label>
        <div class="tw-relative tw-w-[20px] tw-h-[20px] tw-items-center tw-m-auto">
          <input :id="mode" :checked="isModeSelected" type="radio" :value="mode" @blur="onChange" @change="onChange" />
          <div :class="isModeSelected ? 'radio-button-checked' : 'radio-button'" />
        </div>
        <p
          :class="{ 'label-checked': isModeSelected }"
          class="radio-label tw-text-center tw-hidden md:tw-block tw-pt-3"
        >
          {{ $t(`rc_bidding_mode_${mode}`) }}
        </p>
      </label>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  mode: {
    type: String,
    default: '',
  },
  selectedCPCLevel: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['onChange'])

const onChange = e => emit('onChange', e.target.value)

const isModeSelected = computed(() => props.mode === props.selectedCPCLevel)
</script>

<style scoped>
.radio-button-container:first-child {
  transform: translateX(-50%);
  @media (max-width: theme('screens.md')) {
    transform: translateX(0%);
  }
}
.radio-button-container:nth-of-type(2) {
  transform: translateX(-16.66%);
}
.radio-button-container:last-child div label div {
  @media (max-width: theme('screens.md')) {
    margin-right: 0;
  }
}
.radio-button-container:nth-of-type(3) {
  transform: translateX(16.66%);
}
.radio-button-container:last-child div label p {
  @media (max-width: theme('screens.md')) {
    text-align: right;
  }
}
.radio-button-container:hover {
  cursor: pointer;
}
.radio-button-container:first-child div label p {
  @media (max-width: theme('screens.md')) {
    text-align: left;
  }
}
.radio-button-container:hover div p {
  color: theme('colors.gray.800');
}
.radio-button-container:last-child {
  transform: translateX(50%);
  @media (max-width: theme('screens.md')) {
    transform: translateX(0%);
  }
}
.radio-button-container:hover div:after {
  display: block;
}
input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 20px;
  width: 20px;
  z-index: 9;
  @media (max-width: theme('screens.md')) {
    height: 16px;
    width: 16px;
  }
}
.radio-button:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #9ba2a6;
  background-color: theme('colors.white');
  border-radius: 50%;
  @media (max-width: theme('screens.md')) {
    width: 16px;
    height: 16px;
  }
}
.radio-button:after {
  content: '';
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: theme('colors.blue.800');
  @media (max-width: theme('screens.md')) {
    width: 6px;
    height: 6px;
  }
}
.radio-button-checked {
  box-shadow: 0 1px 1px 0 #0056a5;
}
.radio-button-checked:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #007fad;
  background-color: theme('colors.white');
  border-radius: 50%;
  @media (max-width: theme('screens.md')) {
    width: 16px;
    height: 16px;
  }
}
.radio-button-checked:after {
  content: '';
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #fff;
  border: 1px solid #007fad;
  box-shadow: 0px 0px 0px 5px #bfdfea;
  @media (max-width: theme('screens.md')) {
    top: 41%;
    left: 40%;
    box-shadow: 0px 0px 0px 3px #bfdfea;
  }
}
.label-checked {
  @apply tw-text-gray-800;
  @media (max-width: theme('screens.md')) {
    display: block;
  }
}
.radio-button-container:first-child div label div {
  @apply tw-m-auto;
  @media (max-width: theme('screens.md')) {
    margin-left: 0;
  }
}

.radio-label:hover,
label:hover {
  cursor: pointer;
}
</style>
