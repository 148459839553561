<template>
  <CustomModal
    :is-modal-open="isOpen"
    @toggleModal="close"
    @onClickAway="close"
    overlay="dark"
    :with-header="true"
    :with-footer="true"
    :custom-classes="'tw-w-[100vw] md:tw-w-[700px] md:tw-rounded-md tw-rounded-none'"
  >
    <template #modalHeader>
      <p class="tw-text-xl tw-text-gray-700">{{ $t('app_description_hotel_description') }}</p>
      <div class="tw-border-t tw-border-t-gray-300" />
    </template>

    <div v-if="description">
      <ExistingDescriptionModalHeader :description="description" />
      <p class="tw-mt-4 tw-text-sm">
        {{ description.text }}
      </p>
    </div>

    <template #modalFooter>
      <div v-if="isAbleToEdit" class="tw-w-full tw-flex tw-justify-end">
        <MainButton
          size="medium"
          @click="emit('onEditClick', description)"
          @keydown.enter="emit('onEditClick', description)"
          >{{ $t('app_description_edit') }}</MainButton
        >
      </div>
    </template>
  </CustomModal>
</template>

<script setup>
import { computed } from 'vue'

import CustomModal from '@/components/base/CustomModal.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import ExistingDescriptionModalHeader from '@/components/propertyDetails/propertyDescription/ExistingDescriptionModalHeader.vue'

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
    default: false,
  },
  close: {
    type: Function,
    required: false,
    default: () => {},
  },
  description: {
    type: Object,
    default: null,
  },
})
const emit = defineEmits(['onEditClick'])

const isHotelier = computed(() => {
  return props.description?.source === 'hotelier'
})
const isAbleToEdit = computed(() => {
  if (!props.description) return false
  return isHotelier.value && props.description.text
})
</script>
