<template>
  <div class="tw-flex tw-items-center">
    <BaseIcon
      v-for="star in rating"
      :key="star"
      icon-name="star"
      :height="16"
      :width="16"
      :viewbox-height="24"
      :viewbox-width="24"
    >
      <Star />
    </BaseIcon>
  </div>
</template>
<script>
import Star from '@/components/icons/Star.vue'
import BaseIcon from '@/components/BaseIcon.vue'
export default {
  components: {
    BaseIcon,
    Star,
  },
  props: {
    starsNumber: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    rating() {
      return Array.from(Array(Number(this.starsNumber)).keys())
    },
  },
}
</script>
