<template>
  <Notification
    :description="$t('notification_good_cpa_message')"
    :header="$t('notification_good_cpa_heading', { connectivityProvider: partnerName })"
    v-bind="$attrs"
  >
  </Notification>
</template>

<script setup>
import { computed } from 'vue'

import Notification from './Notification.vue'
import { usePartners } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const { currentPartner } = usePartners(selectedAccommodationId)

const partnerName = computed(() => currentPartner.value?.partnerName)
</script>
