<template>
  <BaseSelect
    :label="$t('form.labels.country')"
    :required="required"
    labelRequiresTranslation
    :options="countryListFormatted"
    :value="value"
    :display-text-key="'displayText'"
    :value-key="'value'"
    @onChange="e => emit('onSelectChange', e)"
  />
</template>

<script setup>
import { computed } from 'vue'
import BaseSelect from '@/components/BaseSelect.vue'

const emit = defineEmits(['onSelectChange'])
const props = defineProps({
  countriesData: {
    type: Array,
    default: () => [],
  },
  value: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
})

const countryListFormatted = computed(() => {
  if (!props.countriesData || !Array.isArray(props.countriesData)) return []
  return props.countriesData
    .map(country => ({ value: country.code, displayText: `form.countries.${country.code}` }))
    .sort((a, b) =>
      a.displayText.toLowerCase() === b.displayText.toLowerCase()
        ? 0
        : a.displayText.toLowerCase() < b.displayText.toLowerCase()
        ? -1
        : 1
    )
})
</script>
