<template>
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 5.5L15 15.5" stroke="#6C707A" stroke-width="2" stroke-linecap="round" />
    <path d="M15 5.5L5 15.5" stroke="#6C707A" stroke-width="2" stroke-linecap="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.7175 17.2175C13.0075 20.9275 6.9925 20.9275 3.2825 17.2175C-0.4275 13.5075 -0.4275 7.4925 3.2825 3.7825C6.9925 0.0725 13.0075 0.0725 16.7175 3.7825C20.4275 7.4925 20.4275 13.5075 16.7175 17.2175Z"
      stroke="#6C707A"
      stroke-linecap="round"
    />
  </svg>
</template>
