<template>
  <BaseChart
    v-if="prettyRevenue && getXAxisData && getSeriesData.length > 0"
    container="revenue"
    :series="getSeriesData"
    :xaxis="getXAxisData"
    :yaxis="getYAxisData"
    :options="getOptions"
  />
</template>
<script setup>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

import BaseChart from '@/components/rateConnect/performance/charts/BaseChart.vue'
import { addSeries, columnChartOptions } from '@/components/rateConnect/performance/charts/chartsUtils.js'

const { t } = useI18n()

const props = defineProps({
  categories: {
    type: [Array, Object],
    default: () => [],
  },
  campaignTypes: {
    type: [Array, Object],
    default: () => [],
  },
  tickPositions: {
    type: [Array, Object],
    default: () => [],
  },
  prettyRevenue: {
    type: Object,
    default: () => {},
  },
  currency: {
    type: String,
    default: '',
  },
})
const getSeriesData = computed(() => {
  if (
    !props.prettyRevenue?.bookings ||
    !props.prettyRevenue?.avgBookingRevenue ||
    !props.prettyRevenue?.bookingRevenue ||
    !props.prettyRevenue?.bookingRefundedRevenue
  )
    return []
  return [
    addSeries(
      props.prettyRevenue.bookings,
      t('rc_label_total_bookings_pay_per_stay'),
      'bookings',
      {
        color: 'transparent',
        borderColor: 'transparent',
        highlighted: false,
      },
      props.currency
    ),
    addSeries(
      props.prettyRevenue.avgBookingRevenue,
      t('rc_label_abv'),
      'avgBookingRevenue',
      {
        color: 'transparent',
        borderColor: 'transparent',
        highlighted: false,
      },
      props.currency
    ),
    addSeries(
      props.prettyRevenue.bookingRevenue,
      t('rc_label_revenue'),
      'bookingRevenue',
      {
        columnIndicator: true,
      },
      props.currency
    ),
    addSeries(
      props.prettyRevenue.bookingRefundedRevenue,
      t('rc_refunded_revenue_pay_per_stay'),
      'bookingRefundedRevenue',
      {
        columnIndicator: true,
        color: '#FC9E15',
      },
      props.currency
    ),
  ]
})

const getXAxisData = computed(() => {
  if (!props.categories || !props.campaignTypes || !props.tickPositions) return null
  return {
    categories: props.categories,
    campaignTypes: props.campaignTypes,
    tickPositions: props.tickPositions,
  }
})
const getYAxisData = computed(() => {
  return {
    max: Math.max(...Object.values(props.prettyRevenue?.bookingRevenue)),
  }
})
const getOptions = computed(() => {
  return columnChartOptions
})
</script>
