<template>
  <div :id="containerId"></div>
</template>

<script setup>
import { watch, computed, nextTick } from 'vue'
import Highcharts from 'highcharts'
import { useStore } from 'vuex'
import * as Sentry from '@sentry/vue'

import { areaChartOptions } from './chartUtils'
import { convertDateToFormattedString } from '@/utils/Utility.js'

const store = useStore()
const props = defineProps({
  containerId: {
    type: String,
    required: true,
  },
  seriesData: {
    type: Array,
    default() {
      return []
    },
  },
  seriesName: {
    type: String,
    default: 'Value',
  },
})

const localization = computed(() => store.getters['getLanguage'])
const drawChart = (containerId, data) => {
  if (!containerId || !data) return

  try {
    // without nextTick, highcharts may use the container before it exists in the DOM, it is important!
    nextTick(() => {
      // if for some reason the container is not yet mounted, go back.
      if (!document.getElementById(containerId)) return
      Highcharts.chart(containerId, {
        plotOptions: {
          series: {
            name: props.seriesName,
            marker: {
              radius: 2,
            },
            lineWidth: 1,
            fillColor: '#EBF5FF',
          },
        },
        ...areaChartOptions,
        tooltip: {
          useHTML: true,
          hideDelay: 0,

          formatter: function () {
            const html = ({ date, series }) => `<div>
                    <div>${date}</div>
                    ${series}
                  <div>`

            const series = ({ name, value }) => `<div>
                  <span>${name}: </span><span>${Intl.NumberFormat(localization.value).format(value)}</span>
                </div>`

            let renderedSeries = ''
            let point = this.point
            renderedSeries = series({ name: point.series.name, value: point.y })

            const startDate = convertDateToFormattedString(new Date(point.x), localization.value)
            const endDate = convertDateToFormattedString(new Date(this.x + 6 * 24 * 60 * 60 * 1000), localization.value)
            return html({
              date: `${startDate} - ${endDate}`,
              series: renderedSeries,
            })
          },
        },
        series: [{ data }],
      })
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

watch(
  () => props,
  newProps => {
    if (newProps.seriesData?.length > 0 && newProps.containerId) {
      drawChart(newProps.containerId, newProps.seriesData)
    }
  },
  { deep: true, immediate: true }
)

watch(localization, (currentValue, oldValue) => {
  if (currentValue !== oldValue) {
    drawChart(props.containerId, props.data)
  }
})
</script>
