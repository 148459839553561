import { ref } from 'vue'

const notifications = ref({})

export default function useToastNotifications() {
  function displayNotification(newNotification) {
    const newId = Date.now()
    if (notifications.value[newId]) return

    const timeoutHandler = setTimeout(() => closeNotification(newId), 8000)
    notifications.value[newId] = { ...newNotification, id: newId, timeoutHandler }
  }

  function closeNotification(id) {
    clearTimeoutHandler(id)
    removeNotification(id)
  }

  function removeNotification(id) {
    if (notifications.value[id]) delete notifications.value[id]
  }

  function clearTimeoutHandler(id) {
    const timeoutHandler = notifications.value[id]?.timeoutHandler
    if (timeoutHandler) clearTimeout(timeoutHandler)
  }

  return {
    notifications,
    displayNotification,
    closeNotification,
  }
}
