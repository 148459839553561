<template>
  <div
    v-click-outside="onClickaway"
    class="tw-w-full tw-bg-white tw-absolute tw-min-h-[100px] tw-rounded-lg listing tw-flex-col tw-z-2 tw-py-2"
    :class="{
      'tw-flex tw-justify-center tw-items-center': isSearching || (data && data.length < 1 && isSearching === false),
    }"
  >
    <div
      v-if="data && data.length < 1 && isSearching === false"
      class="tw-items-center tw-flex tw-px-5 tw-py-2 tw-max-h-[100px]"
    >
      <p class="tw-gray-700 tw-font-bold">{{ $t('no_results') }}</p>
    </div>
    <div class="tw-px-5" v-if="data.length > 0">
      <AccommodationItem
        v-for="item in data"
        :key="item.id"
        :isClickable="true"
        :accommodation="item"
        :values="selectedValues"
        @onSelect="option => onSelect(option)"
      />
    </div>
    <div class="tw-w-full tw-flex tw-flex-col" v-if="suggestions.length && data.length === 0">
      <p class="tw-font-bold tw-bg-gray-100 tw-w-full tw-px-5 tw-py-2">
        {{ $t('suggested_competitors_title') }}
      </p>
      <div class="tw-mt-4 tw-px-5">
        <AccommodationItem
          v-for="suggestion in suggestions"
          :key="suggestion.id"
          :isClickable="true"
          :accommodation="suggestion"
          :values="selectedValues"
          @onSelect="option => onSelect(option)"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import AccommodationItem from '@/components/rateInsights/onboarding/AccommodationItem.vue'

defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  suggestions: {
    type: Array,
    default: () => [],
  },
  selectedValues: {
    type: Array,
    default: () => [],
  },
  onClickaway: {
    type: Function,
    default: () => null,
  },
  isSearching: {
    type: Boolean,
  },
})

const emit = defineEmits(['onSelect'])
const onSelect = value => emit('onSelect', value)
</script>
<style scoped>
.listing {
  box-shadow: 0 2px 4px 0 rgba(63, 87, 189, 0.1), 0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
</style>
