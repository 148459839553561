<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <BaseCard no-padding class="tw-p-5">
    <MetricViewHeader
      :selectedTimeFrame="selectedTimeFrame"
      @onSelectTimeFrame="selectTimeFrame"
      :title="$t('vp_trends_header')"
      :titleTooltipLabel="$t('vp_time_trends_tooltip')"
    />
    <Notification v-if="!isWaitingMode && mostCommonValue" :iconType="'bulb'">
      <span v-html="$t(tip)" />
    </Notification>
    <Notification v-if="isWaitingMode" :iconType="'info'">{{ $t('vp_graph_empty_state') }}</Notification>

    <SearchGraph
      class="tw-mt-9"
      :selectedTimeFrame="selectedTimeFrame"
      :isWaitingMode="isWaitingMode"
      :isLoading="isTimeFrameTrendsLoading"
      :isError="isTimeFrameTrendsError"
      :timeFrameTrends="timeFrameTrends"
    />
  </BaseCard>
  <BaseCard class="tw-mt-12">
    <div class="tw-mb-9 tw-flex tw-items-center tw-justify-between">
      <MetricViewHeaderTitle :tooltipLabel="$t('vp_competitor_table_tooltip')">{{
        $t('vp_competitor_table')
      }}</MetricViewHeaderTitle>
      <div class="tw-flex tw-gap-6" v-if="competitors?.length > 0">
        <div class="tw-flex tw-items-center">
          <span class="tw-bg-blue-100 tw-border tw-border-gray-200 tw-h-[27px] tw-w-[15px] tw-mr-2" />
          <p>{{ $t('vp_property_data') }}</p>
        </div>
        <div class="tw-flex tw-items-center">
          <span class="tw-bg-blue-400 tw-border tw-border-gray-200 tw-h-[27px] tw-w-[15px] tw-mr-2" />
          <p>{{ $t('vp_percentage_indicator') }}</p>
        </div>
      </div>
    </div>
    <Notification v-if="!isCompetitorsFetching && competitors?.length > 0" iconType="info"
      ><span v-html="$t('vp_competitor_table_info')"
    /></Notification>
    <SearchTable
      class="tw-mt-9"
      :timeFrame="selectedTimeFrame"
      :isWaitingMode="isWaitingMode"
      :isLoading="isTimeFrameTrendsLoading"
      :isError="isTimeFrameTrendsError"
      :timeFrameTrends="timeFrameTrends"
    />
  </BaseCard>
</template>

<script setup>
import { ref, computed } from 'vue'

import MetricViewHeader from '@/components/visitorsProfile/common/MetricViewHeader.vue'
import Notification from '@/components/visitorsProfile/common/Notification.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import SearchGraph from '@/components/visitorsProfile/searchView/SearchGraph.vue'
import MetricViewHeaderTitle from '@/components/visitorsProfile/common/MetricViewHeaderTitle.vue'
import SearchTable from '@/components/visitorsProfile/searchView/SearchTable.vue'
import { useTimeFrameTrends } from '@/components/visitorsProfile/queries'
import { selectedAccommodationId } from '@/layouts/queries'
import { useCompetitors } from '@/components/rateInsights/queries'
import appAnalytics from '@/utils/tracking'

const { competitors, isCompetitorsFetching } = useCompetitors(selectedAccommodationId)

const selectedTimeFrame = ref('30')

const selectTimeFrame = timeFrame => {
  if (selectedTimeFrame.value !== timeFrame) {
    selectedTimeFrame.value = timeFrame
    appAnalytics.track(appAnalytics.events.VP_TIMEFRAME_CHANGED, {
      tabName: 'Time Period',
      timeFrame: timeFrame,
      accommodationId: selectedAccommodationId.value,
    })
  }
}

const { isTimeFrameTrendsLoading, isTimeFrameTrendsError, timeFrameTrends } = useTimeFrameTrends(
  selectedAccommodationId,
  selectedTimeFrame
)

const mostCommonValue = computed(() => {
  if (isTimeFrameTrendsLoading.value || isTimeFrameTrendsError.value) return
  const values = timeFrameTrends.value?.[selectedAccommodationId?.value]
  return Object.keys(values).reduce((a, b) => (values[a] > values[b] ? a : b))
})

const tip = computed(() => {
  if (mostCommonValue?.value === 'weekend') {
    return 'vp_time_weekends_tip'
  } else if (mostCommonValue?.value === 'week') {
    return 'vp_time_weekdays_tip'
  } else if (mostCommonValue?.value === 'other') {
    return 'vp_time_other_tip'
  } else if (mostCommonValue?.value === 'longTrip') {
    return 'vp_time_extended_stay_tip'
  } else return ''
})

const isWaitingMode = computed(() => {
  return timeFrameTrends.value && !timeFrameTrends.value?.[selectedAccommodationId.value]
})
</script>
