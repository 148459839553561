<template>
  <div ref="root">
    <UiTooltip tooltip-position="top" theme="material" :label="$t(name)">
      <div
        @click="showDropdown"
        class="tw-relative tw-cursor-pointer tw-border tw-rounded-lg tw-flex tw-items-center tw-px-3 tw-py-2 tw-pr-8 tw-box-size-44"
        :class="getFieldClass(show, disabled)"
      >
        <div class="tw-flex tw-justify-between tw-w-full">
          <span class="tw-truncate">{{ $t(name) }}</span>
          <div
            v-if="value.length > 0"
            class="tw-bg-blue-700 tw-rounded-full tw-w-[23px] tw-h-[23px] tw-flex tw-items-center tw-justify-center"
          >
            <span class="tw-text-white tw-text-sm tw-leadning-none">{{ value.length }}</span>
          </div>
        </div>

        <BaseIcon
          icon-name="chevron-right"
          :height="24"
          :width="24"
          :viewbox-height="24"
          :viewbox-width="24"
          class="tw-absolute"
          :class="show ? 'arrow-dropdown-open' : 'arrow-dropdown'"
        >
          <ChevronRight />
        </BaseIcon>
      </div>
    </UiTooltip>
    <div class="tw-relative tw-top-[5px]" v-if="show && !disabled">
      <div class="tw-absolute tw-min-h-[50px] tw-right-0 tw-bg-white tw-rounded dropdown-card tw-p-4 tw-z-[10]">
        <p class="tw-font-bold tw-text-sm tw-mb-3">{{ tagline }}</p>
        <ul :class="{ 'tw-mb-3': name === 'competitors' }">
          <li
            v-for="(option, index) in options"
            :key="option.id"
            @click="onSelect({ option, name })"
            class="tw-flex tw-items-center tw-justify-between tw-mx-1 tw-py-1.5"
          >
            <label class="tw-mb-0" :for="index">{{ name === 'channels' ? option.fullName : option.name }}</label>
            <input type="checkbox" :id="index" :checked="isChecked(option)" />
          </li>
        </ul>

        <button
          @click="redirectToUpdateCompetitors"
          v-if="name === 'competitors'"
          class="tw-mx-1 tw-p-0 tw-whitespace-nowrap"
        >
          <span class="tw-font-bold tw-text-blue-700">{{ $t('manage_competitors') }}</span>
        </button>
        <button
          @click="redirectToUpdateChannels"
          v-if="name === 'channels'"
          class="tw-mx-1 tw-p-0 tw-whitespace-nowrap"
        >
          <span class="tw-font-bold tw-text-blue-700">{{ $t('manage_channels') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, onDeactivated } from 'vue'
import { useRouter } from 'vue-router'
import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'

const router = useRouter()
const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  name: {
    type: String,
    default: '',
  },
  value: {
    type: Array,
    default: () => [],
  },
  tagline: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const root = ref(null)
const show = ref(false)
const emit = defineEmits(['select'])

const showDropdown = () => {
  show.value = !show.value
}
const onSelect = ({ option, name }) => {
  emit('select', { option, name })
}
const isChecked = option => {
  return props.value.find(item => item.id === option.id)
}

const handleClickOutside = evt => {
  if (root?.value !== null && !root?.value?.contains(evt.target)) {
    show.value = false
  }
}

const redirectToUpdateCompetitors = () => {
  router.push({ name: 'rate-insights-update', query: { isUpdate: true } })
}
const redirectToUpdateChannels = () => {
  router.push({ name: 'rate-insights-channels' })
}
const getFieldClass = (show, disabled) => {
  const classes = []
  if (disabled) {
    classes.push('tw-border-gray-300 tw-text-gray-300')
  }
  if (show) {
    classes.push('tw-border-blue-700')
  }
  if (!show && !disabled) {
    classes.push('tw-border-gray-500')
  }
  return classes.toString().replace(',', ' ')
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onDeactivated(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>
<style scoped>
.arrow-dropdown {
  top: 50%;
  transform: translateY(-45%) rotate(90deg);
  right: 6px;
  pointer-events: none;
}
.arrow-dropdown-open {
  top: 50%;
  transform: translateY(-45%) rotate(270deg);
  right: 6px;
  pointer-events: none;
}

.dropdown-card {
  box-shadow:
    0 2px 4px 0 rgba(63, 87, 189, 0.1),
    0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
</style>
