<template>
  <div class="tw-mt-2">
    <p class="tw-font-bold">
      {{ $t('number_selected_channels', { counterSelected: selected.length, counterTotal: 8 }) }}
    </p>
    <div class="tw-flex tw-flex-row tw-flex-wrap">
      <div class="tw-basis-2/4 tw-py-1 tw-mt-2 odd:tw-pr-2 even:tw-pl-2" v-for="item in selected" :key="item.id">
        <div class="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow-md tw-flex tw-items-center tw-justify-between">
          <span>{{ item.fullName }}</span>
          <button class="tw-mb-0 focus:tw-outline-none" @click="() => onRemove(item)">
            <BaseIcon icon-name="close" height="14" width="14" viewbox-height="20" viewbox-width="20" class="tw-mt-1">
              <Close />
            </BaseIcon>
          </button>
        </div>
      </div>
      <div
        class="tw-basis-2/4 tw-py-1 tw-mt-2 odd:tw-pr-2 even:tw-pl-2"
        v-for="item in placeholderElements"
        :key="item"
      >
        <div
          :key="item"
          class="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow-md tw-flex tw-items-center tw-justify-between tw-animate-pulse tw-opacity-50"
        >
          <div class="tw-bg-gray-300 tw-rounded-sm tw-w-[100px] tw-block tw-h-[24px]"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Close from '@/components/icons/Close.vue'

const props = defineProps({
  selected: Array,
})

const emit = defineEmits(['onSelect'])
const onRemove = value => {
  emit('onSelect', value)
}
const placeholderElements = computed(() =>
  Array.from({ length: 8 - props.selected.length }, (v, k) => k + 1 + props.selected.length)
)
</script>
<style scoped>
.item {
  box-shadow: 0 2px 4px 0 rgba(63, 87, 189, 0.1), 0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
</style>
