<template>
  <BookingTable
    v-if="tableData"
    :columns="columns"
    :data="tableData"
    :have-extra-info="hasExtraInfo"
    :currency="currency"
    :language="language"
    :key="selectedMarket"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import BookingTable from '@/components/rateConnect/performance/table/BookingTable.vue'
import { useBookingLog } from '@/components/rateConnect/queries'
import { useRateConnectPerformance } from '@/components/rateConnect/performance/useRateConnectPerformance'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const currency = computed(() => store.state.currency)
const language = computed(() => store.state.locale?.language)
const { selectedMarket, formattedStartDate, formattedEndDate } = useRateConnectPerformance()

const { bookingLogData } = useBookingLog(
  selectedAccommodationId,
  currency,
  formattedStartDate,
  formattedEndDate,
  selectedMarket
)

const allColumns = {
  market: {
    header: 'rc_bookings_list_market',
    id: 'market',
    colType: 'flag',
    sortable: true,
    additional: false,
    className: 'col--market',
    mobileHeader: false,
  },
  bookingDate: {
    header: 'rc_bookings_list_booking_date',
    id: 'bookingDate',
    colType: 'date',
    sortable: true,
    additional: false,
    className: 'col--bookingDate',
    mobileHeader: false,
  },
  arrivalDate: {
    header: 'rc_bookings_list_arr_dep',
    id: 'stayDate',
    colType: 'date',
    sortable: true,
    additional: false,
    className: 'col--stayDate',
    mobileHeader: false,
  },
  bookingValue: {
    header: 'rc_bookings_list_booking_value',
    id: 'bookingValue.amountCampaignCurrency',
    colType: 'currency',
    sortable: true,
    additional: false,
    className: 'col--bookingValue',
    mobileHeader: false,
  },
  commissionCost: {
    header: 'rc_bookings_commission',
    hint: 'rc_def_bookings_commission',
    id: 'bookingValue.commissionCost',
    colType: 'currency',
    sortable: true,
    additional: false,
    className: 'col--commissionCost',
    mobileHeader: false,
  },
  avgRoomRate: {
    header: 'rc_bookings_list_arr',
    hint: 'rc_def_arr',
    id: 'arr',
    colType: 'currency',
    sortable: true,
    additional: false,
    className: 'col--arr',
    mobileHeader: false,
  },
  status: {
    header: 'rc_bookings_list_status',
    hint: 'rc_def_abv',
    id: 'status',
    colType: 'status',
    sortable: true,
    additional: false,
    className: 'col--status',
    mobileHeader: true,
  },
  refundAmount: {
    header: 'rc_label_refunded_revenue_pay_per_stay',
    id: 'refundAmount',
    colType: 'currency',
    sortable: true,
    additional: true,
    className: 'col--refundAmount',
    mobileHeader: false,
  },
  cancelledDate: {
    header: 'rc_label_cancellation_date_pay_per_stay',
    id: 'cancelledDate',
    colType: 'date',
    sortable: false,
    additional: true,
    className: 'col--cancelledDate',
    mobileHeader: false,
  },
  bookingId: {
    header: 'rc_bookings_list_booking_id',
    hint: 'rc_def_booking_id',
    id: 'bookingId',
    colType: 'string',
    sortable: false,
    additional: false,
    className: 'col--bookingId',
    mobileHeader: false,
  },
}

const columns = computed(() => {
  if (!bookingLogData.value) return
  let hasCPA = false

  bookingLogData.value?.forEach(booking => {
    if (booking?.campaignType === 'netCpa' || booking?.campaignType === 'cpa') hasCPA = true
  })

  const displayedColumns = [
    allColumns.market,
    allColumns.bookingDate,
    allColumns.arrivalDate,
    allColumns.cancelledDate,
    allColumns.bookingValue,
    allColumns.avgRoomRate,
    allColumns.refundAmount,
    allColumns.status,
  ]
  if (hasCPA) displayedColumns.push(allColumns.commissionCost)
  displayedColumns.push(allColumns.bookingId)

  return displayedColumns
})

const tableData = computed(() => {
  if (!bookingLogData.value || !Array.isArray(bookingLogData.value)) return null

  return bookingLogData.value.map(
    ({
      requestId,
      bookingId,
      marketCode,
      bookingDate,
      arrivalDate,
      departureDate,
      amountCampaignCurrency,
      bookingAmountLocalCurrency,
      localCurrency,
      confirmed,
      confirmedBy,
      confirmedAt,
      campaignType,
      commissionCost,
      averageAmount,
      cpaLevel,
      status,
      refundAmount: originalRefundAmount,
      cancelledDate: originalCancelledDate,
    }) => {
      const hasExtraInfo = status === 'cancelled' && (originalRefundAmount || originalCancelledDate)
      const refundAmount = originalRefundAmount ? originalRefundAmount / 100 : undefined
      const cancelledDate = originalCancelledDate || undefined
      return {
        requestId,
        market: marketCode,
        bookingId,
        bookingDate,
        confirmedAt,
        campaignType,
        stayDate: {
          arrivalDate,
          departureDate,
        },
        bookingValue: {
          amountCampaignCurrency: amountCampaignCurrency / 100,
          amountLocalCurrency: Number(bookingAmountLocalCurrency) / 100,
          localCurrency,
          commissionCost: commissionCost / 100,
        },
        confirmed,
        cpaLevel,
        confirmedByTrivago: confirmedBy === 'trivago',
        arr: averageAmount / 100,
        status,
        refundAmount,
        cancelledDate,
        extraInfo: hasExtraInfo
          ? {
              refundAmount,
              cancelledDate,
            }
          : undefined,
      }
    }
  )
})

const hasExtraInfo = computed(() => tableData.value?.find(item => item?.extraInfo))
</script>
