<template>
  <div class="tw-mb-10">
    <div class="tw-bg-orange-200 tw-rounded-xl tw-p-8 tw-flex tw-flex-col sm:tw-flex-row">
      <div class="tw-flex tw-flex-col tw-w-full">
        <div class="tw-pb-6">
          <div class="tw-flex tw-items-center tw-justify-start tw-pb-2">
            <BaseIcon height="25" width="24" class="tw-fill-none tw-stroke-gray-800 tw-h-[25px] tw-w-[24px]">
              <TriangleWarning />
            </BaseIcon>
            <p
              class="tw-flex tw-font-bold tw-ml-2 tw-text-xl"
              v-html="$t('business_studio_plus_contact_info_header')"
            />
          </div>
          <p v-html="$t('business_studio_plus_contact_info_subtext')" />
        </div>
        <div class="tw-flex tw-justify-between lg:tw-items-end tw-flex-col lg:tw-flex-row">
          <ul class="tw-w-auto tw-ml-6">
            <li class="tw-pb-1.5 tw-break-words tw-list-disc">
              {{ $t('business_studio_plus_telephone') + ' '
              }}<span class="tw-font-bold">{{ accommodationBasicInfo?.phone }}</span>
            </li>
            <li class="tw-py-1.5 tw-break-words tw-list-disc">
              {{ $t('business_studio_plus_email_address') + ' '
              }}<a class="tw-font-bold tw-text-blue-700 tw-underline" :href="mailAddress" rel="noopener noreferrer">{{
                accommodationBasicInfo?.email
              }}</a>
            </li>
            <li class="tw-pt-1.5 tw-break-words tw-list-disc">
              {{ $t('business_studio_plus_website') + ' '
              }}<a
                :href="websiteUrl"
                target="_blank"
                rel="noopener noreferrer"
                class="tw-font-bold tw-text-blue-700 tw-underline hover:tw-cursor-pointer"
                >{{ accommodationBasicInfo?.homepageUrl }}</a
              >
            </li>
          </ul>
          <div class="tw-mt-6 lg:tw-mt-0 tw-flex">
            <GhostButton class="sm:tw-w-auto tw-w-1/2 tw-mr-4" @click="hideBanner" @keydown.enter="hideBanner">{{
              $t('business_studio_plus_contact_info_confirm')
            }}</GhostButton>
            <MainButton class="sm:tw-w-auto tw-w-1/2" @click="redirectToUpdate" @keydown.enter="redirectToUpdate">
              {{ $t('business_studio_plus_contact_info_update') }}
            </MainButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import BaseIcon from '@/components/BaseIcon.vue'
import TriangleWarning from '@/components/icons/TriangleWarning.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import { useAccommodationBasicInfo } from '@/components/propertyDetails/queries/index.js'
import { selectedAccommodationId } from '@/layouts/queries'

const router = useRouter()

const { accommodationBasicInfo } = useAccommodationBasicInfo(selectedAccommodationId)

const emit = defineEmits(['hideBanner'])
const hideBanner = () => emit('hideBanner')

const mailAddress = computed(() => 'mailto:' + accommodationBasicInfo.value?.email)
const websiteUrl = computed(() =>
  accommodationBasicInfo.value?.homepageUrl?.startsWith('http://') ||
  accommodationBasicInfo.value?.homepageUrl?.startsWith('https://')
    ? accommodationBasicInfo.value?.homepageUrl
    : `https://${accommodationBasicInfo.value?.homepageUrl}`
)

const redirectToUpdate = () => {
  router.push({ name: 'property-details-basic-information', query: { open_update: true } })
}
</script>
<style scoped>
h2 :deep(span) {
  @apply tw-text-blue-700;
}
</style>
