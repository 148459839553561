<template>
  <div class="tw-mx-4 lg:tw-mx-0">
    <div v-if="initialDataLoading" class="tw-rounded-xl tw-animate-pulse tw-bg-gray-300 tw-h-[658px]"></div>
    <div v-else-if="requiredDataHasErrors">
      <p class="tw-text-center tw-text-xl">{{ $t('onboarding.appLaunchError.header') }}</p>
    </div>

    <PerformanceStatsWrapper v-else :markets="marketsWithCampaign" :currentCampaignType="currentCampaignType" />
    <FullScreenLoader v-show="requiredDataIsBeingRefetched" />
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import PerformanceStatsWrapper from '@/components/rateConnect/performance/PerformanceStatsWrapper.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import { useMarkets, usePartners, useStartUp } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const language = computed(() => store.state.locale?.language)
const {
  isFetching: isStartUpFetching,
  isLoading: isStartUpLoading,
  isError: isStartUpError,
  currentCampaignType,
} = useStartUp(selectedAccommodationId)
const {
  isFetching: isPartnersFetching,
  isLoading: isPartnersLoading,
  isError: isPartnersError,
  currentPartner,
} = usePartners(selectedAccommodationId)
const {
  isFetching: isMarketsFetching,
  isInitialLoading: isMarketsLoading,
  isError: isMarketsError,
  marketsWithCampaign,
} = useMarkets(selectedAccommodationId, language, currentPartner)

const initialDataLoading = computed(() => isPartnersLoading.value || isMarketsLoading.value || isStartUpLoading.value)

const requiredDataHasErrors = computed(() => isPartnersError.value || isMarketsError.value || isStartUpError.value)

const requiredDataIsBeingRefetched = computed(
  () => !initialDataLoading.value && (isPartnersFetching.value || isMarketsFetching.value || isStartUpFetching.value)
)
</script>
