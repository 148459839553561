import { useMutation, useQueryClient } from '@tanstack/vue-query'
import OrganisationManagementService from '@/services/OrganisationManagementService'

export function useUpdateUserProfile() {
  const queryClient = useQueryClient()
  const {
    isLoading: updateUserProfileLoading,
    isSuccess: updateUserProfileSuccess,
    isError: updateUserProfileError,
    mutate: updateUserProfile,
  } = useMutation({
    mutationFn: ({ userId, body }) => OrganisationManagementService.updateUserProfile({ userId, body }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userDetails'] })
    },
  })

  return {
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateUserProfileError,
    updateUserProfile,
  }
}

export function useUpdateUserLanguage() {
  const queryClient = useQueryClient()

  const {
    isLoading: updateUserLanguageLoading,
    isSuccess: updateUserLanguageSuccess,
    isError: updateUserLanguageError,
    mutate: updateUserLanguage,
  } = useMutation({
    mutationFn: ({ userId, language }) =>
      OrganisationManagementService.updateUserProfile({ userId, body: { preferredLanguage: language } }),
    // Optimistic update
    onMutate: async ({ userId, language }) => {
      const previousLanguage = queryClient.getQueryData(['userDetails', userId])
      queryClient.setQueryData(['userDetails', userId], old => ({ ...old, preferredLanguage: language }))
      return { previousLanguage }
    },
    onError: (_, { userId }, context) => {
      queryClient.setQueryData(['userDetails', userId], context.previousLanguage)
    },
  })

  return {
    updateUserLanguageLoading,
    updateUserLanguageSuccess,
    updateUserLanguageError,
    updateUserLanguage,
  }
}

export function useCreateUserProfile() {
  const queryClient = useQueryClient()
  const {
    isLoading: createUserProfileLoading,
    isSuccess: createUserProfileSuccess,
    isError: createUserProfileError,
    mutate: createUserProfile,
  } = useMutation({
    mutationFn: ({ body }) => OrganisationManagementService.createUserProfile({ body }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userDetails'] })
    },
  })

  return {
    createUserProfileLoading,
    createUserProfileSuccess,
    createUserProfileError,
    createUserProfile,
  }
}
