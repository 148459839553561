var CLICK_OUTSIDE_HANDLER = '_clickoutside_handler'
export default {
  mounted(el, binding, vnode) {
    if (typeof binding.value !== 'function') {
      return
    }

    var init = false
    setTimeout(function () {
      init = true
    }, 0)

    el[CLICK_OUTSIDE_HANDLER] = function (event) {
      if (init && !(el === event.target || el.contains(event.target))) {
        return binding.value.call(vnode.context, event)
      }
    }

    document.documentElement.addEventListener('click', el[CLICK_OUTSIDE_HANDLER], false)
  },

  unmounted(el) {
    document.documentElement.removeEventListener('click', el[CLICK_OUTSIDE_HANDLER], false)
    delete el[CLICK_OUTSIDE_HANDLER]
  },
}
