<template>
  <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icons/Interface/Click 24x24 Line</title>
    <g id="Icons/Interface/Click-24x24-Line" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g>
        <rect id="Rectangle-Copy-52" x="0" y="0" width="24" height="24"></rect>
        <path
          id="Shape"
          d="M10.49,6 C10.21,6 9.99,5.78 9.99,5.5 L9.99,2.5 C9.99,2.22 10.21,2 10.49,2 C10.77,2 10.99,2.22 10.99,2.5 L10.99,5.5 C10.99,5.78 10.77,6 10.49,6 Z M5.5,11 L2.5,11 C2.22,11 2,10.78 2,10.5 C2,10.22 2.22,10 2.5,10 L5.5,10 C5.78,10 6,10.22 6,10.5 C6,10.78 5.78,11 5.5,11 Z M13.99,7.47 C13.8573961,7.47161655 13.7302803,7.41713835 13.64,7.32 C13.4493584,7.13656038 13.4404692,6.83432704 13.62,6.64 L13.64,6.62 L15.79,4.49 C15.99,4.29 16.3,4.29 16.5,4.49 C16.7,4.69 16.7,5 16.5,5.2 L14.37,7.32 C14.27,7.42 14.13,7.48 13.99,7.47 L13.99,7.47 Z M6.99,7.47 C6.85739614,7.47161655 6.73028033,7.41713835 6.64,7.32 L4.48,5.2 C4.28,5 4.28,4.69 4.48,4.49 C4.68,4.29 4.99,4.29 5.19,4.49 L7.31,6.62 C7.5,6.8 7.51,7.11 7.33,7.3 L7.31,7.32 C7.23,7.41 7.11,7.46 6.99,7.47 Z M4.83,16.66 C4.69834272,16.657063 4.57292491,16.6033125 4.48,16.51 C4.28,16.32 4.28,16 4.48,15.8 L6.61,13.68 C6.8,13.49 7.12,13.49 7.31,13.68 C7.5,13.87 7.5,14.19 7.31,14.38 L5.19,16.51 C5.09,16.61 4.97,16.66 4.83,16.66 Z M18.97,11 L17.05,12.92 L16.34,13.63 L17.05,14.34 L20.49,17.78 L17.24,21.03 L13.8,17.59 L13.09,16.88 L12.38,17.59 L10.98,18.99 L10.98,11 L18.97,11 L18.97,11 Z M18.97,10 L10.99,10 C10.44,10 9.99,10.45 9.99,11 L9.99,18.98 C9.99,19.58 10.48,19.98 11,19.98 C11.25,19.98 11.5,19.89 11.7,19.69 L13.1,18.29 L16.54,21.73 C16.74,21.93 16.99,22.02 17.25,22.02 C17.51,22.02 17.76,21.92 17.96,21.73 L21.21,18.48 C21.3972281,18.2931554 21.502444,18.0395095 21.502444,17.775 C21.502444,17.5104905 21.3972281,17.2568446 21.21,17.07 L17.77,13.63 L19.69,11.71 C20.31,11.08 19.86,10 18.97,10 L18.97,10 Z"
          fill="currentColor"
          fill-rule="nonzero"
        ></path>
      </g>
    </g>
  </svg>
</template>
