<template>
  <div :class="{ 'tw-invisible': !fullName }" class="md:tw-w-nav-user">
    <div
      v-if="isUserMenuOpen"
      aria-label="overlay"
      class="tw-fixed tw-bottom-0 tw-right-0 tw-top-0 tw-z-30 sm:tw-block tw-hidden tw-w-full tw-h-full"
    />
    <div
      :class="{ 'tw-shadow-lg md:tw-left-auto tw-left-0 tw-w-full': isUserMenuOpen }"
      class="nav-user-list-item tw-top-0 tw-block tw-w-auto md:tw-w-nav-user tw-bg-white tw-z-30 tw-h-full md:tw-h-auto"
    >
      <NavUserMenuButton
        :class="{ 'md:tw-hidden': isUserMenuOpen }"
        class="tw-w-full tw-outline-none focus:tw-outline-none md:tw-px-2 md:tw-py-2.5 tw-px-0 tw-h-full md:tw-h-auto"
        :full-name="fullName"
        :last-name="lastName"
        :language="language"
        @toggleUserMenu="toggleUserMenu"
        :is-user-menu-open="isUserMenuOpen"
        :show-welcome-text-on-mobile="false"
      />
      <div
        v-if="isUserMenuOpen"
        v-click-outside="toggleUserMenu"
        class="tw-bg-white tw-w-full tw-z-50 tw-shadow-lg nav-user-menu-options tw-absolute md:tw-w-nav-user md:tw-left-auto tw-left-0 tw-top-0 tw-rounded-lg"
      >
        <NavUserMenuButton
          class="tw-w-full tw-outline-none focus:tw-outline-none tw-px-2 tw-py-2.5 tw-pt-2.5 tw-h-full md:tw-h-auto tw-items-center tw-text-left"
          :full-name="fullName"
          :last-name="lastName"
          :language="language"
          @toggleUserMenu="toggleUserMenu"
          :is-user-menu-open="isUserMenuOpen"
          :show-welcome-text-on-mobile="true"
        />
        <hr class="md:tw-mx-3 tw-bg-gray-300" />
        <div class="tw-my-1">
          <router-link
            data-tooltip
            to="/settings"
            class="tw-mx-2 tw-block hover:tw-bg-gray-200 hover:tw-no-underline active:tw-bg-gray-300 tw-px-1 tw-py-2.5 md:tw-text-gray-700 hover:tw-text-gray-800"
            @click="toggleUserMenu"
          >
            <div class="tw-flex tw-items-center">
              <BaseIcon :height="24" :width="24" icon-name="settings-gray" class="tw-fill-gray-800 tw-mr-1">
                <SettingsGray />
              </BaseIcon>
              <span class="tw-text-base md:tw-text-sm tw-text-gray-800">{{ $t('userMenu.settings') }}</span>
            </div>
          </router-link>
          <router-link
            data-tooltip
            to="/organisations"
            class="tw-mx-2 tw-block hover:tw-bg-gray-200 hover:tw-no-underline active:tw-bg-gray-300 tw-relative tw-px-1 tw-py-2.5 tw-text-gray-700 hover:tw-text-gray-800"
            @click="toggleUserMenu"
          >
            <div class="tw-flex tw-items-center">
              <BaseIcon :height="24" :width="24" icon-name="arrow-repeat" class="tw-fill-gray-800 tw-mr-1">
                <Organization />
              </BaseIcon>
              <span class="tw-text-base md:tw-text-sm tw-text-gray-800">{{ $t('userMenu.organizationSwitcher') }}</span>
            </div>
          </router-link>
        </div>
        <hr class="tw-bg-gray-300 tw-mb-1 md:tw-mx-2" v-if="isTrivagoUser()" />
        <div class="tw-my-1">
          <router-link
            v-if="isTrivagoUser()"
            data-tooltip
            to="/admin-tool"
            class="tw-mx-2 tw-block hover:tw-bg-gray-200 hover:tw-no-underline active:tw-bg-gray-300 tw-relative tw-px-1 tw-py-2.5 tw-text-gray-700 hover:tw-text-gray-800"
            @click="toggleUserMenu"
          >
            <div class="tw-flex tw-items-center">
              <BaseIcon :height="24" :width="24" icon-name="arrow-repeat" class="tw-fill-gray-800 tw-mr-1">
                <Tool />
              </BaseIcon>
              <span class="tw-text-base md:tw-text-sm tw-text-gray-800">{{ $t('Admin tool') }}</span>
            </div>
          </router-link>

          <a
            target="_blank"
            :href="supportCenterUrl({ language })"
            class="md:tw-hidden tw-block hover:tw-bg-gray-200 active:tw-bg-gray-300 hover:tw-no-underline tw-relative tw-px-1 tw-py-2.5 tw-text-gray-700 hover:tw-text-gray-800"
            @click="toggleUserMenu"
          >
            <div class="tw-flex tw-items-center tw-justify-between">
              <div class="tw-items-center tw-flex">
                <BaseIcon :height="24" :width="24" icon-name="arrow-repeat" class="tw-fill-gray-800 tw-mr-1">
                  <QuestionMark />
                </BaseIcon>
                <span class="tw-text-base md:tw-text-sm tw-text-gray-800">{{ $t('navigation.supportCenter') }}</span>
              </div>
              <BaseIcon :height="24" :width="24" icon-name="new-tab" class="tw-fill-none tw-stroke-gray-800">
                <NewTab />
              </BaseIcon>
            </div>
          </a>
        </div>
        <hr class="md:tw-mx-3 tw-bg-gray-300" />
        <div class="tw-p-2 tw-pt-0 tw-mt-1">
          <button
            id="signOutButton"
            data-tooltip
            class="tw-block hover:tw-bg-gray-200 active:tw-bg-gray-300 hover:tw-no-underline tw-relative tw-px-1 tw-py-2.5 tw-text-gray-700 tw-w-full hover:tw-text-gray-800"
            @click="logout"
            @keydown.enter="logout"
          >
            <div class="tw-flex tw-items-center">
              <BaseIcon :height="24" :width="24" icon-name="arrow-left" class="tw-mr-1 tw-fill-none">
                <ArrowLeft />
              </BaseIcon>
              <span class="tw-text-base md:tw-text-sm tw-text-gray-800">{{ $t('userMenu.signOut') }}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, ref } from 'vue'

import NavUserMenuButton from '@/components/dashboard/nav/NavUserMenuButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import SettingsGray from '@/components/icons/SettingsGray.vue'
import Tool from '@/components/icons/Tool.vue'
import QuestionMark from '@/components/icons/QuestionMark.vue'
import ArrowLeft from '@/components/icons/ArrowLeft.vue'
import Organization from '@/components/icons/Organization.vue'
import NewTab from '@/components/icons/NewTab.vue'
import { getSupportCenterUrl } from '@/utils/Utility.js'
import { useUserDetails } from '@/layouts/queries'

const store = useStore()
const userId = computed(() => store.state.session.userId)
const { userDetails, fullName } = useUserDetails(userId)

const isUserMenuOpen = ref(false)
const lastName = computed(() => userDetails.value?.lastName)
const userEmail = computed(() => userDetails.value?.email)
const language = computed(() => store.state.locale.language)

const logout = () => {
  window.sessionStorage?.removeItem?.('cpa_upsell_skip')
  window.sessionStorage?.removeItem?.('studio_plus_popup_skip')
  store.dispatch('session/logout')
}

const toggleUserMenu = () => {
  isUserMenuOpen.value = !isUserMenuOpen.value
}

const isTrivagoUser = () => {
  if (userEmail.value?.split('@')[1] === 'trivago.com' || userEmail.value === 'aniadanilec@gmail.com') return true
  else return false
}

const supportCenterUrl = locale => getSupportCenterUrl(locale.language, 'supportCentre')
</script>
<style scoped>
.nav-user-list-item {
  right: 20px;
}
.nav-user-menu-options {
  clip-path: inset(0px -15px -15px -15px);
}
</style>
