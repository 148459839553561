<template>
  <div>
    <div class="md:tw-hidden tw-w-full tw-border-b tw-border-blue-300">
      <button
        class="tw-flex tw-justify-between tw-text-blue-800 tw-w-full tw-text-left focus:tw-outline-0 tw-py-4 tw-px-4 tw-relative mobile-selected-option"
        @click="mobileDropdownOpen = !mobileDropdownOpen"
        @keydown.enter="mobileDropdownOpen = !mobileDropdownOpen"
      >
        {{ $t(termsAppList[selectedOptionInd].label) }}
        <BaseIcon
          icon-name="chevron-right"
          :height="24"
          :width="24"
          :viewbox-height="24"
          :viewbox-width="24"
          class="tw-text-black"
          :class="mobileDropdownOpen ? '-tw-rotate-90' : 'tw-rotate-90'"
        >
          <ChevronRight />
        </BaseIcon>
      </button>
      <ul :class="mobileDropdownOpen ? 'tw-block' : 'tw-hidden'">
        <li v-for="(appName, i) in termsAppList" :key="i" class="tw-text-black">
          <button
            v-if="i !== selectedOptionInd"
            class="focus:tw-outline-0 tw-text-left tw-w-full tw-pb-4 tw-px-4"
            @click="handleOptionClick(i)"
            @keydown.enter="handleOptionClick(i)"
          >
            {{ $t(appName.label) }}
          </button>
        </li>
      </ul>
    </div>
    <ul
      class="tw-hidden md:tw-flex tw-justify-between tw-gap-2 tw-max-w-screen-xl tw-mx-auto tw-border-b tw-border-gray-300 tw-pb-4 tw-px-4 tw-my-5"
    >
      <li
        v-for="(appName, i) in termsAppList"
        :key="i"
        :class="i === selectedOptionInd ? 'tw-text-blue-800' : 'tw-text-black'"
      >
        <button class="focus:tw-outline-0" @click="handleOptionClick(i)" @keydown.enter="handleOptionClick(i)">
          {{ $t(appName.label) }}
        </button>
      </li>
    </ul>
    <section class="tw-max-w-4xl tw-py-8 tw-px-4 tw-text-justify tw-mx-auto">
      <h1 class="tw-text-3xl md:tw-text-5xl tw-font-bold tw-text-center tw-mb-4">
        {{ $t(termsAppList[selectedOptionInd].label) }}
      </h1>
      <h2
        class="tw-border-b tw-border-gray-300 tw-pb-5 tw-mb-8 tw-text-xl tw-text-center"
        v-html="$t('terms_and_conditions.heading')"
      ></h2>
      <div class="terms-body" v-html="$t(termsAppList[selectedOptionInd].terms)"></div>
    </section>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'

const route = useRoute()
const selectedOptionInd = ref(0)
const mobileDropdownOpen = ref(false)

const termsAppList = computed(() => [
  { label: 'appSwitcher.dashboardTitle', terms: 'terms_and_conditions.terms' },
  { label: 'app.rateConnect.name', terms: 'rc.terms_and_conditions.terms' },
  { label: 'product.proPackage.name', terms: 'terms_and_conditions_terms' },
  {
    label: 'studio.plus.compare.features.businessstudioplus.title',
    terms: 'business_studio_plus_terms_and_conditions.terms',
  },
])

onMounted(() => {
  if (route.query.app === 'rate-connect') {
    selectedOptionInd.value = 1
  } else if (route.query.app === 'business-studio-plus') {
    selectedOptionInd.value = 3
  } else if (route.query.app === 'pro-package') {
    selectedOptionInd.value = 2
  }
})

const handleOptionClick = optionIndex => {
  mobileDropdownOpen.value = false
  selectedOptionInd.value = optionIndex
}
</script>
<style scoped>
.terms-body :deep(p) {
  margin-bottom: 16px;
}
.terms-body :deep(a) {
  color: #007bff;
}
.terms-body :deep(h3) {
  font-weight: bold;
  margin-bottom: 16px;
  font-size: 28px;
  line-height: 35px;
  margin-top: 32px;
}
.terms-body :deep(#exhibit-1) {
  padding-top: 64px;
}

.terms-body :deep(#exhibit-2) {
  padding-top: 64px;
}
</style>
