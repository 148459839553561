<template>
  <div class="tw-mt-6">
    <p class="tw-mb-6" v-html="$t('rc_pause_flow_reminder_info')" />
    <p v-html="$t('rc_pause_flow_support')" />
  </div>
</template>
<style scoped>
p :deep(a) {
  @apply tw-text-blue-700 tw-underline;
}
p :deep(a):hover {
  @apply tw-text-blue-800 tw-underline;
}
</style>
