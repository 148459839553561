<template>
  <g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9816 6C9.96157 6 8.29157 7.481 8.00557 9.412C7.96057 9.72 8.19357 10 8.50457 10H9.52457C9.75557 10 9.96457 9.845 10.0086 9.619C10.1886 8.699 11.0066 8 11.9816 8C13.0866 8 13.9906 8.9 13.9906 10C13.9906 10.82 13.4096 11.625 12.7656 11.84C11.5976 12.229 10.9766 13.281 10.9766 14V14.5C10.9766 14.776 11.2016 15 11.4796 15H12.4836C12.7616 15 12.9856 14.776 12.9856 14.5V14.24H12.9866C12.9866 14.239 12.9856 14.237 12.9856 14.236C12.9856 14.021 13.1246 13.841 13.3156 13.771V13.77C13.8656 13.625 16.0006 12.469 16.0006 10C16.0006 7.79 14.2016 6 11.9816 6Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9814 16C11.4294 16 10.9814 16.448 10.9814 17C10.9814 17.552 11.4294 18 11.9814 18C12.5334 18 12.9814 17.552 12.9814 17C12.9814 16.448 12.5334 16 11.9814 16Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 21C7.038 21 3 16.962 3 12C3 7.038 7.038 3 12 3C16.962 3 21 7.038 21 12C21 16.962 16.962 21 12 21ZM12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2Z"
    />
  </g>
</template>
