<template>
  <div class="tw-w-full tw-flex-1 tw-flex tw-flex-col">
    <ConversionFunnelKPITitle :custom-html="$t('kpi_partners_distribution')" />
    <template v-if="Array.isArray(partnerDistribution)">
      <NoDataAvailable v-show="!partnerDistHasData" class="no-data-partner-dist" />
      <ul v-show="partnerDistHasData" class="tw-text-sm tw-leading-4">
        <li v-for="(partner, i) in partnerDistribution" :key="i" class="tw-mb-2">
          <p class="tw-text-base">
            <strong class="tw-text-blue-700 tw-font-bold tw-mr-1">{{ partner.clicks_to_partner }}</strong
            >{{ partner.partnerTranslatedName }}
          </p>
          <small class="tw-text-gray-700 tw-text-xs">
            {{
              $t('tbs_performance_page_percentage', {
                percentage: percentageValue(partner),
                percentageOf: percentageOfValue(partner),
              })
            }}
          </small>
        </li>
      </ul>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import ConversionFunnelKPITitle from './ConversionFunnelKPITitle.vue'
import NoDataAvailable from '../NoDataAvailable.vue'

const store = useStore()
const props = defineProps({
  partnerDistribution: Array,
})

const language = computed(() => store.state.locale.language)

const partnerDistHasData = computed(() => props.partnerDistribution.length >= 1)
const percentageValue = partner =>
  Intl.NumberFormat(language.value, { style: 'percent' }).format(partner.percentage_of_total_clicks / 100)

const percentageOfValue = partner => Intl.NumberFormat(language.value).format(partner.percentage_of)
</script>
