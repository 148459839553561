<template>
  <div class="tw-animate-pulse tw-w-full">
    <div class="tw-rounded-lg tw-bg-gray-300 tw-w-full" :style="{height}" />
  </div>
</template>
<script>
export default {
  props: {
    height: {
      type: String,
      default: '150px',
    },
  },
}
</script>
