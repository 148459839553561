<template>
  <table v-if="!hasCompetitors && columnCount > 0">
    <thead class="tw-border tw-rounded-t tw-border-gray-300 tw-bg-gray-100 tw-text-[#8D8D8B]">
      <tr class="tw-border-b tw-border-gray-300">
        <th class="tw-border-0 tw-px-5 tw-py-2 tw-font-normal" :colspan="columnCount + 1">
          <div class="tw-flex tw-items-baseline tw-justify-between tw-gap-2">
            <span
              ><strong class="tw-mr-1">{{ $t('vp_timeframe') }} </strong
              ><span>{{ $t(selectedTimeFrameLabel) }}</span></span
            >
            <small>{{ $t('vp_table_graph_info') }}</small>
          </div>
        </th>
      </tr>
      <tr class="tw-border-0 tw-text-center">
        <th class="tw-border-0 tw-min-w-60"></th>
        <th
          v-for="[colKey, colName] in Object.entries(columns)"
          :key="colKey"
          class="tw-border-0 tw-font-normal"
          :style="{ width: `${100 / columnCount}%` }"
        >
          {{ $t(colName) }}
        </th>
      </tr>
    </thead>
    <tbody class="tw-text-center">
      <tr v-for="(accommodation, index) in noCompetitorsTableData" :key="accommodation">
        <td class="tw-max-w-60 tw-text-[#8D8D8B]">
          {{ $t(accommodation) }}
        </td>
        <td v-if="index === 0" :rowspan="noCompetitorsTableData.length" :colspan="columnCount + 1">
          <span class="tw-text-[#6C6C6B] tw-flex tw-flex-col"
            >{{ $t(`vp_competitor_table_empty_state`) }}
            <GhostButton
              class="tw-mt-4 tw-mx-auto tw-max-w-[200px] tw-items-center"
              size="medium"
              @click="rediectToCompetitorsSelection"
              >{{ `+ ${$t('vp_competitor_table_empty_cta')}` }}</GhostButton
            >
          </span>
        </td>
      </tr>
    </tbody>
  </table>

  <table v-else-if="columnCount > 0 && tableData && hasCompetitors" class="tw-border-0">
    <thead class="tw-border tw-rounded-t tw-border-gray-300 tw-bg-gray-100">
      <tr class="tw-border-b tw-border-gray-300">
        <th class="tw-border-0 tw-px-5 tw-py-2 tw-font-normal" :colspan="columnCount + 1">
          <div class="tw-flex tw-items-baseline tw-justify-between tw-gap-2">
            <span
              ><strong class="tw-mr-1">{{ $t('vp_timeframe') }} </strong
              ><span>{{ $t(selectedTimeFrameLabel) }}</span></span
            >
            <small>{{ $t('vp_table_graph_info') }}</small>
          </div>
        </th>
      </tr>
      <tr class="tw-border-0 tw-text-center">
        <th class="tw-border-0 tw-min-w-60"></th>
        <th
          v-for="[colKey, colName] in Object.entries(columns)"
          :key="colKey"
          class="tw-border-0 tw-font-normal"
          :style="{ width: `${100 / columnCount}%` }"
        >
          {{ $t(colName) }}
        </th>
      </tr>
    </thead>
    <tbody class="tw-text-center">
      <tr
        v-for="[accommodation, values] in formattedTableData"
        :key="accommodation.id"
        :class="selectedAccommodationId === accommodation.id ? 'tw-bg-blue-100 tw-text-blue-800' : ''"
      >
        <td class="tw-max-w-60">
          <UiTooltip theme="material" tooltip-position="top" :label="accommodation.name" class="tw-truncate">
            {{ $t(accommodation.name) }}
          </UiTooltip>
        </td>
        <td
          v-for="[valueKey, value] in Object.entries(values)"
          :key="`${accommodation.id}${valueKey}`"
          :class="
            maxValuePerMetricKey?.[valueKey] === getRoundedValue(value) && getRoundedValue(value) !== 0
              ? 'tw-bg-blue-400 tw-text-blue-800 tw-font-bold'
              : ''
          "
        >
          {{ getCellValue(value) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import { selectedAccommodationId } from '@/layouts/queries'
import { useCompetitors } from '@/components/rateInsights/queries'

const store = useStore()
const router = useRouter()
const props = defineProps({
  timeFrame: {
    type: String,
    required: true,
    default: '30',
  },
  /* Expected format:
    {
      lte2days: '1 night',
      days34: '3 - 4 nights',
      days56: '5 - 6 nights',
      days78: '6 - 7 nights',
      gte9days: '8 nights or more',
    }
  */
  columns: {
    type: Object,
    required: true,
    default() {
      return null
    },
  },
  /* Expected format:
    {
      "6608": {
        "other": 16,
        "weekend": 66,
        "week": 4,
        "longTrip": 14
      },
      "6612": {
        "other": 18,
        "weekend": 63,
        "week": 11,
        "longTrip": 9
      },
    }
  */
  tableData: {
    type: Object,
    required: true,
    default() {
      return null
    },
  },
})

const language = computed(() => store.state.locale.language)
const { competitors, isCompetitorsError } = useCompetitors(selectedAccommodationId)

const hasCompetitors = computed(
  () => competitors?.value.length > 0 && competitors.value !== undefined && !isCompetitorsError.value
)

const rediectToCompetitorsSelection = () => {
  router.push({ name: 'rate-insights-onboarding' })
}

const columnCount = computed(() => {
  if (!props.columns) return 0
  return Object.keys(props.columns).length
})

const noCompetitorsTableData = computed(() => {
  let fakeCompetitors = [
    'vp_competitor_table_property',
    'competitor_one',
    'competitor_two',
    'competitor_three',
    'competitor_four',
    'competitor_five',
  ]
  return fakeCompetitors
})

const formattedTableData = computed(() => {
  if (!props.tableData || !selectedAccommodationId.value || !competitors.value) return null

  const myPropertyData = Object.keys(props.columns).reduce((accum, metricKey) => {
    return {
      ...accum,
      [metricKey]: props.tableData?.[selectedAccommodationId.value]?.[metricKey] ?? null,
    }
  }, {})

  const selectedAccommodationData = [
    { id: selectedAccommodationId.value, name: 'vp_competitor_table_property' },
    myPropertyData,
  ]

  const competitorsData = competitors.value.map(competitor => {
    const competitorIdAndName = { id: competitor.id, name: competitor.name }
    const competitorMetric = props.tableData[competitor.id]
    const competitorMetricsWithValues = Object.keys(props.columns).reduce((accum, metricKey) => {
      return {
        ...accum,
        [metricKey]: competitorMetric?.[metricKey] ? competitorMetric?.[metricKey] : null,
      }
    }, {})
    return [competitorIdAndName, competitorMetricsWithValues]
  })

  competitorsData.sort((a, b) => {
    if (a[0].name.toLowerCase() < b[0].name.toLowerCase()) {
      return -1
    }
    if (a[0].name.toLowerCase() > b[0].name.toLowerCase()) {
      return 1
    }
    return 0
  })

  return [selectedAccommodationData, ...competitorsData]
})

const getRoundedValue = value => {
  if (value > 0 && value < 1) {
    return 0.5
  } else {
    return Math.round(value)
  }
}

const maxValuePerMetricKey = computed(() => {
  if (!Array.isArray(formattedTableData.value) || !props.columns) return null

  return Object.keys(props.columns).reduce((accum, metricKey) => {
    const metricKeyValues = formattedTableData.value.map(accommodation =>
      getRoundedValue(accommodation[1]?.[metricKey])
    )
    const maxValue = Math.max(...metricKeyValues)
    return { ...accum, [metricKey]: maxValue }
  }, {})
})

const timeFrameOptions = [
  { label: 'vp_last_30_days', value: '30' },
  { label: 'last_60_days', value: '60' },
  { label: 'vp_last_90_days', value: '90' },
]
const selectedTimeFrameLabel = computed(() => timeFrameOptions.find(option => option.value === props.timeFrame)?.label)

const getCellValue = cell => {
  if (cell === null || cell === 0) {
    return '-'
  } else if (cell < 1) {
    return `<${new Intl.NumberFormat(language, { style: 'percent', maximumFractionDigits: 0 }).format(1 / 100)}`
  } else {
    return new Intl.NumberFormat(language, { style: 'percent', maximumFractionDigits: 0 }).format(cell / 100)
  }
}
</script>
