<template>
  <div
    class="tw-animate-pulse tw-flex tw-flex-col lg:tw-flex-row tw-gap-3 xl:tw-gap-12 tw-items-center"
    aria-hidden="true"
  >
    <div class="tw-bg-gray-300 tw-rounded-full tw-shrink-0 circle-loading"></div>
    <div class="tw-bg-gray-300 tw-rounded rectangle-loading"></div>
    <div class="tw-bg-gray-300 tw-rounded rectangle-loading"></div>
  </div>
</template>

<style scoped>
.circle-loading {
  height: 96px;
  width: 96px;
  @media (max-width: theme('screens.md')) {
    height: 14rem;
    width: 14rem;
  }
}
.rectangle-loading {
  height: 96px;
  width: 100%;
}
</style>
