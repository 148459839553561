<template>
  <button
    class="tw-rounded-lg tw-flex tw-flex-row lg:tw-flex-col performance-card-shadow tw-px-4 tw-py-2 tw-text-gray-800 tw-w-full lg:tw-w-1/4 lg:tw-mx-2 lg:tw-mb-0 tw-mx-0 tw-mb-3 tw-items-center"
    :class="getClass"
    @click="$emit('onClick', $event.target.value)"
  >
    <div
      class="tw-text-base tw-w-3/5 lg:tw-w-full tw-pb-1 tw-border-0 lg:tw-border-b tw-font-bold tw-flex tw-justify-start lg:tw-justify-between tw-items-end"
      :class="isSelected ? 'tw-border-white tw-text-white' : 'tw-border-gray-300 tw-text-gray-800'"
    >
      <p class="tw-w-3/4 lg:tw-w-1/2 tw-text-left">{{ $t(label) }}</p>
      <UiTooltip tooltip-position="top" :label="getTooltip" class="tw-cursor-pointer tw-mb-1">
        <BaseIcon
          icon-name="information-outline"
          :height="16"
          :width="16"
          class="tw-fill-none tw-h-[22px] tw-w-[22px] tw-ml-1"
          :class="isSelected ? 'tw-stroke-white' : 'tw-stroke-blue-700'"
        >
          <InformationOutline />
        </BaseIcon>
      </UiTooltip>
    </div>
    <div
      class="tw-flex tw-w-full tw-justify-between lg:tw-items-end"
      :class="isSelected ? 'tw-text-white' : 'tw-text-gray-800'"
    >
      <p class="tw-text-[1.5rem] lg:tw-text-[1.75rem]">{{ formatNumber }}</p>
      <div class="tw-text-sm tw-items-center tw-flex tw-font-bold tw-p-1.5 tw-pr-0">
        <span class="tw-rounded-full tw-h-4 tw-w-4 tw-p-1 tw-inline-flex tw-items-center tw-mr-1">
          <BaseIcon
            :height="11"
            :width="11"
            :icon-name="isPositive ? 'arrow-up-right' : 'arrow-down-right'"
            :icon-color="isSelected ? '#fff' : '#0A1121'"
          >
            <ArrowUpRight v-if="isPositive" />
            <ArrowDownRight v-else />
          </BaseIcon>
        </span>
        {{ $n(value / 100, 'percent') }}
      </div>
    </div>
  </button>
</template>
<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import ArrowUpRight from '@/components/icons/ArrowUpRight.vue'
import ArrowDownRight from '@/components/icons/ArrowDownRight.vue'
import { CURRENCIES } from '@/constants/currencies.js'

const store = useStore()
const { t, n } = useI18n()
const props = defineProps({
  label: {
    type: String,
    required: true,
    default: '',
  },
  elementId: {
    type: String,
    required: false,
    default: '',
  },
  value: {
    type: Number,
    required: false,
    default: null,
  },
  number: {
    type: Number,
    required: false,
    default: null,
  },
  index: {
    type: Number,
    required: true,
    default: 0,
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
})
const tooltipTexts = {
  visits: 'rc_def_clicks',
  bookings: 'rc_def_bookings',
  revenue: 'rc_def_revenue',
  cpa: 'rc_def_ecpa',
  commissionCost: 'rc_commissions_tooltip_pay_per_stay',
}
const currency = computed(() => store.state.currency)
const isPositive = computed(() => props.value >= 0)
const getTooltip = computed(() => t(tooltipTexts[props.elementId]))
const getNumber = computed(() => props.number ?? 0)
const formatNumber = computed(() => {
  if (props.elementId === 'cpa') {
    return n(getNumber.value / 100, 'percent')
  } else if (props.elementId === 'revenue' || props.elementId === 'commissionCost') {
    return `${CURRENCIES[currency.value]}${n(getNumber.value / 100, 'currency')}`
  } else {
    return n(getNumber.value, 'decimal')
  }
})
const getClass = computed(() => {
  if (props.isSelected) {
    if (props.index === 0) {
      return 'tw-bg-blue-700 lg:tw-ml-0'
    } else if (props.index === 3) {
      return 'tw-bg-blue-700 lg:tw-mr-0'
    } else {
      return 'tw-bg-blue-700'
    }
  } else {
    if (props.index === 0) {
      return 'tw-bg-white lg:tw-ml-0'
    } else if (props.index === 3) {
      return 'tw-bg-white lg:tw-mr-0'
    } else {
      return 'tw-bg-white'
    }
  }
})
</script>
<style scoped>
.performance-card-shadow {
  box-shadow:
    0 2px 4px 0 rgba(63, 87, 189, 0.1),
    0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
</style>
