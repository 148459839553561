<template>
  <div class="tw-my-24 tw-flex tw-items-center tw-justify-center tw-flex-col" v-if="staticMessageType !== 'none'">
    <BaseIcon
      :height="16"
      :width="16"
      icon-name="search"
      class="tw-h-20 tw-w-20"
      v-if="staticMessageType === 'welcome'"
    >
      <Search class="tw-fill-gray-800" />
    </BaseIcon>
    <BaseIcon
      :height="64"
      :width="64"
      icon-name="no-data"
      class="tw-h-20 tw-w-20"
      v-if="staticMessageType === 'unauthorized'"
    >
      <NoData />
    </BaseIcon>
    <BaseIcon
      :height="34"
      :width="34"
      icon-name="no-data"
      class="tw-h-20 tw-w-20"
      v-if="staticMessageType === 'noResults'"
    >
      <NoResults />
    </BaseIcon>
    <BaseIcon
      :height="48"
      :width="48"
      icon-name="no-data"
      class="tw-h-20 tw-w-20"
      v-if="staticMessageType === 'genericError'"
    >
      <GenericError />
    </BaseIcon>
    <p class="tw-max-w-xl tw-mt-12 tw-text-center tw-text-gray-800">{{ messages[staticMessageType].text }}</p>
  </div>
</template>

<script setup>
import BaseIcon from '@/components/BaseIcon.vue'
import Search from '@/components/icons/Search.vue'
import NoData from '@/components/icons/NoData.vue'
import NoResults from '@/components/icons/NoResults.vue'
import GenericError from '@/components/icons/GenericError.vue'

defineProps({
  staticMessageType: {
    type: String,
    default: 'none',
    validator(value) {
      return ['none', 'unauthorized', 'noResults', 'welcome', 'genericError'].includes(value)
    },
  },
})
const messages = {
  unauthorized: {
    text: "Only trivago eyes are allowed to see what's behind that search bar. Please log in with a trivago.com email to interact with this tool. Thanks!",
  },
  noResults: {
    text: 'No assignment found for this accommodation ID. Please check your input and try again.',
  },
  welcome: {
    text: 'Welcome to the Admin tool.  Search for an accommodation id to view information about the organization, users, and product ownerships.',
  },
  genericError: {
    text: 'We are unable to process your request currently. If this message occurs again after a retry, please report to #independent-hotels-bugs',
  },
}
</script>
