<template>
  <BaseCard
    class="tw-relative tw-overflow-hidden tw-max-w-screen-xxl tw-mx-auto tw-mb-8 tw-flex md:tw-justify-between"
    noPadding
  >
    <div class="tw-w-full md:tw-w-2/3 tw-py-8 tw-pl-8 tw-pr-16">
      <div class="md:tw-max-w-[80%]">
        <div class="tw-max-w-[120px] tw-pb-6">
          <BaseIcon
            icon-name="trivago Business Studio"
            :height="23"
            :width="237"
            :viewbox-height="23"
            :viewbox-width="237"
            class="tw-inline-block"
          >
            <TrivagoLogoPlus />
          </BaseIcon>
        </div>
        <h3 class="tw-font-bold tw-text-[32px] tw-mb-8" v-html="$t('business_studio_plus_lp_header')" />
        <div class="tw-font-bold tw-text-base tw-mb-3">{{ $t('studio_plus_banner_subtext') }}</div>
        <div class="tw-flex tw-mb-4 tw-justify-start tw-flex-col tw-flex-wrap">
          <span class="tw-font-light tw-items-center tw-flex tw-mr-2 tw-my-1" v-for="item in list" :key="item">
            <CheckMarkInCircle height="24px" width="24px" class="tw-inline tw-mr-4 tw-stroke-blue-700" />
            {{ $t(item) }}
          </span>
        </div>
        <div class="tw-mt-8 tw-flex tw-items-start tw-flex-col">
          <div class="tw-bg-red-800 tw-text-white tw-px-1 tw-py-[3px] tw-rounded tw-font-bold tw-text-xs tw-uppercase">
            {{ $t('business_studio_plus_discount', { DiscountPercentage: '15' }) }}
          </div>
          <p
            class="tw-font-light current-price"
            v-html="
              $t('business_studio_plus_price_discounted', {
                AnnualPrice: `${CURRENCIES[currency]}${$n(annualPrice / 100, 'currencyCode')}`,
              })
            "
          />
          <p class="tw-font-light tw-line-through">
            {{
              $t('business_studio_plus_price_no_discount', {
                AnnualPrice: `${CURRENCIES[currency]}${$n(monthlyPrice / 100, 'currencyCode')}`,
              })
            }}
          </p>
        </div>
        <div class="tw-flex tw-mt-6 tw-mb-4 tw-flex-row tw-items-center md:tw-items-start">
          <MainButton @click="redirectToCompare" class="tw-mr-2">{{ $t('studio_plus_start_cta') }}</MainButton>
        </div>
        <small>{{ $t('studio_plus_connectivity_footnote') }}</small>
      </div>
    </div>
    <div class="tw-hidden md:tw-flex md:tw-w-1/3 tw-relative tw-bg-decorative-yellow">
      <img
        class="tw-m-auto tw-z-0 tw-absolute tw-top-0 tw-bottom-0 tw-left-[-270px] tw-min-w-[650px]"
        :src="heroImage"
        alt="Studio Plus"
      />
    </div>
  </BaseCard>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { CURRENCIES } from '@/constants/currencies.js'
import { useStudioPlusPrice } from '@/components/payment/queries'

import appAnalytics from '@/utils/tracking'
import CheckMarkInCircle from '@/components/icons/CheckMarkInCircle.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import TrivagoLogoPlus from '@/components/icons/TrivagoLogoPlus.vue'

import { selectedAccommodationId } from '@/layouts/queries'

const router = useRouter()
const store = useStore()
const list = [
  'studio_plus_banner_benefit_1',
  'studio_plus_banner_benefit_2',
  'studio_plus_banner_benefit_3',
  'studio_plus_banner_benefit_4',
]

const currency = computed(() => store.state.currency)
const { studioPlusPriceMonthly, studioPlusPriceAnnual } = useStudioPlusPrice(selectedAccommodationId)
const monthlyPrice = computed(() => studioPlusPriceMonthly.value?.price ?? 0)
const annualPrice = computed(() => studioPlusPriceAnnual.value?.price ?? 0)

const heroImage = new URL(`@/assets/img/studio-plus/studio-plus-home-banner.svg`, import.meta.url)

const redirectToCompare = () => {
  router.push({ name: 'studio-plus-compare' })
  appAnalytics.track(appAnalytics.events.SP_HOMEPAGE_BANNER_CLICKED, {
    item_id: selectedAccommodationId.value,
  })
}
</script>
<style scoped>
.current-price :deep(span) {
  font-size: 32px;
  font-weight: bold;
}
</style>
