<template>
  <div>
    <svg height="100%" width="100%" viewBox="0 0 36 36">
      <path
        class="circle-bg"
        :class="colorClass"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        class="circle"
        :class="colorClass"
        :stroke-dasharray="`${score * 10}, 100`"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <text x="50%" y="50%" class="score md:tw-font-bold">{{ score === 0 ? '/' : score }}</text>
      <text x="50%" y="65%" class="subtext md:tw-hidden tw-fill-current tw-text-gray-500">trivago Rating Index</text>
    </svg>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  score: {
    type: [Number, String],
    default: 10,
  },
})
const colorClass = computed(() => {
  if (props.score <= 0) return 'gray'
  if (props.score <= 7) return 'red'
  if (props.score <= 7.4) return 'orange'
  if (props.score <= 7.9) return 'light-green'
  if (props.score <= 8.4) return 'green'
  return 'strong-green'
})
</script>

<style scoped>
.circle-bg {
  fill: none;
  stroke-width: 2.8;
  opacity: 0.2;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke: theme('colors.green.500');
}

.strong-green {
  stroke: #007400;
}

.green {
  stroke: #72ac2f;
}

.light-green {
  stroke: #98bf6a;
}

.orange {
  stroke: #e57a00;
}

.red {
  stroke: #a3171a;
}

.gray {
  stroke: #808080;
}

.score {
  font-size: 60%;
  text-anchor: middle;
  dominant-baseline: middle;

  @media (min-width: theme('screens.md')) {
    font-size: 80%;
    transform: translateY(4%);
  }
}
.subtext {
  font-size: 15%;
  text-anchor: middle;
  dominant-baseline: middle;
}
</style>
