<template>
  <div class="tw-flex tw-flex-wrap thumbnails-container tw-h-screen">
    <div v-for="item in items" :key="item?.id" class="thumbnail-box">
      <div
        v-if="mainImageBadge(item)"
        class="image-size-badge tw-py-[3px] tw-px-[5px] tw-text-xs tw-text-white tw-absolute tw-bottom-[15px] tw-left-[5px]"
      >
        {{ $t('app_images_main_image') }}
      </div>
      <div @click="emit('changeImage', item)" @keydown.enter="emit('changeImage', item)">
        <img
          referrerPolicy="strict-origin-when-cross-origin"
          :class="{ selectedImg: item?.imageId === selectedImage?.imageId }"
          :style="{
            backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%)',
          }"
          class="tw-rounded-lg"
          :src="`https://imgcy.trivago.com/c_fill,d_dummy.jpeg,f_auto,h_272,q_auto,w_272/${item?.imagePath}`"
          alt="Hotel image"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  selectedImage: Object,
  items: Array,
})
const emit = defineEmits(['changeImage'])

const mainImageBadge = item => {
  return item?.isMainImage
}
</script>

<style scoped>
.thumbnail-box {
  flex-basis: 40%;
  width: 130px;
  height: 130px;
  margin: 10px;
  position: relative;
}
.thumbnails-container {
  align-content: flex-start;
  overflow-y: auto;
  width: calc(130px * 2 + 4 * 10px);
  @media (max-width: theme('screens.lg')) {
    width: calc(130px + 4 * 10px);
  }
  @media (max-width: theme('screens.md')) {
    display: none;
  }
}
.thumbnail {
  @apply tw-rounded-lg;
  width: 130px;
  height: 130px;
  background-size: cover;
}
.selectedImg {
  border: 2px solid white;
}
.image-size-badge {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
}
</style>
