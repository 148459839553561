<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M18 19C18 19.55 17.55 20 17 20H7C6.45 20 6 19.55 6 19V12C6 11.45 6.45 11 7 11H8H16H17C17.55 11 18 11.45 18 12V19ZM8 8C8 5.79 9.79 4 12 4C14.21 4 16 5.79 16 8V10H8V8ZM17 10V8C17 5.24 14.76 3 12 3C9.24 3 7 5.24 7 8V10C5.9 10 5 10.9 5 12V19C5 20.1 5.9 21 7 21H17C18.1 21 19 20.1 19 19V12C19 10.9 18.1 10 17 10Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 13C11.45 13 11 13.45 11 14C11 14.37 11.21 14.67 11.5 14.85V17.5C11.5 17.78 11.72 18 12 18C12.28 18 12.5 17.78 12.5 17.5V14.85C12.79 14.67 13 14.37 13 14C13 13.45 12.55 13 12 13Z"
  />
</template>
