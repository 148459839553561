<template>
  <div>
    <div class="tw-pb-0">
      <AuthMenu isLanding @onRegisterClick="onAuthorize('login')" />
      <ProductPageHero pageName="free-booking-links" class="tw-pt-16" @onLoginClick="checkConnectivityProviderPage" />
      <FreeBookingLinksBenefits class="tw-my-14" />
      <ProductPageFaq pageName="free-booking-links" class="tw-bg-white" />
    </div>
    <ThePageFooter isLanding />
    <TheCookieNotice />
  </div>
</template>
<script setup>
import { ref, computed, onMounted, onUnmounted, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import appAnalytics from '@/utils/tracking'
import AuthMenu from '@/components/landingPage/AuthMenu.vue'
import ProductPageHero from '@/components/productPage/ProductPageHero.vue'
import ThePageFooter from '@/components/page/ThePageFooter.vue'
import ProductPageFaq from '@/components/productPage/ProductPageFaq.vue'
import TheCookieNotice from '@/components/legal/TheCookieNotice.vue'
import FreeBookingLinksBenefits from '@/components/productPage/freeBookingLinks/FreeBookingLinksBenefits.vue'

const store = useStore()
const route = useRoute()
const windowWidth = ref(window.innerWidth)

const locale = computed(() => store.state.locale.language)
const isRussian = ref(locale.value === 'ru-RU')

onMounted(() => {
  nextTick(() => {
    window.addEventListener('resize', onResize)
  })
})
onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})

const onResize = () => {
  windowWidth.value = window.innerWidth
}

watch(locale, newValue => {
  isRussian.value = newValue === 'ru-RU'
})

const authorize = authData => store.dispatch('session/authorize', authData)
const onAuthorize = async (type = 'login') => {
  appAnalytics.track(type === 'login' ? appAnalytics.events.BUTTON_CLICKED : appAnalytics.events.BUTTON_CLICKED, {
    button: type,
    property_id: null,
  })
  await authorize({
    type,
    locale: locale.value,
    stateParams: {
      redirectFrom: route.query.redirectFrom,
    },
  })
}

const checkConnectivityProviderPage = () => {
  const languageMap = {
    'de-DE': 'de',
    'fr-FR': 'fr',
    'it-IT': 'it',
    'es-ES': 'es',
    'pt-BR': 'pt_BR',
    'el-GR': 'el',
    'ja-JP': 'ja',
    'es-MX': 'es_MX',
    'nl-NL': 'nl_NL',
    'tr-TR': 'tr',
    'ru-RU': 'ru',
    'pt-PT': 'pt_BR',
    'pl-PL': 'pl',
    'en-GB': 'en_US',
    'en-US': 'en_US',
  }
  window.open(
    `https://trivago.my.site.com/supportcenter/s/article/Can-I-connect-my-booking-engine-to-trivago?language=${
      languageMap[locale.value] || 'en_US'
    }`,
    '_blank'
  )
}
</script>
