<template>
  <div class="legend-skeleton tw-animate-pulse tw-mt-16">
    <div class="tw-my-6">
      <div class="tw-rounded tw-bg-gray-300 tw-mb-2 tw-h-10 tw-w-full" />
      <div class="tw-rounded tw-bg-gray-300 tw-mb-2 tw-h-10 tw-w-full" />
    </div>
    <div class="tw-my-6">
      <div class="tw-rounded tw-bg-gray-300 tw-mb-2 tw-h-10 tw-w-full" />
      <div class="tw-rounded tw-bg-gray-300 tw-mb-2 tw-h-10 tw-w-full" />
    </div>
    <div class="tw-my-6">
      <div class="tw-rounded tw-bg-gray-300 tw-mb-2 tw-h-10 tw-w-full" />
      <div class="tw-rounded tw-bg-gray-300 tw-mb-2 tw-h-10 tw-w-full" />
    </div>
  </div>
</template>
