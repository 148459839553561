import axios from 'axios'
import BaseService from './BaseService.js'

const descriptionBaseUrl = `${import.meta.env.VITE_DESCRIPTION_API}`

class PropertyDetailsService extends BaseService {
  constructor() {
    super({
      baseUrl: descriptionBaseUrl,
    })
  }

  fetchAvailableLanguages() {
    if (!this.studioToken) throw new Error('Error in POST /languages No studioToken provided')
    return axios({
      url: `${descriptionBaseUrl}/languages`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchDescriptionsByItem({ accommodationId }) {
    if (!this.studioToken)
      throw new Error('Error in POST /accommodations/${accommodationId}/descriptions: No studioToken provided')
    return axios({
      url: `${descriptionBaseUrl}/accommodations/${accommodationId}/descriptions`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  updateDescription({ accommodationId, language, body }) {
    if (!this.studioToken)
      throw new Error(
        'Error in POST /accommodations/${accommodationId}/descriptions/${language}: No studioToken provided'
      )
    return axios({
      url: `${descriptionBaseUrl}/accommodations/${accommodationId}/descriptions/${language}`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }
}

export default new PropertyDetailsService()
