<template>
  <div>
    <NoDataAvailable v-if="!graphHasData" />

    <AreaChart
      v-show="graphHasData"
      :container-id="containerId"
      :series-data="dataFormattedForChart && dataFormattedForChart.graph"
      :series-name="seriesName"
      class="tw-mt-4"
    />
    <p v-if="graphHasData" class="tw-text-xs tw-text-gray-700">
      {{
        $t('tbs_performance_page_date_range', {
          startDate: `${convertDateToFormattedString(dataFormattedForChart.startDate, language, {
            month: '2-digit',
            day: '2-digit',
          })}`,
          endDate: `${convertDateToFormattedString(dataFormattedForChart.endDate, language, {
            month: '2-digit',
            day: '2-digit',
          })}`,
        })
      }}
    </p>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import AreaChart from '../charts/AreaChart.vue'
import { convertDateToFormattedString } from '../charts/chartUtils'
import NoDataAvailable from '../NoDataAvailable.vue'

const store = useStore()
const props = defineProps({
  containerId: {
    type: String,
    required: true,
    default: 'kpi_chart',
  },
  dataFormattedForChart: {
    type: [Object, null],
    default: null,
  },
  seriesName: {
    type: String,
    default: 'Value',
  },
})
const language = computed(() => store.state.locale.language)
const graphHasData = computed(() => props.dataFormattedForChart?.graph?.length > 0)
</script>
