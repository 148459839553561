<template>
  <StdioAuthCallback />
</template>

<script>
import StdioAuthCallback from '@/components/auth/callback.vue'

export default {
  components: {
    StdioAuthCallback,
  },
}
</script>
