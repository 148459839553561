import axios from 'axios'

import BaseService from './BaseService.js'

const profilePerformanceApiBaseUrl = `${import.meta.env.VITE_PROFILE_PERFORMANCE_API_URL}/accommodations`
const GRAPHQL_API = `${import.meta.env.VITE_HOTEL_SEARCH_GRAPHQL_API_URL}`

const getPartnerNames = `query ($input: AdvertiserDetailsInput!) {
  getAdvertiserDetails(input: $input) {
    advertiserDetails {
      nsid {
        id
        ns
      }
      translatedName {
        value
      }
    }
  }
}`

const getAccommodationReviewRatings = `query GetAccommodationReviewRatings($accommodationId: Int!) {
  getAccommodationReviewRatings(id: $accommodationId) {
    formattedRating
    ratingCount
    advertiserRatings {
      formattedValue
      value
      advertiser {
        advertiserDetails {
          logo {
            localizedUrlTail
            urlTail
          }
        }
      }
    }
  }
}`

class DashboardService extends BaseService {
  constructor() {
    super({
      baseUrl: profilePerformanceApiBaseUrl,
    })
  }

  fetchConversionFunnel({ accommodationId }) {
    return axios({
      url: `${this.baseUrl}/${accommodationId}/conversion/`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchPerformance(data) {
    return axios({
      url: `${this.baseUrl}/${data.accommodationId}/performance/${data.aggregationType}/?startDate=${data.startDate}&endDate=${data.endDate}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchPartnersName({ partnerIds }) {
    const input = partnerIds?.map(item => {
      return { id: item, ns: 400 }
    })
    const response = axios({
      url: `${GRAPHQL_API}`,
      method: 'POST',
      data: {
        query: getPartnerNames,
        variables: {
          input: { nsids: input },
        },
      },
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
        'x-trv-language': 'en-US',
        'Apollographql-Client-Name': 'trivago-business-studio',
        'Apollographql-Client-Version': '1.0.0',
      },
    })
    return response
  }

  fetchRateIndex({ accommodationId }) {
    return axios({
      url: `${GRAPHQL_API}`,
      method: 'POST',
      data: {
        query: getAccommodationReviewRatings,
        variables: {
          accommodationId,
          platformCode: 'us',
        },
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
        'Apollographql-Client-Name': 'trivago-business-studio',
        'Apollographql-Client-Version': '1.0.0',
      },
    })
  }
}

export default new DashboardService()
