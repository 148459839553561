<template>
  <div class="tw-rounded-md tw-bg-gray-200 tw-shadow tw-bg-center tw-bg-cover">
    <img :src="src" alt="hotel-main-image" class="tw-rounded-md tw-bg-gray-200 tw-shadow" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
const props = defineProps({
  image: {
    type: Object,
    required: false,
  },
})
const src = computed(() => {
  if (props.image === null) {
    return 'https://imgcy.trivago.com/c_fill,d_dummy.jpeg,e_sharpen:60,f_auto,h_32,q_auto,w_32/dummy.jpeg'
  } else {
    try {
      return `https://imgcy.trivago.com/c_fill,d_dummy.jpeg,e_sharpen:60,f_auto,h_32,q_auto,w_32/${props.image?.imagePath}`
    } catch {
      return 'https://imgcy.trivago.com/c_fill,d_dummy.jpeg,e_sharpen:60,f_auto,h_32,q_auto,w_32/dummy.jpeg'
    }
  }
})
</script>
