<template>
  <div>
    <img src="@/assets/img/apps-loader.svg" alt="Loader image" />
  </div>
</template>

<script>
export default {
  name: 'LaunchpadLoader',
}
</script>
