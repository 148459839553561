<template>
  <div class="tw-bg-blue-100 tw-p-4 tw-rounded-xl tw-border-blue-700 tw-border">
    <div class="tw-flex tw-items-center tw-mb-3">
      <BaseIcon :height="20" :width="12" icon-name="bulb">
        <Bulb />
      </BaseIcon>
      <h4 class="tw-font-bold tw-ml-2">{{ $t('rc_pause_flow_smart_tip_header') }}</h4>
    </div>
    <div v-html="$t(smartTipsText)" />
  </div>
</template>
<script setup>
import { computed } from 'vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Bulb from '@/components/icons/Bulb.vue'

const props = defineProps({
  id: Number,
})
const smartTipsText = computed(() => {
  if (props.id === 0) return 'rc_pause_flow_seasonal_tip'
  if (props.id === 2) return 'rc_pause_flow_booked_tip'
  return ''
})
</script>
<style scoped>
div :deep(p) {
  @apply tw-mb-2;
}
</style>
