<template>
  <g>
    <defs>
      <polygon
        id="path-1"
        points="0.531839022 0.1108 91.7300051 0.1108 91.7300051 91.9168 0.531839022 91.9168"
      ></polygon>
      <polygon
        id="path-3"
        points="0.277869822 0.358757396 6.76721893 0.358757396 6.76721893 7.10059172 0.277869822 7.10059172"
      ></polygon>
      <polygon id="path-5" points="0 134.023669 149.112426 134.023669 149.112426 0 0 0"></polygon>
    </defs>
    <g id="02_Final" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="04.1_LoadingTimeTooLong" transform="translate(-567.000000, -230.000000)">
        <g id="Group-3" transform="translate(284.000000, 182.000000)">
          <g id="Group-39" transform="translate(283.000000, 48.000000)">
            <g id="Group-3" transform="translate(57.692308, 26.627219)">
              <mask id="mask-2" fill="white">
                <use xlink:href="#path-1"></use>
              </mask>
              <g id="Clip-2"></g>
              <path
                id="Fill-1"
                d="M90.0244524,33.2835692 L33.4850739,90.1998769 C31.2103923,92.4891077 27.522975,92.4891077 25.2489047,90.1998769 L13.9470199,78.8232615 C-3.93988793,60.8164923 -3.93988793,31.6220308 13.9470199,13.6158769 L13.9476312,13.6158769 C31.8351503,-4.39089231 60.8356597,-4.39089231 78.7231788,13.6158769 L90.0244524,24.9918769 C92.299134,27.2817231 92.299134,30.9937231 90.0244524,33.2835692"
                fill="#EAEBEC"
                mask="url(#mask-2)"
              ></path>
            </g>
            <path
              id="Fill-4"
              d="M82.5443787,19.0060645 L52.6664467,49.704142 L33.3279071,44.9639657 C31.2920431,43.710273 31.5709285,40.6505917 33.8044914,39.7336192 L76.9115116,14.3087548 C77.4575074,14.0882093 78.0772529,14.2130815 78.4887639,14.6255949 L82.5443787,19.0060645 Z"
              fill="#EAEBEC"
            ></path>
            <polygon
              id="Fill-6"
              fill="#3F80BA"
              points="128.810651 15.4736079 93.7451412 1.77514793 85.1810492 10.0860781 85.1766956 10.0860781 85.1766956 10.0903026 85.1766956 17.2020593 79.9940828 22.2317079 88.1819021 30.1775148 96.7578108 21.8557215 96.756567 12.5737445 125.472085 18.7126437"
            ></polygon>
            <polygon
              id="Fill-8"
              fill="#3F80BA"
              points="92.3076923 29.3262086 61.278389 60.3550296 53.2544379 52.3305961 84.2831341 21.3017751"
            ></polygon>
            <path
              id="Fill-10"
              d="M99.3116065,87.773395 C97.0804096,90.0045229 97.0737673,93.6171991 99.2910759,95.8579882 L107.39645,87.7522612 C105.155591,85.535625 101.543407,85.5416632 99.3116065,87.773395"
              fill="#FFFFFF"
            ></path>
            <path
              id="Fill-12"
              d="M103.372082,86.8634001 C102.097167,86.8634001 100.822253,87.3476939 99.8542968,88.3162815 C98.1256721,90.0443299 97.9391739,92.7513611 99.2996938,94.6915938 L106.229479,87.7610558 C105.375868,87.1624148 104.373669,86.8634001 103.372082,86.8634001 M99.2471074,96.7455621 L99.2446616,96.7455621 C99.0245325,96.7449506 98.812964,96.6568972 98.6576507,96.4997463 C96.0986502,93.9131771 96.1096567,89.715964 98.6821095,87.1434589 C101.253951,84.5715652 105.451078,84.559947 108.037595,87.1189996 C108.194743,87.274316 108.283406,87.4858888 108.284021,87.7060224 C108.284629,87.9267674 108.197189,88.1383402 108.040652,88.2948796 L99.8328954,96.5034152 C99.6775821,96.6587317 99.466625,96.7455621 99.2471074,96.7455621"
              fill="#293339"
            ></path>
            <path
              id="Fill-14"
              d="M101.417116,63.9053254 L95.6243043,63.9053254 C95.2627844,63.9053254 94.9704142,63.7068975 94.9704142,63.4615385 C94.9704142,63.2161794 95.2627844,63.0177515 95.6243043,63.0177515 L101.417116,63.0177515 C101.778029,63.0177515 102.071006,63.2161794 102.071006,63.4615385 C102.071006,63.7068975 101.778029,63.9053254 101.417116,63.9053254"
              fill="#293339"
            ></path>
            <path
              id="Fill-16"
              d="M74.556213,89.8786542 L74.556213,84.0858428 C74.556213,83.7243228 74.7550526,83.4319527 75,83.4319527 C75.2449474,83.4319527 75.443787,83.7243228 75.443787,84.0858428 L75.443787,89.8786542 C75.443787,90.2401742 75.2449474,90.5325444 75,90.5325444 C74.7550526,90.5325444 74.556213,90.2401742 74.556213,89.8786542 Z"
              fill="#293339"
            ></path>
            <path
              id="Fill-18"
              d="M80.8777524,117.843675 L69.5447702,106.517899 C61.4890165,98.4672673 56.6498019,87.7829194 55.9190768,76.4344755 C55.8865865,75.9278267 56.2709528,75.4910182 56.777924,75.4579359 C57.2897994,75.4273042 57.7219816,75.8095882 57.7544719,76.3156244 C58.4563848,87.2192955 63.1055619,97.4833761 70.844995,105.218501 L82.1779772,116.543665 C83.1073221,117.472419 84.3431793,117.984581 85.6575037,117.984581 C86.971828,117.984581 88.2076852,117.472419 89.1370302,116.543665 L145.835657,59.8823131 C147.753811,57.9647667 147.753811,54.8446185 145.835657,52.9276848 L134.502062,41.6012958 C126.493511,33.597837 115.855086,28.9326243 104.546012,28.4645714 C104.038427,28.4437418 103.644253,28.0161229 103.665096,27.5088614 C103.685938,27.0015999 104.11199,26.6015495 104.622027,26.6285054 C116.393321,27.11555 127.466994,31.971292 135.802287,40.3018977 L147.135882,51.6282867 C149.771274,54.262003 149.771274,58.5473822 147.135882,61.1817111 L90.437255,117.843675 C89.1609381,119.119793 87.4628605,119.822485 85.6575037,119.822485 C83.8521468,119.822485 82.1546822,119.119793 80.8777524,117.843675 Z"
              fill="#37454D"
            ></path>
            <path
              id="Fill-20"
              d="M49.3787951,53.2174404 L35.2854011,49.1545048 C34.7931311,49.0126813 34.5098903,48.4996501 34.6521291,48.0088174 C34.7943679,47.5179847 35.3070461,47.233721 35.800553,47.3773944 L49.3682818,51.2886405 L79.4177801,21.3274963 L74.2860506,16.2107502 C74.1425749,16.0683101 73.9347825,16.0171303 73.7424509,16.0744763 L52.1314256,28.1183766 C51.684301,28.3681093 51.1190562,28.2077871 50.8692107,27.7625845 C50.6193651,27.3167654 50.7789199,26.7525544 51.2260446,26.5034383 L72.9038602,14.4225406 C72.9391107,14.4034253 72.9762165,14.3855432 73.0139407,14.3707442 C73.903861,14.0174186 74.9193226,14.2264542 75.5977399,14.9028907 L81.3850049,20.6732582 C81.7474047,21.0345999 81.7474047,21.6203927 81.3850049,21.9811178 L50.2928342,52.9837399 C50.1159633,53.1594778 49.8791047,53.2544379 49.6366803,53.2544379 C49.5507186,53.2544379 49.4641384,53.2421054 49.3787951,53.2174404 Z"
              fill="#37454D"
            ></path>
            <path
              id="Fill-22"
              d="M57.9275738,61.2426036 L49.0885707,52.5234873 C48.7259005,52.1657362 48.7259005,51.5857641 49.0885707,51.2286235 C49.4512409,50.8708723 50.0385686,50.8708723 50.4012388,51.2286235 L57.9257171,58.6504339 L95.9974237,21.0078076 L95.9986614,10.8595182 L125.205372,17.2068542 L127.174064,15.2642531 L94.1525092,2.15078046 L91.0927115,5.16907696 C90.7300413,5.52682812 90.1427136,5.52682812 89.7800434,5.16907696 C89.4173732,4.81193629 89.4173732,4.23196427 89.7800434,3.87421311 L93.7075265,0 L130.473373,14.6000325 L125.798888,19.2111154 L97.8553348,13.1378925 L97.854097,21.7654342 L57.9275738,61.2426036 Z"
              fill="#37454D"
            ></path>
            <path
              id="Fill-24"
              d="M17.7514793,58.5798817 C14.3200408,58.5798817 11.5384615,55.9969866 11.5384615,52.8106509 C11.5384615,49.6243152 14.3200408,47.0414201 17.7514793,47.0414201 L17.7514793,58.5798817 Z"
              fill="#3EB28C"
            ></path>
            <g id="Group-28" transform="translate(21.301775, 126.923077)">
              <mask id="mask-4" fill="white">
                <use xlink:href="#path-3"></use>
              </mask>
              <g id="Clip-27"></g>
              <path
                id="Fill-26"
                d="M6.62639053,0.358757396 L0.418698225,0.358757396 C0.340591716,0.358757396 0.277869822,0.436272189 0.277869822,0.531538462 L0.277869822,6.92798817 C0.277869822,7.02325444 0.340591716,7.10076923 0.418698225,7.10076923 L6.62639053,7.10076923 C6.70390533,7.10076923 6.76721893,7.02325444 6.76721893,6.92798817 L6.76721893,0.531538462 C6.76721893,0.436272189 6.70390533,0.358757396 6.62639053,0.358757396"
                fill="#BCDDE8"
                mask="url(#mask-4)"
              ></path>
            </g>
            <mask id="mask-6" fill="white">
              <use xlink:href="#path-5"></use>
            </mask>
            <g id="Clip-30"></g>
            <path
              id="Fill-29"
              d="M0.746024065,113.77513 L5.46641627,113.77513 L5.46641627,109.005935 L0.746024065,109.005935 L0.746024065,113.77513 Z M6.21301775,113.995109 L6.21301775,108.785398 C6.21301775,108.509028 5.99244407,108.284024 5.72105761,108.284024 L0.491960142,108.284024 C0.220573679,108.284024 0,108.509028 0,108.785398 L0,113.995109 C0,114.272037 0.220573679,114.497041 0.491960142,114.497041 L5.72105761,114.497041 C5.99244407,114.497041 6.21301775,114.272037 6.21301775,113.995109 L6.21301775,113.995109 Z"
              fill="#697379"
              mask="url(#mask-6)"
            ></path>
            <path
              id="Fill-31"
              d="M28.9100349,69.2307692 C28.779986,69.2307692 28.6499371,69.1805302 28.550909,69.0794469 C28.3528528,68.878491 28.3528528,68.5528454 28.550909,68.3512841 L32.5024873,64.3424542 L28.5646297,60.3463354 C28.3665736,60.1453794 28.3665736,59.8197339 28.5646297,59.6181726 C28.7626859,59.4172166 29.0842289,59.4172166 29.2828816,59.6181726 L33.5792685,63.9780702 C33.7773247,64.1790262 33.7773247,64.5046717 33.5792685,64.706233 L29.2691608,69.0794469 C29.1695362,69.1805302 29.0400838,69.2307692 28.9100349,69.2307692"
              fill="#697379"
              mask="url(#mask-6)"
            ></path>
            <path
              id="Fill-32"
              d="M34.8044403,25.739645 C34.4182772,25.739645 34.105922,25.4275299 34.105922,25.0429805 L34.105922,17.3702492 L26.4381633,17.3702492 C26.0520002,17.3702492 25.739645,17.0575452 25.739645,16.6729958 C25.739645,16.2884465 26.0520002,15.9763314 26.4381633,15.9763314 L34.8044403,15.9763314 C35.1906034,15.9763314 35.5029586,16.2884465 35.5029586,16.6729958 L35.5029586,25.0429805 C35.5029586,25.4275299 35.1906034,25.739645 34.8044403,25.739645"
              fill="#F6804D"
              mask="url(#mask-6)"
            ></path>
            <polygon
              id="Fill-33"
              fill="#F25614"
              mask="url(#mask-6)"
              points="20.4142012 83.651985 19.3065376 82.5443787 14.6446717 87.2060032 9.98340328 82.5443787 8.87573964 83.651985 13.5376055 88.3136095 8.87573964 92.975234 9.98340328 94.0828402 14.6446717 89.4212157 19.3065376 94.0828402 20.4142012 92.975234 15.7523353 88.3136095"
            ></polygon>
            <polygon
              id="Fill-34"
              fill="#697379"
              mask="url(#mask-6)"
              points="8.75207307 30.1775148 7.98816568 29.4136724 14.32485 23.0769231 15.0887574 23.8413698"
            ></polygon>
            <path
              id="Fill-35"
              d="M7.10059172,30.6213018 C7.10059172,29.8857149 7.69680864,29.2899408 8.43166739,29.2899408 C9.16709669,29.2899408 9.76331361,29.8857149 9.76331361,30.6213018 C9.76331361,31.3568887 9.16709669,31.9526627 8.43166739,31.9526627 C7.69680864,31.9526627 7.10059172,31.3568887 7.10059172,30.6213018"
              fill="#697379"
              mask="url(#mask-6)"
            ></path>
            <path
              id="Fill-36"
              d="M13.3136095,23.5207101 C13.3136095,22.7851231 13.9098264,22.1893491 14.6446851,22.1893491 C15.3801144,22.1893491 15.9763314,22.7851231 15.9763314,23.5207101 C15.9763314,24.256297 15.3801144,24.852071 14.6446851,24.852071 C13.9098264,24.852071 13.3136095,24.256297 13.3136095,23.5207101"
              fill="#697379"
              mask="url(#mask-6)"
            ></path>
            <path
              id="Fill-37"
              d="M84.7729884,91.9525875 L84.7729884,91.9525875 C84.2208725,92.4497441 83.3459038,92.4213178 82.8289625,91.8896869 L79.3636342,88.321893 C78.8466929,87.7896573 78.8755863,86.9477571 79.4283304,86.4499957 C79.9810744,85.9528392 80.855415,85.9806606 81.3723563,86.5128963 L84.8383127,90.0806902 C85.3546259,90.6123211 85.3251044,91.4548261 84.7729884,91.9525875"
              fill="#293339"
              mask="url(#mask-6)"
            ></path>
            <path
              id="Fill-38"
              d="M101.636894,74.2008918 L101.636894,74.2008918 C101.084778,74.6980484 100.209809,74.670227 99.6928678,74.1379913 L96.2275395,70.5701974 C95.7105982,70.0385664 95.7394916,69.1966663 96.2922357,68.6983001 C96.8449797,68.2011435 97.7193203,68.2295697 98.2362616,68.7612007 L101.702218,72.3289946 C102.218531,72.8612303 102.18901,73.7037353 101.636894,74.2008918"
              fill="#293339"
              mask="url(#mask-6)"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </g>
</template>
