<template>
  <div>
    <div class="tw-flex tw-flex-row">
      <Checkbox
        name="select-all"
        :label="$t('app_images_actions_select_all')"
        :value="isAllSelected()"
        @onChange="e => selectAllItems(e)"
      />
    </div>
    <div class="tw-flex tw-flex-wrap tw-justify-start images-container">
      <div
        v-for="(image, index) in items"
        :key="index"
        class="box sml:tw-h-[224px] tw-h-[300px] tw-mt-4 sml:tw-mt-0 tw-flex tw-items-center tw-justify-center"
      >
        <ImageElement
          v-if="image?.active"
          :id="image?.imageId"
          :imageUrl="`https://imgcy.trivago.com/c_fill,d_dummy.jpeg,e_sharpen:60,f_auto,h_208,q_auto,w_231/${image?.imagePath}`"
          :image="image"
          :is-main-image="image?.isMainImage"
          :is-selected="isImageSelected(image?.imageId)"
          :open-id="openId"
          :images="items"
          @toggleDeleteModal="emit('toggleDeleteModal')"
          :custom-properties="image?.customProperties"
          @openImageGallery="emit('openImageGallery', image)"
          @toggleMenu="toggleMenu"
          @selectItem="selectOneItem"
        />
      </div>
      <div
        v-for="(image, index) in uploadingImages"
        :key="index"
        class="box sml:tw-h-[224px] tw-h-[300px] tw-mt-4 sml:tw-mt-0 tw-flex tw-items-center tw-justify-center"
      >
        <LoadingImageElement v-if="image?.status && image?.status.toLowerCase() === 'uploading'" :item="image" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import Checkbox from '@/components/base/inputs/Checkbox.vue'
import ImageElement from '@/components/propertyDetails/propertyImages/ImageElement.vue'
import LoadingImageElement from '@/components/propertyDetails/propertyImages/LoadingImageElement.vue'
import { usePropertyImages } from '@/components/propertyDetails/propertyImages/usePropertyImages.js'

const { selectedItemIds, selectItem, selectAll, uploadingImages } = usePropertyImages()

const emit = defineEmits(['toggleDeleteModal', 'openImageGallery'])

const props = defineProps({
  items: Array,
})
const openId = ref(null)

const isImageSelected = id => {
  return selectedItemIds !== null && selectedItemIds.value.includes(id)
}
const isAllSelected = () => {
  const allSelectableImages = props.items.filter(item => item.customProperties.isDeletable && !item.isMainImage)
  return allSelectableImages.length === selectedItemIds.value.length
}

const selectAllItems = e => {
  selectAll(e, props.items)
}
const selectOneItem = (e, id) => {
  selectItem(id)
}
const toggleMenu = id => {
  if (id === openId.value) {
    openId.value = null
  } else {
    openId.value = id
  }
}
</script>
<style scoped>
.images-container:after {
  content: '';
  flex: auto;
}
@media (min-width: 10px) {
  .box {
    flex: 0 0 100%;
  }
}

@media (min-width: 515px) {
  .box {
    flex: 0 0 50%;
  }
}

@media (min-width: 768px) {
  .box {
    flex: 0 0 33.33%;
  }
}

@media (min-width: 972px) {
  .box {
    flex: 0 0 25%;
  }
}

@media (min-width: 1200px) {
  .box {
    flex: 0 0 20%;
  }
}
</style>
