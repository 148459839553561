<template>
  <path
    fill="#171717"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 12.7V17H13V11H12.3L10.5 12.8L11.2 13.5L12 12.7Z"
  />
  <path
    fill="#171717"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M19 5H18C18 4.4 17.6 4 17 4C16.4 4 16 4.4 16 5H8C8 4.4 7.6 4 7 4C6.4 4 6 4.4 6 5H5C3.9 5 3 5.9 3 7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V7C21 5.9 20.1 5 19 5ZM20 18C20 18.6 19.6 19 19 19H5C4.4 19 4 18.6 4 18V9H20V18ZM20 8H4V7C4 6.4 4.4 6 5 6H19C19.6 6 20 6.4 20 7V8Z"
  />
</template>
