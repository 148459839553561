const SET_REACTIVATION_ACCOMMODATION_ID = (state, id) => {
  state.reactivationAccommodationId = id.value ? id.value : id
}

const REMOVE_REACTIVATION_ACCOMMODATION_ID = state => {
  state.reactivationAccommodationId = null
}

const SET_REACTIVATION_SUBSCRIPTION_TYPE = (state, type) => {
  state.reactivationSubscriptionType = type
}

const REMOVE_REACTIVATION_SUBSCRIPTION_TYPE = state => {
  state.reactivationSubscriptionType = null
}

export default {
  SET_REACTIVATION_ACCOMMODATION_ID,
  REMOVE_REACTIVATION_ACCOMMODATION_ID,
  REMOVE_REACTIVATION_SUBSCRIPTION_TYPE,
  SET_REACTIVATION_SUBSCRIPTION_TYPE,
}
