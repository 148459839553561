import { store } from '@/store'
import { computed } from 'vue'

// TODO: it may make more sense to just keep selectedAccommodationId in this hook and use it as a global state.
import { selectedAccommodationId, setSelectedAccommodationId } from '@/layouts/queries'

export default function useAccommodations() {
  const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)

  async function switchAccommodation(newSelectedAccommodation, organisation) {
    const newSelectedAccommodationId = newSelectedAccommodation?.accommodationId
    if (!newSelectedAccommodationId || newSelectedAccommodationId === selectedAccommodationId.value) return

    if (organisation?.id !== selectedOrganisationId.value) {
      await store.dispatch('session/switchOrganisation', { newOrganisation: organisation })
    }
    setSelectedAccommodationId(newSelectedAccommodationId)
  }

  async function switchOrganisation(newOrganisation) {
    if (!newOrganisation || newOrganisation?.id === selectedOrganisationId.value) return

    await store.dispatch('session/switchOrganisation', { newOrganisation: newOrganisation })
  }

  return {
    switchAccommodation,
    switchOrganisation,
  }
}
