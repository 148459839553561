<template>
  <a
    :href="href"
    :data-testid="dataTestId"
    class="tw-text-center tw-cursor-pointer tw-transition-colors tw-duration-300"
    v-bind="$attrs"
  >
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: '#',
      required: true,
    },
    dataTestId: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
a:disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
