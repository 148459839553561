<template>
  <g fill="none" fill-rule="nonzero"
    ><path
      class="background"
      d="M9.9 1.5l8 14a1 1 0 0 1-.9 1.6H1a1 1 0 0 1-.9-1.5l8-14.1a1 1 0 0 1 1.8 0z"
      fill="#FFF"
      stroke="#37454D"
    ></path
    ><path d="M7.8 14.3c0 .6.5 1.2 1.2 1.2s1.3-.6 1.3-1.3c0-.6-.6-1.2-1.3-1.2-.7 0-1.3.6-1.3 1.3z" fill="#37454D"></path
    ><rect fill="#37454D" height="7" rx="1" width="2" x="8" y="5"></rect
  ></g>
</template>
