<template>
  <g id="Product-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Single-Hotel-Owner-eligible" transform="translate(-1030.000000, -385.000000)">
      <g id="Group-11" transform="translate(215.000000, 385.000000)">
        <g id="Group-9" transform="translate(676.000000, 0.000000)">
          <g id="trophy-icon" transform="translate(139.000000, 0.000000)">
            <rect id="Rectangle-4-Copy-92" x="0" y="0" width="56" height="56"></rect>
            <path
              id="Page-1"
              d="M42.1866667,34.3 C41.7433333,33.9266667 41.23,33.5766667 40.6933333,33.25 C42.4433333,36.3766667 43.3533333,39.55 43.7966667,42 L46.6666667,42 C46.6666667,41.8366667 46.62,38.0566667 42.1866667,34.3 L42.1866667,34.3 Z M25.9,18.6666667 L26.6,25.6666667 L28,25.6666667 C28.8866667,25.6666667 29.26,25.7133333 29.4,25.7366667 L30.1,18.6666667 L25.9,18.6666667 Z M9.33333333,42 L12.2033333,42 C12.6466667,39.55 13.5566667,36.4 15.33,33.25 C14.77,33.5533333 14.28,33.9033333 13.8133333,34.3 C9.38,38.0566667 9.33333333,41.8366667 9.33333333,42 L9.33333333,42 Z M46.6666667,44.3333333 L44.17,44.3333333 C44.3333333,45.71 44.3333333,46.5966667 44.3333333,46.69 C44.3333333,47.9733333 43.2833333,49 42,49 L14,49 C12.7166667,49 11.6666667,47.9733333 11.6666667,46.69 C11.6666667,46.5966667 11.6666667,45.71 11.83,44.3333333 L9.33333333,44.3333333 C8.05,44.3333333 7.02333333,43.3066667 7,42.0233333 C7,41.5333333 7.07,36.9366667 12.2966667,32.5266667 C13.9066667,31.1733333 15.9366667,30.1233333 18.3866667,29.3766667 C20.0433333,27.86 22.0266667,26.7866667 24.29,26.2033333 L23.5433333,18.6666667 L21,18.6666667 C19.9966667,18.6666667 19.11,18.0133333 18.7833333,17.08 L16.45,10.08 C16.2166667,9.35666667 16.3333333,8.58666667 16.7766667,7.98 C17.22,7.35 17.92,7 18.6666667,7 L37.3333333,7 C38.08,7 38.78,7.35 39.2233333,7.98 C39.6666667,8.58666667 39.7833333,9.35666667 39.55,10.08 L37.2166667,17.08 C36.89,18.0133333 36.0033333,18.6666667 35,18.6666667 L32.4566667,18.6666667 L31.71,26.2033333 C33.9733333,26.7866667 35.98,27.86 37.6366667,29.3766667 C40.0633333,30.1233333 42.0933333,31.1733333 43.68,32.5266667 C48.93,36.9366667 49,41.5333333 49,42.0233333 C48.9766667,43.3066667 47.95,44.3333333 46.6666667,44.3333333 L46.6666667,44.3333333 Z"
              fill="#007CC2"
              transform="translate(28.000000, 28.000000) scale(1, -1) translate(-28.000000, -28.000000) "
            ></path>
          </g>
        </g>
      </g>
    </g>
  </g>
</template>
