<template>
  <div class="tw-px-6 tw-py-8">
    <h2 class="tw-text-xl tw-font-bold tw-pb-2">{{ $t('rc_deactivate_campaign_head') }}</h2>
    <div
      class="tw-relative tw-flex tw-items-center tw-flex-wrap md:tw-flex-nowrap tw-flex-row tw-w-full tw-text-base tw-text-gray-800 tw-text-left tw-border-0 tw-rounded-none tw-focus:outline-none"
    >
      <div class="tw-flex tw-items-start tw-flex-col tw-w-3/5 main-info md:tw-mb-0 tw-mb-5">
        <p class="tw-text-sm tw-p-0 tw-m-0 tw-mr-4">{{ $t('rc_deactivate_campaign_text') }}</p>
      </div>
      <div class="tw-flex tw-w-1/5 tw-flex-col tw-items-start type-info">
        <div class="tw-flex tw-flex-row tw-items-center">
          <span :class="currentCampaignStatus === 'active' ? 'active tw-bg-green-600' : 'paused tw-bg-red-700'" />
          <p class="tw-text-base tw-font-bold tw-ml-2">
            {{ currentCampaignStatus === 'active' ? $t('rc_settings_campaign_on') : $t('rc_settings_campaign_off') }}
          </p>
        </div>
      </div>
      <div class="tw-flex tw-w-1/5 tw-justify-end action-btn">
        <GhostButton v-if="currentCampaignStatus === 'active'" size="medium" class="tw-px-9" @click="openPauseModal">
          {{ $t('rc_deactivate_campaign_button') }}
        </GhostButton>
        <MainButton v-else size="medium" class="tw-px-9" @click="onUpdateCampaignStatus">
          <span v-if="!isUpdatingCampaignStatus">{{ $t('rc_reactivate_campaign_button') }}</span>
          <span v-if="isUpdatingCampaignStatus">
            <RCInlineLoader color="#fff" />
          </span>
        </MainButton>
      </div>
    </div>
  </div>
  <FullScreenLoader v-show="currentCampaignStatus === 'inactive' && isUpdatingCampaignStatus" />
</template>

<script setup>
import { computed } from 'vue'

import GhostButton from '@/components/base/buttons/GhostButton.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import appAnalytics from '@/utils/tracking'
import useToastNotifications from '@/components/notifications/useToastNotifications'

import useRateConnect from '@/components/rateConnect/queries'
import { useCampaigns, useCampaignsMutation } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const { campaignsData } = useCampaigns(selectedAccommodationId)
const { hotelierData } = useRateConnect(selectedAccommodationId)
const { updateCampaignStatus, isUpdatingCampaignStatus } = useCampaignsMutation()

const emit = defineEmits(['openPauseModal'])
const openPauseModal = () => {
  emit('openPauseModal', 'open')
  appAnalytics.track(appAnalytics.events.RC_PAUSE_CAMPAIGN_BUTTON_CLICKED_SETTINGS, {
    accommodationId: selectedAccommodationId.value,
    campaignType: currentCampaignType.value,
    isNetCPAEligible: isNetCPAEligible.value,
  })
}

const { displayNotification } = useToastNotifications()
const isNetCPAEligible = computed(() => hotelierData.value?.eligibleCampaignTypes?.includes('netCpa'))
const currentCampaignStatus = computed(() => campaignsData.value?.overall?.status)
const currentCampaignType = computed(() => campaignsData.value?.overall?.campaignType)
const onUpdateCampaignStatus = () => {
  if (!currentCampaignStatus.value) return

  trackDataInSegment()

  updateCampaignStatus(
    {
      accommodationId: selectedAccommodationId.value,
      body: { overall: { status: 'active' } },
    },
    {
      onSuccess: () => {
        displayNotification({
          message: 'rc_reactivate_campaign_success_message',
          isTranslationKey: true,
          type: 'success',
        })
      },
      onError: () => {
        displayNotification({
          message: 'save_error',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}

const trackDataInSegment = () => {
  appAnalytics.track(appAnalytics.events.RC_CAMPAIGN_RESUMED, {
    item_id: selectedAccommodationId.value,
    campaign_type: currentCampaignType.value,
    budget: currentCampaignType.value === 'cpc' ? campaignsData.value?.overall?.budget : null,
    source: 'settings',
  })
}
</script>

<style scoped>
.main-info {
  @media (max-width: theme('screens.md')) {
    flex: 0 0 100%;
  }
}
.type-info,
.action-btn {
  @media (max-width: theme('screens.md')) {
    flex: 0 0 50%;
  }
}
.active {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}
.paused {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}
.action-btn {
  @media (max-width: theme('screens.sm')) {
    flex: 0 0 100%;
  }
}
</style>
