<template>
  <div class="tw-flex tw-items-center tw-flex-col tw-text-gray-800">
    <label :for="id" :class="label ? 'tw-text-sm' : 'tw-mb-0 tw-text-sm'">
      {{ label }}
    </label>
    <div class="input-group tw-relative tw-w-40">
      <span
        :style="{ 'background-color': inputColorMap[status] }"
        class="input-group-addon tw-absolute tw-rounded-l-lg tw-text-white tw-px-4 tw-py-1.5 tw-font-bold tw-bg-gray-500 tw-text-xl"
        >{{ currency }}</span
      >
      <input
        :id="id"
        :value="maskedValue"
        type="text"
        v-bind="$attrs"
        :max="1000000000"
        :style="{ 'border-color': inputColorMap[status] }"
        class="tw-appearance-none tw-border tw-w-full focus:tw-outline-0 tw-bg-white focus:tw-border-blue-800 tw-py-1.5 tw-px-3 tw-pl-10 tw-rounded-lg"
        @input="e => handleChange(e.target.value)"
        @keydown="keypressValidation($event)"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from 'vue'

const props = defineProps({
  allowNegative: {
    type: Boolean,
    default: false,
  },
  currency: {
    type: String,
    default: 'EUR',
  },
  id: {
    type: String,
    default: 'textfield',
  },
  label: {
    type: String,
    required: false,
    default: '',
  },
  ariaLabel: {
    type: String,
    required: true,
  },
  budget: {
    type: Number,
    required: true,
  },
  defaultBudget: {
    type: Number,
    required: true,
  },
  minBudget: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 9999999999,
  },
  status: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['onChange'])
const maskedValue = ref(0)
const inputColorMap = ref({
  belowConsumption: '#FF8381',
  tooLow: '#FF8381',
  low: '#FDCE8A',
  recommended: '#AFB3BC',
  excellent: '#AFB3BC',
})

watch(
  () => props.defaultBudget,
  newDefaultBudget => {
    setMaskedValue(newDefaultBudget)
  }
)

watch(
  () => props.budget,
  newBudget => {
    const [, calculatedMaskedValue] = calculateValues(newBudget, props.currency)
    setMaskedValue(calculatedMaskedValue)
  }
)
onMounted(() => {
  setMaskedValue(props.defaultBudget)
})
const keypressValidation = e => {
  if (e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39) {
    return true
  } else {
    if (/^\d+$/.test(e.key)) return true
    else e.preventDefault()
  }
}
const formatInput = value => {
  const formatter = new Intl.NumberFormat({
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return formatter.format(value)
}
const normalizeValue = value => {
  const validationRegex = props.allowNegative ? /(?!^-[\d]+)[^0-9]/gm : /[^0-9]/g
  if (typeof value === 'string') {
    return Number(value.replace(validationRegex, ''))
  }

  return value
}
const calculateValues = inputFieldValue => {
  const value = normalizeValue(inputFieldValue)
  const calculatedMaskedValue = formatInput(value)
  return [value, calculatedMaskedValue]
}
const updateValues = event => {
  const [value, calculatedMaskedValue] = calculateValues(event, props.currency)
  if (!props.max || value <= props.max) {
    setMaskedValue(normalizeValue(calculatedMaskedValue))
    return [value, calculatedMaskedValue]
  } else {
    return [normalizeValue(calculatedMaskedValue) / 100, calculatedMaskedValue]
  }
}
const setMaskedValue = value => {
  maskedValue.value = value
}
const handleChange = event => {
  const [value, calculatedMaskedValue] = updateValues(event)
  if (calculatedMaskedValue) {
    emit('onChange', value)
  }
}
</script>
<style scoped>
input {
  border-color: theme('colors.gray.500');
  padding-left: 80px;
  text-align: right;
}
.input-group-addon {
  bottom: 0;
  top: 0;
  left: 0;
}
</style>
