<template>
  <BaseCard
    no-padding
    class="tw-absolute tw-z-[10] tw-flex tw-top-[66px] tw-w-[200px] tw-font-normal tw-text-gray-800 tw-cursor-auto"
  >
    <div class="tw-flex tw-w-full tw-p-3">
      <ul class="tw-w-full">
        <li
          v-for="item in props.links"
          :key="item.id"
          class="tw-w-[100%] tw-flex tw-rounded-md hover:tw-font-bold"
          @mouseover="selectElement(item.id)"
          :data-testid="`${item.dataTestId}`"
        >
          <a
            class="hover:tw-no-underline tw-w-full tw-py-2 tw-px-2 hover:tw-text-gray-800 tw-flex tw-items-center tw-justify-between hover:tw-bg-gray-200 hover:tw-rounded"
            :href="item.newPage ? getSupportCenterUrl(locale.value, item.url) : item.url"
            :target="item.newPage ? '_blank' : ''"
          >
            <span v-html="$t(item.label)" />
            <External v-if="item.withIcon" />
          </a>
        </li>
      </ul>
    </div>
  </BaseCard>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getSupportCenterUrl } from '@/utils/Utility.js'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import External from '@/components/icons/External.vue'

const store = useStore()
const route = useRoute()
const props = defineProps({
  links: Array,
})

const locale = computed(() => store.state.locale.language)
const selected = ref(0)

const selectElement = id => {
  selected.value = id
}
onMounted(() => {
  const path = '/home' + route.path
  const currentUrl = props.links.find(item => item.url === path)
  selectElement(currentUrl?.id || 0)
})
</script>
