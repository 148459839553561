<template>
  <g id="Change-Organisation---DS-Update" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="tBS-Change-Organisation-Modal-Mobile-DS-Update" transform="translate(-440.000000, -16.000000)">
      <g id="cross" transform="translate(440.000000, 16.000000)">
        <circle id="Oval" fill="#EBEBEB" cx="12" cy="12" r="12"></circle>
        <path
          id="Combined-Shape"
          d="M16.6764703,7.33451687 C17.1108566,7.77651272 17.1073812,8.48962788 16.6687078,8.92730417 L13.589,12 L16.6687078,15.0726958 C17.1073812,15.5103721 17.1108566,16.2234873 16.6764703,16.6654831 C16.242084,17.107479 15.5343294,17.1109807 15.095656,16.6733044 L12,13.585 L8.90434401,16.6740199 C8.46567064,17.1116962 7.75791596,17.1081945 7.32352969,16.6661987 C6.88914342,16.2242028 6.8926188,15.5110876 7.33129216,15.0734114 L10.411,11.999 L7.33129216,8.92658864 C6.8926188,8.48891236 6.88914342,7.7757972 7.32352969,7.33380135 C7.75791596,6.8918055 8.46567064,6.8883038 8.90434401,7.32598008 L12,10.414 L15.095656,7.3266956 C15.5343294,6.88901932 16.242084,6.89252102 16.6764703,7.33451687 Z"
          fill="#0A1121"
          fill-rule="nonzero"
        ></path>
      </g>
    </g>
  </g>
</template>
