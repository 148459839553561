<template>
  <CustomModal
    :is-modal-open="isOpen || reactivationCompleted === true"
    @toggleModal="resetModal"
    @onClickAway="resetModal"
    :overlay="'dark'"
    :with-header="true"
    :with-footer="true"
    custom-classes="tw-h-screen md:tw-h-auto tw-w-[100vw] md:tw-max-w-[588px] md:tw-w-[588px] md:tw-rounded-md tw-rounded-none"
  >
    <template #modalHeader>
      <div class="tw-flex tw-items-center">
        <CheckMarkInCircle
          v-if="reactivationStep === 1 && isPositive"
          height="24px"
          width="24px"
          class="tw-inline tw-mr-4 tw-stroke-gray-700"
        />
        <CloseOutline
          v-if="reactivationStep === 1 && !isPositive"
          height="24px"
          width="24px"
          class="tw-inline tw-mr-4 tw-stroke-gray-700"
        />
        <h3 class="tw-font-bold tw-text-xl tw-text-gray-700">{{ $t(titleLabel) }}</h3>
      </div>
    </template>
    <div
      class="tw-flex tw-w-full tw-items-center tw-justify-center tw-min-h-20"
      v-if="(isLoading && !studioPlusCampaign) || existingPaymentMethodsDataLoading"
    >
      <RCInlineLoader />
    </div>
    <div class="tw-flex tw-flex-col" v-else>
      <StudioPlusReactivationCancelledStep1
        v-if="reactivationStep === 0 && cancelled"
        :nextPaymentDate="studioPlusCampaign?.nextPaymentDate"
        :price="price"
        :type="studioPlusCampaign.term"
      />
      <StudioPlusReactivationCancelledStep2
        v-if="reactivationStep === 1 && cancelled"
        :isPositive="isPositive"
        :price="price"
        :type="studioPlusCampaign.term"
        :nextPaymentDate="studioPlusCampaign?.nextPaymentDate"
        :retries="retries"
      />
      <StudioPlusReactivationExpiredStep1
        v-if="reactivationStep === 0 && expired"
        :price="price"
        :type="studioPlusCampaign.term"
        :accommodationId="accommodationId"
        :termsAccepted="termsAccepted"
        :defaultPaymentMethod="defaultPaymentMethod"
        :source="source"
        @toggleTerms="toggleTerms"
        @redirectToPayment="redirectToPaymentPage"
      />
      <StudioPlusReactivationExpiredStep2
        v-if="reactivationStep === 1 && expired"
        :isPositive="isPositive"
        :email="userDetails?.email"
      />
    </div>
    <template #modalFooter>
      <div class="tw-flex tw-justify-end tw-w-full">
        <GhostButton @click="cancelAction">{{ $t(cancelLabel) }}</GhostButton>
        <MainButton @click="nextStep" v-if="showNextActionButton" class="tw-ml-6" :disabled="isDisabled">
          <span v-show="!isUpdating">{{ $t(mainActionLabel) }}</span>
          <span v-show="isUpdating" class="tw-px-4"><RCInlineLoader color="#fff" /></span>
        </MainButton>
      </div>
    </template>
  </CustomModal>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useQueryClient } from '@tanstack/vue-query'

import CustomModal from '@/components/base/CustomModal.vue'
import StudioPlusReactivationCancelledStep1 from '@/components/settings/subscriptions/reactivation/StudioPlusReactivationCancelledStep1.vue'
import StudioPlusReactivationExpiredStep1 from '@/components/settings/subscriptions/reactivation/StudioPlusReactivationExpiredStep1.vue'
import StudioPlusReactivationCancelledStep2 from '@/components/settings/subscriptions/reactivation/StudioPlusReactivationCancelledStep2.vue'
import StudioPlusReactivationExpiredStep2 from '@/components/settings/subscriptions/reactivation/StudioPlusReactivationExpiredStep2.vue'
import CheckMarkInCircle from '@/components/icons/CheckMarkInCircle.vue'
import CloseOutline from '@/components/icons/CloseOutline.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'

import appAnalytics from '@/utils/tracking'
import { useUserDetails } from '@/layouts/queries'
import { useExistingPaymentMethods } from '@/components/payment/queries'
import {
  useStudioPlusCampaign,
  useStudioPlusPrice,
  useStudioPlusNewSubscriptionMutation,
} from '@/components/payment/queries'
import { getSupportCenterUrl } from '@/utils/Utility.js'

const queryClient = useQueryClient()
const store = useStore()
const router = useRouter()
const emit = defineEmits(['closeModal'])
const closeModal = () => emit('closeModal')
const reactivationCompleted = ref(false)

const props = defineProps({
  isOpen: Boolean,
  cancelled: Boolean,
  expired: Boolean,
  accommodationId: Number,
  accommodationName: String,
  source: String,
})

const isUpdating = ref(false)
const reactivationStep = ref(0)
const isPositive = ref(null)
const retries = ref(0)
const termsAccepted = ref(false)

const toggleTerms = () => {
  termsAccepted.value = !termsAccepted.value
}

const userId = computed(() => store.state.session.userId)
const language = computed(() => store.state.locale.language)

const setReactivationAccommodationId = id => store.commit('studioPlus/SET_REACTIVATION_ACCOMMODATION_ID', id)
const setReactivationSubscriptionType = type => store.commit('studioPlus/SET_REACTIVATION_SUBSCRIPTION_TYPE', type)
const removeReactivationAccommodationId = () => store.commit('studioPlus/REMOVE_REACTIVATION_ACCOMMODATION_ID')
const removeReactivationSubscriptionType = () => store.commit('studioPlus/REMOVE_REACTIVATION_SUBSCRIPTION_TYPE')

const { existingPaymentMethodsData, existingPaymentMethodsDataLoading } = useExistingPaymentMethods(
  props.accommodationId
)
const defaultPaymentMethod = computed(() => existingPaymentMethodsData.value?.find(item => item.isDefault))

const supportCenterUrl = computed(() => getSupportCenterUrl(store.state.locale.language, 'contactUs'))
const { userDetails } = useUserDetails(userId)
const { sendStudioPlusNewSubscription } = useStudioPlusNewSubscriptionMutation()
const { studioPlusCampaign, isLoading } = useStudioPlusCampaign(props.accommodationId)
const { studioPlusPriceMonthly, studioPlusPriceAnnual } = useStudioPlusPrice(props.accommodationId)

// TODO: clarify why nextPaymentAmount is not always returned
const price = computed(() => {
  if (studioPlusCampaign.value?.nextPaymentAmount !== null && studioPlusCampaign.value?.nextPaymentAmount !== '') {
    return studioPlusCampaign.value?.nextPaymentAmount
  } else {
    return studioPlusCampaign.value.term === 'MONTHLY'
      ? studioPlusPriceMonthly.value?.price / 100
      : (studioPlusPriceAnnual.value?.price * 12) / 100
  }
})

const titleLabel = computed(() => {
  if (reactivationStep.value === 0) return 'subscription_reactivate_modal_header'
  if (
    (reactivationStep.value === 1 && isPositive.value && props.cancelled) ||
    (reactivationStep.value === 1 && isPositive.value && reactivationCompleted.value) ||
    (reactivationStep.value === 1 && isPositive.value)
  )
    return 'subscription_reactivate_success_header'
  if (reactivationStep.value === 1 && !isPositive.value && props.cancelled) return 'reactivation_error'
  if (reactivationStep.value === 1 && !isPositive.value && props.expired) return 'payment_error'
  else return ''
})

const cancelLabel = computed(() => {
  if (reactivationStep.value === 0) return 'cancel'
  if (reactivationStep.value === 1) return 'close'
  else return ''
})
const mainActionLabel = computed(() => {
  if (reactivationStep.value === 0) return 'subscription_reactivate_modal_header'
  if (reactivationStep.value === 1 && !isPositive.value && props.cancelled && retries.value < 3)
    return 'cancellation_fail_retry_cta'
  if (reactivationStep.value === 1 && !isPositive.value && props.cancelled && retries.value >= 3)
    return 'Cancellation_fail_support_cta'
  if (reactivationStep.value === 1 && !isPositive.value && props.expired) return 'payment_method_new_cta'
  else return ''
})

const showNextActionButton = computed(() => {
  if (props.cancelled || props.expired) {
    if (reactivationStep.value === 1 && isPositive.value) return false
    else return true
  } else if (reactivationCompleted.value) {
    return false
  } else return true
})

onMounted(() => {
  if (router.currentRoute?.value?.query?.reactivate === 'true') {
    reactivationCompleted.value = true
    reactivationStep.value = 1
    isPositive.value = true
  } else {
    reactivationCompleted.value = false
  }
})

const reactivate = isRetry => {
  isUpdating.value = true
  sendStudioPlusNewSubscription(
    {
      accommodationId: props.accommodationId?.value ? props.accommodationId?.value : props.accommodationId,
      partnerId: studioPlusCampaign.value?.partnerId,
      itemName: props.accommodationName,
      email: userDetails?.value?.email,
      language: language?.value,
      studioUserId: userId.value,
      firstName: userDetails?.value?.firstName,
      lastName: userDetails?.value?.lastName,
      term: studioPlusCampaign.value?.term,
    },
    {
      onSuccess: data => {
        isUpdating.value = false
        isPositive.value = true
        reactivationStep.value = 1
        appAnalytics.track(appAnalytics.events.SP_SUBSCRIPTION_REACTIVATED, {
          item_id: props.accommodationId,
          partnerId: studioPlusCampaign.value?.partnerId,
          language: language.value,
          studioUserId: userId.value,
          email: userDetails?.value?.email,
          firstName: userDetails?.value?.firstName,
          lastName: userDetails?.value?.lastName,
          price:
            studioPlusCampaign.value?.term === 'MONTHLY'
              ? studioPlusPriceMonthly.value?.price
              : studioPlusPriceAnnual.value?.price,
          reactivation_date: new Date().toLocaleDateString(store.state.locale.language, {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }),
          subscription_type: studioPlusCampaign.value?.term,
          auto_renewal_date: new Date(data?.data?.nextPaymentDate).toLocaleDateString(store.state.locale.language, {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }),
        })
      },
      onError: () => {
        if (isRetry) {
          retries.value = retries.value + 1
        }
        reactivationStep.value = 1
        isUpdating.value = false
        isPositive.value = false
      },
    }
  )
}

const isDisabled = computed(() => {
  if (props.expired && !termsAccepted.value && reactivationStep.value === 0) return true
  else return false
})
const nextStep = () => {
  if (props.cancelled) {
    if (reactivationStep.value === 0) {
      appAnalytics.track(appAnalytics.events.SP_REACTIVATE_BTN, {
        accommodationId: props.accommodationId,
        isExpired: props.expired,
      })
      reactivate(false)
    }
    if (reactivationStep.value === 1 && !isPositive.value && retries.value < 3) {
      reactivate(true)
    }
    if (reactivationStep.value === 1 && !isPositive.value && retries.value >= 3) {
      resetModal()
      return window.open(supportCenterUrl.value, '_blank')
    }
  }
  if (props.expired) {
    if (reactivationStep.value === 0) {
      appAnalytics.track(appAnalytics.events.SP_REACTIVATE_BTN, {
        accommodationId: props.accommodationId,
        isExpired: props.expired,
      })
      reactivate(false)
    }
    if (reactivationStep.value === 1 && !isPositive.value) {
      redirectToPaymentPage()
    }
  }
}

const redirectToPaymentPage = async () => {
  await setReactivationAccommodationId(props.accommodationId)
  await setReactivationSubscriptionType(props.type === 'YEARLY' ? 'annual' : 'monthly')
  router.push({
    name: 'studio-plus-payment',
    query: {
      source: props.source,
    },
  })
}

const cancelAction = () => {
  appAnalytics.track(appAnalytics.events.SP_REACTIVATION_CANCEL_BTN, {
    accommodationId: props.accommodationId,
    isExpired: props.expired,
  })
  resetModal()
}
const resetModal = () => {
  if ((reactivationStep.value === 1 && isPositive.value) || reactivationCompleted.value) {
    queryClient.invalidateQueries({ queryKey: ['studioPlusCampaign'] })
    queryClient.invalidateQueries({ queryKey: ['studioPlusCampaigns'] })
    queryClient.invalidateQueries({ queryKey: ['studioPlusSubscriptions'] })
  }
  if (reactivationCompleted.value) {
    removeReactivationAccommodationId()
    removeReactivationSubscriptionType()
    router.push({ name: props.source === 'home' ? 'home' : 'settings-subscriptions' })
    reactivationCompleted.value = false
  }
  closeModal()
}
</script>
