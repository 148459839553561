<template>
  <BaseCard no-padding>
    <div class="filters-row tw-mb-8 tw-p-4 lg:tw-p-6 tw-pb-0">
      <ButtonGroup
        :items="statsDisplayType"
        :value="selectedDisplayType"
        class="lg:tw-justify-self-center group-buttons-switcher tw-text-xs tw-justify-self-start"
        version="2"
        @onClick="changeDisplayType"
      />
      <BaseSelect
        :id="'aggregation'"
        :display-text-key="'label'"
        :value-key="'value'"
        :options="aggregationOptions"
        :value="selectedAggregation"
        class="aggregation-select tw-text-xs"
        @onChange="changeAggregation"
      />
    </div>
    <div>
      <slot />
    </div>
  </BaseCard>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import ButtonGroup from '@/components/ButtonGroup.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import BaseSelect from '@/components/BaseSelect.vue'

const { t } = useI18n()

defineProps({
  tabType: {
    type: String,
    default: '',
  },
  selectedDisplayType: {
    type: String,
    default: 'chart',
  },
  selectedAggregation: {
    type: String,
    default: 'day',
  },
})

const emit = defineEmits(['onDisplayTypeChange', 'onSelectedAggregationChange'])

const statsDisplayType = [
  {
    label: 'rc_label_viewas_graph',
    value: 'chart',
  },
  { label: 'rc_label_viewas_table', value: 'table' },
]
const aggregationOptions = computed(() => [
  {
    value: 'day',
    label: `${t('rc_label_groupby')} ${t('rc_day')}`,
  },
  {
    value: 'week',
    label: `${t('rc_label_groupby')} ${t('rc_week')}`,
  },
  {
    value: 'month',
    label: `${t('rc_label_groupby')} ${t('rc_month')}`,
  },
])

const changeDisplayType = value => emit('onDisplayTypeChange', value)
const changeAggregation = value => emit('onSelectedAggregationChange', value)
</script>

<style scoped>
.filters-row {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.group-buttons-switcher {
  grid-column-start: 2;
  @media (max-width: theme('screens.md')) {
    grid-column-start: 1;
  }
}
.aggregation-select {
  display: grid;
  grid-column-start: 3;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-left: unset;
}
</style>
