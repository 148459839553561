<template>
  <div
    class="tw-flex tw-items-center tw-min-h-[20vh] tw-flex-col tw-justify-center tw-mt-5 tw-text-center tw-grow tw-w-full"
  >
    <div
      class="tw-flex tw-flex-col tw-py-[20px] tw-px-[10px] tw-bg-blue-100 tw-border tw-border-blue-300 tw-border-dashed tw-rounded-lg tw-min-h-[180px] tw-flex tw-w-full tw-m-auto tw-items-center tw-justify-center"
      @dragover="$emit('dragover', $event)"
      @dragleave="$emit('dragleave', $event)"
      @drop="$emit('drop', $event)"
    >
      <input
        id="fileInput"
        ref="file"
        type="file"
        multiple
        name="file"
        class="tw-opatity-0 tw-overflow-hidden tw-absolute tw-h-[1px] tw-w-[1px]"
        accept=".jpg,.jpeg,.png"
        @change="$emit('onchange', $event)"
      />

      <label for="fileInput" class="file-label">
        <div v-if="isDragging">Release to drop files here.</div>
        <div v-else>
          <div
            class="tw-w-[fit-content] tw-px-3 tw-font-bold tw-border tw-m-auto tw-bg-blue-700 tw-border-blue-700 tw-text-white tw-text-center tw-cursor-pointer tw-py-[0.44rem] tw-text-sm tw-rounded-lg"
          >
            {{ $t('app_images_header_button_text') }}
          </div>
          <p class="tw-text-sm tw-pt-2">{{ $t('app_images_upload_button_text') }}</p>
        </div>
      </label>
      <div v-if="files.length" class="tw-flex mt-4 tw-w-full">
        <div v-for="(file, index) in files" :key="index" class="tw-flex tw-p-2">
          <div class="tw-relative">
            <button
              class="close-btn tw-absolute tw-top-[3px] tw-right-[3px] tw-mb-0 focus:tw-outline-none no-lineheight tw-justify-end"
              @click="$emit('remove', $event, files.indexOf(file))"
              @keydown.enter="$emit('remove', $event, files.indexOf(file))"
            >
              <BaseIcon icon-name="close" height="14" width="14" viewbox-height="20" viewbox-width="20">
                <Close />
              </BaseIcon>
            </button>
            <UiTooltip
              v-if="file.size > 20000000"
              tooltip-position="top"
              :label="$t('app_images_upload_failed_max_size')"
              class="tw-w-6 tw-absolute tw-bottom-[3px] tw-left-[3px]"
            >
              <BaseIcon icon-name="excalamation-mark" :height="24" :width="24">
                <ExclamationMark class="tw-fill-red-700" />
              </BaseIcon>
            </UiTooltip>
            <img
              alt="img"
              :class="{ 'wrong-image': file.size > 20000000 }"
              class="preview-img"
              :src="generateURL(file)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseIcon from '@/components/BaseIcon.vue'
import Close from '@/components/icons/Close.vue'
import ExclamationMark from '@/components/icons/ExclamationMark.vue'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'

defineProps({
  isDragging: Boolean,
  files: [FileList, Array],
})
const generateURL = file => {
  let fileSrc = URL.createObjectURL(file)
  setTimeout(() => {
    URL.revokeObjectURL(fileSrc)
  }, 1000)
  return fileSrc
}
</script>
<style scoped>
.preview-img {
  @apply tw-rounded-md;
  width: 100px;
  height: 100px;
  object-fit: cover;
  background-color: theme('colors.gray.300');
}
.close-btn {
  background-color: white;
  padding-left: 4px;
  padding-top: 4px;
  border-radius: 10px;
  padding-bottom: 1px;
  padding-right: 1px;
}
.wrong-image {
  border: 2px solid theme('colors.red.800');
}
</style>
