<template>
  <div class="tw-fixed tw-bg-white tw-w-full tw-z-10">
    <div
      class="tw-flex tw-justify-between tw-items-center tw-mx-auto"
      :class="
        isLanding
          ? 'lg:tw-max-w-screen-landing md:tw-max-w-screen-md sm:tw-px-8 tw-px-4 lg:tw-px-0 tw-py-1 lg:tw-py-0'
          : 'tw-w-full tw-px-5'
      "
    >
      <div class="tw-duration-100 tw-relative tw-w-auto">
        <router-link v-if="pageName === 'studio-plus'" to="/getstarted"
          ><img :src="BusinessPlusLogo" alt="Business Studio+" class="tw-h-[18px] xs:tw-h-[22px]"
        /></router-link>
        <router-link v-else-if="pageName === 'rate-connect'" to="/getstarted"
          ><img :src="RateConnectLogo" alt="Business Studio+" class="tw-h-[18px] xs:tw-h-[22px]"
        /></router-link>
        <Logo :isLanding="true" v-else />
      </div>
      <nav class="navigation tw-h-16 lg:tw-flex tw-justify-center tw-py-auto tw-hidden tw-w-auto tw-mx-auto">
        <div
          class="dropdown-menu tw-flex tw-relative tw-items-center tw-w-fit tw-pr-[30px] tw-text-sm tw-transition tw-ease-in-out tw-delay-2 hover:tw-cursor-pointer"
          data-testid="header-account-features-menu"
        >
          {{ $t('navigation_menu_our_solutions') }}
          <BaseIcon
            icon-name="chevron-right"
            :height="14"
            :width="18"
            :viewbox-height="14"
            :viewbox-width="25"
            class="tw-absolute hover:tw-fill-blue-800 arrow-dropdown tw-fill-gray-800 hover:tw-fill-blue-800"
          >
            <ChevronRight />
          </BaseIcon>
          <WebMenu :links="productLinks" class="tw-hidden menu-box" />
        </div>
        <div
          class="dropdown-menu tw-flex tw-relative tw-items-center tw-w-fit tw-text-sm tw-pr-[30px] tw-ml-[30px] tw-transition tw-ease-in-out tw-delay-2 hover:tw-cursor-pointer"
          data-testid="header-solutions-menu"
        >
          {{ $t('navigation_menu_support_center') }}
          <BaseIcon
            icon-name="chevron-right"
            :height="14"
            :width="18"
            :viewbox-height="14"
            :viewbox-width="25"
            class="tw-absolute hover:tw-fill-blue-800 arrow-dropdown tw-fill-gray-800 hover:tw-fill-blue-800"
          >
            <ChevronRight />
          </BaseIcon>
          <WebMenu :links="supportLinks" class="tw-hidden menu-box" />
        </div>
      </nav>
      <nav class="tw-w-[50px] lg:tw-w-[30%]">
        <ul class="tw-flex tw-items-center tw-justify-end tw-gap-2.5 md:tw-gap-2">
          <LanguageSelector size="big" class="md:tw-block tw-hidden tw-min-w-[160px]" version="footer" />
          <li>
            <BaseButton class="md:tw-hidden tw-flex tw-p-0" @click="onRegisterClick" @keydown.enter="onRegisterClick"
              ><UserIcon class="tw-h-[24px] tw-w-[24px]"
            /></BaseButton>
            <MainButton
              size="medium"
              no-padding
              class="tw-hidden md:tw-flex tw-whitespace-nowrap"
              @click="onRegisterClick"
              @keydown.enter="onRegisterClick"
              :dataTestId="'header-register-button'"
            >
              {{ $t('tbs_mp_log_in_cta') }}
            </MainButton>
          </li>
          <li class="lg:tw-hidden tw-flex tw-items-center sm:tw-ml-4">
            <div
              class="nav-icon tw-h-[24px] tw-w-[30px] tw-relative tw-my-[17px] tw-mx-auto tw-cursor-pointer"
              :class="{ 'nav-icon-open': isMenuOpen }"
              @click="() => toggleMenu(true)"
              @keydown.enter="() => toggleMenu(true)"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <span class="tw-hidden md:tw-inline-flex tw-ml-4">{{ 'Menu' }}</span>
          </li>
        </ul>
      </nav>
    </div>
    <MobileMenu
      :supportLinks="supportLinks"
      :productLinks="productLinks"
      class="tw-flex tw-items-center"
      :is-menu-open="isMenuOpen"
      :on-clickaway="onClickaway"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue'

import BaseButton from '@/components/base/buttons/BaseButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import UserIcon from '@/components/icons/UserIcon.vue'
import Logo from '@/components/dashboard/Logo.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import LanguageSelector from '@/components/locale/LanguageSelector.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
import MobileMenu from '@/components/landingPage/MobileMenu.vue'
import WebMenu from '@/components/landingPage/WebMenu.vue'

const BusinessPlusLogo = new URL('/src/assets/img/landing/landing_studio_plus.svg', import.meta.url)
const RateConnectLogo = new URL('/src/assets/img/landing/landing_rate_connect.svg', import.meta.url)

defineProps({
  pageName: String,
  isLanding: {
    type: Boolean,
    default: false,
  },
})
const isMenuOpen = ref(false)
const isMenuMobile = ref(false)
const productLinks = [
  {
    id: 0,
    newPage: false,
    label: 'navigation_menu_business_studio_plus',
    url: '/home/getstarted/studio-plus',
    dataTestId: 'menu-header-studio-plus-link',
    withIcon: false,
  },
  {
    id: 1,
    newPage: false,
    label: 'navigation_menu_rate_connect',
    url: '/home/getstarted/rate-connect',
    dataTestId: 'menu-header-rate-connect-link',
    withIcon: false,
  },
  {
    id: 2,
    newPage: false,
    label: 'navigation_menu_free_booking_links',
    url: '/home/getstarted/free-booking-links',
    dataTestId: 'menu-header-free-booking-links-link',
    withIcon: false,
  },
]
const supportLinks = ref([
  {
    id: 0,
    newPage: true,
    label: 'navigation_menu_support_1',
    url: 'contactUs',
    dataTestId: 'header-contact-us-link',
    withIcon: true,
  },
  {
    id: 1,
    newPage: true,
    label: 'navigation_menu_support_2',
    url: 'supportCentre',
    dataTestId: 'header-support-center-link',
    withIcon: true,
  },
])

const toggleMenu = isMobile => {
  isMenuOpen.value = !isMenuOpen.value
  isMenuMobile.value = isMobile
}
const onClickaway = () => {
  if (isMenuOpen.value) {
    isMenuOpen.value = false
  }
}
const emit = defineEmits(['onRegisterClick'])
const onRegisterClick = e => emit('onRegisterClick', e.target.value)
</script>
<style scoped>
.divider {
  @apply md:tw-block tw-hidden tw-h-[48px] tw-w-[1px] tw-bg-gray-300;
}

.navigation div:hover .menu-box {
  @apply tw-flex;
}
.navigation div:hover svg {
  fill: theme('colors.blue.800');
  transform: translateY(-1px) translateX(-8px) rotate(270deg);
}
.arrow-dropdown {
  top: 1px;
  bottom: 0;
  height: 100%;
  right: 0;
  pointer-events: none;
  transform: translateY(0%) rotate(90deg);
}
.nav-icon {
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}
.nav-icon span {
  @apply tw-block tw-absolute tw-h-[2px] tw-w-full tw-opacity-100 tw-left-0 tw-rounded-lg;
  background: #37454d;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.nav-icon span:nth-child(1) {
  top: 0px;
  transform-origin: left center;
}

.nav-icon span:nth-child(2) {
  top: 10px;
  transform-origin: left center;
}

.nav-icon span:nth-child(3) {
  top: 20px;
  transform-origin: left center;
}
.nav-icon-open span:nth-child(1) {
  transform: rotate(45deg);
  top: 2px;
  left: 0;
}
.nav-icon-open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}
.nav-icon-open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 23px;
  left: 0;
}

.sign-button {
  @media (max-width: 540px) {
    display: none;
  }
}
</style>
