<template>
  <Notification
    :description="
      $t('notification_unavailability_message', {
        unavailability_score: $n(unavailabilityScore, 'percent'),
        partner_name: partnerName,
      })
    "
    :header="$t('notification_unavailability_header')"
    v-bind="$attrs"
  >
  </Notification>
</template>

<script setup>
import Notification from './Notification.vue'
import { computed } from 'vue'

const props = defineProps({
  extraParameters: {
    type: Object,
    required: true,
    default: null,
  },
})
const unavailabilityScore = computed(() => {
  if (!props.extraParameters) return ''
  return props.extraParameters.unavailabilityScore
})
const partnerName = computed(() => {
  if (!props.extraParameters) return ''
  return props.extraParameters.partnerName
})
</script>
