<template>
  <div class="legend-skeleton tw-animate-pulse">
    <div class="tw-rounded tw-bg-gray-300" />
  </div>
</template>

<style scoped>
.legend-skeleton > div {
  height: 400px;
  width: 100%;
}
</style>
