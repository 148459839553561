<template>
  <BaseCard
    class="tw-relative tw-overflow-hidden tw-max-w-screen-xxl tw-mx-auto tw-mb-8 tw-flex md:tw-justify-between"
    noPadding
  >
    <div class="tw-w-full md:tw-w-2/3 tw-py-8 tw-pl-8 tw-pr-16">
      <div class="md:tw-max-w-[80%]">
        <div class="tw-max-w-[120px] tw-pb-6">
          <BaseIcon
            icon-name="trivago Business Studio"
            :height="23"
            :width="237"
            :viewbox-height="23"
            :viewbox-width="237"
            class="tw-inline-block"
          >
            <TrivagoLogoPlus />
          </BaseIcon>
        </div>
        <h3 class="tw-font-bold tw-text-[32px] tw-mb-8" v-html="$t('business_studio_plus_lp_header')" />
        <div class="tw-font-bold tw-text-base tw-mb-3">{{ $t('studio_plus_banner_subtext') }}</div>
        <div class="tw-flex tw-mb-4 tw-justify-start tw-flex-col tw-flex-wrap">
          <span class="tw-font-light tw-items-center tw-flex tw-mr-2 tw-my-1" v-for="item in list" :key="item">
            <CheckMarkInCircle height="24px" width="24px" class="tw-inline tw-mr-4 tw-stroke-blue-700" />
            {{ $t(item) }}
          </span>
        </div>
        <div class="tw-mt-8 tw-flex tw-items-start tw-flex-col">
          <div class="tw-bg-red-800 tw-text-white tw-px-1 tw-py-[3px] tw-rounded tw-font-bold tw-text-xs tw-uppercase">
            {{ $t('business_studio_plus_discount', { DiscountPercentage: '15' }) }}
          </div>
          <p
            class="tw-font-light current-price"
            v-html="
              $t('business_studio_plus_price_discounted', {
                AnnualPrice: `${CURRENCIES[currency]}${$n(annualPrice / 100, 'currencyCode')}`,
              })
            "
          />
          <p class="tw-font-light tw-line-through">
            {{
              $t('business_studio_plus_price_no_discount', {
                AnnualPrice: `${CURRENCIES[currency]}${$n(monthlyPrice / 100, 'currencyCode')}`,
              })
            }}
          </p>
        </div>
        <div class="tw-flex tw-flex-col tw-items-center md:tw-items-start tw-mt-4">
          <MainButton @click="redirectToPaymentPage('annual')" :disabled="!isAdmin">{{
            $t('subscription_annual_cta')
          }}</MainButton>
        </div>
        <small class="tw-text-xs">{{ $t('studio_plus_connectivity_footnote') }}</small>
      </div>
    </div>
    <div class="tw-hidden md:tw-flex md:tw-w-1/3 tw-relative tw-bg-decorative-yellow">
      <img
        class="tw-m-auto tw-z-0 tw-absolute tw-top-0 tw-bottom-0 tw-left-[-270px] tw-min-w-[650px]"
        :src="heroImage"
        alt="Studio Plus"
      />
    </div>
  </BaseCard>
  <div class="tw-my-10">
    <StudioPlusCarousel :isBlue="false" :isCarousel="isMobile" :showNavigation="true" />
  </div>
</template>
<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { CURRENCIES } from '@/constants/currencies.js'
import appAnalytics from '@/utils/tracking'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import CheckMarkInCircle from '@/components/icons/CheckMarkInCircle.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import TrivagoLogoPlus from '@/components/icons/TrivagoLogoPlus.vue'
import StudioPlusCarousel from './StudioPlusCarousel.vue'

import useRateConnect from '@/components/rateConnect/queries'
import { selectedAccommodationId, useAccommodationsByOrgId, useUserDetails } from '@/layouts/queries'

const router = useRouter()
const props = defineProps({
  monthlyPrice: Number,
  annualPrice: Number,
  currency: String,
})

const store = useStore()
const list = [
  'studio_plus_banner_benefit_1',
  'studio_plus_banner_benefit_2',
  'studio_plus_banner_benefit_3',
  'studio_plus_banner_benefit_4',
]
const isAdmin = computed(() => store.state.session?.userRole === 'admin')
const isMobile = document.body.clientWidth >= 500 ? ref(false) : ref(true)
const selectedOrganisationId = computed(() => store.state.session?.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === selectedAccommodationId.value)
)
const { hotelierData } = useRateConnect(selectedAccommodationId)
const directRatesPartnerId = computed(() => hotelierData.value?.directRatesPartner)
const userId = computed(() => store.state.session.user?.id)
const { userDetails } = useUserDetails(userId)

const language = computed(() => store.state.locale.language)

const heroImage = new URL(`@/assets/img/studio-plus/studio-plus-home-banner.svg`, import.meta.url)

const redirectToPaymentPage = type => {
  router.push({ name: 'studio-plus-payment', query: { subscription: type } })

  appAnalytics.track(appAnalytics.events.SP_COMPARE_FEATURES_BANNER_CLICKED, {
    item_id: selectedAccommodationId.value,
    type: type,
    itemName: currentAccommodation?.value?.name,
    monthly_price: props.monthlyPrice,
    annual_price: props.annualPrice,
    partnerId: directRatesPartnerId.value,
    language: language.value,
    studioUserId: userDetails.value?.id,
    email: userDetails.value?.email,
    firstName: userDetails.value?.firstName,
    lastName: userDetails.value?.lastName,
  })
}
</script>
<style scoped>
.current-price :deep(span) {
  font-size: 32px;
  font-weight: bold;
}
.shadow {
  box-shadow: 0 2px 4px 0 rgba(63, 87, 189, 0.1), 0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
</style>
