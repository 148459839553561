<template>
  <div class="tw-w-full tw-flex tw-flex-col md:tw-flex-row">
    <div class="tw-w-full">
      <p class="tw-font-bold tw-text-xl tw-text-gray-800 tw-my-2">{{ $t('app_hotel_details_contact_information') }}</p>
      <div class="tw-flex tw-flex-col">
        <BaseTextField
          required
          :label="$t('form.labels.email_address')"
          name="email"
          aria-label="email"
          type="text"
          class="tw-w-full tw-mb-4 md:tw-mb-auto"
          size="medium"
          :error="errors['email'] ? $t(errors['email']) : false"
          :value="email"
          @onChange="e => setValue('email', e)"
        />
        <BaseTextField
          required
          :label="$t('app_hotel_details_hotel_website')"
          name="homepageUrl"
          aria-label="homepageUrl"
          type="text"
          class="tw-w-full tw-mb-4 md:tw-mb-auto"
          size="medium"
          :error="errors['homepageUrl'] ? $t(errors['homepageUrl']) : false"
          :value="web"
          @onChange="e => setValue('homepageUrl', e)"
        />
      </div>
    </div>
    <div class="divider tw-hidden md:tw-flex" />
    <div class="tw-w-full">
      <div class="tw-flex tw-flex-col md:tw-mt-[44px]">
        <BaseTextField
          :label="$t('form.labels.phone_number')"
          name="phone"
          aria-label="phone"
          required
          type="tel"
          :max-length="150"
          size="medium"
          :disabled="validationInProgress && currentValidation === 'phone'"
          :error="errors['phone'] ? getErrorMessage($t(errors['phone']), 'phone') : false"
          :value="phoneNumber"
          @onChange="e => setValue('phoneNumber', e)"
          @onInput="e => inputChange('phoneNumber', e)"
        />

        <BaseTextField
          :label="$t('app_hotel_details_fax')"
          name="fax"
          aria-label="fax"
          type="tel"
          :max-length="150"
          :disabled="validationInProgress && currentValidation === 'fax'"
          size="medium"
          :error="errors['fax'] ? getErrorMessage($t(errors['fax']), 'fax') : false"
          :value="faxNumber"
          @onChange="e => setValue('faxNumber', e)"
          @onInput="e => inputChange('faxNumber', e)"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue'
import { validate, required, emailAddress, minLength, latinChars, url } from '@/utils/propertyDetailsHelpers.js'
import { usePropertyDetailsBasicInfo } from '@/components/propertyDetails/propertyBasicInfo/usePropertyDetailsBasicInfo.js'

import CountriesService from '@/services/CountriesService.js'
import BaseTextField from '@/components/base/inputs/BaseTextField.vue'

const props = defineProps({
  errors: {
    type: Object,
    default: () => {},
  },
  email: String,
  web: String,
  phone: {
    type: String,
    default: '',
  },
  fax: {
    type: String,
    default: '',
  },
  phoneCodes: Array,
})
const faxNumber = ref(props.fax)
const phoneNumber = ref(props.phone)
const phoneLocale = ref(null)
const faxLocale = ref(null)
const phoneValidationErrors = ref([])
const faxValidationErrors = ref([])
const currentValidation = ref('')
const { setNewValue, setValidation, setValidationInProgress, validationInProgress, setPhoneNumberTouched } =
  usePropertyDetailsBasicInfo()

const locales = computed(() => {
  return props.phoneCodes.map(item => {
    return {
      value: item.countryCode,
      phoneCode: item.phoneCode[0],
      example: item.placeholder,
    }
  })
})

const numberValidation = ({ locales, phoneNumber, type }) => {
  const promises = []
  locales.forEach(locale => {
    promises.push(
      new Promise((resolve, reject) => {
        if (type === 'fax' && (phoneNumber === undefined || phoneNumber.length === 0)) {
          type === 'phone' ? phoneValidationErrors.value.push(false) : faxValidationErrors.value.push(false)
          resolve()
        }

        setValidationInProgress(true)
        const countryCode = locale.phoneCode
        let phoneWithoutCode = phoneNumber
        phoneWithoutCode = phoneWithoutCode.replaceAll(/\D/g, '')
        phoneWithoutCode = phoneWithoutCode.replace(countryCode, '').trim()
        if (!phoneWithoutCode) {
          type === 'phone'
            ? phoneValidationErrors.value.push('error_field_required')
            : faxValidationErrors.value.push('error_field_required')
          resolve()
        }
        const numeric = new RegExp(/^[\d]+$/)
        if (!numeric.test(phoneWithoutCode) || phoneWithoutCode.length < 2) {
          setValidationInProgress(false)
          resolve()
        }
        CountriesService.validatePhoneNumber({ locale: locale.value, phone: phoneWithoutCode })
          .then(res => {
            type === 'phone'
              ? phoneValidationErrors.value.push(res?.data?.valid ? false : 'invalid_phone_number')
              : faxValidationErrors.value.push(res?.data?.valid ? false : 'invalid_phone_number')
            resolve()
          })
          .catch(() => {
            type === 'phone'
              ? phoneValidationErrors.value.push('invalid_phone_number')
              : faxValidationErrors.value.push('invalid_phone_number')
            reject()
          })
      })
    )
  })

  Promise.allSettled(promises).then(() => {
    const isValid =
      type === 'phone' ? phoneValidationErrors.value.some(item => !item) : faxValidationErrors.value.some(item => !item)
    if (isValid) {
      setValidation({ name: type, value: false })
      setValidationInProgress(false)
    } else {
      setValidation({
        name: type,
        value: type === 'phone' ? phoneValidationErrors.value[0] : faxValidationErrors.value[0],
      })
      setValidationInProgress(false)
    }
  })
}

onMounted(() => {
  const fieldsToValidate = [
    { name: 'email', value: props.email },
    { name: 'homepageUrl', value: props.web },
  ]
  fieldsToValidate.forEach(item => {
    const validationFunctions = getValidations(item.name)
    const error = validate(item.value, validationFunctions)
    setValidation({
      name: item.name,
      value: error !== undefined ? error : false,
    })
  })
})

const getErrorMessage = (error, type) => {
  if (error.includes('$example') && localeExample(type)) {
    return error.replace('$example', localeExample(type).replaceAll(' ', ''))
  } else {
    return error
  }
}
const inputChange = (name, value) => {
  if (name === 'phoneNumber') {
    setPhoneNumberTouched(true)
    phoneNumber.value = value
  } else if (name === 'faxNumber') {
    faxNumber.value = value
  }
}

const findLocales = ({ value, type }) => {
  let foundLocales
  if (value !== null && value.startsWith('+')) {
    foundLocales = locales.value.filter(item => value.startsWith(item.phoneCode, 1))
  }
  if (type === 'phone') {
    phoneLocale.value = foundLocales
  } else if (type === 'fax') {
    faxLocale.value = foundLocales
  }
}

const getValidations = name => {
  if (name === 'email') {
    return [required, emailAddress]
  } else if (name === 'homepageUrl') {
    return [required, url, latinChars, minLength]
  } else {
    return []
  }
}
const localeExample = type => {
  let example = ''

  locales.value.forEach(item => {
    if (type === 'phone' && phoneLocale.value !== null) {
      if (item.value === phoneLocale?.value[0]?.value) {
        example = `+ ${item.phoneCode}${item.example.replace(/[^a-zA-Z0-9 ]/g, '')}`
      }
    } else if (faxLocale.value !== null) {
      if (item.value === faxLocale?.value[0]?.value) {
        example = `+ ${item.phoneCode}${item.example.replace(/[^a-zA-Z0-9 ]/g, '')}`
      }
    }
  })
  return example
}

const setValue = (name, value) => {
  if (name === 'phoneNumber') {
    currentValidation.value = 'phone'
    findLocales({ value, type: 'phone' })
    setPhoneNumberTouched(false)

    if (!phoneLocale.value) {
      setValidation({
        name: 'phone',
        value: 'telephone_number_error_message',
      })
    } else if (/[a-zA-Z]/g.test(value)) {
      setValidation({ name: 'phone', value: 'invalid_phone_number' })
    } else {
      phoneNumber.value = phoneNumber.value.replaceAll(' ', '')
      let number = name === 'phoneNumber' ? phoneNumber.value : props.phone.number
      numberValidation({ locales: phoneLocale.value, phoneNumber: number, type: 'phone' })
      setNewValue({
        name: 'phone',
        value: value.replaceAll(' ', ''),
      })
      phoneValidationErrors.value = []
    }
  } else if (name === 'faxNumber') {
    currentValidation.value = 'fax'
    findLocales({ value, type: 'fax' })
    if (!faxLocale.value) {
      setValidation({
        name: 'fax',
        value: 'telephone_number_error_message',
      })
    } else {
      faxNumber.value = phoneNumber.value.replaceAll(' ', '')
      let number = name === 'faxNumber' ? faxNumber.value : props.fax.number
      numberValidation({ locales: faxLocale.value, phoneNumber: number, type: 'fax' })
      setNewValue({
        name: 'fax',
        value: value.replaceAll(' ', ''),
      })
      faxValidationErrors.value = []
    }
  } else {
    const validationFunctions = getValidations(name)
    const error = validate(value, validationFunctions)
    setValidation({ name, value: error !== undefined ? error : false, isFormValid: error === undefined })
    setNewValue({ name, value })
  }
}
</script>
<style scoped>
.divider {
  border-right: 1px solid theme('colors.gray.300');
  height: 100%;
  min-height: 200px;
  @apply tw-mx-4;
}
</style>
