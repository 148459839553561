<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1099.4 108.55">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <rect class="cls-2" x="113.53" y="25.22" width="18.4" height="55.69" rx=".87" ry=".87" />
        <path
          class="cls-3"
          d="m179.22,25.78l-10.97,29.21c-.06.17-.3.17-.36,0l-11.06-29.21c-.13-.34-.45-.56-.81-.56h-18.22c-.61,0-1.03.62-.8,1.19l21.91,53.96c.13.33.45.54.8.54h16.02c.35,0,.67-.21.8-.54l22.01-53.96c.23-.57-.19-1.19-.8-1.19h-17.71c-.36,0-.68.22-.81.56Z"
        />
        <path
          class="cls-4"
          d="m353.94,24.37c-17.39,0-30.06,12.26-30.06,28.75s12.67,28.75,30.06,28.75,30.06-12.36,30.06-28.75-12.77-28.75-30.06-28.75Zm0,41.82c-6.83,0-11.36-5.23-11.36-13.07s4.52-13.07,11.36-13.07,11.36,5.23,11.36,13.07-4.62,13.07-11.36,13.07Z"
        />
        <path
          class="cls-2"
          d="m107.44,24.95c-.47-.17-1.09-.31-1.74-.41-1.07-.17-12.37-1.44-18.66,7-.11.15-.35.07-.35-.12v-4.42s0,0,0,0h0v-.91c0-.48-.39-.87-.87-.87h-16.66c-.48,0-.87.39-.87.87v53.96c0,.48.39.87.87.87h16.66c.48,0,.87-.39.87-.87v-20.03c0-14.98,6.94-18.7,13.77-18.7.8,0,3.14,0,6.44.91.55.15,1.09-.26,1.09-.83v-15.62c0-.36-.22-.69-.56-.81Z"
        />
        <path
          class="cls-2"
          d="m122.73,0c-5.81,0-10.52,4.54-10.52,10.14s4.71,10.14,10.52,10.14,10.52-4.54,10.52-10.14-4.71-10.14-10.52-10.14Z"
        />
        <path
          class="cls-3"
          d="m255.56,25.22h-16.66c-.48,0-.87.39-.87.87v5.31c0,.21-.28.27-.37.08-6.02-12.61-41.66-10.16-41.66,21.59,0,29.68,30.45,34.96,41.69,22.54.12-.13.34-.05.34.13v4.31c0,.48.39.87.87.87h16.66c.48,0,.87-.39.87-.87V26.08c0-.48-.39-.87-.87-.87Zm-29.08,40.91c-7.34,0-12.46-5.12-12.46-13.01s5.12-13.12,12.46-13.12,12.46,5.34,12.46,13.12-5,13.01-12.46,13.01Z"
        />
        <path
          class="cls-4"
          d="m318.49,25.22h-16.66c-.48,0-.87.39-.87.87v5.31c0,.21-.28.27-.37.08-6.04-12.77-39.24-9.56-39.24,19.98,0,28.97,30.26,31.35,39.28,22.92.12-.12.32-.03.32.14v2.61h0c-.23,12.31-15.65,17.64-23.48,8.29-.28-.33-.77-.41-1.13-.17l-14.04,9.08c-.42.27-.52.84-.22,1.25,17.92,24.49,56.99,12.03,57.28-18.39h0V26.08c0-.48-.39-.87-.87-.87Zm-27.48,38c-6.63,0-11.26-4.62-11.26-11.76s4.62-11.86,11.26-11.86,11.26,4.83,11.26,11.86-4.52,11.76-11.26,11.76Z"
        />
        <path
          class="cls-2"
          d="m62.49,50.43h-16.69c-.44,0-.83.36-.86.81-.54,6.85-6.26,12.24-13.25,12.24-8.39,0-11.78-7.12-11.78-7.12-1.45-2.73-1.52-5.36-1.52-5.93,0-.07.04-.13.1-.17l4.77-2.64h0l30.17-16.68c.46-.25.74-.74.74-1.26V11.74c0-.66-.71-1.08-1.28-.76L18.68,29.9c-.13.07-.29-.02-.29-.17V1.53c0-.69-.76-1.1-1.34-.73L.66,11.43c-.41.27-.66.72-.66,1.21v37.79c.14,17.39,14.27,31.44,31.69,31.44s31.06-13.57,31.67-30.53c.02-.49-.37-.91-.87-.91Z"
        />
      </g>
      <g>
        <path
          class="cls-1"
          d="m446.61,14.48c4.06,0,7.66.67,10.8,2,3.13,1.33,5.52,3.22,7.15,5.65,1.63,2.43,2.45,5.28,2.45,8.55,0,2.87-.7,5.4-2.1,7.6-1.4,2.2-3.42,3.95-6.05,5.25-2.63,1.3-5.72,2.08-9.25,2.35v1.5c6.27.2,11.25,1.62,14.95,4.25,3.7,2.63,5.55,6.38,5.55,11.25,0,3.53-.82,6.63-2.45,9.3-1.63,2.67-4.13,4.8-7.5,6.4-3.37,1.6-7.45,2.4-12.25,2.4h-31.9V14.48h30.6Zm-2.7,27.8c2.93,0,5.25-.75,6.95-2.25,1.7-1.5,2.55-3.62,2.55-6.35s-.87-4.63-2.6-6.1c-1.73-1.47-4.04-2.2-6.9-2.2h-14.8v16.9h14.8Zm1.8,27.8c2.2,0,4.06-.35,5.6-1.05,1.53-.7,2.73-1.73,3.6-3.1.87-1.37,1.3-2.98,1.3-4.85,0-2.87-.94-5.12-2.8-6.75-1.87-1.63-4.43-2.45-7.7-2.45h-16.6v18.2h16.6Z"
        />
        <path
          class="cls-1"
          d="m520.41,80.98h-13.2v-.7c.07-1.07.25-2.02.55-2.85.3-.83.75-1.88,1.35-3.15.53-1,1-2.07,1.4-3.2.4-1.13.77-2.37,1.1-3.7l-1.6-.5-.4,1.3c-1.4,4.53-3.63,8-6.7,10.4s-6.97,3.6-11.7,3.6c-3.13,0-5.92-.55-8.35-1.65-2.44-1.1-4.38-2.93-5.85-5.5-1.47-2.57-2.2-5.85-2.2-9.85V30.98h13.3v31.7c0,2.93.77,5.13,2.3,6.6,1.53,1.47,3.7,2.2,6.5,2.2h.3c2.87,0,5.25-.9,7.15-2.7,1.9-1.8,2.85-4.2,2.85-7.2v-30.6h13.2v50Z"
        />
        <path
          class="cls-1"
          d="m538.51,63.98c.13,1.87.63,3.43,1.5,4.7.87,1.27,2.02,2.25,3.45,2.95,1.43.7,2.98,1.05,4.65,1.05,1.53,0,2.9-.2,4.1-.6,1.2-.4,2.18-1.03,2.95-1.9.77-.87,1.15-1.9,1.15-3.1,0-1.53-.57-2.75-1.7-3.65-1.13-.9-2.9-1.68-5.3-2.35l-9.4-2.4c-4.4-1.13-7.69-2.87-9.85-5.2-2.17-2.33-3.25-5.33-3.25-9,0-2.93.9-5.53,2.7-7.8,1.8-2.27,4.3-3.98,7.5-5.15,3.2-1.17,6.77-1.75,10.7-1.75s7.22.68,10.45,2.05c3.23,1.37,5.83,3.35,7.8,5.95,1.97,2.6,3.08,5.7,3.35,9.3h-12.9c-.13-1.87-.65-3.4-1.55-4.6s-2-2.1-3.3-2.7c-1.3-.6-2.72-.9-4.25-.9-2.27,0-4.07.47-5.4,1.4-1.33.93-2,2.23-2,3.9,0,1.27.46,2.35,1.4,3.25.93.9,2.46,1.65,4.6,2.25l9.6,2.3c4.46,1.13,7.92,2.85,10.35,5.15,2.43,2.3,3.65,5.35,3.65,9.15,0,3.27-.85,6.1-2.55,8.5-1.7,2.4-4.17,4.23-7.4,5.5-3.23,1.27-7.02,1.9-11.35,1.9s-8.33-.75-11.6-2.25c-3.27-1.5-5.87-3.6-7.8-6.3-1.94-2.7-3.07-5.92-3.4-9.65h13.1Z"
        />
        <path
          class="cls-1"
          d="m583.31,24.88c-2.2,0-4.05-.75-5.55-2.25s-2.25-3.28-2.25-5.35.75-3.83,2.25-5.3c1.5-1.47,3.35-2.2,5.55-2.2s3.93.73,5.4,2.2c1.46,1.47,2.2,3.23,2.2,5.3s-.73,3.85-2.2,5.35c-1.47,1.5-3.27,2.25-5.4,2.25Zm-6.6,6.1h13.2v50h-13.2V30.98Z"
        />
        <path
          class="cls-1"
          d="m598.41,30.98h13.3v.7c0,1.07-.15,2.02-.45,2.85-.3.83-.79,2.02-1.45,3.55-.54,1.13-1,2.25-1.4,3.35-.4,1.1-.77,2.28-1.1,3.55l1.5.5.5-1.3c1.4-4.47,3.78-7.98,7.15-10.55,3.37-2.57,7.45-3.85,12.25-3.85,3.13,0,5.92.55,8.35,1.65,2.43,1.1,4.38,2.93,5.85,5.5,1.46,2.57,2.2,5.85,2.2,9.85v34.2h-13.3v-31.3c0-2.93-.87-5.13-2.6-6.6-1.73-1.47-4.03-2.2-6.9-2.2h-.3c-1.87,0-3.58.4-5.15,1.2-1.57.8-2.82,1.92-3.75,3.35-.93,1.43-1.4,3.15-1.4,5.15v30.4h-13.3V30.98Z"
        />
        <path
          class="cls-1"
          d="m674.81,82.18c-5.34,0-9.84-1.08-13.5-3.25-3.67-2.17-6.44-5.2-8.3-9.1-1.87-3.9-2.8-8.48-2.8-13.75s1.03-9.98,3.1-13.95c2.06-3.97,4.95-7.02,8.65-9.15,3.7-2.13,8.05-3.2,13.05-3.2s9.3,1.05,12.9,3.15c3.6,2.1,6.35,5.13,8.25,9.1,1.9,3.97,2.85,8.68,2.85,14.15v2.5l-35.5.1v.1c.07,2.53.58,4.8,1.55,6.8.96,2,2.3,3.55,4,4.65,1.7,1.1,3.71,1.65,6.05,1.65h.3c2.87,0,5.28-.77,7.25-2.3,1.96-1.53,3.18-3.43,3.65-5.7h13.5c-.67,3.67-2.12,6.87-4.35,9.6-2.23,2.73-5.15,4.85-8.75,6.35-3.6,1.5-7.57,2.25-11.9,2.25Zm11.3-31.8c-.2-2.07-.73-3.97-1.6-5.7-.87-1.73-2.08-3.1-3.65-4.1s-3.48-1.5-5.75-1.5h-.6c-3.13,0-5.67,1.08-7.6,3.25-1.94,2.17-3.07,4.85-3.4,8.05h22.6Z"
        />
        <path
          class="cls-1"
          d="m717.5,63.98c.13,1.87.63,3.43,1.5,4.7.87,1.27,2.02,2.25,3.45,2.95,1.43.7,2.98,1.05,4.65,1.05,1.53,0,2.9-.2,4.1-.6,1.2-.4,2.18-1.03,2.95-1.9.77-.87,1.15-1.9,1.15-3.1,0-1.53-.57-2.75-1.7-3.65-1.13-.9-2.9-1.68-5.3-2.35l-9.4-2.4c-4.4-1.13-7.69-2.87-9.85-5.2-2.17-2.33-3.25-5.33-3.25-9,0-2.93.9-5.53,2.7-7.8,1.8-2.27,4.3-3.98,7.5-5.15,3.2-1.17,6.77-1.75,10.7-1.75s7.22.68,10.45,2.05c3.23,1.37,5.83,3.35,7.8,5.95,1.97,2.6,3.08,5.7,3.35,9.3h-12.9c-.13-1.87-.65-3.4-1.55-4.6s-2-2.1-3.3-2.7c-1.3-.6-2.72-.9-4.25-.9-2.27,0-4.07.47-5.4,1.4-1.33.93-2,2.23-2,3.9,0,1.27.46,2.35,1.4,3.25.93.9,2.46,1.65,4.6,2.25l9.6,2.3c4.46,1.13,7.92,2.85,10.35,5.15,2.43,2.3,3.65,5.35,3.65,9.15,0,3.27-.85,6.1-2.55,8.5-1.7,2.4-4.17,4.23-7.4,5.5-3.23,1.27-7.02,1.9-11.35,1.9s-8.33-.75-11.6-2.25c-3.27-1.5-5.87-3.6-7.8-6.3-1.94-2.7-3.07-5.92-3.4-9.65h13.1Z"
        />
        <path
          class="cls-1"
          d="m766.1,63.98c.13,1.87.63,3.43,1.5,4.7.87,1.27,2.02,2.25,3.45,2.95,1.43.7,2.98,1.05,4.65,1.05,1.53,0,2.9-.2,4.1-.6s2.18-1.03,2.95-1.9c.77-.87,1.15-1.9,1.15-3.1,0-1.53-.57-2.75-1.7-3.65-1.13-.9-2.9-1.68-5.3-2.35l-9.4-2.4c-4.4-1.13-7.68-2.87-9.85-5.2-2.17-2.33-3.25-5.33-3.25-9,0-2.93.9-5.53,2.7-7.8,1.8-2.27,4.3-3.98,7.5-5.15,3.2-1.17,6.77-1.75,10.7-1.75s7.21.68,10.45,2.05c3.23,1.37,5.83,3.35,7.8,5.95,1.96,2.6,3.08,5.7,3.35,9.3h-12.9c-.13-1.87-.65-3.4-1.55-4.6s-2-2.1-3.3-2.7c-1.3-.6-2.72-.9-4.25-.9-2.27,0-4.07.47-5.4,1.4-1.33.93-2,2.23-2,3.9,0,1.27.47,2.35,1.4,3.25.93.9,2.47,1.65,4.6,2.25l9.6,2.3c4.46,1.13,7.91,2.85,10.35,5.15,2.43,2.3,3.65,5.35,3.65,9.15,0,3.27-.85,6.1-2.55,8.5-1.7,2.4-4.17,4.23-7.4,5.5-3.23,1.27-7.02,1.9-11.35,1.9s-8.33-.75-11.6-2.25c-3.27-1.5-5.87-3.6-7.8-6.3-1.93-2.7-3.07-5.92-3.4-9.65h13.1Z"
        />
        <path
          class="cls-1"
          d="m838.1,56.88c.2,3,.85,5.57,1.95,7.7,1.1,2.13,2.75,3.8,4.95,5,2.2,1.2,4.93,1.8,8.2,1.8,2.27,0,4.33-.32,6.2-.95,1.87-.63,3.35-1.58,4.45-2.85,1.1-1.27,1.65-2.87,1.65-4.8s-.57-3.62-1.7-4.85c-1.13-1.23-2.47-2.13-4-2.7-1.53-.57-3.4-1.08-5.6-1.55l-10-2.5c-6-1.4-10.57-3.65-13.7-6.75-3.13-3.1-4.7-6.98-4.7-11.65,0-4.2,1.1-7.75,3.3-10.65,2.2-2.9,5.23-5.1,9.1-6.6,3.87-1.5,8.27-2.25,13.2-2.25,5.2,0,9.83.88,13.9,2.65,4.07,1.77,7.33,4.33,9.8,7.7,2.46,3.37,3.93,7.35,4.4,11.95h-14.5c-.13-2.33-.82-4.4-2.05-6.2-1.23-1.8-2.83-3.17-4.8-4.1-1.97-.93-4.22-1.4-6.75-1.4-3.13,0-5.85.68-8.15,2.05-2.3,1.37-3.45,3.32-3.45,5.85,0,2.2.88,3.98,2.65,5.35,1.77,1.37,4.25,2.38,7.45,3.05l11,2.7c6,1.4,10.62,3.62,13.85,6.65,3.23,3.03,4.85,7.18,4.85,12.45,0,4.2-1.15,7.82-3.45,10.85-2.3,3.03-5.47,5.35-9.5,6.95-4.03,1.6-8.62,2.4-13.75,2.4-5.67,0-10.65-.97-14.95-2.9-4.3-1.93-7.68-4.78-10.15-8.55-2.47-3.77-3.9-8.38-4.3-13.85h14.6Z"
        />
        <path
          class="cls-1"
          d="m910.8,81.08c-.07,0-.13.02-.2.05-.07.03-.13.05-.2.05-3.27.67-6.1,1-8.5,1-5.73,0-9.77-1.5-12.1-4.5-2.33-3-3.5-7.6-3.5-13.8V16.98h13.3v14.4h10.9v9.7h-10.9v23.1c0,2.2.3,3.78.9,4.75.6.97,1.87,1.45,3.8,1.45.93,0,2.02-.07,3.25-.2,1.23-.13,2.21-.27,2.95-.4h.3v11.3Z"
        />
        <path
          class="cls-1"
          d="m963.1,80.98h-13.2v-.7c.07-1.07.25-2.02.55-2.85.3-.83.75-1.88,1.35-3.15.53-1,1-2.07,1.4-3.2.4-1.13.77-2.37,1.1-3.7l-1.6-.5-.4,1.3c-1.4,4.53-3.63,8-6.7,10.4s-6.97,3.6-11.7,3.6c-3.13,0-5.92-.55-8.35-1.65-2.44-1.1-4.38-2.93-5.85-5.5-1.47-2.57-2.2-5.85-2.2-9.85V30.98h13.3v31.7c0,2.93.77,5.13,2.3,6.6,1.53,1.47,3.7,2.2,6.5,2.2h.3c2.87,0,5.25-.9,7.15-2.7,1.9-1.8,2.85-4.2,2.85-7.2v-30.6h13.2v50Z"
        />
        <path
          class="cls-1"
          d="m990.5,82.18c-4.07,0-7.72-1.05-10.95-3.15-3.23-2.1-5.78-5.12-7.65-9.05-1.87-3.93-2.8-8.57-2.8-13.9s.93-9.8,2.8-13.8,4.43-7.08,7.7-9.25c3.27-2.17,6.93-3.25,11-3.25,2.73,0,5.3.52,7.7,1.55,2.4,1.03,4.53,2.62,6.4,4.75,1.87,2.13,3.4,4.9,4.6,8.3l.5,1.4,1.5-.4c-.4-1.4-.8-2.65-1.2-3.75s-.83-2.25-1.3-3.45c-.53-1.33-.97-2.55-1.3-3.65-.34-1.1-.57-2.25-.7-3.45-.07-.6-.1-1.22-.1-1.85V11.48h13.6v69.5h-13.6v-1c0-1.27.17-2.5.5-3.7.33-1.2.9-2.8,1.7-4.8.07-.2.12-.37.15-.5.03-.13.08-.23.15-.3l-1.5-.7-.3.7c-.93,2.27-2.15,4.25-3.65,5.95-1.5,1.7-3.37,3.05-5.6,4.05s-4.78,1.5-7.65,1.5Zm4.7-10.1c2.6,0,4.8-.68,6.6-2.05,1.8-1.37,3.13-3.25,4-5.65.87-2.4,1.3-5.17,1.3-8.3s-.45-5.92-1.35-8.35c-.9-2.43-2.25-4.33-4.05-5.7-1.8-1.37-3.97-2.05-6.5-2.05h-.6c-2.54,0-4.69.68-6.45,2.05-1.77,1.37-3.07,3.25-3.9,5.65-.84,2.4-1.25,5.2-1.25,8.4,0,4.87.98,8.75,2.95,11.65,1.96,2.9,4.85,4.35,8.65,4.35h.6Z"
        />
        <path
          class="cls-1"
          d="m1035.9,24.88c-2.2,0-4.05-.75-5.55-2.25s-2.25-3.28-2.25-5.35.75-3.83,2.25-5.3c1.5-1.47,3.35-2.2,5.55-2.2s3.93.73,5.4,2.2c1.46,1.47,2.2,3.23,2.2,5.3s-.73,3.85-2.2,5.35c-1.47,1.5-3.27,2.25-5.4,2.25Zm-6.6,6.1h13.2v50h-13.2V30.98Z"
        />
        <path
          class="cls-1"
          d="m1074.1,82.18c-4.93,0-9.32-1.07-13.15-3.2s-6.82-5.18-8.95-9.15c-2.13-3.97-3.2-8.65-3.2-14.05s1.07-10.05,3.2-13.95c2.13-3.9,5.1-6.88,8.9-8.95,3.8-2.07,8.2-3.1,13.2-3.1s9.4,1.03,13.2,3.1c3.8,2.07,6.77,5.05,8.9,8.95,2.13,3.9,3.2,8.55,3.2,13.95s-1.07,10.08-3.2,14.05c-2.13,3.97-5.12,7.02-8.95,9.15-3.83,2.13-8.22,3.2-13.15,3.2Zm.3-10.1c2.53,0,4.65-.7,6.35-2.1,1.7-1.4,2.95-3.33,3.75-5.8.8-2.47,1.2-5.23,1.2-8.3s-.42-5.72-1.25-8.15c-.83-2.43-2.07-4.35-3.7-5.75-1.63-1.4-3.72-2.1-6.25-2.1h-.8c-2.47,0-4.53.72-6.2,2.15-1.67,1.43-2.92,3.37-3.75,5.8-.83,2.43-1.25,5.12-1.25,8.05,0,4.6.93,8.45,2.8,11.55,1.87,3.1,4.7,4.65,8.5,4.65h.6Z"
        />
      </g>
    </g>
  </svg>
</template>
<style scoped>
.cls-1 {
  fill: #1d1d1b;
}

.cls-1,
.cls-2,
.cls-3,
.cls-4 {
  stroke-width: 0px;
}

.cls-2 {
  fill: #e32851;
}

.cls-3 {
  fill: #ff932c;
}

.cls-4 {
  fill: #0088d9;
}
</style>
