<template>
  <Notification
    :description="$t('notification_campaign_inactive_or_paused_message')"
    :header="$t('notification_campaign_inactive_or_paused_heading')"
    v-bind="$attrs"
  >
    <template #ctaContent>
      <div class="tw-mt-3 md:tw-mt-0">
        <MainButton size="medium" class="tw-w-full" @click="onUpdateCampaignStatus">
          <span v-if="isUpdatingCampaignStatus" class="tw-px-4">
            <RCInlineLoader color="#fff" />
          </span>
          <span v-else>{{ $t('notification_action_reactivate') }}</span>
        </MainButton>
      </div>
    </template>
  </Notification>
</template>

<script setup>
import { computed } from 'vue'

import Notification from './Notification.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import appAnalytics from '@/utils/tracking'
import { useCampaigns, useCampaignsMutation } from '@/components/rateConnect/queries'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'

const { campaignsData } = useCampaigns(selectedAccommodationId)
const { updateCampaignStatus, isUpdatingCampaignStatus } = useCampaignsMutation()
const { displayNotification } = useToastNotifications()

const currentCampaignType = computed(() => campaignsData.value?.overall?.campaignType)

const onUpdateCampaignStatus = () => {
  trackDataInSegment()

  updateCampaignStatus(
    {
      accommodationId: selectedAccommodationId.value,
      body: { overall: { status: 'active' } },
    },
    {
      onSuccess: () => {
        displayNotification({
          message: 'rc_reactivate_campaign_success_message',
          isTranslationKey: true,
          type: 'success',
        })
      },
      onError: () => {
        displayNotification({
          message: 'save_error',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}

const trackDataInSegment = () => {
  appAnalytics.track(appAnalytics.events.TRC_CAMPAIGN_RESUMED, {
    item_id: selectedAccommodationId.value,
    campaign_type: currentCampaignType.value,
    budget: currentCampaignType.value === 'cpc' ? campaignsData.value?.overall?.budget : null,
    source: 'notification',
  })
}
</script>
