<template>
  <CustomModal
    :is-modal-open="isOpen"
    @toggleModal="resetModal"
    @onClickAway="resetModal"
    :overlay="'dark'"
    :with-header="true"
    :with-footer="true"
    custom-classes="tw-h-screen md:tw-h-auto tw-w-[100vw] md:tw-max-w-[588px] md:tw-w-[588px] md:tw-rounded-md tw-rounded-none"
  >
    <template #modalHeader>
      <div class="tw-flex tw-items-center">
        <CheckMarkInCircle
          v-if="cancellationStep === 2 && isPositive"
          height="24px"
          width="24px"
          class="tw-inline tw-mr-4 tw-stroke-gray-700"
        />
        <CloseOutline
          v-if="cancellationStep === 2 && !isPositive"
          height="24px"
          width="24px"
          class="tw-inline tw-mr-4 tw-stroke-gray-700"
        />
        <h3 class="tw-font-bold tw-text-xl tw-text-gray-700">{{ $t(titleLabel) }}</h3>
      </div>
    </template>

    <div class="tw-flex tw-flex-col">
      <CancellationStep1 v-if="cancellationStep === 0" :accommodationId="accommodationId" :type="type" />
      <CancellationStep2
        v-if="cancellationStep === 1"
        :reason="reason"
        :reasonInfo="reasonInfo"
        :reasons="sortedCancellationReasons"
        :type="type"
        @updateReason="updateReason"
        @updateReasonInfo="updateReasonInfo"
        @updateImprovements="updateImprovements"
      />
      <CancellationStep3
        v-if="cancellationStep === 2"
        :endDate="activeUntilDate"
        :isPositive="isPositive"
        :type="type"
        :retries="retries"
      />
    </div>
    <template #modalFooter>
      <div class="tw-flex tw-justify-end tw-w-full">
        <GhostButton @click="keepBenefits" :disabled="isCancelStudioPlusLoading">{{ $t(cancelBtnLabel) }}</GhostButton>
        <DangerButton class="tw-ml-6" :disabled="isDisabled" @click="nextStep" v-if="cancellationStep !== 2">
          <span v-show="!isCancelStudioPlusLoading">{{
            cancellationStep === 0 ? $t('cancel_flow_cta') : $t('subscription_cancel_confirm_cta')
          }}</span>
          <span v-show="isCancelStudioPlusLoading" class="tw-px-4"><RCInlineLoader color="#fff" /></span>
        </DangerButton>
        <MainButton
          v-if="cancellationStep === 2 && !isPositive && retries < 3"
          class="tw-ml-6"
          @click="cancelPlus(true)"
        >
          <span v-show="!isCancelStudioPlusLoading">{{ $t('cancellation_fail_retry_cta') }}</span>
          <span v-show="isCancelStudioPlusLoading" class="tw-px-4"><RCInlineLoader color="#fff" /></span
        ></MainButton>
        <MainButton
          v-if="cancellationStep === 2 && !isPositive && retries >= 3"
          class="tw-ml-6"
          @click="redirectToContactUs"
        >
          <span v-show="!isCancelStudioPlusLoading">{{ $t('Cancellation_fail_support_cta') }}</span>
          <span v-show="isCancelStudioPlusLoading" class="tw-px-4"><RCInlineLoader color="#fff" /></span
        ></MainButton>
      </div>
    </template>
  </CustomModal>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useQueryClient } from '@tanstack/vue-query'

import CustomModal from '@/components/base/CustomModal.vue'
import CancellationStep1 from '@/components/settings/subscriptions/cancellation/CancellationStep1.vue'
import CancellationStep2 from '@/components/settings/subscriptions/cancellation/CancellationStep2.vue'
import CancellationStep3 from '@/components/settings/subscriptions/cancellation/CancellationStep3.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import DangerButton from '@/components/base/buttons/DangerButton.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import CheckMarkInCircle from '@/components/icons/CheckMarkInCircle.vue'
import CloseOutline from '@/components/icons/CloseOutline.vue'
import appAnalytics from '@/utils/tracking'

import { useCancelStudioPlusMutation } from '@/components/payment/queries'
import { getSupportCenterUrl } from '@/utils/Utility.js'

const { t } = useI18n()
const store = useStore()
const emit = defineEmits(['closeModal'])

const props = defineProps({
  isOpen: Boolean,
  accommodationName: String,
  accommodationId: Number,
  language: String,
  userDetails: Object,
  partnerId: Number,
  type: String,
})

const queryClient = useQueryClient()

const reason = ref(null)
const reasonInfo = ref('')
const improvements = ref('')
const cancellationStep = ref(0)
const activeUntilDate = ref(null)
const isPositive = ref(null)
const retries = ref(0)

const { cancelStudioPlus, isCancelStudioPlusLoading } = useCancelStudioPlusMutation()

const supportCenterUrl = computed(() => getSupportCenterUrl(store.state.locale.language, 'contactUs'))
const redirectToContactUs = () => {
  resetModal()
  return window.open(supportCenterUrl.value, '_blank')
}
const isDisabled = computed(() => {
  if (cancellationStep?.value === 1) {
    if (reason?.value === null) {
      return true
    } else if ((reason?.value?.id === 3 || reason?.value?.id === 5) && reasonInfo?.value?.length < 3) {
      return true
    } else {
      return false
    }
  } else return false
})

const cancelBtnLabel = computed(() => {
  if (cancellationStep.value === 0) return 'keep_benefits_cta'
  if (cancellationStep.value === 1) return 'keep_subscription_cta'
  if (cancellationStep.value === 2) return 'close'
  else return ''
})

const titleLabel = computed(() => {
  if (cancellationStep.value === 0) return 'subscription_cancel_header'
  if (cancellationStep.value === 1) return 'confirm_cancellation_header'
  if (cancellationStep.value === 2 && isPositive?.value) return 'confirm_cancellation_header'
  if (cancellationStep.value === 2 && !isPositive?.value) return 'cancellation_fail_header'
  else return ''
})

const cancellationReasons = computed(() => [
  { label: t('tbs_plus_cancel_reason_1'), id: 1, value: 'The price is high' },
  {
    label: t('tbs_plus_cancel_reason_2'),
    id: 2,
    value: 'There’s a change in business strategy',
  },
  { label: t('tbs_plus_cancel_reason_3'), id: 3, value: 'My expectations weren’t met' },
  { label: t('tbs_plus_cancel_reason_4'), id: 4, value: 'There’s a change of ownership' },
])

const sortedCancellationReasons = computed(() => {
  const other = { label: t('tbs_plus_cancel_reason_5'), id: 5, value: 'Other' }
  const sorted = [...cancellationReasons.value].sort((a, b) => {
    const labelA = a.label
    const labelB = b.label
    if (labelA < labelB) {
      return -1
    }
    if (labelA > labelB) {
      return 1
    }
    return 0
  })
  return [...sorted, other]
})

const updateReason = newReason => {
  reason.value = newReason
}

const updateReasonInfo = text => {
  reasonInfo.value = text
}

const updateImprovements = text => {
  improvements.value = text
}

const cancelPlus = isRetry => {
  cancelStudioPlus(
    {
      accommodationId: props.accommodationId,
      itemName: props.accommodationName,
      email: props.userDetails?.email,
      reason: `${reason.value?.value}: ${reasonInfo?.value}`,
      additionalInformation: improvements.value,
      partnerId: props.partnerId,
      language: props.language,
      studioUserId: props.userDetails?.id,
      firstName: props.userDetails?.firstName,
      lastName: props.userDetails?.lastName,
    },
    {
      onSuccess: data => {
        cancellationStep.value = 2

        isPositive.value = true
        activeUntilDate.value = data.data.endDate
        appAnalytics.track(appAnalytics.events.SP_SUBSCRIPTION_CANCELED, {
          item_id: props.accommodationId,
          reason: `${reason.value.value}: ${reasonInfo.value}`,
          additionalInformation: improvements.value,
        })
      },
      onError: () => {
        cancellationStep.value = 2
        isPositive.value = false
        if (isRetry) {
          retries.value = retries.value + 1
        }
      },
    }
  )
}

const nextStep = () => {
  if (cancellationStep?.value === 0) {
    appAnalytics.track(appAnalytics.events.SP_CONTINUE_CANCELLATION, {
      accommodationId: props.accommodationId,
    })
    cancellationStep.value = 1
  } else if (cancellationStep?.value === 1) {
    appAnalytics.track(appAnalytics.events.SP_CANCEL_SUBSCRIPTION, {
      accommodationId: props.accommodationId,
      reason: `${reason.value?.value}: ${reasonInfo.value}`,
      additionalInformation: improvements?.value,
    })
    cancelPlus(false)
  } else if (cancellationStep?.value === 2 && isPositive?.value) {
    resetModal()
  }
}

const keepBenefits = () => {
  if (cancellationStep.value === 0) {
    appAnalytics.track(appAnalytics.events.SP_KEEP_BENEFITS, {
      accommodationId: props.accommodationId,
    })
  } else if (cancellationStep.value === 1) {
    appAnalytics.track(appAnalytics.events.SP_KEEP_SUBSCRIPTION, {
      accommodationId: props.accommodationId,
    })
  }

  resetModal()
}
const resetModal = () => {
  if (cancellationStep.value === 2) {
    queryClient.invalidateQueries({ queryKey: ['studioPlusCampaigns'] })
    queryClient.invalidateQueries({ queryKey: ['studioPlusCampaign'] })
    queryClient.invalidateQueries({ queryKey: ['studioPlusSubscriptions'] })
  }
  reason.value = null
  reasonInfo.value = ''
  improvements.value = ''
  cancellationStep.value = 0
  isPositive.value = null
  activeUntilDate.value = null
  emit('closeModal')
}
</script>
