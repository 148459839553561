<template>
  <CustomModal
    class="basic-info-edit-modal"
    :is-modal-open="isOpen"
    @toggleModal="toggleEditing"
    @onClickAway="toggleEditing"
    :overlay="'dark'"
    :with-header="true"
    :with-footer="true"
    :custom-classes="'tw-w-[100vw] md:tw-max-w-[768px] md:tw-w-[768px] tw-rounded-none md:tw-rounded-md'"
  >
    <template #modalHeader>
      <p class="tw-text-xl tw-text-gray-700">{{ $t('app_hotel_details') }}</p>
    </template>
    <div v-if="editItem !== null" class="tw-mt-2 tw-w-full">
      <span class="required-info tw-text-xs tw-text-gray-700" v-html="$t('required_information')" />
      <div
        class="tw-flex md:tw-flex-row tw-flex-col tw-py-2 tw-px-4 tw-border-gray-300 tw-border-solid tw-border tw-rounded-lg"
      >
        <PropertyBasicInfo
          ref="basicInfo"
          :name="editItem.name"
          :errors="errors"
          :hotel-chain="editItem.chainId"
          :accommodation-type="editItem.accommodationTypeId"
          :check-in="checkIn"
          :check-out="checkOut"
          :express-check-in-out="editItem.expressCheckInOut"
          :grade="editItem.starRating"
          :hotel-chains="hotelChains"
          :lodging-types="lodgingTypes"
        />
        <div class="divider tw-hidden md:tw-flex" />
        <PropertyBasicInfoAddress
          ref="addressInfo"
          :errors="errors"
          :address="editItem.streetAddress"
          :zip="editItem.postalCode"
          :city="editItem.city"
          :propertyId="editItem.propertyId"
        />
      </div>
      <div
        class="tw-my-4 tw-flex tw-flex-row tw-py-2 tw-px-4 tw-border-gray-300 tw-border-solid tw-border tw-rounded-lg"
      >
        <PropertyBasicInfoContact
          ref="contactInfo"
          :errors="errors"
          :email="editItem.email"
          :web="editItem.homepageUrl"
          :phone="editItem.phone"
          :fax="editItem.fax"
          :phoneCodes="phoneCodes"
        />
      </div>
      <PropertyBasicInfoLocation :lng="editItem.longitude" :lat="editItem.latitude" />
    </div>
    <LaunchpadLoader v-else class="tw-w-full tw-min-h-screen tw-flex tw-items-center tw-justify-center" />

    <template #modalFooter>
      <div class="tw-w-full tw-flex tw-justify-end">
        <MainButton
          size="medium"
          class="tw-px-9"
          :disabled="!isFormValid || phoneNumberTouched"
          @click="savePropertyDetails"
        >
          <span v-if="!savePropertyBasicInfoUpdateLoading">{{ $t('save') }}</span>
          <span v-if="savePropertyBasicInfoUpdateLoading">
            <RCInlineLoader color="#fff" />
          </span>
        </MainButton>
      </div>
    </template>
  </CustomModal>
</template>
<script setup>
import { computed, watch, ref, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import { usePropertyDetailsBasicInfo } from '@/components/propertyDetails/propertyBasicInfo/usePropertyDetailsBasicInfo.js'
import CustomModal from '@/components/base/CustomModal.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import PropertyBasicInfoLocation from '@/components/propertyDetails/propertyBasicInfo/edit/PropertyBasicInfoLocation.vue'
import PropertyBasicInfo from '@/components/propertyDetails/propertyBasicInfo/edit/PropertyBasicInfo.vue'
import PropertyBasicInfoAddress from '@/components/propertyDetails/propertyBasicInfo/edit/PropertyBasicInfoAddress.vue'
import PropertyBasicInfoContact from '@/components/propertyDetails/propertyBasicInfo/edit/PropertyBasicInfoContact.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import { useSavePropertyBasicInfoUpdate } from '@/components/propertyDetails/queries'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'

const { editItem, isFormValid, errors, phoneNumberTouched } = usePropertyDetailsBasicInfo()
const route = useRoute()
defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  lodgingTypes: {
    type: Array,
    default() {
      return []
    },
  },
  hotelChains: {
    type: Array,
    default() {
      return []
    },
  },
  phoneCodes: {
    type: Array,
    default() {
      return []
    },
  },
})
const contactInfo = ref(null)
const addressInfo = ref(null)
const basicInfo = ref(null)

const checkIn = computed(() =>
  editItem.value?.checkInHour !== null ? editItem.value?.checkInHour.toString() + ':00' : editItem.value?.checkInHour
)
const checkOut = computed(() =>
  editItem.value?.checkOutHour !== null ? editItem.value?.checkOutHour.toString() + ':00' : editItem.value?.checkOutHour
)
const emit = defineEmits(['toggleEditing'])
const toggleEditing = () => emit('toggleEditing')
const { savePropertyBasicInfoUpdateLoading, savePropertyBasicInfoUpdate } = useSavePropertyBasicInfoUpdate()
const { displayNotification } = useToastNotifications()

watch(isFormValid, newIsFormValid => {
  nextTick(() => {
    if (!newIsFormValid) {
      if (errors.value.name) {
        basicInfo.value?.$el.scrollIntoView({ behavior: 'smooth' })
      } else if (errors.value.postalCode || errors.value.streetAddress) {
        addressInfo.value?.$el.scrollIntoView({ behavior: 'smooth' })
      } else if (errors.value.email || errors.value.fax || errors.value.phone || errors.value.homepageUrl) {
        contactInfo.value?.$el.scrollIntoView({ behavior: 'smooth' })
      }
    }
  })
})

const savePropertyDetails = () => {
  savePropertyBasicInfoUpdate(
    {
      accommodationId: selectedAccommodationId.value,
      body: editItem.value,
    },
    {
      onSuccess: () => {
        displayNotification({
          message: 'app_hotel_details_successfully_saved',
          isTranslationKey: true,
          isHtml: false,
          type: 'success',
        })
        if (route.query.open_update) {
          window.localStorage.setItem(`contact_info_banner_${selectedAccommodationId.value}`, 'false')
          window.dispatchEvent(
            new CustomEvent('contact_info_banner_localstorage-changed', {
              detail: {
                storage: localStorage.getItem(`contact_info_banner_${selectedAccommodationId.value}`),
              },
            })
          )
        }
        toggleEditing()
      },
      onError: error => {
        if (error.response?.data.errors.length > 0) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            const item = error.response.data.errors[i]
            displayNotification({
              message: `${item.path.charAt(0).toUpperCase()}${item.path.slice(1)} - ${item.messageTemplate}`,
              isTranslationKey: true,
              isHtml: false,
              type: 'error',
            })
          }
        } else if (error.response?.data) {
          displayNotification({
            message: error.response?.data.detail,
            isTranslationKey: true,
            isHtml: false,
            type: 'error',
          })
        } else {
          displayNotification({
            message: 'something_went_wrong',
            isTranslationKey: true,
            isHtml: false,
            type: 'error',
          })
        }
      },
    }
  )
}
</script>
<style scoped>
.divider {
  border-right: 1px solid theme('colors.gray.300');
  height: 100%;
  min-height: 420px;
  @apply tw-mx-4;
}
.required-info :deep(span) {
  @apply tw-text-red-700;
}
</style>
