<template>
  <div class="tw-my-16 shadow tw-bg-white tw-max-w-screen-xl tw-mx-auto tw-rounded-lg tw-p-10">
    <p v-html="$t('trivago_business_blog_notification')" />
    <img src="@/assets/img/blog-announcement.png" alt="Blog Announcement" />
  </div>
</template>
<style scoped>
.shadow {
  box-shadow: 0 2px 4px 0 rgba(63, 87, 189, 0.1), 0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
p :deep(a) {
  color: theme('colors.blue.700');
}
p :deep(h3) {
  @apply tw-text-xl tw-mb-5;
}
p :deep(p) {
  @apply tw-mb-4;
}
</style>
