<template>
  <section>
    <div
      class="tw-flex tw-flex-col tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-screen-md sm:tw-px-8 tw-px-4 lg:tw-px-0 tw-mx-auto tw-pb-8"
    >
      <div class="tw-flex tw-flex-col tw-items-center md:tw-items-start">
        <img :src="BusinessStudioLogo" alt="rate-connect-logo" height="28" class="tw-mb-4" />
        <h1
          class="tw-font-bold tw-text-blue-800 md:tw-text-4xl lg:tw-text-[44px] lg:tw-leading-[50px] tw-text-center md:tw-text-left"
        >
          {{ $t('lp_free_link_subtext') }}
        </h1>
      </div>
    </div>

    <!-- Web Version -->
    <div
      class="tw-flex tw-flex-col tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-screen-md sm:tw-px-8 tw-px-4 lg:tw-px-0 tw-mx-auto"
    >
      <div class="sm:tw-flex tw-hidden tw-w-full tw-flex-wrap tw-justify-between tw-text-gray-800 tw-gap-5">
        <div v-for="(benefit, index) in benefits" :key="index" class="tw-gap-4 tw-flex tw-w-full tw-items-center">
          <div class="tw-w-[200px] tw-min-h-[126px] tw-bg-white tw-rounded-lg tw-p-4 tw-self-stretch tw-flex">
            <img
              :src="benefit.image"
              :alt="$t(benefit.title)"
              :height="benefit.imageHeight"
              class="tw-m-auto"
              :class="getSizes(index)"
            />
          </div>
          <div class="tw-w-full tw-bg-white tw-rounded-lg tw-flex tw-flex-col tw-px-8 tw-py-[15px] tw-self-stretch">
            <p class="tw-font-bold tw-text-2xl tw-mb-4 tw-text-blue-700" v-html="$t(benefit.title)" />

            <p>{{ $t(benefit.text) }}</p>
          </div>
        </div>
      </div>
      <p class="tw-text-sm tw-mt-12 sm:tw-flex tw-hidden">
        {{ $t('lp_free_link_footnote') }}
      </p>
    </div>

    <!-- Mobile Version -->
    <div
      class="tw-w-full tw-flex sm:tw-hidden tw-justify-between tw-text-gray-800 tw-flex-col md:tw-flex-row tw-w-auto"
    >
      <div
        v-for="(benefit, index) in benefits"
        :key="index"
        class="tw-bg-white tw-rounded-lg tw-flex tw-mx-auto tw-my-0 tw-p-2.5 tw-gap-1 tw-mb-4 tw-w-[300px] xs:tw-min-w-[330px] tw-min-h-[85px] tw-flex-col"
      >
        <p class="tw-font-bold tw-text-base tw-mb-2 tw-text-blue-700" v-html="$t(benefit.title)" />
        <div class="tw-flex tw-flex-row">
          <div class="tw-min-w-[55px] tw-mr-4">
            <img :src="benefit.image" :alt="$t(benefit.title)" class="tw-h-[53px] tw-w-[55px]" />
          </div>
          <div>
            <p class="tw-text-xs">{{ $t(benefit.text) }}</p>
          </div>
        </div>
      </div>

      <p class="tw-px-4 tw-text-sm">
        {{ $t('lp_free_link_footnote') }}
      </p>
    </div>
  </section>
</template>
<script setup>
import { ref } from 'vue'

const BusinessStudioLogo = new URL(
  '/src/assets/img/free-booking-links-landing/business_studio_logo.svg',
  import.meta.url
)

const benefitOne = new URL('/src/assets/img/studio-plus-landing/sp-benefit-one.svg', import.meta.url)
const benefitTwo = new URL('/src/assets/img/free-booking-links-landing/magnifying_glass.svg', import.meta.url)
const benefitThree = new URL('/src/assets/img/free-booking-links-landing/piggy_bank.svg', import.meta.url)

const benefits = ref([
  {
    title: 'lp_free_link_benefit_1_header',
    text: 'lp_free_link_benefit_1_text',
    image: benefitOne,
    imageHeight: 100,
  },
  {
    title: 'lp_free_link_benefit_2_header',
    text: 'lp_free_link_benefit_2_text',
    image: benefitTwo,
    imageHeight: 76,
  },
  {
    title: 'lp_free_link_benefit_3_header',
    text: 'lp_free_link_benefit_3_text',
    image: benefitThree,
    imageHeight: 60,
  },
])

const getSizes = itemIndex => {
  if (itemIndex === 0) return 'tw-max-h-[100px]'
  if (itemIndex === 1) return 'tw-max-h-[76px]'
  if (itemIndex === 2) return 'tw-max-h-[60px]'
}
</script>
<style scoped>
.carousel-mobile {
  @apply tw-flex tw-justify-start tw-items-center tw-overflow-hidden;
  @media (max-width: theme('screens.md')) {
    width: auto;
  }
}
</style>
