<template>
  <g id="Homepage-UX-Copy" stroke="none" stroke-width="1" fill="inherit" fill-rule="evenodd">
    <g id="Overlay" transform="translate(-1311.000000, -96.000000)">
      <path
        id="Page-1"
        d="M1320.88533,103.999833 L1326.60933,109.723714 C1326.86933,109.983709 1327,110.325035 1327,110.666361 C1327,111.007687 1326.86933,111.349014 1326.60933,111.609008 C1326.088,112.130331 1325.24533,112.130331 1324.724,111.609008 L1319,105.885127 L1313.276,111.609008 C1312.75467,112.130331 1311.912,112.130331 1311.39067,111.609008 C1311.13067,111.349014 1311,111.007687 1311,110.666361 C1311,110.325035 1311.13067,109.983709 1311.39067,109.723714 L1317.11467,103.999833 L1311.39067,98.2759526 C1311.13067,98.015958 1311,97.6746318 1311,97.3333056 C1311,96.9919793 1311.13067,96.6506531 1311.39067,96.3906585 C1311.65067,96.1306639 1311.992,96 1312.33333,96 C1312.67467,96 1313.016,96.1306639 1313.276,96.3906585 L1319,102.114539 L1324.724,96.3906585 C1324.984,96.1306639 1325.32533,96 1325.66667,96 C1326.008,96 1326.34933,96.1306639 1326.60933,96.3906585 C1326.86933,96.6506531 1327,96.9919793 1327,97.3333056 C1327,97.6746318 1326.86933,98.015958 1326.60933,98.2759526 L1320.88533,103.999833 Z"
      />
    </g>
  </g>
</template>
