<template>
  <fieldset class="tw-px-4">
    <legend class="tw-font-bold tw-py-6" v-if="showHeader">
      {{ $t('form.header.paymentMethod') }}
    </legend>
    <div class="tw-border-t tw-border-t-gray-300 tw-mb-3" v-if="showHeader" />
    <ExistingPaymentMethodList
      :existingPaymentMethods="existingPaymentMethods"
      :selectedPaymentMethod="selectedPaymentMethod"
      @onChange="onSelectedValueChange"
    />

    <div :class="{ 'tw-py-5': !showNewPaymentMethodForm || !showHeader }">
      <BaseLinkButton
        v-if="!showNewPaymentMethodForm"
        class="tw-text-blue-700 hover:tw-no-underline"
        @click="showNewPaymentMethodForm = true"
      >
        <span>+ </span>{{ $t('form.paymentMethods.new') }}
      </BaseLinkButton>
      <div v-else :class="{ 'tw-mt-[24px]': showHeader }" class="tw-flex tw-justify-between tw-gap-2">
        <span class="tw-font-bold"> {{ $t('form.paymentMethods.new') }}</span>

        <BaseLinkButton
          v-if="existingPaymentMethods.length > 0"
          class="tw-text-blue-700 hover:tw-no-underline"
          @click="onNewPaymentMethodFormCancel"
        >
          {{ $t('form.paymentMethods.new-cancel') }}
        </BaseLinkButton>
      </div>
      <div :class="{ 'tw-border-t tw-border-t-gray-300 tw-mt-6 tw-mb-3': showNewPaymentMethodForm }" />
    </div>

    <NewPaymentMethodForm
      v-if="showNewPaymentMethodForm"
      :availablePaymentMethods="availablePaymentMethods"
      :fetchingPaymentMethods="fetchingPaymentMethods"
      :currency="currency"
      :accommodationId="accommodationId"
      :productName="productName"
      @onChange="onSelectedValueChange"
      @onZuoraCallbackSuccess="e => emit('onZuoraCallbackSuccess', e)"
      @onZuoraCallbackError="onZuoraCallbackError"
      @onZuoraClientValidationError="emit('onZuoraClientValidationError')"
      @setError="onSetError"
    />
  </fieldset>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'

import BaseLinkButton from '@/components/base/buttons/BaseLinkButton.vue'
import NewPaymentMethodForm from '@/components/payment/NewPaymentMethodForm.vue'
import ExistingPaymentMethodList from './ExistingPaymentMethodList.vue'

const emit = defineEmits([
  'onSelectedValueChange',
  'onShowNewFormCancel',
  'onZuoraCallbackSuccess',
  'onZuoraCallbackError',
  'onZuoraClientValidationError',
  'setError',
])

const onZuoraCallbackError = withRerender => emit('onZuoraCallbackError', withRerender)

const onSetError = error => {
  emit('setError', error)
}

const props = defineProps({
  source: {
    type: String,
    default: 'plus-checkout',
  },
  currency: {
    type: String,
    default: 'EUR',
  },
  accommodationId: Number,
  productName: String,
  existingPaymentMethods: {
    type: Array,
    default: () => [],
  },
  availablePaymentMethods: {
    type: Array,
    default: () => [],
  },
  selectedPaymentMethod: {
    type: String,
    default: null,
  },
  fetchingPaymentMethods: {
    type: Boolean,
    default: false,
  },
})

const showNewPaymentMethodForm = ref(false)

const onNewPaymentMethodFormCancel = () => {
  emit('onShowNewFormCancel')
  showNewPaymentMethodForm.value = false
}

const showHeader = computed(() => {
  if (props.existingPaymentMethods.length === 0) return false
  if (props.source === 'billing-information-form') return false
  else return true
})

const onSelectedValueChange = paymentMethod => {
  if (paymentMethod !== 'CREDIT_CARD' && paymentMethod !== 'SEPA') {
    showNewPaymentMethodForm.value = false
  }
  emit('onSelectedValueChange', paymentMethod)
}

onMounted(() => {
  const onlyCards = props.existingPaymentMethods.every(item => item.type === 'CREDIT_CARD')
  const cardMethods = props.existingPaymentMethods.filter(item => item.type === 'CREDIT_CARD')
  const allExpired = cardMethods?.every(item => item?.expired)
  if (props.existingPaymentMethods.length < 1 || (allExpired && onlyCards)) {
    showNewPaymentMethodForm.value = true
  }
})
</script>
