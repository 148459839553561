<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.75 6.75L7.5 12" stroke-width="2" stroke-linecap="round" />
    <path d="M7.5 12L5.25 9.75" stroke-width="2" stroke-linecap="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 1.875C12.9353 1.875 16.125 5.06475 16.125 9C16.125 12.9353 12.9353 16.125 9 16.125C5.06475 16.125 1.875 12.9353 1.875 9C1.875 5.06475 5.06475 1.875 9 1.875Z"
      stroke-linecap="round"
    />
  </svg>
</template>
