<template>
  <CustomModal
    class="images-tips-modal"
    :is-modal-open="isOpen"
    @toggleModal="() => emit('closeModal')"
    @onClickAway="() => emit('closeModal')"
    :overlay="'dark'"
    :with-header="true"
    :with-footer="true"
    :custom-classes="'tw-w-[100vw] md:tw-max-w-[636px] md:tw-w-[636px] md:tw-rounded-md tw-rounded-none'"
  >
    <template #modalHeader>
      <p class="tw-text-xl tw-text-gray-700">{{ $t('app_images_smart_tips') }}</p>
    </template>

    <TipsContent />

    <template #modalFooter>
      <div class="tw-w-full tw-flex tw-h-[10px]" />
    </template>
  </CustomModal>
</template>
<script setup>
import CustomModal from '@/components/base/CustomModal.vue'
import TipsContent from '@/components/propertyDetails/propertyImages/TipsContent.vue'

const emit = defineEmits(['closeModal'])
defineProps({
  isOpen: Boolean,
})
</script>
