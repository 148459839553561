<template>
  <BaseCard noPadding v-if="invitedUsers.length > 0">
    <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-text-gray-800 tw-mb-4 tw-p-5">
      <div class="tw-flex tw-justify-between tw-w-full tw-flex-col">
        <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-text-gray-800">
          <div class="tw-flex tw-justify-between tw-w-full tw-flex-col">
            <div class="tw-mb-5">
              <p class="tw-text-xl tw-font-bold">{{ $t('invited_users_header') }}</p>
            </div>
            <div>
              <table class="tw-min-w-full tw-border-none" v-if="!isLoading">
                <thead class="tw-hidden md:tw-table-header-group">
                  <tr class="tw-border-b">
                    <th
                      v-for="column in columns"
                      :key="column.prop"
                      class="tw-p-5 tw-pl-0 tw-border-none tw-bg-white tw-text-left tw-text-sm tw-leading-4 tw-font-bold tw-text-gray-600"
                      :class="`tw-w-[${column.width}]`"
                    >
                      <span>{{ $t(column.label) }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="tw-bg-white">
                  <tr
                    v-for="(invitation, index) in invitedUsers"
                    :key="index"
                    class="tw-text-sm tw-border-top md:tw-border-none tw-flex md:tw-table-row tw-flex-col"
                  >
                    <td class="tw-border-none tw-p-5 tw-pl-0">
                      <div class="tw-flex tw-flex-row md:tw-hidden tw-justify-between tw-w-full tw-bg-blue-200 tw-p-2">
                        <span>{{ $t('form.labels.accounts') }}{{ ': ' }}</span>
                        <span>{{ invitation.invitedEmail }}</span>
                      </div>
                      <span class="md:tw-flex tw-hidden">{{ invitation.invitedEmail }}</span>
                    </td>
                    <td class="tw-border-none tw-p-5 tw-pl-0">
                      <div
                        class="tw-flex tw-flex-row md:tw-hidden tw-justify-between tw-w-full tw-bg-blue-200 tw-p-2 tw-lowercase"
                      >
                        <span>{{ $t('invited_user_status') }}{{ ': ' }}</span>
                        <span>{{ $t('invited_user_status_pending') }}</span>
                      </div>
                      <span
                        class="md:tw-flex tw-hidden tw-bg-orange-300 tw-w-auto tw-rounded-lg tw-p-1 tw-w-fit tw-lowercase"
                        >{{ $t('invited_user_status_pending') }}</span
                      >
                    </td>
                    <td class="tw-border-none tw-p-5 tw-pl-0">
                      <div class="tw-flex tw-flex-row md:tw-hidden tw-justify-between tw-w-full tw-bg-blue-200 tw-p-2">
                        <span>{{ $t('form.labels.role') }}{{ ': ' }}</span>
                        <span>{{
                          invitation.role.toUpperCase() === 'NORMAL'
                            ? $t('form.labels.user_role.normal')
                            : $t('form.labels.user_role.admin')
                        }}</span>
                      </div>
                      <span class="md:tw-flex tw-hidden">{{
                        invitation.role.toUpperCase() === 'NORMAL'
                          ? $t('form.labels.user_role.normal')
                          : $t('form.labels.user_role.admin')
                      }}</span>
                    </td>
                    <td class="tw-border-none tw-flex tw-justify-end">
                      <button
                        type="button"
                        class="tw-flex tw-items-center tw-border tw-p-2 tw-rounded-lg tw-border-gray-800 md:tw-border-none"
                        @click.prevent="openDeletingModal(invitation.invitedEmail)"
                      >
                        <BaseIcon icon-name="delete" :height="24" :width="24" :viewbox-height="24" :viewbox-width="24">
                          <Delete />
                        </BaseIcon>
                        <span class="tw-ml-2">
                          {{ $t('remove_user') }}
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <RemoveUserModal
                :isOpen="isDeletingModalOpen"
                :closeModal="closeDeletingModal"
                :userEmail="deletingUserEmail"
                :loading="removeUserInvitationFromOrgLoading"
                :isInvitationRemoval="true"
                @removeUser="onRemoveUser"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script setup>
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Delete from '@/components/icons/Delete.vue'
import RemoveUserModal from './RemoveUserModal.vue'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { useUsersInvitations, removeUserInvitationFromOrgMutation } from '@/components/settings/teamSettings/queries'

const store = useStore()
const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { removeUserInvitationFromOrgLoading, removeUserInvitationFromOrg } = removeUserInvitationFromOrgMutation()
const { usersInvitations, isLoading } = useUsersInvitations(selectedOrganisationId)

const { displayNotification } = useToastNotifications()

const isDeletingModalOpen = ref(false)
const deletingUserEmail = ref(null)
const columns = ref([
  {
    label: 'form.labels.accounts',
    prop: 'invitedEmail',
    width: '30%',
  },
  {
    label: 'invited_user_status',
    prop: 'usersInvitations',
    width: '25%',
  },
  {
    label: 'form.labels.role',
    prop: 'role',
    width: '25%',
  },
  {
    label: '',
    prop: 'remove',
    width: '20%',
  },
])

const invitedUsers = computed(() => {
  const invitations = usersInvitations.value?.filter(item => item.userInvitationStatus.toLowerCase() === 'pending')
  return invitations.sort((a, b) => (a.invitedEmail > b.invitedEmail ? 1 : b.invitedEmail > a.invitedEmail ? -1 : 0))
})

const openDeletingModal = email => {
  isDeletingModalOpen.value = true
  deletingUserEmail.value = email
}

const closeDeletingModal = () => {
  isDeletingModalOpen.value = false
  deletingUserEmail.value = null
}

const onRemoveUser = async email => {
  removeUserInvitationFromOrg(
    { email: email },
    {
      onSuccess: () => {
        displayNotification({
          message: 'invite_user_cancel_success',
          isTranslationKey: true,
          isHtml: false,
          type: 'success',
        })
        closeDeletingModal()
      },
      onError: () => {
        displayNotification({
          message: 'form.messages.error.unknown',
          isTranslationKey: true,
          isHtml: false,
          type: 'error',
        })
        closeDeletingModal()
      },
    }
  )
}
</script>
