<template>
  <component
    :is="tag"
    v-tippy="{ placement: tooltipPosition, arrow: arrow, theme: theme, allowHTML: true, zIndex: zIndex }"
    :content="label"
  >
    <slot />
  </component>
</template>

<script>
// v-tippy documentation: https://vue-tippy.netlify.app/
export default {
  name: 'UiTooltip',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    label: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      default: 'light',
    },
    tooltipPosition: {
      type: String,
      default: 'right',
    },
    arrow: {
      type: Boolean,
      default: true,
    },
    zIndex: {
      type: Number,
      default: 99999,
    },
  },
}
</script>
<style>
.tippy-tooltip.customlightwithpadding-theme {
  padding: 8px;
  font-size: 14px;
  color: #37454d;
  background-color: theme('colors.white');
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15), 0 4px 80px -8px rgba(36, 40, 47, 0.25),
    0 4px 4px -2px rgba(91, 94, 105, 0.15);
  line-height: 14px;
}
.tippy-popper[x-placement^='top'] .tippy-tooltip.customlightwithpadding-theme .tippy-arrow {
  border-top: 7px solid white;
}
.tippy-popper[x-placement^='top'] .tippy-tooltip.customlightwithpadding-theme .tippy-arrow::after {
  content: '';
  position: absolute;
  top: -8px;
  left: -6px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
}
</style>
