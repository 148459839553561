<template>
  <Notification
    :description="
      $t('notification_good_budget_heading_message', {
        budgetLeftPercentage: $n(budgetLeftPercentage, 'percent'),
        month: $d(new Date(), 'longMonth'),
        budgetleft: `${currency} ${$n(budgetLeft, 'currencyCode')}`,
      })
    "
    :header="$t('notification_good_budget_heading_heading', { connectivityProvider: partnerName })"
    v-bind="$attrs"
  >
  </Notification>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import Notification from './Notification.vue'
import { usePartners } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const { currentPartner } = usePartners(selectedAccommodationId)

const props = defineProps({
  extraParameters: {
    type: Object,
    required: true,
    default: null,
  },
})

const currency = computed(() => store.state.currency)
const partnerName = computed(() => currentPartner.value?.partnerName)
const budgetLeft = computed(() => {
  if (!props.extraParameters) return 0
  return (props.extraParameters?.totalBudget - props.extraParameters?.budgetConsumed || 0) / 100
})
const budgetLeftPercentage = computed(() => {
  if (!props.extraParameters) return 0
  return (
    (props.extraParameters?.totalBudget - props.extraParameters?.budgetConsumed || 0) /
    props.extraParameters?.totalBudget
  )
})
</script>
