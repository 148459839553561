<template>
  <div>
    <div class="tw-flex tw-items-start md:tw-items-center">
      <label class="tw-flex tw-justify-center tw-items-center tw-mb-0">
        <input
          :id="id"
          :checked="value"
          type="checkbox"
          class="hover:tw-cursor-pointer"
          v-bind="$attrs"
          @blur="$emit('onBlur', $event.target.checked)"
          @change="$emit('onChange', $event.target.checked)"
          :data-testid="dataTestId"
        />
        <span
          :for="id"
          :class="{ 'tw-mb-0': !label }"
          class="tw-mb-0 tw-ml-2 hover:tw-cursor-pointer label-text"
          v-html="$t(label)"
        />
      </label>
    </div>
    <small v-if="error" class="tw-text-red-700">{{ error }}</small>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    id: {
      type: String,
      default: 'checkbox',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Boolean,
      required: true,
    },
    error: {
      type: [Boolean, String],
      default: false,
    },
    dataTestId: {
      type: String,
      default: '',
    },
  },
}
</script>
<style scoped>
input {
  border-color: theme('colors.gray.500');
}
label :deep(a) {
  @apply tw-text-blue-800;
}
</style>
