<template>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15 6H17V4H15V6Z" fill="#0A1121" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15 10H17V8H15V10Z" fill="#0A1121" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 6H9V4H7V6Z" fill="#0A1121" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 10H9V8H7V10Z" fill="#0A1121" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11 6H13V4H11V6Z" fill="#0A1121" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11 10H13V8H11V10Z" fill="#0A1121" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15 14H17V12H15V14Z" fill="#0A1121" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 14H9V12H7V14Z" fill="#0A1121" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11 14H13V12H11V14Z" fill="#0A1121" />
  <mask id="mask0_1309_290" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="3" y="2" width="18" height="20">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21 12.0002V22H3V12.0002V2.0004H21V12.0002Z" fill="white" />
  </mask>
  <g mask="url(#mask0_1309_290)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 21.0004V17.0004C14 16.5004 13.5 16.0004 13 16.0004H11C10.5 16.0004 10 16.5004 10 17.0004V21.0004H6V3.0004H18V21.0004H14ZM20.5 21.0004H19V2.5004C19 2.2004 18.8 2.0004 18.5 2.0004H5.5C5.2 2.0004 5 2.2004 5 2.5004V21.0004H3.5C3.2 21.0004 3 21.1994 3 21.5004C3 21.8004 3.2 22.0004 3.5 22.0004H20.5C20.8 22.0004 21 21.8004 21 21.5004C21 21.1994 20.8 21.0004 20.5 21.0004Z"
      fill="#0A1121"
    />
  </g>
</template>
