import * as Sentry from '@sentry/vue'
import appAnalytics from '@/utils/tracking'

import { getPayloadFromJwt } from '@/utils/tokenUtils'

const SET_LOADING = state => {
  state.isLoading = true
  state.isError = false
  state.isSuccess = false
}

const SET_ERROR = state => {
  state.isError = true
  state.isLoading = false
  state.isSuccess = false
}

const SET_SUCCESS = state => {
  state.isSuccess = true
  state.isError = false
  state.isLoading = false
}

const SET_JLO_TOKEN = (state, { token }) => {
  state.JLoToken = token
  if (token?.access_token) {
    const payload = getPayloadFromJwt(token.access_token)
    state.JLoUserId = payload?.uuid
    Sentry.setUser({ id: payload?.uuid ?? 'N/A' })
  }
  window.localStorage.setItem('jlo_token', JSON.stringify(token))
}

const SET_STUDIO_TOKEN = (state, { token }) => {
  state.studioToken = token
  if (token?.access_token) {
    const payload = getPayloadFromJwt(token.access_token)
    state.userRole = payload?.user_role.toLowerCase()
    state.userEmail = payload?.email
    if (state.userId !== payload?.user_id) appAnalytics.identify(payload?.user_id)
    state.userId = payload?.user_id
    Sentry.setUser({
      id: state.JLoUserId ?? 'N/A',
      studioUserId: state.userId ?? 'N/A',
      userRole: state.userRole ?? 'N/A',
    })
  }

  window.localStorage.setItem('studio_token', JSON.stringify(token))
}

const SET_ORGANISATIONS = (state, { organisations = [] }) => {
  organisations?.sort?.((a, b) => {
    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1
    if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
    return 0
  })
  state.organisations = organisations
}

const SET_SELECTED_ORGANISATION = (state, { organisation }) => {
  state.selectedOrganisation = organisation
  window.localStorage.setItem('selected_organisation_id', organisation?.id)
  Sentry.setContext('organisation', {
    id: organisation?.id ?? 'N/A',
  })
}

const SET_USER_ID = (state, { userId = '' }) => {
  state.userId = userId
}

const SET_IS_ONBOARDING_USER_DETAILS = (state, value = false) => {
  state.isOnboardingUserDetails = value
}

const SET_IS_ONBOARDING_ASSIGNMENTS = (state, value = false) => {
  state.isOnboardingAssignments = value
}

export default {
  SET_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_JLO_TOKEN,
  SET_ORGANISATIONS,
  SET_SELECTED_ORGANISATION,
  SET_STUDIO_TOKEN,
  SET_USER_ID,
  SET_IS_ONBOARDING_USER_DETAILS,
  SET_IS_ONBOARDING_ASSIGNMENTS,
}
