<template>
  <div>
    <p class="tw-font-bold">{{ $t('app_hotel_details_number_of_stars') }}</p>
    <div class="tw-flex">
      <div
        v-for="star in maxStars"
        :key="star"
        :class="{ active: star <= stars }"
        class="rate-star tw-flex tw-items-center tw-justify-center"
        @click.stop="rate(star)"
        @keydown.enter="rate(star)"
        @mouseover="starOver(star)"
        @mouseout="starOut"
        @blur.prevent=""
        @focus.prevent=""
      >
        <BaseIcon
          :icon-name="star <= stars ? 'star' : 'star-outline'"
          :height="16"
          :width="16"
          :viewbox-height="24"
          :viewbox-width="24"
          class="star-icon"
        >
          <path
            :class="star <= stars ? 'star' : 'star-outline'"
            d="M21 10.8c-.1-.4-.4-.7-.8-.8l-5.4-.9L13 3.7c-.2-.4-.6-.7-1-.7s-.8.3-.9.7L9.2 9.1l-5.4.9c-.4.1-.7.4-.8.8s.1.8.5 1l4.3 2.6-1.7 5.2c-.1.4 0 .9.4 1.1.1.2.3.3.5.3s.4-.1.6-.2l4.4-3.5 4.4 3.5c.2.1.4.2.6.2.2 0 .4-.1.6-.2.4-.3.5-.7.4-1.1l-1.7-5.2 4.3-2.6c.3-.3.5-.7.4-1.1z"
          ></path>
        </BaseIcon>
      </div>
    </div>
    <Checkbox
      name="category"
      :label="$t('wizard_filters_nostar')"
      :value="grade === 0"
      @onChange="e => setNoStars(e)"
    />
  </div>
</template>
<script setup>
import { ref } from 'vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Checkbox from '@/components/base/inputs/Checkbox.vue'

const props = defineProps({
  grade: {
    type: Number,
    required: true,
  },
  maxStars: {
    type: Number,
    default: 5,
  },
})

const tempValue = ref(null)
const stars = ref(props.grade)
const isDisabled = ref(false)

const emit = defineEmits(['setNewValue'])
const setNewValue = star => emit('setNewValue', star)

const rate = star => {
  if (!isDisabled.value) {
    tempValue.value = star
    setNewValue(star)
    return (stars.value = star)
  }
}
const starOver = index => {
  if (!isDisabled.value) {
    tempValue.value = stars.value
    return (stars.value = index)
  }
}
const starOut = () => {
  if (!isDisabled.value) {
    return (stars.value = tempValue.value)
  }
}
const setNoStars = e => {
  stars.value = e ? 0 : tempValue.value
  isDisabled.value = e
  setNewValue(0)
}
</script>
<style scoped>
.star-icon {
  scale: 2;
  margin: 15px 10px;
}
.star {
  fill: #f6ab3f;
  stroke: #f6ab3f;
}
.star-outline {
  stroke: #f6ab3f;
  fill-opacity: 0;
}
.rate-star:hover {
  cursor: pointer;
}
.rate-star:hover ~ .rate-star:not(.active) {
  fill: #f6ab3f;
}
</style>
