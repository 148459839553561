import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import PremiumService from '@/services/PremiumService'

export function useStudioPlusCampaigns(organisationId) {
  const { isLoading, isFetching, isSuccess, isError, data } = useQuery({
    queryKey: ['studioPlusCampaigns', organisationId],
    queryFn: () => PremiumService.fetchStudioPlusCampaignsForOrganisation(),
  })

  const studioPlusCampaigns = computed(() => data?.value?.data ?? [])

  return {
    isStudioPlusCampaignsLoading: isLoading,
    isStudioPlusCampaignsFetching: isFetching,
    isStudioPlusCampaignsSuccess: isSuccess,
    isStudioPlusCampaignsError: isError,
    studioPlusCampaigns,
  }
}

const getTransactionsHistory = async id => {
  const response = await PremiumService.fetchTransactionsHistory(id)
  return response?.data
}

export function useSubscriptionHistory(subscriptionNumber) {
  const { data, isError, isLoading, isFetching } = useQuery({
    queryKey: ['subscriptionHistory', subscriptionNumber],
    queryFn: ({ queryKey }) => getTransactionsHistory(queryKey[1]),
  })

  const subscriptionHistory = computed(() => data?.value)
  return { subscriptionHistory, isError, isLoading, isFetching }
}
