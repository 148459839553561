export default {
  productName: {
    rateConnect: 'Rate Connect',
    plus: 'Business Studio+',
    settings: 'Settings - Edit Payment Method',
  },
  metricErrorCodes: {
    hppLoadError: '110001',
    paymentError: '110002',
    configurationError: '110003',
    clientSideValidationError: '110004',
    emptyOrderIdError: '110005',
    billingInformation: '110006',
  },
  metricScopes: {
    zuora: 'zuora',
    order: 'order',
  },
  metricTypes: {
    error: 'ERROR',
    metric: 'METRIC',
  },
}
