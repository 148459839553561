export const CAMPAIGN_MODES = {
  BOTH: 'both',
  CPA: 'cpa',
  CPC: 'cpc',
  NET_CPA: 'netCpa',
  UNKNOWN: 'unknown',
}

export function campaignsAggregateType(campaigns, currentCampaignType) {
  let hasCPC
  let hasCPA

  campaigns.forEach(periodStats => {
    if (periodStats.commissionCost !== null) {
      hasCPA = true
    }
    if (periodStats.cpc !== null) {
      hasCPC = true
    }
  })

  return hasCPA && hasCPC
    ? CAMPAIGN_MODES.BOTH
    : hasCPA
    ? CAMPAIGN_MODES.CPA
    : hasCPC
    ? CAMPAIGN_MODES.CPC
    : currentCampaignType
}

export function isAggregateValidForBoth({ cpc, commissionCost }) {
  return cpc !== null && commissionCost !== null
}

export function inferCampaignAggregateType(campaign) {
  return isAggregateValidForBoth(campaign)
    ? CAMPAIGN_MODES.BOTH
    : campaign.commissionCost !== null
    ? CAMPAIGN_MODES.CPA
    : campaign.cpc !== null
    ? CAMPAIGN_MODES.CPC
    : CAMPAIGN_MODES.UNKNOWN
}

export const isCpaCampaign = campaignType =>
  campaignType === CAMPAIGN_MODES.CPA || campaignType === CAMPAIGN_MODES.NET_CPA

function removeItemOnce(arr, value) {
  const index = arr.indexOf(value)
  if (index > -1) {
    arr.splice(index, 1)
  }
  return arr
}

/**
 * @export
 * Gets the available campaigns to select based on eligibleCampaignTypes and payPerStayFeatureFlag
 * @param {object} params
 * @returns
 */
export function getCampaignTypesToSelect(eligibleCampaignTypes = [], payPerStayFeatureFlag = false) {
  const allCampaignTypes = [...eligibleCampaignTypes]
  const availabeCampaignTypes = eligibleCampaignTypes.includes('netCpa')
    ? removeItemOnce(allCampaignTypes, 'cpa')
    : eligibleCampaignTypes

  const campaignTypes = {
    cpc: {
      description: payPerStayFeatureFlag ? 'rc_pay_per_click_setup_flow_description' : 'rc_pay_per_click_description',
      name: 'rc_pay_per_click',
      id: CAMPAIGN_MODES.CPC,
    },
    cpa: {
      description: payPerStayFeatureFlag ? 'rc_pay_per_booking_setup_flow_description' : 'rc_setup_cpa_text_new',
      name: 'rc_pay_per_booking',
      id: CAMPAIGN_MODES.CPA,
    },
  }

  if (payPerStayFeatureFlag) {
    campaignTypes.netCpa = {
      description: 'rc_pay_per_stay_setup_flow_description',
      name: 'rc_pay_per_stay',
      id: CAMPAIGN_MODES.NET_CPA,
    }
  }

  return availabeCampaignTypes.reduce((reducedCampaignTypes, currentEligibleCampaignType) => {
    if (Object.prototype.hasOwnProperty.call(campaignTypes, currentEligibleCampaignType)) {
      reducedCampaignTypes.push(campaignTypes[currentEligibleCampaignType])
    }

    return reducedCampaignTypes
  }, [])
}

export const getBudgetStatus = (budgetRecommendationInfo, inputBudget, budgetConsumed) => {
  if (inputBudget > 0 && inputBudget < budgetConsumed) {
    return 'belowConsumption'
  } else if (inputBudget < budgetRecommendationInfo?.budgetMin) {
    return 'tooLow'
  } else if (inputBudget < budgetRecommendationInfo?.budgetRecommendedMin) {
    return 'low'
  } else if (inputBudget < budgetRecommendationInfo?.budgetRecommendedMax) {
    return 'recommended'
  } else {
    return 'excellent'
  }
}
