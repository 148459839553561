<template>
  <div class="tw-mx-4 lg:tw-mx-0">
    <div class="tw-my-4">
      <CardLoadingSkeleton v-if="isAccommodationBasicInfoLoading" height="202px" />
      <PropertyBasicInfo v-else :accommodationInfo="accommodationBasicInfo" />
    </div>
    <div class="tw-my-4">
      <CardLoadingSkeleton v-if="accommodationFeaturesLoading" height="1060px" />
      <PropertyFeatures v-else :accommodationFeatures="accommodationFeatures" />
    </div>
    <div class="tw-my-4">
      <CardLoadingSkeleton v-if="isLanguageFieldsLoading || isAvailableLanguagesLoading" height="202px" />
      <PropertyLanguages v-else />
    </div>
  </div>
  <FullScreenLoader
    v-show="
      isAccommodationBasicInfoFetching ||
      isLanguageFieldsLoading ||
      isAvailableLanguagesLoading ||
      accommodationFeaturesLoading
    "
  />
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import PropertyBasicInfo from '@/components/propertyDetails/propertyBasicInfo/PropertyBasicInfo.vue'
import PropertyFeatures from '@/components/propertyDetails/propertyFeatures/PropertyFeatures.vue'
import PropertyLanguages from '@/components/propertyDetails/propertyLanguages/PropertyLanguages.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import CardLoadingSkeleton from '@/components/loadingSkeletons/CardLoadingSkeleton.vue'
import { useAccommodationBasicInfo, usePropertyFeatures } from '@/components/propertyDetails/queries/index.js'
import { useLanguageFields, useAvailableLanguages } from '@/components/propertyDetails/propertyLanguages/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const languageCode = computed(() => store.state.locale?.language)

const { isLanguageFieldsLoading } = useLanguageFields(selectedAccommodationId)
const { isAvailableLanguagesLoading } = useAvailableLanguages()
const { isAccommodationBasicInfoLoading, isAccommodationBasicInfoFetching, accommodationBasicInfo } =
  useAccommodationBasicInfo(selectedAccommodationId)
const { accommodationFeaturesLoading, accommodationFeatures } = usePropertyFeatures(
  selectedAccommodationId,
  languageCode
)
</script>
