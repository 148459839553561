import { reactive, toRefs } from 'vue'

const state = reactive({
  selectedItems: [],
})

export function useAssignedProperty() {
  const removeHotelAssignment = () => {
    state.selectedItems = []
  }

  const addHotelAssignment = payload => {
    state.selectedItems = [payload]
  }

  const resetHotelAssignment = () => {
    state.selectedItems = []
  }

  return {
    ...toRefs(state),
    removeHotelAssignment,
    addHotelAssignment,
    resetHotelAssignment,
  }
}
