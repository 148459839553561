<template>
  <div>
    <PerformanceFilters
      v-if="!requiredDataHasErrors && markets"
      :markets="markets"
      :lastUpdated="lastUpdated"
      :selectedPeriod="selectedPeriod"
      @onSelectedPeriodChange="onSelectedPeriodChange"
      @onSelectedMarketChange="onSelectedMarketChange"
    />
    <div v-if="requiredDataHasErrors">
      <RCGeneralErrorMessage />
    </div>
    <div v-if="!requiredDataHasErrors && !accommodationHasVisits && !requiredDataIsBeingFetched">
      <PerformanceEmptyWrapper :selectedAccommodationId="selectedAccommodationId" :marketDomain="marketDomain" />
    </div>
    <div v-if="!isBookingLogError && accommodationHasVisits">
      <PerformanceStats
        :prettyKpis="prettyKpis"
        :currentCampaignType="currentCampaignType"
        :currency="currency"
        :bookings="bookings"
        :categories="categories"
        :campaignTypes="campaignTypes"
        :tickPositions="tickPositions"
        :visits="visits"
        :revenue="revenue"
        :cpa="cpa"
        :commissionCosts="commissionCosts"
      />
    </div>
    <FullScreenLoader v-show="requiredDataIsBeingFetched" />
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

import PerformanceFilters from '@/components/rateConnect/performance/PerformanceFilters.vue'
import PerformanceStats from '@/components/rateConnect/performance/PerformanceStats.vue'
import PerformanceEmptyWrapper from '@/components/rateConnect/performance/PerformanceEmptyWrapper.vue'
import RCGeneralErrorMessage from '@/components/rateConnect/RCGeneralErrorMessage.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import { useBookingLog, useKpis, useStats } from '@/components/rateConnect/queries'
import { useRateConnectPerformance, usePrettyStats } from './useRateConnectPerformance'
import { selectedAccommodationId } from '@/layouts/queries'

const props = defineProps({
  markets: {
    type: Object,
    default: null,
  },
  currentCampaignType: {
    type: String,
    default: '',
  },
})

const { selectedPeriod, selectedMarket, formattedStartDate, formattedEndDate, selectedAggregation, resetData } =
  useRateConnectPerformance()

const store = useStore()
const currency = computed(() => store.state.currency)
const language = computed(() => store.state.locale?.language)
const {
  isFetching: isKpisFetching,
  isError: isKpisError,
  KpisData,
  prettyKpis,
} = useKpis(selectedAccommodationId, currency, formattedStartDate, formattedEndDate, selectedMarket)
const { isFetching: isBookingLogFetching, isError: isBookingLogError } = useBookingLog(
  selectedAccommodationId,
  currency,
  formattedStartDate,
  formattedEndDate,
  selectedMarket
)
const {
  isFetching: isStatsFetching,
  isError: isStatsError,
  statsData,
} = useStats(
  selectedAccommodationId,
  currency,
  formattedStartDate,
  formattedEndDate,
  selectedMarket,
  selectedAggregation
)
const lastUpdated = computed(() => KpisData.value?.lastUpdated)
const { bookings, visits, revenue, cpa, commissionCosts, categories, campaignTypes, tickPositions } = usePrettyStats(
  statsData,
  lastUpdated,
  language
)

const requiredDataHasErrors = computed(() => isKpisError.value || isBookingLogError.value || isStatsError.value)
const requiredDataIsBeingFetched = computed(
  () => isKpisFetching.value || isBookingLogFetching.value || isStatsFetching.value
)
const accommodationHasVisits = computed(() => KpisData.value?.visits > 0)
const marketDomain = computed(() => props.markets?.[0]?.domain)

const onSelectedPeriodChange = newPeriod => (selectedPeriod.value = newPeriod)
const onSelectedMarketChange = newMarket => (selectedMarket.value = newMarket)

onMounted(() => {
  resetData()
})
</script>
