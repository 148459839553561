import axios from 'axios'
import BaseService from './BaseService.js'

const RATES_API = `${import.meta.env.VITE_RATES_API}`
const CHANNELS_API = `${import.meta.env.VITE_CHANNELS_API}`
const COMPETITORS_API = `${import.meta.env.VITE_COMPETITORS_API}`
const GRAPHQL_API = `${import.meta.env.VITE_HOTEL_SEARCH_GRAPHQL_API_URL}`

const getPartnerAvatar = `query s($input: AdvertiserDetailsInput!) {
  getAdvertiserDetails(input: $input) {
    advertiserDetails {
      nsid {
        id
        ns
      }
      logo {
        localizedUrlTail
        localizedFaviconUrlTail
      }
      translatedName {
        value
      }
    }
  }
}`

class RateInsightsService extends BaseService {
  getRatesDefaults() {
    return axios({
      url: `${RATES_API}/default`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getRatesFilters() {
    return axios({
      url: `${RATES_API}/filters`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  async getSelectedChannels({ accommodationId }) {
    return await axios({
      url: `${CHANNELS_API}/channels/${accommodationId}/selected`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    }).then(async response => {
      const partnerIds = []
      response.data.map(item => partnerIds.push(item.id))
      const partnersData = await this.getPartnerAvatar({ partnerIds })
      return {
        selectedChannels: response.data,
        partnersData: partnersData?.data?.data?.getAdvertiserDetails,
      }
    })
  }

  getCompetitors({ accommodationId }) {
    return axios({
      url: `${COMPETITORS_API}/competitors/${accommodationId}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  getSuggestions({ accommodationId }) {
    return axios({
      url: `${COMPETITORS_API}/competitors/${accommodationId}/suggestions`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  searchForCompetitors(q) {
    return axios({
      url: `${COMPETITORS_API}/competitors/search?`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      params: {
        q,
      },
    })
  }

  getRates({ marketRates, accommodationId, params }) {
    return axios({
      url: `${RATES_API}/rates/${marketRates}/${accommodationId}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      params: params,
    })
  }

  getTravelersDemand({ accommodationId, params }) {
    return axios({
      url: `${RATES_API}/searches/${accommodationId}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      params: params,
    })
  }

  updateSelectedCompetitors({ accommodationId, competitors }) {
    return axios({
      url: `${COMPETITORS_API}/competitors/${accommodationId}`,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      data: { competitors: competitors },
    })
  }

  updateBookingSites({ accommodationId, bookingSites }) {
    return axios({
      url: `${CHANNELS_API}/channels/${accommodationId}`,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
      data: { channels: bookingSites },
    })
  }

  async getAvailableChannels({ accommodationId }) {
    return await axios({
      url: `${CHANNELS_API}/channels/${accommodationId}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
      },
    }).then(async response => {
      const partnerIds = []
      response.data.map(item => partnerIds.push(item.id))
      const partnersData = await this.getPartnerAvatar({ partnerIds })
      return {
        bookingSites: response.data,
        partnersData: partnersData?.data?.data?.getAdvertiserDetails,
      }
    })
  }

  getPartnerAvatar({ partnerIds }) {
    const input = partnerIds?.map(item => {
      return { id: item, ns: 400 }
    })
    const response = axios({
      url: `${GRAPHQL_API}`,
      method: 'POST',
      data: {
        query: getPartnerAvatar,
        variables: {
          input: { nsids: input },
        },
      },
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
        'x-trv-language': 'en-US',
        'Apollographql-Client-Name': 'trivago-business-studio-rate-insights',
        'Apollographql-Client-Version': '1.0.0',
      },
    })
    response.data = response.data?.data?.getAdvertiserDetails?.advertiserDetails
    return response
  }
}
export default new RateInsightsService()
