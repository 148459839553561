import axios from 'axios'
import BaseService from './BaseService.js'

class AdminService extends BaseService {
  constructor() {
    super({
      baseUrl: import.meta.env.VITE_ORGANISATION_MANAGEMENT_API_URL,
    })
  }

  search(accommodationId) {
    return axios({
      url: `${this.baseUrl}/admin/accommodations/${accommodationId}`,
      method: 'GET',
      headers: {
        'X-Studio-Authorization': this.studioToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  }

  deleteAssignment({ accommodationId }) {
    return axios({
      url: `${this.baseUrl}/admin/assignments/${accommodationId}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
        'Content-Type': 'application/json',
      },
    })
  }

  inviteUserAsAdmin({ organisationId, data }) {
    return axios({
      url: `${this.baseUrl}/admin/organisation/${organisationId}/invite`,
      method: 'POST',
      data: data,
      headers: {
        Accept: 'application/json',
        'X-Studio-Authorization': this.studioToken,
        'Content-Type': 'application/json',
      },
    })
  }
}

export default new AdminService()
