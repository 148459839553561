<template>
  <div class="tw-px-4 tw-mb-6" :key="selectedAccommodationId">
    <div class="tw-text-center tw-mt-8">
      <h1 class="tw-text-3xl">{{ $t('rc_product_page_header') }}</h1>
      <p class="tw-text-gray-700 tw-text-xl tw-mt-2">{{ $t('rc_product_page_text') }}</p>
      <div v-if="isStartUpFetching || isPartnersFetching">
        <LaunchpadLoader class="tw-w-full tw-flex tw-justify-center tw-items-center tw-flex-1" />
      </div>

      <div v-else-if="isEnabledForRateConnect && recommendedPartnerName && !isBlockedCountry" class="tw-mt-8">
        <MainButton :disabled="currentRole !== 'admin'" @click="onCreateCampaignClick">{{
          $t('rc_product_page_cta')
        }}</MainButton>
        <div class="tw-mt-8 tw-text-gray-700 tw-text-sm tw-flex tw-justify-center tw-items-center">
          <i18n-t tag="p" keypath="connectivity_provider_info">
            <template #partnerName>
              <strong>{{ recommendedPartnerName }}</strong>
            </template>
          </i18n-t>
          <UiTooltip
            tooltip-position="top"
            :label="$t('connectivity_provider_info_tooltip', { partnerName: recommendedPartnerName })"
            class="tw-cursor-pointer tw-flex"
          >
            <BaseIcon
              icon-name="information-outline"
              :height="16"
              :width="16"
              class="tw-fill-none tw-stroke-gray-700 tw-h-[16px] tw-w-[16px] tw-ml-1"
            >
              <InformationOutline />
            </BaseIcon>
          </UiTooltip>
        </div>
      </div>
      <div v-else class="tw-mt-8">
        <MainButton @click="onReadMoreClick">{{ $t('tbs_home_carousel_1_cta') }}</MainButton>
      </div>
    </div>
    <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between tw-gap-8 tw-mt-8">
      <div class="tw-text-center tw-flex-1 tw-items-center tw-flex tw-flex-col">
        <BaseIcon :height="56" :width="56" class="tw-mb-3" icon-name="eye-icon">
          <EyeIcon />
        </BaseIcon>
        <p class="tw-mb-4 tw-text-xl">{{ $t('rc_product_page_benefit_1_header') }}</p>
        <p class="tw-text-gray-700">{{ $t('rc_product_page_benefit_1_text') }}</p>
      </div>
      <div class="tw-text-center tw-flex-1 tw-items-center tw-flex tw-flex-col">
        <BaseIcon :height="56" :width="56" class="tw-mb-3" icon-name="arrow-icon">
          <ArrowIcon />
        </BaseIcon>
        <p class="tw-mb-4 tw-text-xl">{{ $t('rc_product_page_benefit_2_header') }}</p>
        <p class="tw-text-gray-700">{{ $t('rc_product_page_benefit_2_text') }}</p>
      </div>
      <div class="tw-text-center tw-flex-1 tw-items-center tw-flex tw-flex-col">
        <BaseIcon :height="56" :width="56" class="tw-mb-3" icon-name="trophy-icon">
          <ThropyIcon />
        </BaseIcon>
        <p class="tw-mb-4 tw-text-xl">{{ $t('rc_product_page_benefit_3_header') }}</p>
        <p class="tw-text-gray-700">{{ $t('rc_product_page_benefit_3_text') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import MainButton from '@/components/base/buttons/MainButton.vue'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import EyeIcon from '@/components/icons/EyeIcon.vue'
import ArrowIcon from '@/components/icons/ArrowIcon.vue'
import ThropyIcon from '@/components/icons/ThropyIcon.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import { useStartUp, usePartners } from '@/components/rateConnect/queries'
import { selectedAccommodationId, useAccommodationsByOrgId } from '@/layouts/queries'
import { RC_BLOCKED_COUNTRIES } from '@/constants/rcBlockedCountries.js'

const router = useRouter()
const store = useStore()

const currentRole = computed(() => store.state.session?.userRole)
const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { isFetching: isStartUpFetching, hotelierData } = useStartUp(selectedAccommodationId)
const { isFetching: isPartnersFetching, recommendedPartner } = usePartners(selectedAccommodationId)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === selectedAccommodationId.value)
)
const isEnabledForRateConnect = computed(() => hotelierData.value?.isEnabledForRateConnect)
const recommendedPartnerName = computed(() => recommendedPartner.value?.partnerName)
const isBlockedCountry = computed(() => RC_BLOCKED_COUNTRIES.includes(currentAccommodation.value?.countryId))

const onCreateCampaignClick = () => {
  router.push('/rate-connect/setup')
}

const onReadMoreClick = () => {
  router.push('/rate-connect/noteligible')
}
</script>
