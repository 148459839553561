import { computed } from 'vue'
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import * as Sentry from '@sentry/vue'

import OrganisationManagementService from '@/services/OrganisationManagementService'
import PremiumService from '@/services/PremiumService'

export function useGetUserCurrency({ token, isGuest }) {
  const { isLoading, isFetching, data, fetchStatus } = useQuery({
    queryKey: ['organisationCurrency', token, isGuest],
    queryFn: async () => {
      if (!isGuest.value && token.value !== null) {
        const response = await PremiumService.getUserCurrency()
        return response.data
      } else {
        const response = await PremiumService.getGuestCurrency()
        return response.data
      }
    },
    onError: error => {
      Sentry.withScope(scope => {
        scope.setTag('feature', 'currency-selector-getOrganisationCurrency')
        scope.setExtra('Response error', error)
        Sentry.captureException(error)
      })
    },
  })

  const isOrganisationsCurrencyLoading = computed(() => isLoading.value && fetchStatus.value !== 'idle')
  const currency = computed(() => data.value?.currency)
  const language = computed(() => data.value?.language?.languageCode)

  return {
    isOrganisationsCurrencyLoading,
    isOrganisationCurrencyFetching: isFetching,
    userCurrency: currency,
    userLanguage: language,
  }
}

export function useUpdateCurrency() {
  const queryClient = useQueryClient()
  const {
    isLoading: updatingUserCurrencyLoading,
    isSuccess: updatingUserCurrencySuccess,
    isError: updatingUserCurrencyError,
    mutate: updateCurrency,
  } = useMutation({
    mutationFn: ({ userId, currency }) =>
      OrganisationManagementService.updateUserProfile({ userId, body: { currency } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organisationCurrency'] })
    },
    onError: error => {
      Sentry.withScope(scope => {
        scope.setTag('feature', 'currency-selector-updateCurrency')
        scope.setExtra('Response error', error)
        Sentry.captureException(error)
      })
    },
  })

  return {
    updatingUserCurrencyLoading,
    updatingUserCurrencySuccess,
    updatingUserCurrencyError,
    updateCurrency,
  }
}
