<template>
  <path
    stroke-width="1"
    fill-rule="evenodd"
    d="M7.5 17H4.5C4.224 17 4 16.776 4 16.5C4 16.224 4.224 16 4.5 16H7.5C7.776 16 8 16.224 8 16.5C8 16.776 7.776 17 7.5 17Z"
  />
  <path
    stroke-width="1"
    fill-rule="evenodd"
    d="M7.5 18H4.5C4.224 18 4 18.224 4 18.5C4 18.776 4.224 19 4.5 19H5C5 19.552 5.448 20 6 20C6.552 20 7 19.552 7 19H7.5C7.776 19 8 18.776 8 18.5C8 18.224 7.776 18 7.5 18Z"
  />

  <path
    stroke-width="1"
    fill-rule="evenodd"
    d="M9.00782 9.96776L8.65682 11.3738L7.99982 13.9998H6.49982V7.99976H8.49982C8.77682 7.99976 8.99982 7.77676 8.99982 7.49976C8.99982 7.22376 8.77682 6.99976 8.49982 6.99976H6.49982H5.49982H3.49982C3.22382 6.99976 2.99982 7.22376 2.99982 7.49976C2.99982 7.77676 3.22382 7.99976 3.49982 7.99976H5.49982V13.9998H3.99982L3.34282 11.3738L2.99182 9.96776C2.36282 9.48976 1.85382 8.87276 1.50782 8.15476C1.19282 7.49976 0.999817 6.77476 0.999817 5.99976C0.999817 3.24276 3.24282 0.999756 5.99982 0.999756C8.75682 0.999756 10.9998 3.24276 10.9998 5.99976C10.9998 6.77476 10.8078 7.49976 10.4918 8.15476C10.1458 8.87276 9.63682 9.48976 9.00782 9.96776ZM5.99982 -0.000244141C2.68682 -0.000244141 -0.000183105 2.68676 -0.000183105 5.99976C-0.000183105 7.82776 0.819817 9.46276 2.10982 10.5628L3.02982 14.2428C3.14082 14.6878 3.54082 14.9998 3.99982 14.9998H5.49982H6.49982H7.99982C8.45882 14.9998 8.85882 14.6878 8.96982 14.2428L9.88982 10.5628C11.1798 9.46276 11.9998 7.82776 11.9998 5.99976C11.9998 2.68676 9.31382 -0.000244141 5.99982 -0.000244141Z"
  />
</template>
