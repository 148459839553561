import { useMutation, useQueryClient } from '@tanstack/vue-query'
import OrganisationManagementService from '@/services/OrganisationManagementService'

export function useAssignProperty() {
  const queryClient = useQueryClient()
  const {
    isLoading,
    isSuccess,
    isError,
    mutate: assignProperty,
  } = useMutation({
    mutationFn: ({ accommodationId }) => OrganisationManagementService.postOrganisationAssignments({ accommodationId }),
    onSuccess: (_, mutationInput) => {
      /* Setting the assignment directly instead of invalidating the query, otherwise the invalidation of 
         organisationAccommodations will start running before the organisationAssignments new fetch finishes. */
      queryClient.setQueryData(['organisationAssignments', mutationInput.organisationId], oldData =>
        oldData ? [...oldData, mutationInput.accommodationId] : oldData
      )
      queryClient.invalidateQueries({ queryKey: ['organisationAccommodations', mutationInput.organisationId] })
    },
  })

  return {
    assignPropertyLoading: isLoading,
    isSuccess,
    isError,
    assignProperty,
  }
}
