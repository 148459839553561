<template>
  <header class="tw-flex tw-flex-row">
    <div
      class="lg:tw-mt-[56px] lg:tw-mb-[20px] tw-my-[45px] lg:tw-min-h-[460px] md:tw-min-h-[350px] tw-w-full tw-relative lg:tw-max-w-screen-landing md:tw-max-w-screen-md tw-px-4 sm:tw-px-8 lg:tw-px-0 tw-mx-auto tw-flex tw-justify-between tw-items-start tw-w-full tw-flex-col md:tw-flex-row"
    >
      <div
        class="tw-w-full md:tw-w-2/3 tw-items-center md:tw-items-start md:tw-justify-start tw-justify-center tw-m-auto tw-mb-8 md:tw-m-0"
      >
        <h1
          class="tw-text-center md:tw-text-left tw-text-[28px] lg:tw-text-[40px] tw-leading-[35px] lg:tw-leading-[50px] tw-font-bold tw-mb-4"
          v-html="$t('tbs_mp_headline')"
          :class="{ 'md:tw-whitespace-nowrap': language !== 'ja-JP' }"
        />
        <div class="tw-flex tw-justify-center md:tw-justify-start">
          <MainButton @click="onRegisterClick" @keydown.enter="onRegisterClick" :dataTestId="'hero-register-button'">{{
            $t('tbs_mp_cta_register')
          }}</MainButton>
        </div>
      </div>
      <img
        :src="heroWeb"
        alt="trivago business studio hero image"
        class="lg:tw-h-[350px] md:tw-h-[270px] md:tw-block tw-hidden tw-absolute lg:tw-right-0 tw-right-0 tw-top-[65px]"
      />
      <img :src="heroMobile" alt="trivago business studio hero image" class="md:tw-hidden tw-block" height="126px" />
    </div>
  </header>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import MainButton from '@/components/base/buttons/MainButton.vue'

const heroWeb = new URL('/src/assets/img/landing/web-hero.svg', import.meta.url)
const heroMobile = new URL('/src/assets/img/landing/mobile-hero.svg', import.meta.url)

const store = useStore()
const language = computed(() => store.state.locale.language)

const emit = defineEmits(['onLoginClick'])
const onRegisterClick = e => emit('onLoginClick', e.target.value)
</script>
<style scoped>
header {
  background: rgb(249, 250, 250);
  background: linear-gradient(180deg, rgba(249, 250, 250, 1) 71%, rgba(0, 136, 217, 1) 60%);
  @media (max-width: theme('screens.sm')) {
    background: rgb(249, 250, 250);
    background: linear-gradient(180deg, rgba(249, 250, 250, 1) 85%, rgba(0, 136, 217, 1) 70%);
  }
}
</style>
