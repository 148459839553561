<template>
  <div class="tw-overflow-y-auto tw-overflow-x-hidden tw-flex tw-flex-col">
    <ul class="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto tw-max-h-64">
      <li
        v-for="organisation in organisations"
        :key="organisation.id"
        @click.prevent="selectOrganisation(organisation)"
        @keydown.prevent="selectOrganisation(organisation)"
      >
        <label
          :for="organisation.id"
          :title="organisation.name"
          tabindex="0"
          class="tw-flex tw-items-center tw-m-0 tw-relative tw-cursor-pointer tw-px-4 tw-py-3 focus:tw-outline-none tw-leading-4 tw-max-w-full tw-h-12"
          :class="selectedOrganisationInSwitcher?.id === organisation.id ? 'tw-bg-blue-100 hover:tw-bg-blue-100' : ''"
        >
          <div class="tw-ml-3 tw-overflow-hidden tw-flex-1">
            <p class="tw-truncate tw-text-sm tw-black tw-font-bold">
              {{ organisation.name }}
            </p>
            <p class="tw-truncate tw-text-xxs tw-text-gray-700"><strong>ID:</strong> {{ organisation.id }}</p>
          </div>
          <BaseIcon icon-name="chevron-right" :height="24" :width="24" :viewbox-height="24" :viewbox-width="24">
            <ChevronRight />
          </BaseIcon>
        </label>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'

defineProps({
  selectedOrganisationInSwitcher: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['organisationInSwitcherClick'])

const store = useStore()
const organisations = computed(() => store.state.session.organisations)

const selectOrganisation = organisation => {
  emit('organisationInSwitcherClick', organisation)
}
</script>

<style scoped>
.id-text {
  font-size: 10px;
}
</style>
