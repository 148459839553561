<template>
  <div class="tw-fixed tw-h-full tw-inset-0 tw-z-50">
    <div class="tw-overlay-white-75 tw-h-full tw-grid md:tw-place-items-center">
      <BaseCard
        v-click-outside="() => emit('onCancel')"
        no-padding
        class="tw-bg-white tw-w-screen md:tw-w-[95vw] md:tw-max-w-[550px] md:tw-mx-auto tw-flex tw-flex-col"
      >
        <div class="tw-pb-3 tw-flex tw-justify-between tw-border-b tw-border-gray-300 tw-p-4 tw-pb-3">
          <h2 class="tw-font-bold">{{ $t('pages.organizationSwitcher.leaveOverlay.title') }}</h2>
          <button @click.stop="emit('onCancel')" @keydown.enter="emit('onCancel')">
            <BaseIcon
              icon-name="cross"
              :height="24"
              :width="24"
              :viewbox-height="24"
              :viewbox-width="24"
              class="tw-cursor-pointer"
            >
              <Cross />
            </BaseIcon>
          </button>
        </div>
        <p class="tw-px-4 tw-pt-3 tw-text-sm">{{ $t('pages.organizationSwitcher.leaveOverlay.body') }}</p>
        <div
          class="tw-mt-10 tw-flex tw-flex-col md:tw-flex-row md:tw-justify-end md:tw-items-center tw-gap-3 md:tw-gap-4 tw-px-4 tw-pb-5"
        >
          <GhostButton size="medium" class="md:tw-self-center tw-min-w-[120px]" @click.stop="emit('onCancel')">{{
            $t('pages.organizationSwitcher.leaveOverlay.cta.cancel')
          }}</GhostButton>
          <DangerButton size="medium" class="tw-min-w-[120px]" @click.stop="emit('onConfirm')">{{
            $t('pages.organizationSwitcher.leaveOverlay.cta.leave')
          }}</DangerButton>
        </div>
      </BaseCard>
    </div>
  </div>
</template>

<script setup>
import DangerButton from '@/components/base/buttons/DangerButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Cross from '@/components/icons/Cross.vue'

const emit = defineEmits(['onCancel', 'onConfirm'])
</script>
