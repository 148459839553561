<template>
  <section>
    <div class="tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-screen-md sm:tw-px-8 tw-px-4 lg:tw-px-0 tw-mx-auto">
      <div class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-center tw-items-center">
        <div class="lg:tw-w-2/3 lg:tw-mr-20 tw-mr-0 tw-w-auto">
          <img :src="StudioPlusLogo" alt="rate-connect-logo" class="tw-mx-auto tw-h-[28px] lg:tw-mx-0" />
          <div class="tw-flex tw-flex-row tw-items-center tw-gap-4 sm:tw-gap-0">
            <img :src="StudioBanner" alt="rate-connect-logo" class="tw-h-[79px] banner-image tw-block sm:tw-hidden" />
            <p class="tw-font-bold md:tw-text-3xl tw-py-4 sm:tw-text-center lg:tw-text-left md:tw-px-20 lg:tw-px-0">
              {{ $t('studio_plus_mp_cta_text') }}
            </p>
          </div>
          <div class="lg:tw-block tw-hidden">
            <MainButton @click="onGetStartedClick" @keydown.enter="onGetStartedClick">{{
              $t('rc_mp_get_started')
            }}</MainButton>
            <p class="tw-text-xs tw-pt-4">
              {{ $t('lp_free_link_footnote') }}
            </p>
          </div>
          <div class="tw-block sm:tw-hidden tw-text-center">
            <MainButton size="small" class="" @click="onGetStartedClick" @keydown.enter="onGetStartedClick">{{
              $t('rc_mp_get_started')
            }}</MainButton>
            <p class="tw-text-xs tw-pt-4">
              {{ $t('lp_free_link_footnote') }}
            </p>
          </div>
        </div>
        <div class="lg:tw-w-1/3 tw-hidden tw-w-auto sm:tw-flex tw-items-center tw-mt-8 lg:tw-mt-9">
          <img :src="StudioBanner" alt="rate-connect-logo" class="tw-max-h-[118px] banner-image" />
          <div class="lg:tw-hidden tw-block tw-ml-4 tw-max-w-[180px]">
            <MainButton @click="onGetStartedClick" @keydown.enter="onGetStartedClick">{{
              $t('rc_mp_get_started')
            }}</MainButton>
            <p class="tw-text-xs tw-pt-4">
              {{ $t('lp_free_link_footnote') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import MainButton from '@/components/base/buttons/MainButton.vue'

const StudioPlusLogo = new URL('/src/assets/img/landing/landing_studio_plus.svg', import.meta.url)
const StudioBanner = new URL('/src/assets/img/studio-plus-landing/banner_pig_image.svg', import.meta.url)

const emit = defineEmits(['onLoginClick'])
const onGetStartedClick = e => emit('onLoginClick', e.target.value)
</script>
<style scoped>
.banner-image {
  @media (max-width: theme('screens.lg')) {
    transform: scaleX(-1);
  }
}
</style>
