export const CURRENCIES = {
  EUR: '€',
  GBP: '£',
  USD: '$',
  MXN: '$',
  JPY: '¥',
  PLN: 'zł',
  BRL: 'R$',
  RUB: '₽',
  TRY: '₺',
  AED: 'AED',
  ARS: '$',
  AUD: 'AU$',
  AZN: 'AZN',
  BGN: '\u043b\u0432',
  BHD: 'BHD',
  CAD: 'C$',
  CHF: 'CHF',
  CLP: '$',
  CNY: '\u00a5',
  COP: '$',
  CZK: 'K\u010d',
  DKK: 'kr',
  DZD: 'DZD',
  EGP: 'EGP',
  HKD: '$',
  HRK: 'HRK',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '\u20aa',
  INR: '\u20b9',
  JOD: 'JOD',
  KRW: '\uc6d0',
  KWD: 'KWD',
  KZT: 'KZT',
  LBP: 'LBP',
  MAD: 'MAD',
  MDL: 'MDL',
  MYR: 'RM',
  NGN: '\u20a6',
  NOK: 'kr',
  NZD: 'NZ$',
  OMR: 'OMR',
  PEN: 'S/.',
  PHP: '\u20b1',
  QAR: 'QAR',
  RON: 'lei',
  RSD: 'RSD',
  SAR: 'SAR',
  SEK: 'kr',
  SGD: 'S$',
  THB: '\u0e3f',
  TND: 'TND',
  TWD: 'NT$',
  UAH: 'UAH',
  UYU: '$',
  VND: '\u0111',
  ZAR: 'R',
}
