export default () => ({
  JLoToken: null,
  studioToken: null,
  organisations: [],
  selectedOrganisation: null,
  userId: '',
  userRole: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  isOnboardingUserDetails: false,
  isOnboardingAssignments: false,
})
