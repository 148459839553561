<template>
  <div v-if="isLoading" class="tw-h-[425px] tw-bg-gray-300 tw-animate-pulse tw-rounded-lg"></div>
  <div v-else-if="isError" class="tw-h-[425px]"></div>
  <table v-else class="tw-border-0">
    <thead class="tw-border tw-rounded-t tw-border-gray-300 tw-bg-gray-100">
      <tr class="tw-border-b tw-border-gray-300">
        <th class="tw-border-0 tw-px-5 tw-py-2 tw-font-normal" :colspan="3">
          <div class="tw-flex tw-items-baseline tw-justify-between tw-gap-2">
            <span
              ><strong class="tw-mr-1">{{ $t('vp_timeframe') }} </strong
              ><span>{{ $t(selectedTimeFrameLabel) }}</span></span
            >
            <small>{{ $t('vp_table_graph_info') }}</small>
          </div>
        </th>
      </tr>
      <tr class="tw-border-0 tw-text-center">
        <th
          v-for="([colKey, colName], index) in Object.entries(columns)"
          :key="colKey"
          class="tw-border-0 tw-font-normal"
          :style="{ width: getWidth(index) }"
        >
          {{ $t(colName) }}
        </th>
      </tr>
    </thead>
    <tbody class="tw-text-center">
      <tr v-for="item in tableData" :key="item.rank">
        <td v-for="(cell, index) in Object.values(item)" :key="cell" :class="index === 1 ? 'tw-text-left' : ''">
          {{ item.share === cell ? getCellValue(cell) : cell }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const props = defineProps({
  selectedTimeFrame: {
    type: String,
    required: true,
    default: '30',
  },
  visitorsProfileCountries: Object,
  isLoading: Boolean,
  isError: Boolean,
})

const columns = {
  rank: 'vp_graph_ranking',
  country: 'vp_graph_country',
  share: 'vp_graph_click',
}

const timeFrameOptions = [
  { label: 'vp_last_30_days', value: '30' },
  { label: 'last_60_days', value: '60' },
  { label: 'vp_last_90_days', value: '90' },
]

const language = computed(() => store.state.locale.language)
const selectedTimeFrameLabel = computed(
  () => timeFrameOptions.find(option => option.value === props.selectedTimeFrame)?.label
)

const getWidth = index => {
  if (index === 0) return '20%'
  if (index === 1) return '30%'
  if (index === 2) return '50%'
}

const tableData = computed(() => {
  const data = !props.isLoading ? [...props.visitorsProfileCountries] : []
  let table = data.map(({ locale, share }, index) => ({
    rank: index + 1,
    country: locale,
    share: share,
  }))

  return table
})

const getCellValue = cell => {
  if (cell < 1 && cell !== 0) {
    return `<${new Intl.NumberFormat(language.value, { style: 'percent' }).format(1 / 100)}`
  } else {
    return new Intl.NumberFormat(language.value, { style: 'percent' }).format(cell / 100)
  }
}
</script>
