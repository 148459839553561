<template>
  <div class="tw-flex tw-items-center tw-mb-5">
    <BaseIcon :height="24" :width="24" class="tw-mr-1" icon-name="tri-icon">
      <TriIcon />
    </BaseIcon>
    <h4 class="tw-text-xl tw-font-bold">trivago Rating Index</h4>
  </div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon.vue'
import TriIcon from '@/components/icons/TriIcon.vue'

export default {
  components: {
    BaseIcon,
    TriIcon,
  },
}
</script>
