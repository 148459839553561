<template>
  <div class="tw-hidden sm:tw-flex tw-relative" :key="selectedAccommodationId">
    <LaunchpadLoader v-if="initialDataLoading" class="tw-w-full tw-flex tw-justify-center tw-items-center tw-flex-1" />
    <div v-else-if="isRequiredDataError" class="tw-flex tw-justify-center tw-items-center tw-flex-1">
      <ErrorModal
        :allow-retry="false"
        :app-name="'Rate Insights'"
        :use-app-name="true"
        header-key="onboarding.appLaunchError.header"
        content-key="onboarding.appLaunchError.text"
        retry-key="onboarding.appLaunchError.retryButton"
      />
    </div>
    <router-view v-else />
    <FullScreenLoader v-show="isRequiredDataFetching" />
  </div>
</template>
<script setup>
import { ref, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import {
  useRateDefaults,
  useRateFilters,
  useSelectedBookingSites,
  useCompetitors,
} from '@/components/rateInsights/queries'
import { useStudioPlusCampaign } from '@/components/payment/queries'
import { selectedAccommodationId } from '@/layouts/queries'

import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import ErrorModal from '@/components/dashboard/ErrorModal.vue'

const router = useRouter()
const route = useRoute()

const { isDefaultsLoading, isDefaultsFetching, isDefaultsError } = useRateDefaults()
const { isFiltersLoading, isFiltersFetching, isFiltersError } = useRateFilters()
const { isSelectedChannelsLoading, isSelectedChannelsFetching } = useSelectedBookingSites(selectedAccommodationId)
const { isCompetitorsLoading, competitors, isCompetitorsFetching, isCompetitorsError } =
  useCompetitors(selectedAccommodationId)
const {
  studioPlusCampaign,
  isFetching: isStudioPlusCampaignFetching,
  isExpiredSubscription,
  isError: isStudioPlusCampaignError,
} = useStudioPlusCampaign(selectedAccommodationId)

const isRequiredDataFetching = computed(
  () =>
    isStudioPlusCampaignFetching.value ||
    isDefaultsFetching.value ||
    isFiltersFetching.value ||
    isSelectedChannelsFetching.value ||
    isCompetitorsFetching.value
)
const isRequiredDataError = computed(
  () => isStudioPlusCampaignError.value || isDefaultsError.value || isFiltersError.value
)

const initialDataLoading = computed(
  () =>
    isDefaultsLoading.value || isFiltersLoading.value || isSelectedChannelsLoading.value || isCompetitorsLoading.value
)

const hasCompetitors = computed(
  () => competitors?.value.length > 0 && competitors.value !== undefined && !isCompetitorsError.value
)
const isMobile = document.body.clientWidth >= 500 ? ref(false) : ref(true)

const isUserAllowedAccess = computed(() => {
  if (studioPlusCampaign.value && !isExpiredSubscription.value && !isMobile.value) return true
  return false
})

const redirectFn = () => {
  if (
    (route.name === 'rate-insights' && !hasCompetitors.value) ||
    (route.name === 'rate-insights-view' && !hasCompetitors.value)
  ) {
    router.replace('/rate-insights/onboarding')
  } else if (
    (route.name === 'rate-insights' && hasCompetitors.value) ||
    (route.name === 'rate-insights-onboarding' && hasCompetitors.value)
  ) {
    router.replace('/rate-insights/view')
  }
}

watch(isRequiredDataFetching, newVal => {
  if (newVal === false && !isUserAllowedAccess.value && !initialDataLoading.value) {
    router.replace('/')
  } else {
    redirectFn()
  }
})

watch(selectedAccommodationId, newSelectedAccommodationId => {
  if (newSelectedAccommodationId && !isUserAllowedAccess.value && !initialDataLoading.value) {
    router.replace('/')
  } else {
    redirectFn()
  }
})

watch(
  route,
  () => {
    if (isUserAllowedAccess.value && !initialDataLoading.value && !isRequiredDataFetching.value) {
      redirectFn()
    }
  },
  { flush: 'pre', immediate: true, deep: true }
)
</script>
