<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.99984 1.66699C11.4978 1.66699 14.3332 4.50233 14.3332 8.00033C14.3332 11.4983 11.4978 14.3337 7.99984 14.3337C4.50184 14.3337 1.6665 11.4983 1.6665 8.00033C1.6665 4.50233 4.50184 1.66699 7.99984 1.66699Z"
    stroke-linecap="round"
  />
  <path d="M7.99984 7.33398V11.334" stroke-width="2" stroke-linecap="round" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.66634 4.66667C8.66634 5.03467 8.36767 5.33333 7.99967 5.33333C7.63167 5.33333 7.33301 5.03467 7.33301 4.66667C7.33301 4.29867 7.63167 4 7.99967 4C8.36767 4 8.66634 4.29867 8.66634 4.66667"
  />
  <path
    d="M8.66634 4.66667C8.66634 5.03467 8.36767 5.33333 7.99967 5.33333C7.63167 5.33333 7.33301 5.03467 7.33301 4.66667C7.33301 4.29867 7.63167 4 7.99967 4C8.36767 4 8.66634 4.29867 8.66634 4.66667"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.99984 1.66699C11.4978 1.66699 14.3332 4.50233 14.3332 8.00033C14.3332 11.4983 11.4978 14.3337 7.99984 14.3337C4.50184 14.3337 1.6665 11.4983 1.6665 8.00033C1.6665 4.50233 4.50184 1.66699 7.99984 1.66699Z"
    stroke-linecap="round"
  />
  <path d="M7.99984 7.33398V11.334" stroke-linecap="round" />
</template>
