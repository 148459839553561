<template>
  <section class="tw-py-14">
    <div
      class="lg:tw-max-w-screen-landing md:tw-max-w-screen-md tw-px-4 sm:tw-px-8 lg:tw-px-0 tw-mx-auto tw-flex tw-w-full tw-flex-col"
    >
      <div class="tw-flex tw-flex-col">
        <h2 class="tw-font-bold tw-text-2xl tw-mb-8 tw-text-center sm:tw-text-left">{{ $t('tbs_testimonials') }}</h2>

        <!-- Web Version -->
        <div class="tw-relative tw-hidden lg:tw-flex">
          <div class="tw-w-screen-landing tw-overflow-hidden tw-h-[330px]">
            <transition-group name="slider" tag="div">
              <div
                class="card tw-inline-flex tw-w-1/2 tw-bg-gray-100 tw-items-start"
                v-for="(review, index) in reviewsToShow"
                :key="index"
              >
                <img :src="review.image" :alt="review.title" class="tw-w-[180px] tw-h-[230px] tw-object-cover" />
                <div class="tw-flex tw-flex-col tw-mx-4">
                  <p class="tw-text-gray-800 tw-mb-3 tw-text-sm">{{ $t(review.text) }}</p>
                  <p class="tw-text-gray-800 author" v-html="$t(review.author)" />
                </div>
              </div>
            </transition-group>
            <button @click="previous" class="tw-absolute tw-rotate-180 tw-left-[-30px] tw-top-[120px]">
              <BaseIcon icon-name="chevron-right" :height="24" :width="24">
                <ChevronRight />
              </BaseIcon>
            </button>
            <button @click="next" class="tw-absolute tw-right-[-30px] tw-top-[120px]">
              <BaseIcon icon-name="chevron-right" :height="24" :width="24">
                <ChevronRight />
              </BaseIcon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile Version -->
    <div class="lg:tw-hidden tw-flex tw-flex-row tw-relative">
      <transition-group class="carousel tw-w-vw tw-overflow-hidden sm:tw-ml-[50px]" tag="div" :name="transitionName">
        <div
          v-for="review in reviews"
          :key="`key_${review.id}`"
          class="slide card tw-inline-flex tw-bg-gray-100 tw-items-start tw-mx-4"
        >
          <img
            :src="review.image"
            :alt="review.title"
            class="tw-w-[92px] sm:tw-w-[180px] tw-h-[230px] tw-object-cover"
          />
          <div class="tw-flex tw-flex-col tw-ml-8">
            <p class="tw-text-gray-800 tw-mb-3 tw-text-sm sm:tw-text-base">{{ $t(review.text) }}</p>
            <p class="tw-text-gray-800 author" v-html="$t(review.author)" />
          </div>
        </div>
      </transition-group>
    </div>

    <div class="tw-flex lg:tw-hidden tw-flex-row tw-items-center tw-justify-center tw-w-full tw-mt-8">
      <button
        v-for="(_, index) in reviews"
        :key="index"
        class="tw-w-[12px] tw-mx-[5px] tw-h-[12px] tw-rounded-full tw-mb-6"
        :class="current === index ? 'tw-bg-blue-800' : 'tw-bg-gray-300'"
      />
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'

import TouchEvent from '@/utils/touchEvents.js'

const review_slider_1 = new URL('/src/assets/img/rate-connect-landing/Testimonial_slider_image1.webp', import.meta.url)
const review_slider_2 = new URL('/src/assets/img/rate-connect-landing/Testimonial_slider_image2.webp', import.meta.url)
const review_slider_3 = new URL('/src/assets/img/rate-connect-landing/Testimonial_slider_image3.webp', import.meta.url)
const review_slider_4 = new URL('/src/assets/img/rate-connect-landing/Testimonial_slider_image4.webp', import.meta.url)
const review_slider_5 = new URL('/src/assets/img/rate-connect-landing/Testimonial_slider_image5.webp', import.meta.url)
const review_slider_6 = new URL('/src/assets/img/rate-connect-landing/Testimonial_slider_image6.jpeg', import.meta.url)

const reviews = ref([
  {
    image: review_slider_1,
    id: 0,
    text: 'rc_mp_testimonial_1_text',
    author: 'rc_mp_testimonial_1_hotelier',
  },
  {
    image: review_slider_2,
    id: 1,
    text: 'rc_mp_testimonial_2_text',
    author: 'rc_mp_testimonial_2_hotelier',
  },
  {
    image: review_slider_3,
    id: 2,
    text: 'rc_mp_testimonial_3_text',
    author: 'rc_mp_testimonial_3_hotelier',
  },
  {
    image: review_slider_4,
    id: 3,
    text: 'rc_mp_testimonial_4_text',
    author: 'rc_mp_testimonial_4_hotelier',
  },
  {
    image: review_slider_5,
    id: 4,
    text: 'rc_mp_testimonial_5_text',
    author: 'rc_mp_testimonial_5_hotelier',
  },
  {
    image: review_slider_6,
    id: 5,
    text: 'rc_mp_testimonial_6_text',
    author: 'rc_mp_testimonial_6_header',
  },
])
const reviewsToShow = ref([])
const touchEvent = ref(null)
const current = ref(null)
const timer = ref('')

const next = () => {
  const lastShownReview = reviews.value.find(item => item.id === reviewsToShow.value[1].id)
  const lastReviewIndex = reviews.value.findIndex(item => item.id === lastShownReview.id)
  if (lastReviewIndex === reviews.value.length - 1) {
    reviewsToShow.value = [reviews.value[lastReviewIndex], reviews.value[0]]
  } else {
    reviewsToShow.value = [reviews.value[lastReviewIndex], reviews.value[lastReviewIndex + 1]]
  }
}

const previous = () => {
  const firstShownReview = reviews.value.find(item => item.id === reviewsToShow.value[0].id)
  const firstReviewIndex = reviews.value.findIndex(item => item.id === firstShownReview.id)
  if (firstReviewIndex === 0) {
    reviewsToShow.value = [reviews.value[reviews.value.length - 1], reviews.value[0]]
  } else {
    reviewsToShow.value = [reviews.value[firstReviewIndex - 1], reviews.value[firstReviewIndex]]
  }
}

onMounted(() => {
  autoplay()
  reviewsToShow.value = [reviews.value[0], reviews.value[1]]
  document.addEventListener('touchstart', event => {
    touchEvent.value = new TouchEvent(event)
  })

  document.addEventListener('touchend', handleSwipe)
})

const nextMobile = () => {
  if (current.value === null) {
    current.value = 0
  } else {
    current.value = current.value === 5 ? 0 : current.value + 1
    const first = reviews.value.shift()
    reviews.value = reviews.value.concat(first)
  }
}
const previousMobile = () => {
  const last = reviews.value.pop()
  current.value = current.value === 0 ? 5 : current.value - 1
  reviews.value = [last].concat(reviews.value)
}

const autoplay = () => {
  nextMobile()
  timer.value = setTimeout(autoplay, 5000)
}

const handleSwipe = event => {
  if (!touchEvent.value) {
    return
  }

  touchEvent.value?.setEndEvent(event)
  if (touchEvent.value?.isSwipeRight()) {
    previousMobile()
  } else if (touchEvent.value?.isSwipeLeft()) {
    nextMobile()
  }
  touchEvent.value = null
}
</script>
<style scoped>
.slider-enter-active,
.slider-leave-active {
  transition: all 1s ease;
}

.slider-enter-from,
.slider-leave-to {
  opacity: 0;
  transform: translateX(-65%);
}

.carousel {
  @apply tw-flex tw-justify-start tw-items-start tw-overflow-hidden;
  width: 100vw;
}

.slide {
  transition: all 0.3s;
  @apply tw-flex tw-justify-center tw-mr-4;
  flex: 0 0 65%;
  @media (max-width: theme('screens.md')) {
    flex: 0 0 92%;
  }
}
.author :deep(p:first-child) {
  font-size: 18px;
}
</style>
