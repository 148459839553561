<template>
  <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-w-full md:tw-justify-between tw-gap-4 md:tw-gap-10">
    <div class="tw-grow">
      <h3 class="tw-text-xl tw-font-bold tw-mb-6 md:tw-mb-8">{{ $t('rc_budget_input_header') }}</h3>
      <div class="tw-flex tw-justify-center tw-gap-5">
        <CurrencyBudget
          id="budget-input-modify"
          aria-label="Budget"
          :currency="currency"
          :budget="inputBudget"
          :default-budget="initialBudget"
          :min-budget="minBudget"
          :status="status"
          @onChange="updateInputBudget"
        />
        <MainButton
          size="medium"
          :disabled="initialBudget === inputBudget || inputBudget < minBudget"
          @click="onNewBudgetConfirm"
          >{{ $t('rc_budget_input_cta') }}</MainButton
        >
      </div>
      <BudgetStatusInfo
        :status="status"
        :min-budget="minBudget"
        :hide-message="initialBudget === inputBudget"
        class="tw-text-center tw-mt-2 tw-pb-2 md:tw-mb-8"
      />
      <BudgetSlider
        v-if="budget && currency && minBudget && maxBudget && minRecommendedBudget && maxRecommendedBudget"
        :budget="inputBudget > maxBudget ? maxBudget : inputBudget"
        :status="status"
        :default-budget="initialBudget"
        :currency="currency"
        :min="minBudget"
        :max="maxBudget"
        :recommended-min="minRecommendedBudget"
        :recommended-max="maxRecommendedBudget"
        class="tw-mt-4 tw-mx-9 md:tw-mx-0"
        @update="updateInputBudget"
      />
    </div>
    <div class="md:tw-max-w-xs">
      <h3 class="tw-text-xl tw-font-bold tw-mb-3">{{ $t('rc_campaign_type_budget_head') }}</h3>
      <p class="tw-text-sm">{{ $t('/snippets/hgw/rc_budget_input_subtext.inc') }}</p>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

import BudgetSlider from '@/components/rateConnect/settings/wizards/BudgetSlider.vue'
import CurrencyBudget from '@/components/rateConnect/settings/wizards/CurrencyBudget.vue'
import BudgetStatusInfo from '@/components/rateConnect/settings/wizards/BudgetStatusInfo.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import { getBudgetStatus } from '@/utils/rateConnectUtils.js'

const props = defineProps({
  minBudget: {
    type: Number,
    default: null,
  },
  maxBudget: {
    type: Number,
    default: null,
  },
  minRecommendedBudget: {
    type: Number,
    default: null,
  },
  maxRecommendedBudget: {
    type: Number,
    default: null,
  },
  budget: {
    type: Object,
    default: null,
  },
  currency: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['onBudgetSave'])

const newBudget = ref(null)

const dailyBudgetRecommendation = computed(() => {
  return {
    budgetMin: Math.round(props.minBudget),
    budgetRecommendedMin: Math.round(props.minRecommendedBudget),
    budgetRecommendedMax: Math.round(props.maxRecommendedBudget),
  }
})
const initialBudget = computed(() => (props.budget?.limit ? Math.round(props.budget.limit / 100) : 0))
const inputBudget = computed(() => newBudget.value ?? initialBudget.value)
const consumedBudget = computed(() => {
  if (!props.budget?.used) return 0
  return props.budget.used / 100
})
const status = computed(() => {
  if (!dailyBudgetRecommendation.value) return ''
  return getBudgetStatus(dailyBudgetRecommendation.value, inputBudget.value, consumedBudget.value)
})

const updateInputBudget = newInput => {
  newBudget.value = newInput
}

const onNewBudgetConfirm = () => {
  emit('onBudgetSave', inputBudget.value)
}
</script>
