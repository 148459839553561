<template>
  <div class="tw-overflow-y-auto tw-overflow-x-hidden tw-flex tw-flex-col">
    <div v-if="!accommodations || !accommodations.length" class="tw-text-gray-500 tw-px-8 tw-py-10">
      <p class="tw-text-center tw-font-bold tw-text-sm">{{ $t('accommodationSwitcher.emptyOrganization') }}</p>
    </div>
    <div v-else>
      <SearchField
        v-if="enableSearch"
        id="search-accommodation-switcher"
        class="tw-border-0 tw-border-b tw-border-gray-200 tw-rounded-none tw-text-sm"
        :placeholder="$t('organization_hotel_search_field')"
        :value="searchFilterValue"
        @onInput="updateSearchFilterValue"
      />
      <ul class="tw-flex-1 tw-overflow-y-auto tw-block tw-max-h-64">
        <li
          v-for="accommodation in filteredAccommodations"
          :key="accommodation.accommodationId"
          :class="listItemClass(accommodation.accommodationId)"
          class="tw-relative tw-flex tw-items-center"
        >
          <label
            :for="accommodation.accommodationId"
            :title="accommodation.name"
            :class="{
              'tw-bg-blue-100 hover:tw-bg-blue-20': accommodation.accommodationId === selectedAccommodationId,
            }"
            tabindex="0"
            class="tw-block tw-m-0 tw-cursor-pointer hover:tw-bg-blue-100 tw-px-4 tw-py-2 focus:tw-outline-none tw-leading-4 tw-flex-1 tw-max-w-full tw-h-12"
            :data-testid="`accommodation-switcher-${accommodation.accommodationId}`"
          >
            <div class="tw-flex tw-items-center">
              <AccommodationImage
                :image="accommodation.mainImageUrls"
                class="tw-min-h-full tw-h-6 tw-w-6 tw-shrink-0 tw-mr-4"
              />
              <div class="tw-flex tw-items-start tw-flex-col tw-justify-start tw-grow tw-truncate">
                <p class="tw-text-sm tw-truncate tw-w-full">{{ accommodation.name }}</p>
                <p class="tw-text-xxs tw-w-full tw-truncate tw-text-gray-700">
                  <strong>ID:</strong> <span>{{ accommodation.accommodationId }}</span>
                </p>
              </div>
            </div>
            <input
              :id="accommodation.accommodationId"
              :value="accommodation.accommodationId"
              :checked="accommodation.accommodationId === selectedAccommodationId"
              type="radio"
              name="accommodation-switcher"
              class="tw-invisible tw-hidden"
              @input="selectAccommodation(accommodation)"
            />
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

import AccommodationImage from '@/components/dashboard/accommodation/AccommodationImage.vue'
import SearchField from '@/components/base/inputs/SearchField.vue'
import { selectedAccommodationId } from '@/layouts/queries'

const props = defineProps({
  accommodations: {
    type: Array,
    required: true,
  },
  enableSearch: {
    type: Boolean,
    required: false,
  },
})
const emit = defineEmits(['accommodationSelected'])

const searchFilterValue = ref('')

const filteredAccommodations = computed(() => {
  if (!props.enableSearch || !searchFilterValue.value) return props.accommodations
  return props.accommodations?.filter(
    ({ name, id }) =>
      name.toLowerCase()?.includes(searchFilterValue.value?.toLowerCase()) ||
      id?.toString()?.includes(searchFilterValue.value)
  )
})

const updateSearchFilterValue = e => (searchFilterValue.value = e)

const selectAccommodation = async accommodation => {
  emit('accommodationSelected', accommodation)
}

const listItemClass = accommodationId => {
  return accommodationId === selectedAccommodationId.value ? 'tw-order-first tw-font-bold' : 'accommodation-list-item'
}
</script>
