<template>
  <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3333 8.83333V6.33333C7.86666 6.33333 6.475 6.7 4.66666 9.66667C4.66666 9.66667 4.83333 6.275 7.74166 4.66667C8.64166 4.16667 9.81666 3.83333 11.3333 3.83333V1.33333L15.5 5.08333L11.3333 8.83333ZM16.025 4.44167L11.8583 0.691667C11.7083 0.566667 11.525 0.5 11.3333 0.5C11.2083 0.5 11.0917 0.525 10.975 0.583333C10.6833 0.716667 10.5 1.00833 10.5 1.33333V3.03333C9.30833 3.125 8.33333 3.41667 7.53333 3.83333C7.05833 4.075 6.65 4.35833 6.29166 4.66667C4.01666 6.60833 3.84166 9.58333 3.83333 9.625C3.81666 10.0083 4.05833 10.35 4.425 10.4667C4.5 10.4917 4.58333 10.5 4.66666 10.5C4.95 10.5 5.225 10.35 5.375 10.1C6.81666 7.74167 7.81666 7.24167 10.5 7.175V8.83333C10.5 9.15833 10.6833 9.45 10.975 9.58333C11.0917 9.64167 11.2083 9.66667 11.3333 9.66667C11.525 9.66667 11.7083 9.6 11.8583 9.475L16.025 5.725C16.225 5.56667 16.3333 5.33333 16.3333 5.08333C16.3333 4.83333 16.225 4.6 16.025 4.44167Z"
      fill="#171717"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 13.0002C13 13.4593 12.6258 13.8335 12.1667 13.8335H2.16667C1.7075 13.8335 1.33333 13.4593 1.33333 13.0002V5.50016C1.33333 5.041 1.7075 4.66683 2.16667 4.66683H5.11C5.3075 4.451 5.5125 4.236 5.7475 4.03516C5.8325 3.96266 5.925 3.90183 6.0125 3.8335H2.16667C1.24667 3.8335 0.5 4.58016 0.5 5.50016V13.0002C0.5 13.9202 1.24667 14.6668 2.16667 14.6668H12.1667C13.0867 14.6668 13.8333 13.9202 13.8333 13.0002V8.81766L13 9.56766V13.0002Z"
      fill="#171717"
    />
  </svg>
</template>
