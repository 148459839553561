<template>
  <div>
    <ToastNotifications />
    <NavBar v-if="isSessionSuccess" />
    <div
      class="tw-mx-auto tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-pt-nav-h tw-min-h-screen-minus-footer tw-flex tw-justify-center tw-items-center"
    >
      <div v-if="isSessionLoading" class="tw-flex tw-justify-center tw-items-center">
        <LaunchpadLoader />
      </div>
      <div v-else-if="isSessionSuccess" class="tw-w-full">
        <slot />
      </div>
      <div v-else>
        <ErrorModal
          header-key="dashboard.loadFail.header.launch"
          retry-key="dashboard.loadFail.retryButton"
          @retry="handleRetry"
        />
      </div>
    </div>
    <ThePageFooter />
    <TheCookieNotice />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import ThePageFooter from '@/components/page/ThePageFooter.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import TheCookieNotice from '@/components/legal/TheCookieNotice.vue'
import ToastNotifications from '@/components/notifications/ToastNotifications.vue'
import ErrorModal from '@/components/dashboard/ErrorModal.vue'
import NavBar from '@/components/dashboard/nav/NavBar.vue'

const store = useStore()

// Loading indicators
const isSessionLoading = computed(() => store.state.session.isLoading)
const isSessionSuccess = computed(() => store.state.session.isSuccess)

// Other functionality
const handleRetry = () => {
  store.dispatch('session/restoreStudioSession')
}
</script>
