<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7 4H5C4.448 4 4 4.448 4 5V7C4 7.552 4.448 8 5 8H7C7.552 8 8 7.552 8 7V5C8 4.448 7.552 4 7 4ZM5 7H7V5H5V7Z"
    fill="#0A1121"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13 4H11C10.448 4 10 4.448 10 5V7C10 7.552 10.448 8 11 8H13C13.552 8 14 7.552 14 7V5C14 4.448 13.552 4 13 4ZM11 7H13V5H11V7Z"
    fill="#0A1121"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M19 4H17C16.448 4 16 4.448 16 5V7C16 7.552 16.448 8 17 8H19C19.552 8 20 7.552 20 7V5C20 4.448 19.552 4 19 4ZM17 7H19V5H17V7Z"
    fill="#0A1121"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7 10H5C4.448 10 4 10.448 4 11V13C4 13.552 4.448 14 5 14H7C7.552 14 8 13.552 8 13V11C8 10.448 7.552 10 7 10ZM5 13H7V11H5V13Z"
    fill="#0A1121"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13 10H11C10.448 10 10 10.448 10 11V13C10 13.552 10.448 14 11 14H13C13.552 14 14 13.552 14 13V11C14 10.448 13.552 10 13 10ZM11 13H13V11H11V13Z"
    fill="#0A1121"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M19 10H17C16.448 10 16 10.448 16 11V13C16 13.552 16.448 14 17 14H19C19.552 14 20 13.552 20 13V11C20 10.448 19.552 10 19 10ZM17 13H19V11H17V13Z"
    fill="#0A1121"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7 16H5C4.448 16 4 16.448 4 17V19C4 19.552 4.448 20 5 20H7C7.552 20 8 19.552 8 19V17C8 16.448 7.552 16 7 16ZM5 19H7V17H5V19Z"
    fill="#0A1121"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13 16H11C10.448 16 10 16.448 10 17V19C10 19.552 10.448 20 11 20H13C13.552 20 14 19.552 14 19V17C14 16.448 13.552 16 13 16ZM11 19H13V17H11V19Z"
    fill="#0A1121"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M19 16H17C16.448 16 16 16.448 16 17V19C16 19.552 16.448 20 17 20H19C19.552 20 20 19.552 20 19V17C20 16.448 19.552 16 19 16ZM17 19H19V17H17V19Z"
    fill="#0A1121"
  />
</template>
