<template>
  <Notification
    :description="$t('notification_ams_message')"
    :header="$t('notification_ams_heading')"
    v-bind="$attrs"
    :severity="''"
  >
    <template #ctaContent>
      <div class="tw-mt-3 md:tw-mt-0 md:tw-mr-9">
        <MainButton
          size="medium"
          class="tw-w-full"
          :disabled="isFetchingRequiredData || isUpdatingCampaign"
          @click="onUpdateAutomatedMarkets"
        >
          <RCInlineLoader v-if="isFetchingRequiredData || isUpdatingCampaign" color="#fff" />
          <span v-else>{{ $t('action_activate_ams') }}</span>
        </MainButton>
      </div>
    </template>
  </Notification>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import Notification from './Notification.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import { usePartners, useMarkets, useCampaignsMutation } from '@/components/rateConnect/queries'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const language = computed(() => store.state.locale?.language)
const { displayNotification } = useToastNotifications()

const { isFetching: isPartnersFetching, currentPartner } = usePartners(selectedAccommodationId)
const { isFetching: isMarketsFetching } = useMarkets(selectedAccommodationId, language, currentPartner)
const { updateCampaign, isUpdatingCampaign } = useCampaignsMutation()

const isFetchingRequiredData = computed(() => isPartnersFetching.value || isMarketsFetching.value)

const onUpdateAutomatedMarkets = () => {
  updateCampaign(
    {
      accommodationId: selectedAccommodationId.value,
      body: { overall: { useAutomatedMarkets: true } },
    },
    {
      onSuccess: () => {
        displayNotification({
          message: 'rc_markets_auto_success_message',
          isTranslationKey: true,
          type: 'success',
        })
      },
      onError: () => {
        displayNotification({
          message: 'save_error',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}
</script>
