import { reactive, toRefs } from 'vue'

const state = reactive({
  isUpdating: false,
  isUploading: false,
  selectedItemIds: [],
  deleteItemId: [],
  loadingItemIds: null,
  uploadingImages: [],
})

export function usePropertyImages() {
  const setUpdatedImage = itemId => {
    state.isUpdating = itemId
  }

  const setDeletingIds = value => {
    state.deleteItemId = value
  }

  const resetDeletingIds = () => {
    state.deleteItemId = []
  }

  const selectItem = itemId => {
    const itemIndex = state.selectedItemIds.indexOf(itemId)
    const currentSelectedItems = [...state.selectedItemIds]
    if (itemIndex >= 0) {
      currentSelectedItems.splice(itemIndex, 1)
      state.selectedItemIds = currentSelectedItems
    } else {
      state.selectedItemIds = [...state.selectedItemIds, itemId]
    }
  }

  const selectAll = (value, items) => {
    const allIds = []
    const itemsToSelect = items
    itemsToSelect.map(item => {
      if (item.customProperties.isDeletable && !item.isMainImage) {
        allIds.push(item.imageId)
      }
    })
    if (!value) {
      state.selectedItemIds = []
    } else {
      state.selectedItemIds = state.selectedItemIds.length === 0 ? allIds : [...state.selectedItemIds, ...allIds]
    }
  }

  const resetSelectedIds = () => {
    state.selectedItemIds = []
  }

  const setUploading = uploading => {
    state.isUploading = uploading
  }

  const setUploadingImages = item => {
    const currentUpload = state.uploadingImages.length > 0 ? [...state.uploadingImages] : []
    currentUpload.push(item)
    state.uploadingImages = currentUpload
  }

  const removeUploadingImages = () => {
    state.uploadingImages = []
  }

  return {
    ...toRefs(state),
    setUpdatedImage,
    setDeletingIds,
    resetDeletingIds,
    selectItem,
    selectAll,
    resetSelectedIds,
    setUploading,
    setUploadingImages,
    removeUploadingImages,
  }
}
