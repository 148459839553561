<template>
  <div class="legend-skeleton tw-animate-pulse">
    <div class="tw-rounded tw-bg-gray-300" />
    <div class="tw-rounded sm:tw-ml-4 tw-bg-gray-300" />
    <div class="tw-rounded sm:tw-ml-4 tw-bg-gray-300" />
  </div>
</template>

<style scoped>
.legend-skeleton > div {
  display: inline-block;
  height: 24px;
  width: 250px;
}
</style>
