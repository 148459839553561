<template>
  <MainButton class="tw-w-full" @click="activateFreeLink">
    <transition name="fade" mode="out-in">
      <span v-if="freeLinkActivationStatus === 'pending'" key="1">{{ $t('fbl_activate_cta') }}</span>
      <span v-else-if="freeLinkActivationStatus === 'inProgress'" key="2">
        <span class="tw-mr-2">{{ $t('fbl_link_activating') }}</span>
        <SpinnerLoader class="tw-h-4 tw-w-4" />
      </span>
      <span
        v-else-if="freeLinkActivationStatus === 'success'"
        key="3"
        class="tw-flex tw-gap-2 tw-items-center tw-justify-center"
      >
        <span>{{ $t('fbl_activation_confirmed') }}</span>
        <CheckMarkInCircle class="tw-inline tw-stroke-gray-100" height="20" width="20" />
      </span>
    </transition>
  </MainButton>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useQueryClient } from '@tanstack/vue-query'

import useRateConnect from '@/components/rateConnect/queries'
import { useFreeLinksMutation } from '@/components/rateConnect/queries'
import MainButton from '@/components/base/buttons/MainButton.vue'
import SpinnerLoader from '@/components/base/loaders/SpinnerLoader.vue'
import CheckMarkInCircle from '@/components/icons/CheckMarkInCircle.vue'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'

const { displayNotification } = useToastNotifications()

const queryClient = useQueryClient()
const { hotelierData } = useRateConnect(selectedAccommodationId)
const { requestFreeLinksOptIn } = useFreeLinksMutation()
const freeLinkActivationStatus = ref('pending')

const partnerId = computed(() => +hotelierData?.value?.directRatesPartner)

const activateFreeLink = () => {
  if (freeLinkActivationStatus.value === 'pending') {
    freeLinkActivationStatus.value = 'inProgress'
    requestFreeLinksOptIn(
      { accommodationId: selectedAccommodationId?.value, partnerId: partnerId?.value },
      {
        onSuccess: () => {
          displayNotification({
            message: 'fbl_link_activated_success',
            isTranslationKey: true,
            isHtml: true,
            type: 'success',
          })
          freeLinkActivationStatus.value = 'success'
          setTimeout(() => {
            queryClient.invalidateQueries({ queryKey: ['RCStartUp'] })
          }, 5000)
        },
        onError: () => {
          displayNotification({
            message: 'fbl_link_error_message',
            isTranslationKey: true,
            type: 'error',
          })
          freeLinkActivationStatus.value = 'pending'
        },
      }
    )
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 0.3s;
}

.fade-leave-to,
.fade-enter {
  opacity: 0;
}
</style>
