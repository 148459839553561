<template>
  <Notification
    :description="
      $t('notification_budget_runout_message', {
        month: $d(budgetRenewDate, 'longMonth'),
      })
    "
    :header="$t('notification_budget_runout_heading')"
    v-bind="$attrs"
  >
    <template #ctaContent>
      <div class="tw-mt-3 md:tw-mt-0 tw-shrink-0">
        <MainButton size="medium" class="tw-w-full" @click="navigateToBudget">
          <span>{{ $t('notification_action_update_budget') }}</span>
        </MainButton>
      </div>
    </template>
  </Notification>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import Notification from './Notification.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'

const router = useRouter()

const budgetRenewDate = computed(() => {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth() + 1, 1)
})

const navigateToBudget = () => {
  router.push({ path: 'budget' })
}
</script>
