<template>
  <div>
    <div class="tw-pb-0">
      <AuthMenu isLanding @onRegisterClick="onAuthorize('signup')" />
      <div>
        <LandingPageHero class="hero-padding tw-pt-16" @onLoginClick="onAuthorize('login')" />
        <LandingPageHighlights class="tw-bg-white" />
      </div>
      <LandingPageFeatures id="landing-page-features" class="tw-mt-14" @onRegisterClick="onAuthorize('login')" />
      <LandingPageProducts id="landing-page-products" class="tw-bg-white" />
      <LandingPageJoinBanner id="landing-page-join-banner" class="tw-mt-14" @onRegisterClick="onAuthorize('login')" />
      <LandingPageFaq id="landing-page-products" class="tw-bg-white" />
    </div>
    <ThePageFooter isLanding />
    <TheCookieNotice />
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { useStore } from 'vuex'
import AuthMenu from '@/components/landingPage/AuthMenu.vue'
import LandingPageHero from '@/components/landingPage/LandingPageHero.vue'
import LandingPageHighlights from '@/components/landingPage/LandingPageHighlights.vue'
import LandingPageFeatures from '@/components/landingPage/LandingPageFeatures.vue'
import LandingPageProducts from '@/components/landingPage/LandingPageProducts.vue'
import LandingPageFaq from '@/components/landingPage/LandingPageFaq.vue'
import LandingPageJoinBanner from '@/components/landingPage/LandingPageJoinBanner.vue'
import ThePageFooter from '@/components/page/ThePageFooter.vue'
import TheCookieNotice from '@/components/legal/TheCookieNotice.vue'
import appAnalytics from '@/utils/tracking'

const route = useRoute()
const store = useStore()
const locale = computed(() => store.state.locale)
const authorize = params => store.dispatch('session/authorize', params)

const onAuthorize = async (type = 'login') => {
  appAnalytics.track(type === 'login' ? appAnalytics.events.BUTTON_CLICKED : appAnalytics.events.BUTTON_CLICKED, {
    button: type,
    property_id: null,
  })
  await authorize({
    type,
    stateParams: {
      redirectFrom: route.query.redirectFrom,
    },
    locale: locale.value.language,
  })
}
</script>
<style scoped>
.hero-padding {
  padding-top: 4rem;
  @media (max-width: theme('screens.lg')) {
    padding-top: calc(1rem + 64px);
  }
}
</style>
