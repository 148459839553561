<template>
  <CustomModal
    class="delete-language-modal"
    :is-modal-open="isOpen"
    @toggleModal="closeDelete"
    @onClickAway="closeDelete"
    :overlay="'dark'"
    :with-header="true"
    :with-footer="true"
    :custom-classes="'md:tw-max-w-[500px] md:tw-w-[500px] md:tw-h-auto tw-h-[100vh] tw-w-[100vw] md:tw-rounded-md tw-rounded-none'"
  >
    <template #modalHeader>
      <p class="tw-text-xl tw-text-gray-700">{{ $t('app_images_actions_delete') }}</p>
    </template>

    <div class="tw-mt-2 tw-flex tw-py-11">
      {{ $t('app_hotel_details_delete_confirmation') }}
    </div>

    <template #modalFooter>
      <div class="tw-w-full tw-flex tw-justify-end">
        <GhostButton size="medium" class="tw-px-9 tw-mr-2" @click="closeDelete">{{
          $t('app_hotel_details_cancel_delete_language')
        }}</GhostButton>
        <DangerButton size="medium" class="tw-px-9" @click="emit('deleteLanguage', languageCode)">
          <span v-if="!isDeleting">{{ $t('app_hotel_details_delete_language') }}</span>
          <span v-if="isDeleting">
            <RCInlineLoader color="#fff" />
          </span>
        </DangerButton>
      </div>
    </template>
  </CustomModal>
</template>
<script setup>
import CustomModal from '@/components/base/CustomModal.vue'
import DangerButton from '@/components/base/buttons/DangerButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'

defineProps({
  languageCode: String,
  language: String,
  isOpen: Boolean,
  isDeleting: Boolean,
})

const emit = defineEmits(['deleteLanguage', 'closeDelete'])
const closeDelete = () => emit('closeDelete')
</script>
