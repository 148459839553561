<template>
  <BaseCard noPadding>
    <div class="tw-flex tw-justify-between tw-flex-col lg:tw-flex-row">
      <div class="tw-flex tw-flex-col tw-p-6 md:tw-p-12">
        <p class="tw-font-bold tw-text-xl tw-mb-6 tw-text-center lg:tw-text-left">
          {{ $t('studio_plus_subscription_recap_header_pro') }}
        </p>
        <div class="lg:tw-hidden tw-flex tw-justify-center tw-py-6">
          <img :src="ProBanner" alt="Studio PRO Subscription" height="175" width="220" />
        </div>
        <ul class="tw-hidden lg:tw-flex tw-flex-col tw-mx-auto lg:tw-mx-0">
          <li
            v-for="(item, index) in benefitsList"
            :key="index"
            class="tw-flex tw-items-center tw-py-1 first:tw-pt-0 last:tw-pb-0"
          >
            <BaseIcon :height="24" :width="24" icon-name="checkmark-blue" class="tw-text-gray-800 tw-stroke-gray-800">
              <CheckmarkBlue />
            </BaseIcon>
            <span class="lg:tw-pl-2 tw-pl-4" v-html="$t(item)" />
          </li>
        </ul>
        <ul class="lg:tw-hidden tw-flex tw-flex-col tw-mx-auto lg:tw-mx-0 lg: lg:tw-ml-4">
          <li
            v-for="(item, index) in benefitsListMobile"
            :key="index"
            class="tw-flex tw-items-center tw-py-1 first:tw-pt-0 last:tw-pb-0"
          >
            <BaseIcon :height="24" :width="24" icon-name="checkmark-blue" class="tw-text-gray-800 tw-min-w-[24px]">
              <CheckmarkBlue />
            </BaseIcon>
            <span class="lg:tw-pl-2 tw-pl-4" v-html="$t(item)" />
          </li>
        </ul>
      </div>
      <div class="tw-hidden lg:tw-w-[380px] lg:tw-flex tw-justify-start tw-bg-blue-500 tw-rounded-r-lg tw-relative">
        <img :src="ProBanner" alt="Studio PRO Subscription" height="175" width="220" class="tw-ml-[-100px]" />
      </div>
    </div>
  </BaseCard>
</template>
<script setup>
import BaseCard from '@/components/dashboard/BaseCard.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import CheckmarkBlue from '@/components/icons/CheckmarkBlue.vue'

const ProBanner = new URL('/src//assets/img/pro/pro_banner.svg', import.meta.url)

const benefitsList = [
  'pro_subscription_recap_point_1',
  'pro_subscription_recap_point_2',
  'pro_subscription_recap_point_3',
]

const benefitsListMobile = [
  'pro_subscription_recap_point_1',
  'pro_subscription_recap_point_2_mobile',
  'pro_subscription_recap_point_3_mobile',
]
</script>
<style scoped>
.shadow {
  box-shadow: 0px 2px 10px 0px #6c707a26;
  box-shadow: 0px 2px 4px 0px #3f57bd1a;
}
li :deep(a) {
  color: theme('colors.blue.700');
  text-decoration: underline;
}
</style>
