<template>
  <div
    class="tw-opacity-50 hover:tw-cursor-pointer tw-flex tw-w-full tw-py-2 tw-border-b tw-border-gray-300 last:tw-border-none tw-items-center tw-justify-between"
  >
    <div class="tw-flex tw-w-full tw-animate-pulse">
      <div class="tw-h-[64px] tw-overflow-hidden tw-w-[64px] tw-rounded tw-mr-3 tw-bg-gray-300" />
      <div>
        <p class="tw-font-bold tw-text-sm">{{ $t(competitorNames[competitorNumber]) }}</p>
        <div class="tw-bg-gray-300 tw-rounded-sm tw-w-[100px] tw-block tw-h-[15px] tw-mt-1"></div>
        <div class="tw-bg-gray-300 tw-rounded-sm tw-w-[80px] tw-block tw-h-[15px] tw-mt-1"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
defineProps({
  competitorNumber: Number,
})

const competitorNames = {
  1: 'competitor_one',
  2: 'competitor_two',
  3: 'competitor_three',
  4: 'competitor_four',
  5: 'competitor_five',
}
</script>
