<template>
  <div :class="{ 'tw-w-full': fullwidth }" :data-testid="dataTestId">
    <p class="tw-font-bold tw-mb-1" v-if="label">
      {{ label }}<span v-if="required" class="tw-text-red-700">{{ '*' }}</span>
    </p>
    <div
      class="tw-relative tw-inline-block tw-cursor-pointer select-container tw-w-full"
      :class="{ 'tw-pointer-events-none tw-opacity-70': isDisabled }"
    >
      <select
        :id="id"
        :name="name"
        :value="value"
        class="tw-appearance-none tw-cursor-pointer tw-border tw-rounded-lg tw-border-solid tw-w-full focus:tw-outline-0 focus:tw-border-blue-800 focus:tw-text-blue-800"
        v-bind="$attrs"
        :class="error ? `${getStyles()} tw-bg-red-200 tw-border-red-700` : `${getStyles()} tw-border-gray-500`"
        @blur="onBlur"
        @change="onChange"
        :data-testid="selectTestId"
      >
        <option v-if="placeholder && value === null" value="" disabled selected>{{ placeholder }}</option>
        <template v-if="!groupedOptions">
          <option
            v-for="option in sortedOptions"
            :key="option[valueKey]"
            :value="option[valueKey]"
            :class="{ 'tw-text-gray-700': version === 'dark' }"
          >
            {{ labelRequiresTranslation ? $t(option[displayTextKey]) : option[displayTextKey] }}
          </option>
        </template>
        <template v-else v-for="option in options">
          <optgroup v-if="option.group" :label="$t(option.group.label)" :key="option.group.label">
            <option
              v-for="opt in option.options"
              :key="opt[valueKey]"
              :value="`${option.group.value} - ${opt['id']}`"
              :class="{ 'tw-text-gray-700': version === 'dark' }"
            >
              {{ labelRequiresTranslation ? $t(opt[displayTextKey]) : opt[displayTextKey] }}
            </option>
          </optgroup>
        </template>
      </select>
      <BaseIcon
        v-if="version !== 'transparent'"
        icon-name="chevron-right"
        :height="24"
        :width="24"
        :viewbox-height="24"
        :viewbox-width="24"
        :icon-color="error ? '#DB3734' : '#6C707A'"
        class="tw-absolute arrow-dropdown"
      >
        <ChevronRight />
      </BaseIcon>
    </div>
    <small v-if="error" class="tw-text-red-700" :data-testid="errorTestId">{{ error }}</small>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import BaseIcon from '@/components/BaseIcon.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'

const emit = defineEmits(['onChange', 'onBlur'])
const onChange = e => emit('onChange', e.target.value)
const onBlur = e => emit('onBlur', e.target.value)

const { t } = useI18n()
const props = defineProps({
  placeholder: {
    type: String,
    required: false,
  },
  fullwidth: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: 'select',
  },
  name: {
    type: String,
    default: 'select',
  },
  options: {
    type: Array,
    default: () => [],
  },
  groupedOptions: {
    type: Boolean,
    default: false,
  },
  value: {
    type: [String, Number],
    default: '',
  },
  valueKey: {
    type: String,
    default: 'value',
  },
  displayTextKey: {
    type: String,
    default: 'displayTextKey',
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: null,
  },
  error: {
    type: [Boolean, String],
    default: false,
  },
  version: {
    type: String,
    default: 'light',
  },
  size: {
    type: String,
    default: 'medium',
  },
  labelRequiresTranslation: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  requireSorting: {
    type: Boolean,
    default: true,
  },
  dataTestId: {
    type: String,
    default: '',
  },
  selectTestId: {
    type: String,
    default: '',
  },
  errorTestId: {
    type: String,
    default: '',
  },
})
const sizeClass = computed(() => {
  if (props.size === 'medium') return 'tw-pl-3 tw-pr-8 tw-box-size-36 tw-text-sm tw-rounded-lg line-height-180 '
  else return 'tw-pl-4 tw-pr-8 tw-box-size-44 tw-text-base tw-rounded-lg line-height-260'
})
const sortedOptions = computed(() => {
  let sortedOptions = [...props.options]
  if (props.labelRequiresTranslation && props.requireSorting) {
    sortedOptions.sort((a, b) => {
      if (t(a[props.displayTextKey]) < t(b[props.displayTextKey])) {
        return -1
      }
      if (t(a[props.displayTextKey]) > t(b[props.displayTextKey])) {
        return 1
      }
      return 0
    })
  }

  return sortedOptions
})

const getStyles = () => {
  const styles = []
  if (props.version === 'dark') {
    styles.push(
      'tw-bg-transparent tw-text-white tw-border-gray-500 focus:tw-border-gray-500 active:tw-border-gray-500 active:tw-text-white focus:tw-text-white'
    )
  } else if (props.version === 'light') {
    if (props.isDisabled) {
      styles.push('tw-bg-gray-200 tw-text-gray-500 tw-border-gray-300')
    } else {
      styles.push('tw-bg-white tw-border-gray-500 focus:tw-border-gray-500 active:tw-border-gray-500 tw-text-gray-800')
    }
  } else if (props.version === 'transparent') {
    styles.push('tw-bg-transparent tw-border-0')
  }
  styles.push(sizeClass.value)
  return styles.join(',').replace(',', ' ')
}
</script>

<style scoped>
.arrow-dropdown {
  top: 50%;
  transform: translateY(-45%) rotate(90deg);
  right: 6px;
  pointer-events: none;
}
</style>
