<template>
  <section class="tw-py-14">
    <div
      class="lg:tw-max-w-screen-landing md:tw-max-w-screen-md tw-px-4 sm:tw-px-8 lg:tw-px-0 tw-mx-auto tw-flex tw-flex-col"
    >
      <!-- Web & Tablet Version -->
      <div class="tw-hidden md:tw-flex tw-justify-center tw-items-stretch tw-flex-row tw-gap-10 lg:tw-gap-[60px]">
        <img
          src="@/assets/img/rate-connect-landing/rc_hotels.svg"
          alt="trivago business studio hero image"
          class="tw-h-[124px]"
        />
        <div class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-w-2/3">
          <div class="tw-grow">
            <img :src="RateConnectLogo" alt="trivago business studio hero image" class="tw-max-h-[28px] tw-w-auto" />
          </div>
          <div>
            <p class="tw-font-bold tw-text-xl lg:tw-text-2xl">{{ $t('rc_mp_campaign') }}</p>
            <p class="tw-text-xl lg:tw-text-2xl">{{ $t('rc_mp_campaign_info') }}</p>
          </div>
        </div>
      </div>

      <!-- Mobile Version -->
      <div class="md:tw-hidden tw-flex tw-justify-center tw-items-stretch tw-flex-col tw-gap-4">
        <img :src="RateConnectLogo" alt="trivago business studio hero image" class="tw-max-h-[28px] tw-w-auto" />

        <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
          <img
            src="@/assets/img/landing/landing_banner_hotel.svg"
            alt="trivago business studio hero image"
            width="114"
            height="66"
          />

          <div class="tw-ml-4">
            <p class="tw-font-bold">{{ $t('rc_mp_campaign') }}</p>
            <p>{{ $t('rc_mp_campaign_info') }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
const RateConnectLogo = new URL('/src/assets/img/landing/landing_rate_connect.svg', import.meta.url)
</script>
