<template>
  <g id="Homepage-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="tBS-Home-FL-Desktop-DS-Update" transform="translate(-94.000000, -889.000000)">
      <g id="Group-8" transform="translate(94.000000, 888.000000)">
        <g id="Dashboard-24x24-Line" transform="translate(0.000000, 1.000000)">
          <rect id="Rectangle-4-Copy-119" x="0" y="0" width="24" height="24"></rect>
          <g
            id="Page-1"
            transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) translate(2.000000, 2.000000)"
            fill="#0A1121"
          >
            <path
              id="Fill-1"
              d="M10,16 C10.552,16 11,16.448 11,17 C11,17.552 10.552,18 10,18 C9.448,18 9,17.552 9,17 C9,16.448 9.448,16 10,16"
            ></path>
            <path
              id="Fill-3"
              d="M16,13 C16.552,13 17,13.448 17,14 C17,14.552 16.552,15 16,15 C15.448,15 15,14.552 15,14 C15,13.448 15.448,13 16,13"
            ></path>
            <path
              id="Fill-5"
              d="M4,13 C4.552,13 5,13.448 5,14 C5,14.552 4.552,15 4,15 C3.448,15 3,14.552 3,14 C3,13.448 3.448,13 4,13"
            ></path>
            <path
              id="Fill-7"
              d="M10,1 C6.476,1 3.426,3.042 1.95,6 L18.05,6 C16.574,3.042 13.524,1 10,1 M9,7.99 C9,8.012 9.004,8.087 9.021,8.225 C9.098,8.931 9.426,10.013 10,11.453 C10.574,10.012 10.902,8.927 10.98,8.212 C10.996,8.087 11,8.012 11,8 C11,7.449 10.551,7 10,7 C9.449,7 9,7.449 9,7.99 M10,19 C14.962,19 19,14.962 19,10 C19,8.947 18.809,7.94 18.475,7 L18.06,7 L11.74,7 L11.724,7 C11.895,7.295 12,7.633 12,8 C12,8 12,8.113 11.973,8.333 C11.887,9.126 11.513,10.64 10,14 C8.487,10.64 8.113,9.126 8.027,8.333 C8,8.113 8,8 8,8 C8,7.633 8.105,7.295 8.276,7 L8.27,7 L1.94,7 L1.525,7 C1.191,7.94 1,8.947 1,10 C1,14.962 5.038,19 10,19 M10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 C15.523,0 20,4.477 20,10 C20,15.523 15.523,20 10,20"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </g>
</template>
