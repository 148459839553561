<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 1H4C2.9 1 2 1.9 2 3V14C2 15.1 2.9 16 4 16C4.44729 16 4.78062 16 5 16C11.1688 16 15.8355 16 19 16C19.2222 16 19.5556 16 20 16C21.1 16 22 15.1 22 14V3C22 1.9 21.1 1 20 1ZM21 14C21 14.55 20.55 15 20 15L19 16H4L5 15H4C3.45 15 3 14.55 3 14V6H21V14ZM3 5V3C3 2.45 3.45 2 4 2H20C20.55 2 21 2.45 21 3V5H3Z"
      fill="#0A1121"
    />
    <path
      d="M13.5 4H5.5C5.22 4 5 3.78 5 3.5C5 3.22 5.22 3 5.5 3H13.5C13.78 3 14 3.22 14 3.5C14 3.78 13.78 4 13.5 4Z"
      fill="#0A1121"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.8301 8.16987C17.2701 6.60987 14.7301 6.60987 13.1701 8.16987L11.0701 10.2799C11.0201 10.3199 10.9701 10.3699 10.9301 10.4199C10.8801 10.4999 10.8501 10.5999 10.8501 10.6999C10.8501 10.9399 11.0201 11.1399 11.2501 11.1899C11.2801 11.1999 11.3201 11.1999 11.3501 11.1999C11.4801 11.1999 11.6101 11.1399 11.7001 11.0599L13.8801 8.87987C15.0501 7.70987 16.9501 7.70987 18.1201 8.87987C19.2901 10.0499 19.2901 11.9499 18.1201 13.1199L15.2901 15.9499C15.1401 16.0999 14.9801 16.2299 14.8201 16.3399C14.4901 16.5499 14.1401 16.6899 13.7701 16.7699C13.4301 16.8399 13.0701 16.8499 12.7201 16.7999C12.1101 16.6999 11.5201 16.4199 11.0501 15.9499C10.7201 15.6199 10.4801 15.2299 10.3401 14.8099C10.2601 14.6299 10.0801 14.5099 9.8801 14.5099C9.7801 14.5099 9.6901 14.5399 9.6201 14.5799C9.5801 14.6099 9.5401 14.6399 9.5101 14.6699C9.4301 14.7599 9.3801 14.8799 9.3801 15.0099C9.3801 15.0399 9.3801 15.0799 9.3901 15.1099C9.4001 15.1499 9.4101 15.1799 9.4201 15.2199C9.6101 15.7499 9.9201 16.2399 10.3401 16.6599C10.8501 17.1699 11.4701 17.5199 12.1301 17.6899C12.5301 17.7999 12.9401 17.8399 13.3401 17.8199C13.7601 17.8099 14.1801 17.7299 14.5801 17.5799C15.0101 17.4199 15.4101 17.1799 15.7701 16.8699C15.8501 16.8099 18.8301 13.8299 18.8301 13.8299C20.3901 12.2699 20.3901 9.72987 18.8301 8.16987Z"
      fill="#0A1121"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.75 18.81C12.72 18.8 12.68 18.8 12.65 18.8C12.51 18.8 12.38 18.86 12.3 18.95C12.27 18.97 12.25 19 12.23 19.02L10.12 21.12C8.95 22.29 7.05 22.29 5.88 21.12C4.71 19.95 4.71 18.05 5.88 16.88L8.71 14.05C8.86 13.9 9.02 13.77 9.18 13.66C9.51 13.45 9.86 13.31 10.23 13.23C10.57 13.16 10.93 13.15 11.28 13.2C11.89 13.3 12.48 13.58 12.95 14.05C13.28 14.38 13.51 14.77 13.66 15.18C13.74 15.36 13.92 15.49 14.12 15.49C14.22 15.49 14.32 15.46 14.4 15.4C14.43 15.38 14.46 15.36 14.49 15.33C14.57 15.24 14.62 15.12 14.62 14.99C14.62 14.96 14.62 14.92 14.61 14.89C14.6 14.85 14.59 14.82 14.58 14.78C14.39 14.25 14.08 13.76 13.66 13.34C13.15 12.83 12.53 12.48 11.87 12.31C11.47 12.2 11.06 12.16 10.66 12.18C10.24 12.19 9.82 12.27 9.42 12.42C8.99 12.58 8.59 12.82 8.23 13.13C8.15 13.19 5.17 16.17 5.17 16.17C3.61 17.73 3.61 20.27 5.17 21.83C6.73 23.39 9.27 23.39 10.83 21.83L13 19.66C13.09 19.56 13.15 19.44 13.15 19.3C13.15 19.06 12.97 18.86 12.75 18.81Z"
      fill="#0A1121"
    />
  </svg>
</template>
