<template>
  <header class="tw-flex tw-flex-row">
    <div
      class="lg:tw-mt-[56px] lg:tw-mb-[20px] tw-my-[45px] lg:tw-min-h-[460px] md:tw-min-h-[350px] tw-w-full tw-relative lg:tw-max-w-screen-landing md:tw-max-w-screen-md tw-px-4 sm:tw-px-8 lg:tw-px-0 tw-mx-auto tw-flex tw-justify-between tw-items-start tw-w-full tw-flex-col md:tw-flex-row"
    >
      <div
        class="tw-w-full md:tw-w-2/3 tw-items-center md:tw-items-start md:tw-justify-start tw-justify-center tw-m-auto tw-mb-8 md:tw-m-0"
      >
        <h1
          class="tw-text-center md:tw-text-left tw-text-[28px] lg:tw-text-[40px] tw-leading-[35px] lg:tw-leading-[50px] tw-font-bold tw-mb-4"
          v-html="$t(title)"
          :class="{ 'md:tw-whitespace-nowrap': language !== 'ja-JP' }"
        />
        <div class="tw-flex tw-justify-center md:tw-justify-start">
          <MainButton
            v-if="pageName !== 'free-booking-links'"
            @click="onRegisterClick"
            @keydown.enter="onRegisterClick"
            :dataTestId="'hero-register-button'"
            >{{ $t(actionLabel) }}</MainButton
          >
          <GhostButton
            v-if="pageName === 'free-booking-links'"
            @click="onRegisterClick"
            @keydown.enter="onRegisterClick"
            :dataTestId="'hero-register-button'"
          >
            <span class="fbl-connectivity" v-html="$t(actionLabel)" />
          </GhostButton>
        </div>
      </div>
      <div v-if="pageName === 'studio-plus'" class="tw-mx-auto">
        <img
          :src="image"
          :alt="`trivago business studio ${pageName} image`"
          class="lg:tw-h-[306px] md:tw-h-[270px] md:tw-block tw-hidden tw-absolute lg:tw-right-0 tw-right-0 md:tw-top-[40px] lg:tw-top-[85px]"
        />
        <img
          :src="image"
          :alt="`trivago business studio ${pageName} image`"
          class="md:tw-hidden tw-flex tw-h-[136px] tw-max-h-[140px] tw-w-full"
          height="126px"
        />
      </div>
      <div v-if="pageName === 'rate-connect'" class="tw-mx-auto">
        <img
          :src="image"
          :alt="`trivago business studio ${pageName} image`"
          class="lg:tw-h-[465px] md:tw-h-[280px] md:tw-block tw-hidden tw-absolute lg:tw-right-0 tw-right-20 md:tw-top-[0px] lg:tw-top-[-20px]"
        />
        <img
          :src="image"
          :alt="`trivago business studio ${pageName} image`"
          class="md:tw-hidden tw-flex tw-h-[121px] tw-max-h-[121px] tw-w-full"
          height="126px"
        />
      </div>
      <div v-if="pageName === 'free-booking-links'" class="tw-mx-auto">
        <img
          :src="image"
          :alt="`trivago business studio ${pageName} image`"
          class="lg:tw-h-[380px] md:tw-h-[353px] md:tw-block tw-hidden tw-absolute tw-right-[180px] tw-top-[10px]"
        />
        <img
          :src="image"
          :alt="`trivago business studio ${pageName} image`"
          class="md:tw-hidden tw-flex tw-m-auto tw-h-[300px] tw-max-h-[300px]"
        />
      </div>
    </div>
  </header>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import MainButton from '@/components/base/buttons/MainButton.vue'
import GhostButton from '../base/buttons/GhostButton.vue'

const rateConnectHero = new URL('/src/assets/img/rate-connect-landing/web-hero.svg', import.meta.url)
const studioPlusHero = new URL('/src/assets/img/studio-plus-landing/web-hero.svg', import.meta.url)
const freeBookingLinksHero = new URL('/src/assets/img/free-booking-links-landing/web-hero.svg', import.meta.url)

const props = defineProps({
  pageName: String,
})
const emit = defineEmits(['onLoginClick'])
const onRegisterClick = e => emit('onLoginClick', e.target.value)

const store = useStore()
const language = computed(() => store.state.locale.language)

const image = computed(() => {
  if (props.pageName === 'rate-connect') {
    return rateConnectHero
  } else if (props.pageName === 'studio-plus') {
    return studioPlusHero
  } else if (props.pageName === 'free-booking-links') {
    return freeBookingLinksHero
  } else return null
})

const title = computed(() => {
  if (props.pageName === 'rate-connect') {
    return 'rc_mp_header'
  } else if (props.pageName === 'studio-plus') {
    return 'studio_plus_mp_header'
  } else if (props.pageName === 'free-booking-links') {
    return 'lp_free_link_header'
  } else return null
})

const actionLabel = computed(() => {
  if (props.pageName === 'rate-connect' || props.pageName === 'studio-plus') {
    return 'rc_mp_get_started'
  } else if (props.pageName === 'free-booking-links') {
    return 'lp_free_link_connectivity_provider_link'
  } else return null
})
</script>
<style scoped>
header {
  background: rgb(249, 250, 250);
  background: linear-gradient(180deg, rgba(249, 250, 250, 1) 71%, rgba(0, 136, 217, 1) 70%);
  @media (max-width: theme('screens.sm')) {
    background: rgb(249, 250, 250);
    background: linear-gradient(180deg, rgba(249, 250, 250, 1) 85%, rgba(0, 136, 217, 1) 70%);
  }
}

.fbl-connectivity :deep(a):hover {
  color: theme('colors.white');
  text-decoration: none;
}
</style>
