<template>
  <div class="tw-pt-8">
    <h2 class="tw-text-xl tw-font-bold tw-pb-2">{{ $t('rc_billing_head') }}</h2>
    <div
      class="tw-relative tw-flex tw-items-center tw-flex-wrap md:tw-flex-nowrap tw-flex-row tw-w-full tw-text-base tw-text-gray-800 tw-text-left tw-border-0 tw-rounded-none tw-focus:outline-none"
    >
      <div class="tw-flex tw-items-start tw-flex-col tw-w-3/5 main-info md:tw-mb-0 tw-mb-5">
        <p
          class="tw-text-sm tw-p-0 tw-m-0 tw-mr-4"
          v-html="
            currentCampaignType === 'cpc' ? $t(`/snippets/hgw/rc_billing_text.inc`) : $t('rc_billing_text_both_types')
          "
        />
      </div>
      <div class="tw-flex tw-w-1/5 tw-flex-col tw-items-start type-info tw-self-start" />
      <div class="tw-flex tw-w-1/5 tw-justify-end action-btn">
        <router-link to="/settings/payment-methods" v-slot="{ navigate }">
          <GhostButton class="tw-px-9" size="medium" @click="navigate">{{ $t('rc_edit') }} </GhostButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import { useStartUp } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const { currentCampaignType } = useStartUp(selectedAccommodationId)
</script>

<style scoped>
.main-info {
  @media (max-width: theme('screens.md')) {
    flex: 0 0 100%;
  }
}
.type-info,
.action-btn {
  @media (max-width: theme('screens.md')) {
    flex: 0 0 50%;
  }
}
</style>
