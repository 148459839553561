<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M19.9744 4V9H14.9744L19.9744 4ZM3.47036 11.999C3.76436 12.017 3.98336 11.805 3.99836 11.529C4.24536 7.307 7.74837 4 11.9744 4C13.7554 4 15.4754 4.6 16.8744 5.686L14.2674 8.293C13.9814 8.579 13.8954 9.009 14.0504 9.383C14.2054 9.756 14.5694 10 14.9744 10H19.9744C20.5264 10 20.9744 9.552 20.9744 9V4C20.9744 3.596 20.7304 3.231 20.3574 3.076C20.2334 3.025 20.1034 3 19.9744 3C19.7144 3 19.4584 3.102 19.2674 3.293L17.5854 4.975C15.9954 3.704 14.0204 3 11.9744 3C7.21937 3 3.27736 6.721 3.00036 11.471C2.98436 11.747 3.19536 11.983 3.47036 11.999Z"
    fill="#171717"
  />

  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.02539 19.9999V14.9999H9.02539L4.02539 19.9999ZM20.5294 12.0009C20.2354 11.9829 20.0164 12.1949 20.0014 12.4709C19.7544 16.6929 16.2514 19.9999 12.0254 19.9999C10.2444 19.9999 8.52439 19.3999 7.12539 18.3139L9.73239 15.7069C10.0184 15.4209 10.1044 14.9909 9.94939 14.6169C9.79439 14.2439 9.43039 13.9999 9.02539 13.9999H4.02539C3.47339 13.9999 3.02539 14.4479 3.02539 14.9999V19.9999C3.02539 20.4039 3.26939 20.7689 3.64239 20.9239C3.76639 20.9749 3.89639 20.9999 4.02539 20.9999C4.28539 20.9999 4.54139 20.8979 4.73239 20.7069L6.41439 19.0249C8.00439 20.2959 9.97939 20.9999 12.0254 20.9999C16.7804 20.9999 20.7224 17.2789 20.9994 12.5289C21.0154 12.2529 20.8044 12.0169 20.5294 12.0009Z"
    fill="#171717"
  />
</template>
