import { createStore } from 'vuex'
import sessionStore from './session'
import studioPlus from './studioPlus'

const HotjarPlugin = store => {
  store.subscribe((mutation, state) => {
    var userId = state.session.userId || null
    var language = navigator.language || navigator.userLanguage
    if (window.hj) {
      window.hj('identify', userId, {
        language,
      })
    }
  })
}

export const plugins = [HotjarPlugin]

export const store = createStore({
  plugins,
  state() {
    return {
      isInitialized: false,
      locale: {
        language: 'en-US',
      },
      currency: null,
      currencyName: null,
      isCurrencyEditable: true,
    }
  },
  mutations: {
    SET_INITIALIZED(state) {
      state.isInitialized = true
    },
    SET_LANGUAGE(state, locale = 'en-US') {
      state.locale.language = locale
    },
    SET_CURRENCY(state, currency) {
      state.currency = currency
    },
    SET_CURRENCY_NAME(state, value) {
      state.currencyName = value
    },
    SET_IS_CURRENCY_EDITABLE(state, value = true) {
      state.isCurrencyEditable = value
    },
  },
  getters: {
    getLanguage(state) {
      return state.locale.language
    },
  },
  modules: {
    session: {
      namespaced: true,
      state: sessionStore.state,
      actions: sessionStore.actions,
      mutations: sessionStore.mutations,
      getters: sessionStore.getters,
    },
    studioPlus: {
      namespaced: true,
      state: studioPlus.state,
      mutations: studioPlus.mutations,
    },
  },
})
