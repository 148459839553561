/**
 * Makes sure that an app is an internal one where we use numeric id's
 * and have a "trusted" flag set to true.
 * @param {Object} app The app object one want to check
 */
export const isInternalApp = (app = {}) => {
  return typeof app === 'object' && !isNaN(app.app_id) && app.trusted
}

export const getSupportCenterUrl = (language, type) => {
  const languageMap = {
    'de-DE': 'de',
    'fr-FR': 'fr',
    'it-IT': 'it',
    'es-ES': 'es',
    'pt-BR': 'pt_BR',
    'el-GR': 'el',
    'ja-JP': 'ja',
    'es-MX': 'es_MX',
    'nl-NL': 'nl_NL',
    'tr-TR': 'tr',
    'ru-RU': 'ru',
    'pt-PT': 'pt_BR',
    'pl-PL': 'pl',
    'en-GB': 'en_US',
    'en-US': 'en_US',
  }
  if (type === 'supportCentre') {
    return `https://studiosupport.trivago.com/s/?language=${languageMap[language] || 'en_US'}`
  } else if (type === 'contactUs') {
    return `https://studiosupport.trivago.com/s/contactsupport?language=${languageMap[language] || 'en_US'}`
  }
}

export function convertYYYYMMDDtoObject(str) {
  if (!/^(\d){8}$/.test(str)) return null
  let y = str.substr(0, 4)
  let m = str.substr(4, 2) - 1
  let d = str.substr(6, 2)
  return { y, m, d }
}

export function formatYYYYMMDD(date, delimiter = '') {
  let mm = date.getMonth() + 1
  let dd = date.getDate()

  return [date.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join(delimiter)
}

const DATE_LOCALE_OPTIONS = {
  month: 'long',
  day: '2-digit',
}

export function convertDateToFormattedString(date, locale = 'en-US', options = DATE_LOCALE_OPTIONS) {
  if (locale === 'es-MX') {
    options.day = 'numeric'
  }
  return date instanceof Date ? date.toLocaleDateString(locale, options) : ''
}

export const getDataFormattedForGraph = unformattedData => {
  const graphData = []
  const SIX_DAYS = 6 * 24 * 60 * 60 * 1000

  for (const [key, value] of Object.entries(unformattedData)) {
    const date = convertYYYYMMDDtoObject(key)
    graphData.push([Date.UTC(date.y, date.m, date.d), value])
  }

  const currentDate = new Date()
  const tomorrow = new Date()
  tomorrow.setDate(currentDate.getDate() + 1)

  return {
    graph: graphData,
    startDate: graphData.length > 0 ? new Date(graphData[0][0]) : currentDate,
    endDate: graphData.length > 0 ? new Date(graphData.slice(-1)[0][0] + SIX_DAYS) : tomorrow,
  }
}
