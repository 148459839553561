<template>
  <div class="md:tw-px-0 tw-px-0 md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-w-full">
    <AppMenu :data="menuItems" :loading="isFetching" />
    <div class="tw-mx-4 lg:tw-mx-0">
      <Notifications class="tw-px-5 tw-py-3 tw-mt-5" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import AppMenu from '@/components/appMenu/AppMenu.vue'
import Notifications from '@/components/rateConnect/Notifications/index.vue'
import { useStartUp } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const { isFetching, currentCampaignType } = useStartUp(selectedAccommodationId)

const menuItems = computed(() => {
  const allMenuItems = [
    {
      label: 'rc_tabs_performance',
      route: '/rate-connect/running/performance',
      testId: 'rc-tabs-performance',
    },
    {
      label: 'rc_tabs_bidding',
      route: '/rate-connect/running/bidding',
      testId: 'rc-tabs-bidding',
    },
    {
      label: 'rc_tabs_markets',
      route: '/rate-connect/running/markets',
      testId: 'rc-tabs-markets',
    },
    {
      label: 'tcm_campaign',
      route: '/rate-connect/running/budget',
      testId: 'rc-tabs-budget',
    },
    {
      label: 'rc_tabs_settings',
      route: '/rate-connect/running/settings',
      testId: 'rc-tabs-settings',
    },
  ]
  if (currentCampaignType.value !== 'cpc') {
    allMenuItems.splice(1, 1)
    allMenuItems.splice(2, 1)
  }
  return allMenuItems
})
</script>
