<template>
  <div class="tw-text-sm tw-text-center md:tw-text-left" v-if="sortedPartners.length > 0">
    <div class="md:tw-text-base tw-text-gray-800 md:tw-mt-2">
      <div class="tw-flex tw-flex-wrap tw-justify-center md:tw-justify-start">
        <span
          v-for="(partner, i) in sortedPartners"
          :key="i"
          class="tw-flex tw-items-center tw-mr-3 tw-text-sm tw-mb-1"
        >
          <b>{{ partner.rating }}</b>
          <img
            class="tw-max-h-[17px] tw-mx-1 tw-py-[2px]"
            :src="`https://imgcy.trivago.com/e_trim/f_auto,dpr_2,q_auto,w_70/partnerlogos-s3/${partner?.logo}`"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  ratingBreakdown: {
    type: Array,
    default: null,
    required: true,
  },
})

const partners = computed(() =>
  props.ratingBreakdown.map(item => {
    return {
      logo:
        item.advertiser?.advertiserDetails?.logo?.urlTail !== null
          ? item.advertiser?.advertiserDetails?.logo?.urlTail
          : item.advertiser?.advertiserDetails?.logo?.localizedUrlTail,
      rating: item?.formattedValue,
      ratingValue: item?.value,
    }
  })
)

const sortedPartners = computed(() => {
  return partners.value.slice(0, 4).sort((a, b) => {
    const aRating = a.ratingValue
    const bRating = b.ratingValue
    if (aRating < bRating) {
      return 1
    }
    if (aRating > bRating) {
      return -1
    }
    return 0
  })
})
</script>
