<template>
  <footer class="tw-border-t tw-border-gray-300">
    <div class="tw-bg-white">
      <div
        class="tw-text-center lg:tw-flex lg:tw-justify-between tw-max-w-screen-xl tw-mx-auto tw-text-sm tw-p-4"
        :class="
          isLanding
            ? 'lg:tw-max-w-screen-landing md:tw-max-w-screen-md tw-px-4 sm:tw-px-8 md:tw-px-0 tw-flex md:tw-justify-between md:tw-items-center tw-flex-col lg:tw-flex-row'
            : 'tw-max-w-screen-xl'
        "
      >
        <div
          class="md:tw-mb-0 tw-flex tw-items-center lg:tw-justify-start tw-justify-between tw-w-full lg:tw-w-2/3 tw-py-[13px] lg:tw-py-0 lg:tw-gap-6 tw-flex-wrap tw-text-gray-800"
        >
          <BaseLink
            :href="getSupportCenterUrl(selectedLanguage, 'contactUs')"
            target="_blank"
            class="tw-cursor-pointer hover:tw-underline hover:tw-text-gray-300 tw-flex-1 md:tw-flex-auto"
          >
            {{ $t('legal.contact') }}
          </BaseLink>
          <BaseLink
            :to="getTermsAndConditionsUrl()"
            target="_blank"
            class="tw-cursor-pointer hover:tw-underline hover:tw-text-gray-300 tw-flex-1 md:tw-flex-auto"
          >
            {{ $t('legal.termsAndConditions') }}
          </BaseLink>
          <BaseLink
            to="/privacy-policy"
            target="_blank"
            class="tw-cursor-pointer hover:tw-underline hover:tw-text-gray-300 tw-flex-1 md:tw-flex-auto"
          >
            {{ $t('legal.privacyPolicy') }}
          </BaseLink>
          <BaseLink
            to="/imprint"
            target="_blank"
            class="tw-cursor-pointer hover:tw-underline hover:tw-text-gray-300 tw-flex-1 md:tw-flex-auto"
          >
            {{ $t('legal.legalInformation') }}
          </BaseLink>
        </div>
        <div class="tw-flex tw-flex-row tw-justify-center lg:tw-justify-end">
          <CurrencySelector
            v-show="isCurrencyEditable && isCurrencySelectorVisible"
            size="big"
            version="footer"
            :isLanding="isLanding"
          />
          <LanguageSelector size="big" version="footer" :isLanding="isLanding" />
        </div>
      </div>
    </div>
    <div class="tw-mx-auto tw-h-[0px] lg:tw-h-[1px] tw-max-w-screen-xl tw-text-center tw-bg-purple-300" />
    <div class="tw-bg-gray-800">
      <div
        class="tw-max-w-screen-xl tw-w-full tw-pt-8 tw-pb-5 tw-mx-auto tw-flex tw-flex-col tw-space-between tw-items-center tw-px-4"
      >
        <div class="tw-text-center">
          <BaseIcon
            class="tw-mx-auto"
            :height="32"
            :width="102"
            :viewbox-height="248.77"
            :viewbox-width="800"
            name="trivago-white"
          >
            <TrivagoWhite />
          </BaseIcon>
        </div>
        <div class="tw-flex tw-items-center tw-mt-4 tw-flex-col md:tw-flex-row">
          <small
            class="tw-block tw-text-xs tw-text-gray-500 tw-text-sm tw-mr-4 tw-mt-4 md:tw-mt-0 md:tw-order-0 tw-order-1"
          >
            {{ $t('legal.brand.copyright', { year: currentYear }) }}
            | {{ $t('legal.brand.rights') }}
          </small>
          <address
            class="tw-m-0 tw-pt-0 tw-pb-0 tw-text-sm tw-text-gray-500 md:tw-order-1 tw-order-0 tw-text-center sm:tw-text-left"
          >
            trivago N.V. , Kesselstraße 5 - 7, 40221 Düsseldorf, Germany.
          </address>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import BaseLink from '@/components/BaseLink.vue'
import CurrencySelector from '@/components/locale/CurrencySelector.vue'
import LanguageSelector from '@/components/locale/LanguageSelector.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import TrivagoWhite from '@/components/icons/TrivagoWhite.vue'
import { getSupportCenterUrl } from '@/utils/Utility'

const store = useStore()
const route = useRoute()

defineProps({
  isLanding: {
    type: Boolean,
    default: false,
  },
})
const isCurrencyEditable = computed(() => store.state.isCurrencyEditable)
const isCurrencySelectorVisible = computed(
  () => route.path !== '/studio-plus/compare' && route.path !== '/studio-plus/payment'
)
const selectedLanguage = computed(() => store.state.locale.language)
const currentYear = computed(() => {
  const localYear = new Date().getFullYear()
  return localYear >= 2019 ? localYear : 2019
})

function getTermsAndConditionsUrl() {
  if (route.path.includes('rate-connect')) {
    return '/terms-and-conditions?app=rate-connect'
  } else {
    return '/terms-and-conditions'
  }
}
</script>
