<template>
  <section class="tw-my-11">
    <div
      class="lg:tw-max-w-screen-landing md:tw-max-w-screen-md tw-px-4 sm:tw-px-8 lg:tw-px-0 tw-mx-auto tw-flex tw-flex-col"
    >
      <div class="tw-hidden md:tw-flex tw-justify-center tw-items-center tw-flex-col lg:tw-flex-row">
        <img
          src="@/assets/img/landing/landing_banner_hotel.svg"
          alt="trivago business studio hero image"
          width="137"
          height="82"
          class="tw-mb-4 lg:tw-mb-0"
        />
        <p
          class="tw-font-bold tw-text-xl tw-text-center tw-mx-10 tw-mb-4 lg:tw-mb-0"
          v-html="$t('tbs_mp_register_text')"
        />
        <MainButton class="tw-whitespace-nowrap" @click="emit('onRegisterClick')">{{
          $t('tbs_mp_log_in_cta')
        }}</MainButton>
      </div>
      <div class="md:tw-hidden tw-flex tw-justify-center tw-items-center tw-flex-col lg:tw-flex-row">
        <div class="tw-flex tw-flex-row">
          <img
            :src="webHotel"
            alt="trivago business studio hero image"
            width="137"
            height="82"
            class="tw-hidden md:tw-flex"
          />
          <img
            :src="mobileHotel"
            alt="trivago business studio hero image"
            width="82"
            height="76"
            class="md:tw-hidden tw-flex"
          />
          <div>
            <p
              class="tw-font-bold tw-text-sm tw-ml-4 sm:tw-mx-10 tw-mb-2 sm:tw-mb-4 lg:tw-mb-0"
              v-html="$t('tbs_mp_register_text')"
            />

            <MainButton size="medium" @click="emit('onRegisterClick')" class="tw-ml-4 sm:tw-mx-10">{{
              'Register for free'
            }}</MainButton>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import MainButton from '@/components/base/buttons/MainButton.vue'

const mobileHotel = new URL('/src/assets/img/landing/landing_image_gallery.svg', import.meta.url)
const webHotel = new URL('/src/assets/img/landing/landing_banner_hotel.svg', import.meta.url)

const emit = defineEmits(['onRegisterClick'])
</script>
