<template>
  <path
    pid="0"
    _fill="#0A1121"
    d="M23 16c0-1.65.4-3.2 1.11-4.57C20 12.67 17 16.49 17 21c0 1.36.27 2.66.77 3.84 2.1.97 3.77 2.74 4.62 4.91A6.87 6.87 0 0 1 27 28c1.82 0 3.46.68 4.7 1.83a10.07 10.07 0 0 0 4.19-4.26c-.91.28-1.88.43-2.89.43-5.52 0-10-4.48-10-10z"
  /><path
    pid="1"
    _fill="#0A1121"
    d="M30.7 30.28C29.68 29.48 28.4 29 27 29s-2.68.48-3.7 1.29c-.2.15-.39.32-.57.5-.08.08-.16.16-.22.25-.229.24-.42.51-.59.78-.07-.42-.16-.83-.29-1.22-.01-.02-.01-.04-.02-.06-.18-.58-.44-1.13-.75-1.65a7.912 7.912 0 0 0-3.86-3.3c-.92-.38-1.94-.59-3-.59-4.42 0-8 3.58-8 8s3.58 8 8 8h13c3.31 0 6-2.69 6-6 0-1.52-.56-2.91-1.5-3.96-.24-.28-.51-.53-.8-.76z"
  /><circle pid="2" cx="34" cy="13" r="1" _fill="#0A1121" /><circle
    pid="3"
    cx="35.5"
    cy="20.5"
    r=".5"
    _fill="#0A1121"
  /><circle pid="4" cx="38.5" cy="9.5" r=".5" _fill="#0A1121" /><circle
    pid="5"
    cx="29.5"
    cy="13.5"
    r=".5"
    _fill="#0A1121"
  />
</template>
