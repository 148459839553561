import { reactive, toRefs } from 'vue'

const state = reactive({
  isFormValid: true,
  editItem: null,
  errors: {
    name: false,
    streetAddress: false,
    web: false,
  },
})

export function usePropertyLanguages() {
  const setEditItem = payload => {
    state.editItem = payload
  }

  const setValidation = payload => {
    state.errors[payload.name] = payload.value
    state.isFormValid = Object.values(state.errors).every(item => item === false)
  }

  const setNewValue = payload => {
    state.editItem[payload.name] = payload.value
  }

  const resetValidation = () => {
    state.isFormValid = true
    state.errors = {
      name: false,
      streetAddress: false,
      web: false,
    }
  }

  return {
    ...toRefs(state),
    setEditItem,
    setValidation,
    setNewValue,
    resetValidation,
  }
}
