<template>
  <BaseSelect
    :label="$t('form.labels.state')"
    :required="required"
    :options="statesListFormatted"
    :value="selectedValue"
    :display-text-key="'displayText'"
    :value-key="'value'"
    @onChange="onSelectChange"
  />
</template>

<script setup>
import { computed } from 'vue'
import BaseSelect from '@/components/BaseSelect.vue'

const emit = defineEmits(['onSelectChange'])
const onSelectChange = e => {
  emit('onSelectChange', e)
}
const props = defineProps({
  states: {
    type: Array,
    default: () => [],
  },
  value: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
})

const statesListFormatted = computed(() => {
  if (!props.states || !Array.isArray(props.states)) return []
  return props.states
    .map(state => ({ value: state.code, displayText: state.name }))
    .sort((a, b) =>
      a.displayText.toLowerCase() === b.displayText.toLowerCase()
        ? 0
        : a.displayText.toLowerCase() < b.displayText.toLowerCase()
        ? -1
        : 1
    )
})
const selectedValue = computed(() => {
  if (props.value) {
    return props.value
  } else {
    return statesListFormatted.value[0].value
  }
})
</script>
