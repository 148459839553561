import { useMutation } from '@tanstack/vue-query'

import { store } from '@/store'
import OrganisationManagementService from '@/services/OrganisationManagementService'

export function useOrganisationsMutation() {
  const {
    isLoading: isRemovingUserFromOrganisationLoading,
    isSuccess: isRemovingUserFromOrganisationSuccess,
    isError: isRemovingUserFromOrganisationError,
    mutate: removeUserFromOrganisation,
  } = useMutation({
    mutationFn: ({ organisationId }) => OrganisationManagementService.removeUserFromOrganisation({ organisationId }),
    onSuccess: () => {
      store.dispatch('session/getOrganisationsList')
    },
  })

  return {
    isRemovingUserFromOrganisationLoading,
    isRemovingUserFromOrganisationSuccess,
    isRemovingUserFromOrganisationError,
    removeUserFromOrganisation,
  }
}
