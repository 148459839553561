<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${viewboxWidth > 0 ? viewboxWidth : width} ${viewboxHeight > 0 ? viewboxHeight : height}`"
    :aria-labelledby="iconName"
    role="presentation"
    :fill="iconColor"
    :data-testid="dataTestId"
  >
    <g>
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    viewboxWidth: {
      type: [Number, String],
      default: 0,
    },
    viewboxHeight: {
      type: [Number, String],
      default: 0,
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    dataTestId: {
      type: String,
      default: '',
    },
  },
}
</script>
<style scoped>
svg {
  vertical-align: middle;
}
</style>
