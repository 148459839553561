<template>
  <div>
    <label :for="id" :class="{ 'tw-mb-0': !label }" class="tw-w-full">
      <p class="tw-font-bold">{{ label }}</p>
      <div class="tw-relative">
        <select
          :id="id"
          :value="value"
          class="tw-appearance-none tw-cursor-pointer tw-py-2 tw-pl-3 tw-pr-8 tw-border tw-rounded-lg tw-border-solid tw-w-full focus:tw-outline-0 focus:tw-border-blue-800 focus:tw-text-blue-800"
          v-bind="$attrs"
          :class="disabled ? getDisabledClass : getClass"
          @blur="$emit('onBlur', $event.target.value)"
          @change="$emit('onChange', $event.target.value)"
        >
          <option v-if="!value" disabled selected>{{ $t('rc_activate') }}</option>
          <option v-for="(option, index) in options" :key="index" :value="option" class="tw-color-gray-700">
            {{ option }}
          </option>
        </select>
        <span class="tw-text-[22px] tw-color-gray-700 tw-absolute arrow-dropdown">{{ '&#9202;' }}</span>
      </div>
    </label>
    <small v-if="error" class="tw-text-red-700">{{ error }}</small>
  </div>
</template>

<script setup>
import { computed } from 'vue'
const props = defineProps({
  id: {
    type: String,
    default: 'textfield',
  },
  label: {
    type: [String, Number],
    required: false,
    default: '',
  },
  ariaLabel: {
    type: String,
    required: true,
  },
  value: {
    type: [String, null],
    default: '',
  },
  type: {
    type: String,
    required: false,
    default: 'text',
  },
  error: {
    type: [Boolean, String],
    default: false,
  },
  size: {
    type: String,
    default: 'medium',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})
const options = computed(() => {
  var hours = []
  for (var i = 0; i < 24; i++) {
    hours.push(i + ':00')
  }
  return hours
})
const getDisabledClass = computed(() => {
  const commonClass = 'tw-appearance-none  tw-w-full focus:tw-outline-0 tw-py-2 tw-px-3 tw-rounded-lg'
  const disableClass = 'tw-bg-gray-200 tw-text-gray-500 tw-border-gray-300'
  return props.error
    ? `${commonClass} ${disableClass} ${sizeClass()} tw-bg-red-200 tw-border-red-700`
    : `${commonClass} ${disableClass} ${sizeClass()} tw-border-gray-500`
})

const getClass = computed(() => {
  const commonClass = 'tw-appearance-none  tw-w-full focus:tw-outline-0 tw-py-2 tw-px-3 tw-rounded-lg'
  const baseClass = 'tw-bg-white tw-border tw-text-gray-800 focus:tw-border-blue-800 focus:tw-text-blue-800 '
  return props.error
    ? `${commonClass} ${baseClass} ${sizeClass()} tw-bg-red-200 tw-border-red-700`
    : `${commonClass} ${baseClass} ${sizeClass()} tw-border-gray-500`
})

const sizeClass = () => {
  if (props.size === 'medium') return 'tw-px-4 tw-box-size-36 tw-text-sm tw-rounded-lg '
  else return 'tw-px-6 tw-box-size-44 tw-text-base tw-rounded-lg'
}
</script>
<style scoped>
.arrow-dropdown {
  top: 50%;
  transform: translateY(-45%);
  right: 15px;
  pointer-events: none;
}
label {
  @apply tw-mb-0;
}
</style>
