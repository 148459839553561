<template>
  <div class="tw-text-sm tw-mt-4 tw-flex tw-flex-col">
    <h4 class="tw-font-bold">{{ $t('app_description_choose_language') }}</h4>
    <div v-if="isPropertyDescriptionsFetching">
      <LaunchpadLoader class="tw-w-full tw-flex tw-justify-center tw-items-center tw-flex-1" />
    </div>
    <div v-else-if="propertyDescriptions">
      <div class="tw-mb-2">
        <BaseSelect
          name="lang-desc-select"
          :display-text-key="'label'"
          :value-key="'key'"
          :options="availableLanguages"
          :placeholder="$t('locale.currencySelector.enabledTitle')"
          :value="languageSelected"
          version="light"
          size="medium"
          @onChange="e => emit('onLanguageSelectedChange', e)"
        />
      </div>
      <p v-if="languageSelected">
        {{ $t('app_description_list_item_hint', { language: translatedCurrLang }) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import BaseSelect from '@/components/BaseSelect.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import { usePropertyDescriptions } from '@/components/propertyDetails/queries'
import { useAvailableLanguages } from '@/components/propertyDetails/propertyLanguages/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const props = defineProps({
  languageSelected: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['onLanguageSelectedChange'])

const { t } = useI18n()
const { propertyDescriptions, isPropertyDescriptionsFetching } = usePropertyDescriptions(selectedAccommodationId)
const { allAvailableLanguages } = useAvailableLanguages()

const availableLanguages = computed(() => {
  if (!propertyDescriptions.value || !allAvailableLanguages.value) return []

  const existingDescriptionsLanguages = propertyDescriptions.value
    ? propertyDescriptions.value?.map(description => description.language_tag)
    : []
  const languageList = Object.keys(allAvailableLanguages.value)?.reduce((acc, key) => {
    if (!existingDescriptionsLanguages.includes(key)) {
      if (key === 'cs') {
        acc.push({ key: 'cz', label: t(`lang_cz`) })
      } else if (key === 'da') {
        acc.push({ key: 'dk', label: t(`lang_dk`) })
      } else {
        acc.push({ key, label: t(`lang_${key}`) })
      }
    }
    return acc
  }, [])

  return languageList?.sort((a, b) =>
    a.label.toLowerCase() > b.label.toLowerCase() ? 1 : b.label.toLowerCase() > a.label.toLowerCase() ? -1 : 0
  )
})
const translatedCurrLang = computed(() => {
  if (!props.languageSelected) return ''
  return t(`lang_${props.languageSelected}`)
})
</script>
