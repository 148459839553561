<template>
  <div :class="{ 'tw-min-h-[630px]': !isWaitingMode }">
    <div v-if="isLoading" class="tw-h-full tw-bg-gray-300 tw-animate-pulse tw-rounded-lg" />
    <div v-else-if="isError" class="tw-h-full" />
    <div v-else>
      <BarChart
        container="vp-searches-container"
        :series="dataFormattedForGraph"
        :categories="columnsFormattedForGraph"
        :isWaitingMode="isWaitingMode"
        :class="{ 'tw-h-[550px] tw-pb-4 tw-border-b-2 tw-border-b-gray-200': !isWaitingMode }"
      />
      <div class="tw-pl-4 tw-mt-9 tw-flex tw-items-center tw-gap-2" v-if="!isWaitingMode">
        <span class="tw-w-4 tw-h-4 tw-rounded-full tw-bg-graph-blue tw-inline-block" />
        <span>{{ $t('vp_time_graph_info') }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import BarChart from '@/components/visitorsProfile/common/BarChart.vue'
import { selectedAccommodationId } from '@/layouts/queries'

const { t } = useI18n()
const props = defineProps({
  selectedTimeFrame: {
    type: String,
    required: true,
    default: '30',
  },
  isLoading: Boolean,
  isError: Boolean,
  timeFrameTrends: Object,
  isWaitingMode: Boolean,
})

const defaultCols = ['weekend', 'week', 'longTrip', 'other']
const translations = {
  weekend: 'vp_time_graph_weekends',
  week: 'vp_time_grap_weekdays',
  longTrip: 'vp_time_graph_extended',
  other: 'vp_time_graph_other',
}
const columnsFormattedForGraph = computed(() => {
  if (
    !props.timeFrameTrends ||
    !selectedAccommodationId.value ||
    !props.timeFrameTrends[selectedAccommodationId.value]
  ) {
    return defaultCols.map(item => t(translations[item]))
  }

  const data = props.timeFrameTrends[selectedAccommodationId.value]
  const dataFilled = Object.values(defaultCols).reduce((accum, metricKey) => {
    return { ...accum, [metricKey]: data?.[metricKey] ?? { name: metricKey, data: 0 } }
  }, {})
  const graphData = Object.keys(dataFilled)
    .map(item => {
      return {
        name: item,
        translation: translations[item],
        data: data[item],
      }
    })
    .sort((a, b) => (a.data > b.data ? -1 : 1))

  const categories = graphData.map(item => t(item.translation))
  return categories
})

const dataFormattedForGraph = computed(() => {
  if (!props.timeFrameTrends || !selectedAccommodationId.value || !props.timeFrameTrends[selectedAccommodationId.value])
    return [{ data: [0, 0, 0, 0] }]

  const data = props.timeFrameTrends[selectedAccommodationId.value]
  const graphData = Object.keys(data)
    .map(item => {
      return {
        name: item,
        data: data[item],
      }
    })
    .sort((a, b) => (a.data > b.data ? -1 : 1))

  const graphDataFilled = Object.keys(defaultCols).reduce((accum, metricKey) => {
    return {
      ...accum,
      [metricKey]: graphData?.[metricKey]
        ? { name: graphData?.[metricKey].name, data: Number(graphData?.[metricKey].data) }
        : {
            name: defaultCols[metricKey],
            data: 0,
          },
    }
  }, {})
  const simplifiedSeries = Object.values(graphDataFilled).map(item => item.data ?? 0)

  return [{ data: simplifiedSeries }]
})
</script>
