<template>
  <div class="tw-leading-3 tw-grow">
    <div class="tw-flex tw-items-baseline tw-gap-1">
      <ConversionFunnelKPIValue :value="value" />
      <UiTooltip
        tooltip-position="top"
        :label="`${variation >= 0 ? $t('percentage_change_up') : $t('percentage_change_down')}
          ${$t('infobubble_change_explanation', {
            iPercentage: `${Intl.NumberFormat(language, { style: 'percent', minimumFractionDigits: 2 }).format(
              variationValue / 100
            )}`,
          })}`"
        class="tw-cursor-pointer"
      >
        <ConversionFunnelKPIVariation :value="variationValue" :isPositive="variation >= 0" />
      </UiTooltip>
    </div>
    <small
      class="tw-text-gray-700 tw-text-[12px]"
      :class="value > 0 && percentage >= 0 && percentageOf ? 'tw-visible' : 'tw-invisible'"
    >
      {{
        $t('tbs_performance_page_percentage', {
          percentage: Intl.NumberFormat(language, { style: 'percent', minimumFractionDigits: 2 }).format(
            percentage / 100
          ),
          percentageOf: Intl.NumberFormat(language).format(percentageOf),
        })
      }}
    </small>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import ConversionFunnelKPIValue from '@/components/profilePerformance/conversionFunnel/ConversionFunnelKPIValue.vue'
import ConversionFunnelKPIVariation from '@/components/profilePerformance/conversionFunnel/ConversionFunnelKPIVariation.vue'

const store = useStore()
const props = defineProps({
  value: {
    type: Number,
    default: null,
  },
  variation: {
    type: Number,
    default: 0,
  },
  percentage: {
    type: Number,
    default: null,
  },
  percentageOf: {
    type: Number,
    default: null,
  },
})
const language = computed(() => store.state.locale.language)
const variationValue = computed(() => Math.abs(props.variation))
</script>
