<template>
  <section>
    <div
      class="lg:tw-max-w-screen-landing md:tw-max-w-screen-md tw-px-4 sm:tw-px-8 lg:tw-px-0 tw-mx-auto tw-flex tw-flex-col"
    >
      <div class="tw-flex tw-justify-center tw-items-center tw-flex-row tw-gap-8">
        <div class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-w-2/3 tw-order-1 sm:tw-order-0">
          <div>
            <p class="tw-font-bold tw-text-sm md:tw-text-xl lg:tw-text-3xl">{{ $t('rc_mp_start_header') }}</p>
            <p class="tw-text-sm md:tw-text-xl lg:tw-text-3xl" v-html="$t('rc_mp_start_subtext')" />
          </div>
          <div class="tw-mt-2 sm:tw-mt-8 tw-flex tw-flex-col tw-items-start">
            <MainButton class="tw-hidden sm:tw-flex" @click="onLoginClick">{{ $t('rc_mp_get_started') }}</MainButton>
            <MainButton class="sm:tw-hidden tw-flex" size="small" @click="onLoginClick">{{
              $t('rc_mp_get_started')
            }}</MainButton>
            <p class="tw-mt-4 tw-text-sm tw-hidden sm:tw-flex">{{ $t('rc_mp_connectivity_info') }}</p>
          </div>
        </div>
        <img
          :src="RateConnectStart"
          alt="trivago business studio hero image"
          class="tw-w-1/2 sm:tw-w-1/3 sm:tw-h-[218px] tw-order-0 sm:tw-order-1"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import MainButton from '@/components/base/buttons/MainButton.vue'

const RateConnectStart = new URL('/src/assets/img/rate-connect-landing/start-rc.svg', import.meta.url)

const emit = defineEmits(['onLoginClick'])
const onLoginClick = e => emit('onLoginClick', e.target.value)
</script>
