import { store } from '@/store'
import * as Sentry from '@sentry/vue'

export default async function (to, from, next) {
  try {
    if (to.query.orgID && to.query.itemID) {
      window.localStorage.setItem('selected_organisation_id', to.query.orgID)
      window.localStorage.setItem('selected_accommodation_id', to.query.itemID)
    }
    await store.dispatch('session/restoreStudioSession', { from, to })
    const isEuro = store.state.currency === null || store.state.currency === 'EUR'
    const isOnboardingUserDetails = store.state.session.isOnboardingUserDetails
    const isOnboardingAssignments = store.state.session.isOnboardingAssignments
    if (isOnboardingAssignments || isOnboardingUserDetails) {
      if (to.name !== 'onboarding') {
        next({ name: 'onboarding' })
      } else {
        next()
      }
    } else if (to.name === 'onboarding') {
      next('/')
    } else if (to.name.includes('studio-plus') && !isEuro) {
      next(false)
    } else {
      next()
    }
  } catch (error) {
    Sentry.withScope(scope => {
      scope.setTag('feature', 'isAuthenticated-middleware')
      Sentry.captureException(error)
    })

    return next()
  }
}
