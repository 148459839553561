<template>
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.8332 4.75C9.35634 4.75 4.9165 9.18983 4.9165 14.6667C4.9165 20.1435 9.35634 24.5833 14.8332 24.5833C20.31 24.5833 24.7498 20.1435 24.7498 14.6667C24.7498 9.18983 20.31 4.75 14.8332 4.75Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path d="M30.4165 30.25L21.9165 21.75" stroke="white" stroke-width="2" stroke-linecap="round" />
  </svg>
</template>
