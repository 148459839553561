<template>
  <div class="tw-flex tw-items-center tw-justify-between tw-mb-9">
    <MetricViewHeaderTitle :tooltip-label="titleTooltipLabel">{{ $t(title) }}</MetricViewHeaderTitle>
    <TimeFrameSelect
      class="tw-w-auto tw-min-w-[160px]"
      :options="timeFrameOptions"
      @onSelect="onSelect"
      name="timeFrame"
      :selectedValue="selectedTimeFrame"
    />
  </div>
</template>

<script setup>
import MetricViewHeaderTitle from '@/components/visitorsProfile/common/MetricViewHeaderTitle.vue'
import TimeFrameSelect from '@/components/visitorsProfile/common/TimeFrameSelect.vue'

defineProps({
  selectedTimeFrame: {
    type: String,
    required: true,
    default: '30',
  },
  title: {
    type: String,
    required: true,
    default: '',
  },
  titleTooltipLabel: {
    type: String,
    required: true,
    default: '',
  },
})

const emit = defineEmits(['onSelectTimeFrame'])

const onSelect = timeFrame => emit('onSelectTimeFrame', timeFrame)
</script>
