<template>
  <p :style="{ color: textColorMap[status] }" :class="{ 'tw-invisible': hideMessage }">
    {{ $t(textMap[status], { budgetMin: minBudget }) }}
  </p>
</template>

<script setup>
defineProps({
  status: {
    type: String,
    default: '',
  },
  minBudget: {
    type: Number,
    default: 0,
  },
  hideMessage: {
    type: Boolean,
    default: false,
  },
})

const textMap = {
  belowConsumption: 'rc_budget_input_indication_below_consumed',
  excellent: 'rc_budget_input_indication_above_recommended',
  low: 'rc_budget_input_indication_below_recommended',
  recommended: 'rc_budget_input_indication_recommended',
  tooLow: 'rc_budget_input_indication_minimum',
}
const textColorMap = {
  belowConsumption: '#FF8381',
  tooLow: '#FF8381',
  low: '#FDCE8A',
  recommended: '#99CC66',
  excellent: '#99CC66',
}
</script>
