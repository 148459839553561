<template>
  <div class="tw-mt-10" :id="container"></div>
</template>

<script setup>
import { useStore } from 'vuex'
import { nextTick, watch, computed } from 'vue'
import Highcharts from 'highcharts'
import * as Sentry from '@sentry/vue'

import { formatCurrency, formatDate, getTooltip } from '@/components/rateInsights/utils.js'

const store = useStore()
const props = defineProps({
  container: {
    type: String,
    required: true,
  },
  series: {
    type: Array,
    default() {
      return []
    },
  },
  categories: {
    type: Object,
    default() {
      return {}
    },
  },
  days: {
    type: Object,
    default() {
      return {}
    },
  },
  competitors: {
    type: Object,
    default() {
      return {}
    },
  },
  filters: {
    type: Object,
    default() {
      return {}
    },
  },
  translationKeys: {
    type: Object,
    default() {
      return {}
    },
  },
  selectTimestamp: {
    type: Function,
    default: () => null,
  },
  currency: {
    type: String,
    default: '',
  },
})

const localization = computed(() => store.getters['getLanguage'])
const drawChart = (
  container,
  data,
  categories,
  days,
  competitors,
  filters,
  translationKeys,
  selectTimestamp,
  currency
) => {
  if (!container || !data) return
  try {
    // without nextTick, highcharts may use the container before it exists in the DOM, it is important!
    nextTick(() => {
      // if for some reason the container is not yet mounted, go back.
      if (!document.getElementById(container)) return
      Highcharts.chart(container, {
        title: {
          text: null,
        },
        chart: {
          alignTicks: true,
          type: 'spline',
          backgroundColor: null,
          scrollablePlotArea: {
            minWidth: 700,
            scrollPositionX: 1,
          },
          plotBorderWidth: 0,
          style: {
            fontFamily: `SourceSansPro, Sans-Serif, "Sans Serif"`,
          },
        },
        credits: {
          enabled: false,
        },
        series: data,
        xAxis: {
          categories,
          gridLineColor: '#C4E3F4',
          lineColor: '#C4E3F4',
          tickColor: '#C4E3F4',
          
          labels: {
            style: { color: '#6C707A', fontSize: '14px' },
            formatter: function () {
              return formatDate(this.value, localization.value)
            },
          },
          tickLength: 0,
          gridLineWidth: 1,
          crosshair: {
            dashStyle: 'solid',
          },
        },
        yAxis: [
          {
            gridLineColor: '#C4E3F4',
            title: false,
            labels: {
              padding: 30,

              formatter: function () {
                return formatCurrency(this.value, currency, localization.value)
              },
            },
          },
        ],
        legend: {
          enabled: false,
        },
        plotOptions: {
          column: {
            maxPointWidth: 50,
          },
          series: {
            stickyTracking: true,
            allowPointSelect: true,
            connectNulls: true,
            color: '#FFFF00',
            animation: false,
            point: {
              events: {
                select: function (e) {
                  e.preventDefault()
                  selectTimestamp(e.target.category.timestamp)
                },
              },
            },
          },
          marker: {
            lineColor: null,
            states: {
              select: {
                fillColor: null,
                radius: 8,
              },
            },
          },
        },
        tooltip: {
          useHTML: true,
          shadow: false,
          borderWidth: 0,
          backgroundColor: null,
          style: {
            padding: 0,
          },
          outside: true,
          shared: false,
          formatter: function () {
            const colors = ['#007BC1', '#8291D3', '#FC9E15', '#599A19', '#FF8381', '#3F57BD']
            const point = this.point
            point.colorIndex = colors.indexOf(point.color)

            return getTooltip({
              ...this,
              params: {
                competitors: competitors,
                currency: currency,
                locale: localization.value,
                filterValues: filters,
                translationKeys: translationKeys,
                point: point,
                colors: colors,
              },
            })
          },
        },
      })
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

watch(
  () => props,
  newProps => {
    if (newProps.series?.length > 0 && newProps.container) {
      drawChart(
        newProps.container,
        newProps.series,
        newProps.categories,
        newProps.days,
        newProps.competitors,
        newProps.filters,
        newProps.translationKeys,
        newProps.selectTimestamp,
        newProps.currency
      )
    }
  },
  { deep: true, immediate: true }
)
</script>
