<template>
  <div>
    <ToastNotifications />
    <NavBar v-if="isCoreDataSuccess" />
    <div
      class="tw-mx-auto tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-pt-nav-h tw-min-h-screen-minus-footer tw-flex tw-justify-center tw-items-center"
    >
      <div v-if="isCoreDataLoading" class="tw-flex tw-justify-center tw-items-center">
        <LaunchpadLoader />
      </div>
      <div v-else-if="isCoreDataSuccess" class="tw-w-full">
        <slot />
      </div>
      <div v-else-if="isCoreDataError">
        <ErrorModal
          header-key="dashboard.loadFail.header.launch"
          retry-key="dashboard.loadFail.retryButton"
          @retry="handleRetry"
        />
      </div>
    </div>
    <ThePageFooter />
    <TheCookieNotice />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import ThePageFooter from '@/components/page/ThePageFooter.vue'
import NavBar from '@/components/dashboard/nav/NavBar.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import TheCookieNotice from '@/components/legal/TheCookieNotice.vue'
import ToastNotifications from '@/components/notifications/ToastNotifications.vue'
import ErrorModal from '@/components/dashboard/ErrorModal.vue'
import {
  useUserDetails,
  useMultipleOrganisationAssignmentsQueries,
  useOrganisationAssignments,
  useAccommodationsByOrgId,
  useMultipleAccommodationsByOrgIdQueries,
} from '@/layouts/queries'
import { useGetUserCurrency } from '@/components/locale/queries'
import { useZuoraAccount } from '@/components/payment/queries'

const store = useStore()
const token = computed(() => store.state.session?.studioToken)
const isGuest = computed(() => false)

// Loading currency and language
const { isOrganisationsCurrencyLoading } = useGetUserCurrency({ token, isGuest })
const { isLoading } = useZuoraAccount(token)

// Loading user data
const userId = computed(() => store.state.session.userId)
const { isUserDetailsFetching, isUserDetailsSuccess, isUserDetailsError } = useUserDetails(userId)

// Loading selected organisation assignments
const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { isOrganisationAssignmentsFetching, isOrganisationAssignmentsSuccess, isOrganisationAssignmentsError } =
  useOrganisationAssignments(selectedOrganisationId)

// Loading accommodations for selected organisation.
const { isAccommodationsByOrgIdSuccess, isAccommodationsByOrgIdError, isAccommodationsByOrgIdInitialLoading } =
  useAccommodationsByOrgId(selectedOrganisationId)

// Preloading non-selected organisation assignments and accommodations.
const organisations = computed(() => store.state.session.organisations)
useMultipleOrganisationAssignmentsQueries(organisations)
useMultipleAccommodationsByOrgIdQueries(organisations)

// Loading indicators
const isSessionLoading = computed(() => store.state.session.isLoading)
const isSessionSuccess = computed(() => store.state.session.isSuccess)
const isSessionError = computed(() => store.state.session.isError)

const isCoreDataLoading = computed(
  () =>
    isSessionLoading.value ||
    isUserDetailsFetching.value ||
    isOrganisationAssignmentsFetching.value ||
    isAccommodationsByOrgIdInitialLoading.value ||
    isLoading.value ||
    isOrganisationsCurrencyLoading.value
)

const isCoreDataSuccess = computed(
  () =>
    !isCoreDataLoading.value &&
    isSessionSuccess.value &&
    isUserDetailsSuccess.value &&
    isOrganisationAssignmentsSuccess.value &&
    isAccommodationsByOrgIdSuccess.value
)

const isCoreDataError = computed(
  () =>
    !isCoreDataLoading.value &&
    (isSessionError.value ||
      isUserDetailsError.value ||
      isOrganisationAssignmentsError.value ||
      isAccommodationsByOrgIdError.value)
)

// Other functionality
const handleRetry = () => {
  store.dispatch('session/restoreStudioSession')
}
</script>
