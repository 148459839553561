<template>
  <div class="tw-relative tw-h-full sml:tw-h-[208px] tw-w-full sml:tw-w-[231px]">
    <div
      v-if="item.status.toLowerCase() === 'uploading'"
      class="loading-image-box tw-h-full sml:tw-h-[208px] tw-w-full sml:tw-w-[231px] tw-flex tw-items-center tw-justify-center tw-text-gray-500 tw-flex-col"
    >
      <span class="tw-text-center truncating">{{ $t('app_images_uploading', { file: item.name }) }}</span>
      <div class="tw-mt-4 tw-w-[90%] tw-mx-auto tw-bg-white tw-rounded-full">
        <div
          :id="`${item.name}_progress-bar`"
          class="tw-border-2 tw-box-border tw-border-white tw-border-solid tw-bg-blue-800 tw-text-xs tw-font-medium tw-text-blue-100 tw-text-center tw-p-0.5 tw-leading-none tw-rounded-full"
          style="width: 0%"
        >
          {{ percentage }}{{ '%' }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'

const props = defineProps({
  item: Object,
})
const percentage = ref(0)

onMounted(() => progress())

const progress = () => {
  const storage = (window.__uploadProgress__ = window.__uploadProgress__ || {})
  const value = storage[props.item.id] || 0
  const percentageVal = parseInt(value)
  let el = document.getElementById(`${props.item.name}_progress-bar`)
  if (!el) {
    return null
  }

  if (percentageVal < 100) {
    let newPercentage = percentageVal + 5
    percentage.value = newPercentage
    el.style.width = `${newPercentage}%`
    storage[props.item.id] = newPercentage

    if (newPercentage < 100) {
      window.setTimeout(progress, 500)
    }
  }
}
</script>
<style scoped>
.loading-image-box {
  @apply tw-rounded-xl;
  background-color: theme('colors.gray.200');
  box-sizing: content-box;
}
.truncating {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  max-width: 90%;
}
</style>
