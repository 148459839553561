import { store } from '@/store'

export default async function (to, from, next) {
  try {
    const isDeepLink = window.localStorage.getItem('fromDeepLink') || (to.query.itemID && to.query.orgID)
    const adminRoutes = [
      'settings-team-settings',
      'settings-payment-methods',
      'settings-subscriptions',
      'settings-subscription-details',
      'settings-subscriptions-edit-payment',
      'settings-payment-methods',
    ]
    const sessionModule = store.state.session

    if (
      sessionModule.userRole !== 'admin' &&
      adminRoutes.includes(to.name) &&
      (isDeepLink || from.name === undefined)
    ) {
      window.localStorage.removeItem('fromDeepLink')
      return next({ name: 'not-admin-error' })
    } else if (sessionModule.userRole !== 'admin' && adminRoutes.includes(to.name) && from.name !== undefined) {
      return next({ name: 'settings-profile-settings' })
    } else return next()
  } catch {
    return next({ name: 'getstarted' })
  }
}
