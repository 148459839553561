const trustedAppSession = state => {
  if (!state.studioToken) return {}

  return {
    leelooToken: `${state.JLoToken.token_type} ${state.JLoToken.access_token}`,
    accessToken: `${state.studioToken.token_type} ${state.studioToken.access_token}`,
    currentRole: state.userRole,
    currentOrganisation: state.selectedOrganisation,
  }
}

const appSession = state => {
  if (!state.JLoToken) return {}

  return {
    accessToken: state.studioToken,
  }
}

export default {
  trustedAppSession,
  appSession,
}
