<template>
  <mask
    id="mask0_3411_34408"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="2"
    y="4"
    width="20"
    height="17"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 4.5H22V20.5H2V4.5Z" fill="white" />
  </mask>
  <g mask="url(#mask0_3411_34408)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.50018 17.5L10.0002 13.5H3.00018L6.50018 17.5ZM2.08918 13.087C2.25118 12.729 2.60718 12.5 3.00018 12.5H6.00018C6.00018 8.089 9.58918 4.5 14.0002 4.5C18.4112 4.5 22.0002 8.089 22.0002 12.5C22.0002 16.911 18.4112 20.5 14.0002 20.5C13.7242 20.5 13.5002 20.276 13.5002 20C13.5002 19.724 13.7242 19.5 14.0002 19.5C17.8602 19.5 21.0002 16.36 21.0002 12.5C21.0002 8.64 17.8602 5.5 14.0002 5.5C10.1402 5.5 7.00018 8.64 7.00018 12.5H10.0002C10.3932 12.5 10.7492 12.729 10.9112 13.087C11.0732 13.444 11.0112 13.863 10.7522 14.159L7.25218 18.159C7.06218 18.375 6.78818 18.5 6.50018 18.5C6.21118 18.5 5.93818 18.375 5.74818 18.159L2.24818 14.159C1.98918 13.863 1.92718 13.444 2.08918 13.087Z"
      fill="#0A1121"
    />
  </g>
</template>
