<template>
  <g id="RC-FL---Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="RC-FL-Settings-Campaing-Type-1-5" transform="translate(-721.000000, -390.000000)">
      <g id="Group-9" transform="translate(306.000000, 192.000000)">
        <g id="Group-10" transform="translate(32.000000, 128.000000)">
          <g id="Group-11" transform="translate(383.000000, 69.000000)">
            <g id="Icons/Interface/Info-24x24-Fill" transform="translate(0.000000, 1.000000)">
              <rect id="Rectangle-4-Copy-90" x="0" y="0" width="24" height="24"></rect>
              <g
                id="Page-1"
                transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) translate(2.000000, 2.000000)"
              >
                <circle id="Oval" stroke="#007CC2" cx="10" cy="10" r="9.5"></circle>
                <path
                  id="Fill-1"
                  d="M9,15 C9,14.448 9.448,14 10,14 C10.552,14 11,14.448 11,15 C11,15.552 10.552,16 10,16 C9.448,16 9,15.552 9,15 Z"
                  fill="#007CC2"
                ></path>
                <path
                  id="Fill-3"
                  d="M10,12 C9.44771525,12 9,11.5469637 9,11.0029699 L9,4.99703014 C9,4.4463856 9.44386482,4 10,4 L10,4 C10.5522847,4 11,4.45303631 11,4.99703014 L11,11.0029699 C11,11.5536144 10.5561352,12 10,12 L10,12 Z"
                  fill="#007CC2"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</template>
