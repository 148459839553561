<template>
  <div class="tw-flex tw-items-center tw-bg-white tw-py-2 tw-pl-4 tw-gap-2" :class="sizeClass">
    <BaseIcon :height="17" :width="17" icon-name="search">
      <Search />
    </BaseIcon>
    <label class="tw-visually-hidden" :for="id">Search</label>
    <input
      :id="id"
      type="search"
      class="focus:tw-outline-0 tw-grow"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('onInput', $event.target.value)"
    />
  </div>
</template>

<script setup>
import BaseIcon from '@/components/BaseIcon.vue'
import Search from '@/components/icons/Search.vue'
import { computed } from 'vue'

const props = defineProps({
  id: {
    type: String,
    required: true,
    default: 'search-field',
  },
  placeholder: {
    type: String,
    required: false,
    default: '',
  },
  value: {
    type: String,
    required: false,
    default: '',
  },
  size: {
    type: String,
    default: 'big',
  },
})

const sizeClass = computed(() => {
  if (props.size === 'medium') return 'tw-pr-4 tw-box-size-36 tw-text-sm tw-rounded-lg '
  else return 'tw-pr-6 tw-box-size-44 tw-text-base tw-rounded-lg'
})
</script>
