<template>
  <div class="tw-flex tw-flex-row md:tw-inline-flex md:tw-flex-row tw-text-sm tw-justify-between tw-w-full tw-mb-6">
    <div class="tw-flex md:tw-flex-row tw-flex-col tw-gap-5 tw-justify-between tw-w-full">
      <div class="tw-flex md:tw-flex-row tw-flex-col tw-gap-5">
        <div class="tw-flex tw-flex-col tw-gap-2 md:tw-flex-row">
          <BaseSelect
            :id="'performance-range-select'"
            :display-text-key="'label'"
            :value-key="'value'"
            :options="periodTypeOptions"
            :value="periodType"
            labelRequiresTranslation
            @onChange="changePeriodTypeFn"
          >
          </BaseSelect>
          <div v-if="periodType == 0" class="tw-flex tw-flex-col md:tw-flex-row tw-flex-1 tw-gap-2">
            <BaseTextField
              :id="'datefrom'"
              :type="'date'"
              aria-label="Date from"
              :value="startDate"
              :max="getMaxStartDate"
              :min="getMinStartDate"
              @onChange="changeStartDateFn"
            />
            <BaseTextField
              :id="'dateto'"
              :value="endDate"
              :max="getMaxEndDate"
              :min="getMinEndDate"
              :type="'date'"
              aria-label="Date to"
              @onChange="changeEndDateFn"
            />
          </div>
        </div>
        <ButtonGroup :items="aggregationOptions" :value="aggregationType" @onClick="changeAggregationTypeFn" />
      </div>
      <PerformanceToggle :isEnabled="impressions" @toggleImpressions="changeImpressionsFn" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import BaseSelect from '@/components/BaseSelect.vue'
import ButtonGroup from '@/components/ButtonGroup.vue'
import BaseTextField from '@/components/base/inputs/BaseTextField.vue'
import PerformanceToggle from '@/components/profilePerformance/performance/PerformanceToggle.vue'

const props = defineProps({
  aggregationType: String,
  startDate: String,
  endDate: String,
  periodType: Number,
  impressions: Boolean,
})

const emit = defineEmits([
  'changeAggregationType',
  'changeStartDate',
  'changeEndDate',
  'changeImpressions',
  'changePeriodType',
])

const changeImpressionsFn = value => emit('changeImpressions', value)
const changeStartDateFn = date => emit('changeStartDate', date)
const changeEndDateFn = date => emit('changeEndDate', date)
const changeAggregationTypeFn = type => emit('changeAggregationType', type)
const changePeriodTypeFn = type => emit('changePeriodType', type)

const getMaxEndDate = computed(() => {
  let date = new Date()
  date = date.setDate(date.getDate() - 1)
  return new Date(date).toISOString().split('T')[0]
})
const getMinEndDate = computed(() => {
  // prevents selecting end date before start date
  let date = new Date(props.startDate).getTime() + 1000 * 60 * 60 * 24
  new Date(date).setFullYear(new Date(date).getFullYear() - 1)
  return new Date(date).toISOString().split('T')[0]
})

const getMaxStartDate = computed(() => {
  let date = new Date(props.endDate)
  date = date.setDate(date.getDate() - 1)
  return new Date(date).toISOString().split('T')[0]
})

const getMinStartDate = computed(() => {
  let date = new Date()
  date.setFullYear(date.getFullYear() - 1)
  return new Date(date).toISOString().split('T')[0]
})
const periodTypeOptions = computed(() => {
  return [
    { label: 'performance_last90days', value: 90 },
    { label: 'performance_last30days', value: 30 },
    { label: 'performance_last7days', value: 7 },
    { label: 'performance_custom_dates', value: 0 },
  ]
})
const aggregationOptions = computed(() => {
  return [
    { label: 'performance_aggregation_daily', value: 'daily' },
    { label: 'performance_aggregation_weekly', value: 'weekly' },
    { label: 'performance_aggregation_monthly', value: 'monthly' },
  ]
})
</script>
