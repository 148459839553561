export default class BaseService {
  constructor({ baseUrl = '' } = {}) {
    this.baseUrl = baseUrl
  }

  setJLoToken(value = '') {
    this.JLoToken = `Bearer ${value}`
  }

  setStudioToken(value = '') {
    this.studioToken = `Bearer ${value}`
  }
}
