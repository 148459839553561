<template>
  <g id="RC-FL---Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="RC-FL-Settings-Campaing-Type-1-5" transform="translate(-721.000000, -320.000000)">
      <g id="Group-9" transform="translate(306.000000, 192.000000)">
        <g id="Group-10" transform="translate(32.000000, 128.000000)">
          <g id="Icons/Interface/Calendar-24x24-Line" transform="translate(383.000000, 0.000000)">
            <g id="Group" transform="translate(3.000000, 4.000000)" fill="#007CC2">
              <polygon id="Shape" points="9 8.7 9 13 10 13 10 7 9.3 7 7.5 8.8 8.2 9.5"></polygon>
              <path
                id="Shape"
                d="M16,1 L15,1 C15,0.4 14.6,0 14,0 C13.4,0 13,0.4 13,1 L5,1 C5,0.4 4.6,0 4,0 C3.4,0 3,0.4 3,1 L2,1 C0.9,1 0,1.9 0,3 L0,14 C0,15.1 0.9,16 2,16 L16,16 C17.1,16 18,15.1 18,14 L18,3 C18,1.9 17.1,1 16,1 L16,1 Z M17,14 C17,14.6 16.6,15 16,15 L2,15 C1.4,15 1,14.6 1,14 L1,5 L17,5 L17,14 L17,14 Z M17,4 L1,4 L1,3 C1,2.4 1.4,2 2,2 L16,2 C16.6,2 17,2.4 17,3 L17,4 L17,4 Z"
              ></path>
            </g>
            <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
          </g>
        </g>
      </g>
    </g>
  </g>
</template>
