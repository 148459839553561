import { useMutation, useQueryClient } from '@tanstack/vue-query'
import AdminService from '@/services/AdminService'

export function useDeleteAssignment() {
  const queryClient = useQueryClient()
  const {
    isLoading,
    isSuccess,
    isError,
    mutate: deleteAssignment,
  } = useMutation({
    mutationFn: async ({ accommodationId }) => await AdminService.deleteAssignment({ accommodationId }),
    onSuccess: (_, mutationInput) => {
      queryClient.removeQueries({ queryKey: ['accommodation', mutationInput.accommodationId] })
      queryClient.removeQueries({ queryKey: ['paidSubscriptions', mutationInput.accommodationId] })
    },
  })

  return {
    isLoading,
    isSuccess,
    isError,
    deleteAssignment,
  }
}

export function useInviteUserInAdminTool() {
  const {
    isLoading,
    isSuccess,
    isError,
    mutate: inviteUserAsAdmin,
  } = useMutation({
    mutationFn: ({ organisationId, data }) => AdminService.inviteUserAsAdmin({ organisationId, data }),
  })

  return {
    isLoading,
    isSuccess,
    isError,
    inviteUserAsAdmin,
  }
}
