<template>
  <div class="tw-bg-white tw-rounded-xl base-card" :class="{ 'tw-p-5': !noPadding }" :data-testid="dataTestId">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    noPadding: {
      type: Boolean,
      required: false,
    },
    dataTestId: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
.base-card {
  box-shadow: 0 2px 4px 0 rgba(63, 87, 189, 0.1), 0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
</style>
