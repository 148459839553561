<template>
  <div :class="{ 'tw-min-h-[630px]': dataForCurrentAccommodation }">
    <div v-if="isVisitorsProfileDurationLoading" class="tw-h-full tw-bg-gray-300 tw-animate-pulse tw-rounded-lg"></div>
    <div v-else-if="isVisitorsProfileDurationError" class="tw-h-full"></div>
    <div v-else>
      <DurationGraph
        :series="dataFormattedForGraph"
        :isWaitingMode="!dataForCurrentAccommodation"
        :language="language"
        :categories="categories"
        :class="{ 'tw-h-[550px] tw-pb-4 tw-border-b-2 tw-border-b-gray-200': dataForCurrentAccommodation }"
      />

      <div class="tw-pl-4 tw-mt-9 tw-flex tw-items-center tw-gap-2" v-if="dataForCurrentAccommodation">
        <span class="tw-w-4 tw-h-4 tw-rounded-full tw-bg-graph-blue tw-inline-block" />
        <span>{{ $t('vp_stay_graph_info') }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import DurationGraph from '@/components/visitorsProfile/durationView/DurationGraph.vue'
import { useVisitorsProfileDuration } from '@/components/visitorsProfile/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const { t } = useI18n()
const store = useStore()
const props = defineProps({
  timeFrame: {
    type: String,
    required: true,
    default: '30',
  },
})

const language = computed(() => store.state.locale.language)

const timeFrameSelected = computed(() => props.timeFrame)

const { isVisitorsProfileDurationLoading, isVisitorsProfileDurationError, visitorsProfileDuration } =
  useVisitorsProfileDuration(selectedAccommodationId, timeFrameSelected)

const dataForCurrentAccommodation = computed(() => visitorsProfileDuration.value?.[selectedAccommodationId.value])

const dataFormattedForGraph = computed(() => {
  if (!selectedAccommodationId.value || !dataForCurrentAccommodation.value) return [{ data: [0, 0, 0, 0, 0] }]
  const { lte2days, days34, days56, days78, gte9days } = dataForCurrentAccommodation.value
  const series = [
    {
      data: [lte2days ?? 0, days34 ?? 0, days56 ?? 0, days78 ?? 0, gte9days ?? 0],
    },
  ]
  return series
})

const categories = computed(() => {
  return [
    t('vp_graph_1night'),
    t('vp_graph_2nights'),
    t('vp_graph_4nights'),
    t('vp_graph_6nights'),
    t('vp_graph_8nights'),
  ]
})
</script>
