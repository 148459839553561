<template>
  <svg width="237" height="23" viewBox="0 0 237 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.1467 5.65039H22.823C22.7271 5.65039 22.6494 5.7288 22.6494 5.82553V16.6864C22.6494 16.7831 22.7271 16.8615 22.823 16.8615H26.1467C26.2426 16.8615 26.3203 16.7831 26.3203 16.6864V5.82553C26.3203 5.7288 26.2426 5.65039 26.1467 5.65039Z"
      fill="#E32851"
    />
    <path
      d="M35.7551 5.76313L33.5666 11.6435C33.5546 11.6777 33.5067 11.6777 33.4948 11.6435L31.2882 5.76313C31.2623 5.69468 31.1985 5.65039 31.1266 5.65039H27.4917C27.37 5.65039 27.2862 5.7752 27.3321 5.88995L31.7032 16.7528C31.7291 16.8192 31.793 16.8615 31.8628 16.8615H35.0589C35.1287 16.8615 35.1925 16.8192 35.2185 16.7528L39.6095 5.88995C39.6554 5.7752 39.5716 5.65039 39.4499 5.65039H35.9167C35.8449 5.65039 35.7811 5.69468 35.7551 5.76313Z"
      fill="#FF932C"
    />
    <path
      d="M70.6123 5.48047C67.1429 5.48047 64.6152 7.94856 64.6152 11.2682C64.6152 14.5879 67.1429 17.056 70.6123 17.056C74.0817 17.056 76.6094 14.5677 76.6094 11.2682C76.6094 7.9687 74.0617 5.48047 70.6123 5.48047ZM70.6123 13.8994C69.2497 13.8994 68.346 12.8465 68.346 11.2682C68.346 9.68992 69.2477 8.63705 70.6123 8.63705C71.9769 8.63705 72.8787 9.68992 72.8787 11.2682C72.8787 12.8465 71.957 13.8994 70.6123 13.8994Z"
      fill="#0088D9"
    />
    <path
      d="M21.4346 5.59637C21.3408 5.56215 21.2171 5.53397 21.0875 5.51383C20.874 5.47961 18.6196 5.22394 17.3647 6.92302C17.3428 6.95322 17.2949 6.93712 17.2949 6.89887V6.00906V5.82587C17.2949 5.72924 17.2171 5.65073 17.1213 5.65073H13.7976C13.7018 5.65073 13.624 5.72924 13.624 5.82587V16.6887C13.624 16.7853 13.7018 16.8639 13.7976 16.8639H17.1213C17.2171 16.8639 17.2949 16.7853 17.2949 16.6887V12.6564C17.2949 9.64075 18.6795 8.89186 20.0421 8.89186C20.2017 8.89186 20.6685 8.89186 21.3269 9.07506C21.4366 9.10526 21.5443 9.02272 21.5443 8.90797V5.76346C21.5443 5.69099 21.5004 5.62456 21.4326 5.6004L21.4346 5.59637Z"
      fill="#E32851"
    />
    <path
      d="M24.4855 0.574219C23.3264 0.574219 22.3867 1.48818 22.3867 2.61553C22.3867 3.74288 23.3264 4.65684 24.4855 4.65684C25.6446 4.65684 26.5843 3.74288 26.5843 2.61553C26.5843 1.48818 25.6446 0.574219 24.4855 0.574219Z"
      fill="#E32851"
    />
    <path
      d="M50.985 5.65139H47.6613C47.5655 5.65139 47.4877 5.7299 47.4877 5.82653V6.8955C47.4877 6.93778 47.4318 6.94986 47.4139 6.91161C46.2129 4.37305 39.1025 4.86627 39.1025 11.258C39.1025 17.2329 45.1774 18.2959 47.4199 15.7955C47.4438 15.7694 47.4877 15.7855 47.4877 15.8217V16.6894C47.4877 16.786 47.5655 16.8645 47.6613 16.8645H50.985C51.0807 16.8645 51.1586 16.786 51.1586 16.6894V5.82452C51.1586 5.72789 51.0807 5.64937 50.985 5.64937V5.65139ZM45.1834 13.8871C43.7191 13.8871 42.6976 12.8564 42.6976 11.268C42.6976 9.67966 43.7191 8.62679 45.1834 8.62679C46.6478 8.62679 47.6692 9.7018 47.6692 11.268C47.6692 12.8342 46.6717 13.8871 45.1834 13.8871Z"
      fill="#FF932C"
    />
    <path
      d="M63.5403 5.6507H60.2165C60.1208 5.6507 60.043 5.72921 60.043 5.82584V6.89482C60.043 6.93709 59.9871 6.94917 59.9692 6.91092C58.7642 4.34015 52.1406 4.98637 52.1406 10.9332C52.1406 16.7652 58.1776 17.2443 59.9771 15.5472C60.0011 15.5231 60.041 15.5412 60.041 15.5754V16.1009C59.9951 18.579 56.9187 19.652 55.3566 17.7697C55.3008 17.7033 55.203 17.6872 55.1312 17.7355L52.3302 19.5634C52.2464 19.6178 52.2264 19.7325 52.2863 19.8151C55.8614 24.7452 63.656 22.2369 63.7138 16.1129V5.82383C63.7138 5.7272 63.636 5.64869 63.5403 5.64869V5.6507ZM58.0579 13.3006C56.7352 13.3006 55.8115 12.3705 55.8115 10.9332C55.8115 9.49578 56.7332 8.54558 58.0579 8.54558C59.3826 8.54558 60.3043 9.51792 60.3043 10.9332C60.3043 12.3484 59.4026 13.3006 58.0579 13.3006Z"
      fill="#0088D9"
    />
    <path
      d="M12.467 10.7262H9.13728C9.04949 10.7262 8.97169 10.7987 8.9657 10.8893C8.85797 12.2683 7.71681 13.3533 6.32228 13.3533C4.64844 13.3533 3.97212 11.92 3.97212 11.92C3.68284 11.3704 3.66888 10.841 3.66888 10.7262C3.66888 10.7121 3.67686 10.7 3.68883 10.692L4.64046 10.1605L10.6595 6.80263C10.7513 6.7523 10.8071 6.65366 10.8071 6.54897V2.93742C10.8071 2.80455 10.6655 2.72 10.5518 2.78442L3.72673 6.59326C3.7008 6.60735 3.66888 6.58924 3.66888 6.55904V0.882014C3.66888 0.743108 3.51725 0.66057 3.40154 0.735056L0.131673 2.87501C0.049876 2.92937 0 3.01996 0 3.1186V10.7262C0.0279305 14.227 2.84692 17.0555 6.32228 17.0555C9.79763 17.0555 12.5189 14.3237 12.6406 10.9094C12.6446 10.8108 12.5667 10.7262 12.467 10.7262Z"
      fill="#E32851"
    />
    <path
      d="M89.1009 3.48828C89.9109 3.48828 90.6291 3.62316 91.2556 3.89091C91.88 4.15865 92.3568 4.53913 92.682 5.02832C93.0072 5.51752 93.1708 6.09126 93.1708 6.74955C93.1708 7.32732 93.0311 7.83664 92.7518 8.27953C92.4725 8.72242 92.0695 9.07471 91.5448 9.33642C91.0201 9.59813 90.4037 9.75515 89.6994 9.80951V10.1115C90.9503 10.1517 91.9438 10.4376 92.682 10.9671C93.4202 11.4965 93.7892 12.2514 93.7892 13.2318C93.7892 13.9425 93.6257 14.5665 93.3005 15.104C92.9753 15.6415 92.4765 16.0703 91.8042 16.3924C91.1319 16.7145 90.3179 16.8756 89.3603 16.8756H82.9961V3.48828H89.1009ZM88.5622 9.08478C89.1468 9.08478 89.6096 8.9338 89.9488 8.63183C90.288 8.32986 90.4575 7.90307 90.4575 7.35349C90.4575 6.80391 90.284 6.42141 89.9388 6.12548C89.5937 5.82955 89.1328 5.68259 88.5622 5.68259H85.6096V9.08478H88.5622ZM88.9214 14.6813C89.3603 14.6813 89.7313 14.6108 90.0386 14.4699C90.3438 14.329 90.5832 14.1216 90.7568 13.8458C90.9304 13.57 91.0161 13.2459 91.0161 12.8695C91.0161 12.2917 90.8286 11.8387 90.4575 11.5106C90.0845 11.1825 89.5737 11.0174 88.9214 11.0174H85.6096V14.6813H88.9214Z"
      fill="#1D1D1B"
    />
    <path
      d="M103.824 16.8762H101.19V16.7353C101.204 16.5199 101.24 16.3286 101.3 16.1615C101.36 15.9944 101.45 15.7831 101.57 15.5274C101.675 15.3261 101.769 15.1107 101.849 14.8832C101.929 14.6557 102.002 14.4061 102.068 14.1383L101.749 14.0377L101.669 14.2994C101.39 15.2113 100.945 15.9099 100.333 16.393C99.7201 16.8762 98.9421 17.1178 97.9984 17.1178C97.374 17.1178 96.8174 17.007 96.3326 16.7856C95.8458 16.5642 95.4587 16.1958 95.1655 15.6784C94.8722 15.161 94.7266 14.5007 94.7266 13.6954V6.81055H97.38V13.1922C97.38 13.782 97.5336 14.2249 97.8388 14.5208C98.1441 14.8168 98.577 14.9637 99.1356 14.9637H99.1954C99.768 14.9637 100.243 14.7825 100.622 14.4202C101.001 14.0578 101.19 13.5747 101.19 12.9707V6.81055H103.824V16.8762Z"
      fill="#1D1D1B"
    />
    <path
      d="M107.436 13.4533C107.462 13.8297 107.561 14.1438 107.735 14.3994C107.909 14.6551 108.138 14.8524 108.423 14.9933C108.709 15.1342 109.018 15.2047 109.351 15.2047C109.656 15.2047 109.93 15.1644 110.169 15.0839C110.408 15.0034 110.604 14.8765 110.758 14.7014C110.911 14.5263 110.987 14.3189 110.987 14.0773C110.987 13.7693 110.873 13.5237 110.648 13.3425C110.422 13.1614 110.069 13.0043 109.59 12.8695L107.715 12.3863C106.837 12.1588 106.181 11.8085 105.75 11.3395C105.317 10.8704 105.102 10.2665 105.102 9.52766C105.102 8.93781 105.281 8.4144 105.64 7.95742C105.999 7.50044 106.498 7.15619 107.137 6.92066C107.775 6.68512 108.487 6.56836 109.271 6.56836C110.055 6.56836 110.712 6.70525 111.356 6.98105C112 7.25685 112.519 7.65545 112.912 8.17886C113.305 8.70228 113.527 9.32635 113.58 10.0511H111.007C110.981 9.67462 110.877 9.36661 110.698 9.12503C110.518 8.88346 110.299 8.70228 110.039 8.58149C109.78 8.4607 109.497 8.40031 109.191 8.40031C108.739 8.40031 108.379 8.49492 108.114 8.68214C107.849 8.86937 107.715 9.13107 107.715 9.46727C107.715 9.72293 107.807 9.94035 107.994 10.1215C108.18 10.3027 108.485 10.4537 108.912 10.5745L110.827 11.0375C111.717 11.265 112.407 11.6112 112.892 12.0743C113.377 12.5373 113.62 13.1513 113.62 13.9163C113.62 14.5746 113.451 15.1443 113.112 15.6274C112.773 16.1106 112.28 16.479 111.635 16.7347C110.991 16.9903 110.235 17.1172 109.371 17.1172C108.507 17.1172 107.709 16.9662 107.057 16.6642C106.404 16.3622 105.886 15.9395 105.501 15.3959C105.114 14.8524 104.888 14.2042 104.822 13.4533H107.436Z"
      fill="#1D1D1B"
    />
    <path
      d="M116.374 5.58279C115.935 5.58279 115.566 5.43181 115.266 5.12984C114.967 4.82787 114.817 4.46953 114.817 4.05282C114.817 3.6361 114.967 3.28179 115.266 2.98586C115.566 2.68993 115.935 2.54297 116.374 2.54297C116.812 2.54297 117.158 2.68993 117.451 2.98586C117.742 3.28179 117.89 3.6361 117.89 4.05282C117.89 4.46953 117.744 4.82787 117.451 5.12984C117.158 5.43181 116.798 5.58279 116.374 5.58279ZM115.057 6.8108H117.69V16.8764H115.057V6.8108Z"
      fill="#1D1D1B"
    />
    <path
      d="M119.386 6.80993H122.039V6.95085C122.039 7.16626 122.009 7.35751 121.949 7.5246C121.89 7.69169 121.792 7.93125 121.66 8.23926C121.552 8.46674 121.461 8.69221 121.381 8.91365C121.301 9.1351 121.227 9.37265 121.161 9.62832L121.461 9.72897L121.56 9.46727C121.84 8.5674 122.314 7.86079 122.987 7.34341C123.659 6.82604 124.473 6.56836 125.431 6.56836C126.055 6.56836 126.612 6.67908 127.097 6.90053C127.581 7.12197 127.97 7.49037 128.264 8.00775C128.555 8.52512 128.703 9.18543 128.703 9.99068V16.8756H126.049V10.5745C126.049 9.98464 125.876 9.54175 125.53 9.24582C125.185 8.94989 124.726 8.80293 124.154 8.80293H124.094C123.721 8.80293 123.38 8.88346 123.067 9.04451C122.753 9.20556 122.504 9.43103 122.318 9.71891C122.133 10.0068 122.039 10.353 122.039 10.7557V16.8756H119.386V6.80993Z"
      fill="#1D1D1B"
    />
    <path
      d="M134.628 17.1172C133.562 17.1172 132.664 16.8997 131.934 16.4629C131.202 16.026 130.649 15.4161 130.278 14.6309C129.905 13.8458 129.72 12.9238 129.72 11.8629C129.72 10.802 129.925 9.85379 130.338 9.05457C130.749 8.25536 131.326 7.64136 132.064 7.21256C132.802 6.78376 133.67 6.56836 134.667 6.56836C135.665 6.56836 136.523 6.77974 137.241 7.20249C137.959 7.62525 138.508 8.23523 138.887 9.03444C139.266 9.83365 139.456 10.7818 139.456 11.883V12.3863L132.373 12.4064V12.4266C132.387 12.9359 132.489 13.3929 132.682 13.7955C132.874 14.1981 133.141 14.5102 133.48 14.7316C133.82 14.953 134.221 15.0638 134.687 15.0638H134.747C135.32 15.0638 135.801 14.9088 136.194 14.6007C136.585 14.2927 136.828 13.9102 136.922 13.4533H139.615C139.481 14.1921 139.192 14.8363 138.747 15.3859C138.302 15.9354 137.72 16.3622 137.002 16.6642C136.283 16.9662 135.491 17.1172 134.628 17.1172ZM136.882 10.7154C136.842 10.2987 136.736 9.91619 136.563 9.56792C136.389 9.21965 136.148 8.94385 135.835 8.74254C135.521 8.54123 135.14 8.44057 134.687 8.44057H134.568C133.943 8.44057 133.436 8.65799 133.051 9.09484C132.664 9.53169 132.439 10.0712 132.373 10.7154H136.882Z"
      fill="#1D1D1B"
    />
    <path
      d="M143.145 13.4533C143.171 13.8297 143.27 14.1438 143.444 14.3994C143.618 14.6551 143.847 14.8524 144.132 14.9933C144.418 15.1342 144.727 15.2047 145.06 15.2047C145.365 15.2047 145.639 15.1644 145.878 15.0839C146.117 15.0034 146.313 14.8765 146.466 14.7014C146.62 14.5263 146.696 14.3189 146.696 14.0773C146.696 13.7693 146.582 13.5237 146.357 13.3425C146.131 13.1614 145.778 13.0043 145.299 12.8695L143.424 12.3863C142.546 12.1588 141.89 11.8085 141.459 11.3395C141.026 10.8704 140.811 10.2665 140.811 9.52766C140.811 8.93781 140.99 8.4144 141.349 7.95742C141.708 7.50044 142.207 7.15619 142.845 6.92066C143.484 6.68512 144.196 6.56836 144.98 6.56836C145.764 6.56836 146.421 6.70525 147.065 6.98105C147.709 7.25685 148.228 7.65545 148.621 8.17886C149.014 8.70228 149.236 9.32635 149.289 10.0511H146.716C146.69 9.67462 146.586 9.36661 146.407 9.12503C146.227 8.88346 146.008 8.70228 145.748 8.58149C145.489 8.4607 145.206 8.40031 144.9 8.40031C144.448 8.40031 144.088 8.49492 143.823 8.68214C143.558 8.86937 143.424 9.13107 143.424 9.46727C143.424 9.72293 143.516 9.94035 143.703 10.1215C143.889 10.3027 144.194 10.4537 144.621 10.5745L146.536 11.0375C147.426 11.265 148.116 11.6112 148.601 12.0743C149.086 12.5373 149.329 13.1513 149.329 13.9163C149.329 14.5746 149.16 15.1443 148.821 15.6274C148.481 16.1106 147.989 16.479 147.344 16.7347C146.7 16.9903 145.944 17.1172 145.08 17.1172C144.216 17.1172 143.418 16.9662 142.766 16.6642C142.113 16.3622 141.595 15.9395 141.21 15.3959C140.823 14.8524 140.597 14.2042 140.531 13.4533H143.145Z"
      fill="#1D1D1B"
    />
    <path
      d="M152.84 13.4533C152.866 13.8297 152.966 14.1438 153.139 14.3994C153.313 14.6551 153.542 14.8524 153.828 14.9933C154.113 15.1342 154.422 15.2047 154.755 15.2047C155.061 15.2047 155.334 15.1644 155.573 15.0839C155.813 15.0034 156.008 14.8765 156.162 14.7014C156.315 14.5263 156.391 14.3189 156.391 14.0773C156.391 13.7693 156.278 13.5237 156.052 13.3425C155.827 13.1614 155.474 13.0043 154.995 12.8695L153.119 12.3863C152.242 12.1588 151.587 11.8085 151.154 11.3395C150.721 10.8704 150.506 10.2665 150.506 9.52766C150.506 8.93781 150.685 8.4144 151.045 7.95742C151.404 7.50044 151.902 7.15619 152.541 6.92066C153.179 6.68512 153.891 6.56836 154.675 6.56836C155.46 6.56836 156.114 6.70525 156.76 6.98105C157.405 7.25685 157.923 7.65545 158.316 8.17886C158.707 8.70228 158.931 9.32635 158.985 10.0511H156.411C156.385 9.67462 156.282 9.36661 156.102 9.12503C155.922 8.88346 155.703 8.70228 155.444 8.58149C155.184 8.4607 154.901 8.40031 154.596 8.40031C154.143 8.40031 153.784 8.49492 153.518 8.68214C153.253 8.86937 153.119 9.13107 153.119 9.46727C153.119 9.72293 153.213 9.94035 153.399 10.1215C153.584 10.3027 153.891 10.4537 154.316 10.5745L156.232 11.0375C157.121 11.265 157.81 11.6112 158.296 12.0743C158.781 12.5373 159.025 13.1513 159.025 13.9163C159.025 14.5746 158.855 15.1443 158.516 15.6274C158.177 16.1106 157.684 16.479 157.04 16.7347C156.395 16.9903 155.639 17.1172 154.775 17.1172C153.911 17.1172 153.113 16.9662 152.461 16.6642C151.809 16.3622 151.29 15.9395 150.905 15.3959C150.52 14.8524 150.292 14.2042 150.227 13.4533H152.84Z"
      fill="#1D1D1B"
    />
    <path
      d="M167.205 12.0253C167.245 12.6292 167.374 13.1466 167.594 13.5754C167.813 14.0042 168.142 14.3404 168.581 14.582C169.02 14.8235 169.565 14.9443 170.217 14.9443C170.67 14.9443 171.081 14.8799 171.454 14.7531C171.827 14.6263 172.123 14.435 172.342 14.1793C172.561 13.9237 172.671 13.6016 172.671 13.213C172.671 12.8245 172.557 12.4843 172.332 12.2367C172.107 11.9891 171.839 11.8079 171.534 11.6931C171.229 11.5784 170.856 11.4757 170.417 11.3811L168.422 10.8778C167.225 10.596 166.313 10.143 165.689 9.51894C165.064 8.89487 164.751 8.11378 164.751 7.17365C164.751 6.32813 164.97 5.61347 165.409 5.02967C165.848 4.44586 166.453 4.00297 167.225 3.701C167.997 3.39903 168.875 3.24805 169.858 3.24805C170.896 3.24805 171.819 3.4252 172.631 3.78153C173.443 4.13785 174.094 4.65321 174.586 5.33164C175.077 6.01006 175.37 6.81129 175.464 7.73733H172.571C172.545 7.26827 172.408 6.85155 172.162 6.48918C171.917 6.12682 171.598 5.85102 171.205 5.6638C170.812 5.47658 170.363 5.38196 169.858 5.38196C169.234 5.38196 168.691 5.51886 168.232 5.79466C167.773 6.07045 167.544 6.46301 167.544 6.97234C167.544 7.41522 167.719 7.77356 168.073 8.04936C168.426 8.32516 168.92 8.52848 169.559 8.66336L171.753 9.20691C172.95 9.48875 173.872 9.93566 174.517 10.5456C175.161 11.1556 175.484 11.9911 175.484 13.052C175.484 13.8975 175.255 14.6263 174.796 15.2362C174.337 15.8462 173.705 16.3133 172.901 16.6354C172.097 16.9575 171.181 17.1185 170.157 17.1185C169.026 17.1185 168.033 16.9232 167.175 16.5347C166.317 16.1462 165.643 15.5724 165.15 14.8135C164.657 14.0545 164.372 13.1265 164.292 12.0253H167.205Z"
      fill="#1D1D1B"
    />
    <path
      d="M181.708 16.8963C181.694 16.8963 181.682 16.9004 181.668 16.9064C181.654 16.9124 181.642 16.9165 181.628 16.9165C180.976 17.0514 180.411 17.1178 179.933 17.1178C178.789 17.1178 177.983 16.8158 177.519 16.2119C177.054 15.6079 176.82 14.6819 176.82 13.4338V3.99219H179.474V6.89109H181.648V8.84383H179.474V13.4942C179.474 13.937 179.534 14.2551 179.653 14.4504C179.773 14.6457 180.026 14.7423 180.411 14.7423C180.597 14.7423 180.814 14.7282 181.06 14.702C181.305 14.6759 181.501 14.6477 181.648 14.6215H181.708V16.8963Z"
      fill="#1D1D1B"
    />
    <path
      d="M192.142 16.8762H189.509V16.7353C189.523 16.5199 189.559 16.3286 189.619 16.1615C189.678 15.9944 189.768 15.7831 189.888 15.5274C189.994 15.3261 190.087 15.1107 190.167 14.8832C190.247 14.6557 190.321 14.4061 190.387 14.1383L190.067 14.0377L189.988 14.2994C189.708 15.2113 189.263 15.9099 188.651 16.393C188.038 16.8762 187.26 17.1178 186.317 17.1178C185.692 17.1178 185.136 17.007 184.651 16.7856C184.164 16.5642 183.777 16.1958 183.484 15.6784C183.191 15.161 183.045 14.5007 183.045 13.6954V6.81055H185.698V13.1922C185.698 13.782 185.852 14.2249 186.157 14.5208C186.462 14.8168 186.895 14.9637 187.454 14.9637H187.514C188.086 14.9637 188.561 14.7825 188.94 14.4202C189.319 14.0578 189.509 13.5747 189.509 12.9707V6.81055H192.142V16.8762Z"
      fill="#1D1D1B"
    />
    <path
      d="M197.609 17.1176C196.797 17.1176 196.069 16.9062 195.425 16.4835C194.78 16.0607 194.272 15.4527 193.898 14.6616C193.525 13.8704 193.34 12.9363 193.34 11.8633C193.34 10.7903 193.525 9.89046 193.898 9.0852C194.272 8.27995 194.782 7.65991 195.435 7.22306C196.087 6.78621 196.817 6.56879 197.629 6.56879C198.174 6.56879 198.687 6.67348 199.165 6.88083C199.644 7.08818 200.069 7.40827 200.442 7.83706C200.815 8.26586 201.12 8.8235 201.36 9.50796L201.46 9.7898L201.759 9.70927C201.679 9.42744 201.599 9.1758 201.52 8.95435C201.44 8.73291 201.354 8.5014 201.26 8.25982C201.154 7.99207 201.067 7.74647 201.001 7.52503C200.933 7.30358 200.887 7.07207 200.861 6.8305C200.847 6.70971 200.841 6.5849 200.841 6.45807V2.88477H203.554V16.876H200.841V16.6747C200.841 16.419 200.875 16.1714 200.941 15.9298C201.007 15.6883 201.121 15.3662 201.28 14.9635C201.294 14.9233 201.304 14.8891 201.31 14.8629C201.316 14.8367 201.326 14.8166 201.34 14.8025L201.041 14.6616L200.981 14.8025C200.795 15.2595 200.552 15.6581 200.253 16.0003C199.953 16.3425 199.58 16.6143 199.135 16.8156C198.691 17.0169 198.182 17.1176 197.609 17.1176ZM198.547 15.0843C199.066 15.0843 199.505 14.9474 199.864 14.6716C200.223 14.3958 200.488 14.0174 200.662 13.5342C200.835 13.0511 200.921 12.4934 200.921 11.8633C200.921 11.2332 200.831 10.6715 200.652 10.1824C200.472 9.69317 200.203 9.31067 199.844 9.03488C199.485 8.75908 199.052 8.62218 198.547 8.62218H198.427C197.92 8.62218 197.492 8.75908 197.14 9.03488C196.787 9.31067 196.528 9.68914 196.362 10.1723C196.195 10.6554 196.113 11.2191 196.113 11.8633C196.113 12.8437 196.308 13.6248 196.701 14.2086C197.093 14.7924 197.669 15.0843 198.427 15.0843H198.547Z"
      fill="#1D1D1B"
    />
    <path
      d="M206.666 5.58279C206.228 5.58279 205.858 5.43181 205.559 5.12984C205.26 4.82787 205.11 4.46953 205.11 4.05282C205.11 3.6361 205.26 3.28179 205.559 2.98586C205.858 2.68993 206.228 2.54297 206.666 2.54297C207.105 2.54297 207.451 2.68993 207.744 2.98586C208.035 3.28179 208.183 3.6361 208.183 4.05282C208.183 4.46953 208.037 4.82787 207.744 5.12984C207.451 5.43181 207.091 5.58279 206.666 5.58279ZM205.35 6.8108H207.983V16.8764H205.35V6.8108Z"
      fill="#1D1D1B"
    />
    <path
      d="M214.288 17.1172C213.304 17.1172 212.428 16.9018 211.664 16.473C210.9 16.0442 210.304 15.4302 209.879 14.6309C209.454 13.8317 209.24 12.8896 209.24 11.8025C209.24 10.7154 209.454 9.7793 209.879 8.99418C210.304 8.20906 210.896 7.60915 211.654 7.19243C212.412 6.77571 213.29 6.56836 214.288 6.56836C215.285 6.56836 216.163 6.77571 216.921 7.19243C217.679 7.60915 218.272 8.20906 218.697 8.99418C219.122 9.7793 219.335 10.7154 219.335 11.8025C219.335 12.8896 219.122 13.8317 218.697 14.6309C218.272 15.4302 217.675 16.0442 216.911 16.473C216.147 16.9018 215.271 17.1172 214.288 17.1172ZM214.348 15.0839C214.852 15.0839 215.275 14.943 215.614 14.6611C215.954 14.3793 216.203 13.9908 216.363 13.4935C216.522 12.9963 216.602 12.4407 216.602 11.8226C216.602 11.2046 216.518 10.6711 216.353 10.1819C216.187 9.69274 215.94 9.30621 215.614 9.02438C215.289 8.74254 214.872 8.60162 214.367 8.60162H214.208C213.715 8.60162 213.304 8.74656 212.971 9.03444C212.638 9.32232 212.388 9.71287 212.223 10.2021C212.057 10.6912 211.973 11.2328 211.973 11.8226C211.973 12.7487 212.159 13.5237 212.532 14.1478C212.905 14.7719 213.47 15.0839 214.228 15.0839H214.348Z"
      fill="#1D1D1B"
    />
    <path
      d="M230.438 11.348V16.22H228.35V11.348H224.03V9.452H228.35V4.772H230.438V9.452H234.734V11.348H230.438Z"
      fill="black"
    />
  </svg>
</template>
