<template>
  <CustomModal
    class="add-language-modal"
    :is-modal-open="isOpen"
    @toggleModal="closeAdding"
    @onClickAway="closeAdding"
    :overlay="'dark'"
    :with-header="true"
    :with-footer="true"
    :custom-classes="'md:tw-max-w-[500px] md:tw-w-[500px] md:tw-h-auto tw-h-[100vh] tw-w-[100vw] md:tw-rounded-md tw-rounded-none'"
  >
    <template #modalHeader>
      <p class="tw-text-xl tw-text-gray-700">{{ $t('hotel_details_add_language') }}</p>
    </template>
    <div v-if="editItem !== null && !isLoading && availableLanguages" class="tw-flex tw-w-full tw-flex-col">
      <div class="tw-mt-2 tw-flex tw-w-full">
        <BaseSelect
          :label="$t('app_description_language')"
          name="language"
          :display-text-key="'label'"
          :value-key="'value'"
          :options="getLanguageOptions"
          labelRequiresTranslation
          :value="editItem.languageCode"
          :placeholder="$t('form.options.default')"
          version="light"
          class="tw-w-full tw-mb-4 md:tw-mb-auto"
          size="medium"
          :error="errors['languageCode'] ? $t(errors['languageCode']) : false"
          @onChange="e => updateValue('languageCode', e)"
        />
      </div>
      <div class="tw-mt-2 tw-flex tw-w-full">
        <BaseTextField
          :label="$t('app_hotel_details_hotel_name_form')"
          name="name"
          aria-label="name"
          type="text"
          class="tw-w-full tw-mb-4 md:tw-mb-auto"
          size="medium"
          :value="editItem.name"
          :error="errors['name'] ? $t(errors['name']) : false"
          @onChange="e => updateValue('name', e)"
        />
      </div>
      <div class="tw-mt-2 tw-flex tw-w-full">
        <BaseTextField
          :label="$t('app_hotel_details_hotel_address_form')"
          name="streetAddress"
          aria-label="streetAddress"
          type="text"
          class="tw-w-full tw-mb-4 md:tw-mb-auto"
          size="medium"
          :error="errors['streetAddress'] ? $t(errors['streetAddress']) : false"
          :value="editItem.streetAddress"
          @onChange="e => updateValue('streetAddress', e)"
        />
      </div>
      <div class="tw-mt-2 tw-flex tw-w-full">
        <BaseTextField
          :label="$t('app_hotel_details_hotel_website_form')"
          name="web"
          aria-label="web"
          type="text"
          class="tw-w-full tw-mb-4 md:tw-mb-auto"
          size="medium"
          :error="errors['web'] ? $t(errors['web']) : false"
          :value="editItem.web"
          @onChange="e => updateValue('web', e)"
        />
      </div>
    </div>

    <template #modalFooter>
      <div class="tw-w-full tw-flex tw-justify-end">
        <MainButton size="medium" class="tw-px-9" :disabled="!isFormValid" @click="() => saveNewValues()">
          <span v-if="!isUpdating">{{ $t('app_hotel_details_add') }}</span>
          <span v-if="isUpdating">
            <RCInlineLoader color="#fff" />
          </span>
        </MainButton>
      </div>
    </template>
  </CustomModal>
</template>
<script setup>
import { computed } from 'vue'
import { validate, required, url } from '@/utils/propertyDetailsHelpers.js'
import CustomModal from '@/components/base/CustomModal.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseTextField from '@/components/base/inputs/BaseTextField.vue'
import BaseSelect from '@/components/BaseSelect.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import { usePropertyLanguages } from '@/components/propertyDetails/propertyLanguages/usePropertyLanguages.js'

const { setValidation, setNewValue, errors, isFormValid, editItem } = usePropertyLanguages()
const props = defineProps({
  isOpen: Boolean,
  availableLanguages: Object,
  usedLanguages: [Object],
  isLoading: Boolean,
  isUpdating: Boolean,
})

const emit = defineEmits(['closeAdding', 'save'])
const closeAdding = () => emit('closeAdding')
const getLanguageOptions = computed(() => {
  const options = []
  const cleanUsedLanguages = JSON.parse(JSON.stringify(props.usedLanguages))
  const languages = Array.isArray(cleanUsedLanguages) ? cleanUsedLanguages : []
  const languagesInUse = languages.map(item => item.languageCode)
  const allLanguages = JSON.parse(JSON.stringify(props.availableLanguages))
  const unusedLanguages = Object.keys(allLanguages).filter(item => languagesInUse.indexOf(item) === -1)
  unusedLanguages.map(item => {
    if (item === 'cs') return 'cz'
    if (item === 'da') return 'dk'
    options.push({
      label: `lang_${item}`,
      value: item,
    })
  })
  return options
})

const saveNewValues = () => {
  let fields = ['name', 'streetAddress', 'web', 'languageCode']
  fields.forEach(item => {
    const value = editItem.value[item] === undefined ? '' : editItem.value[item]
    const validations = getValidations(item)
    const error = validate(value, validations)
    setValidation({ name: item, value: error !== undefined ? error : false })
  })
  if (isFormValid.value) {
    emit('save', editItem.value.languageCode)
  }
}
const getValidations = name => {
  if (name === 'name' || name === 'streetAddress' || name === 'languageCode') {
    return [required]
  } else if (name === 'web') {
    return [url]
  } else {
    return []
  }
}
const updateValue = (name, value) => {
  const validationFunctions = getValidations(name)
  const error = validate(value, validationFunctions)
  setValidation({ name, value: error !== undefined ? error : false })
  setNewValue({ name, value })
}
</script>
