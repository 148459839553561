<template>
  <div>
    <NoDataAvailable v-if="!graphHasData" class="tw-mb-16" />
    <SplineChart
      v-show="graphHasData"
      :container-id="'performance-graph-container'"
      :series-data="seriesData"
      :studioPlus="studioPlus"
      :isMobile="isMobile"
      :impressions="impressions"
      :maxValue="getMaxValue"
    />
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import SplineChart from '../charts/SplineChart.vue'
import NoDataAvailable from '../NoDataAvailable.vue'

const { t } = useI18n()
const props = defineProps({
  aggregationType: {
    type: String,
    default: 'daily',
  },
  impressions: {
    type: Boolean,
    default: true,
  },
  data: {
    type: Object,
    default: () => {},
  },
  studioPlus: {
    type: Number,
    default: null,
  },
  isMobile: {
    type: Boolean,
    required: true,
  },
})

const graphHasData = computed(() =>
  Object.keys(props.data).every(kpi => Array.isArray(props.data[kpi]) && props.data[kpi].length > 0)
)
const getMaxValue = computed(() => {
  let valuesArr = []
  props.data.visits?.forEach(item => {
    valuesArr.push(item[1])
  })
  let maxValue = Math.round(Math.max(...valuesArr))
  return maxValue > 0 ? maxValue : 10
})

const seriesData = computed(() => {
  if (props.impressions) {
    return [
      {
        data: props.data.views,
        color: '#007cc2 ',
        name: t('kpi_chart_views'),
        internalName: 'views',
        aggregationType: props.aggregationType,
      },
      {
        data: props.data.visits,
        color: '#99cc66',
        name: t('kpi_chart_visits'),
        internalName: 'visits',
        aggregationType: props.aggregationType,
      },
      {
        data: props.data.clickThroughRate,
        aggregationType: props.aggregationType,
        name: t('rc_label_ctr'),
        internalName: 'clickThroughRate',
        color: 'red',
        lineColor: 'transparent',
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    ]
  } else {
    return [
      {
        data: props.data.views,
        color: '#007cc2 ',
        name: t('kpi_chart_views'),
        internalName: 'views',
        aggregationType: props.aggregationType,
        lineColor: 'transparent',
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      {
        data: props.data.visits,
        color: '#99cc66',
        name: t('kpi_chart_visits'),
        internalName: 'visits',
        aggregationType: props.aggregationType,
      },
      {
        data: props.data.clickThroughRate,
        aggregationType: props.aggregationType,
        name: t('rc_label_ctr'),
        internalName: 'clickThroughRate',
        color: 'red',
        lineColor: 'transparent',
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    ]
  }
})
</script>
