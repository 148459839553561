<template>
  <Graph
    :competitors="competitors"
    container="rate-insights-qraph"
    :series="series"
    :categories="categories"
    :days="days"
    :filters="filters"
    :translationKeys="getTranslationKeys"
    :currency="currency"
    :select-timestamp="value => selectTimestamp(value)"
  />
</template>
<script setup>
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import Graph from './Graph.vue'
import appAnalytics from '@/utils/tracking'

const { t } = useI18n()
const props = defineProps({
  isWaitingMode: {
    type: Boolean,
    default: false,
  },
  accommodationId: {
    type: Number,
    default: 0,
  },
  series: {
    type: Array,
    default() {
      return []
    },
  },
  currency: {
    type: String,
    default: '',
  },
  categories: {
    type: Object,
    default() {
      return {}
    },
  },
  days: {
    type: Object,
    default() {
      return {}
    },
  },
  competitors: {
    type: Object,
    default() {
      return {}
    },
  },
  filters: {
    type: Object,
    default() {
      return {}
    },
  },
  selectTimestamp: {
    type: Function,
    default: () => null,
  },
})

const getTranslationKeys = computed(() => {
  return {
    averageRate: t('rate_insights_avg_rates'),
    room_type_double: t('room_type_double'),
    room_type_single: t(`room_type_single`),
    room_type_multi: t('room_type_FAM'),
    incl_breakfast: t('incl_breakfast'),
    excl_breakfast: t('excl_breakfast'),
  }
})

onMounted(() => {
  if (props.isWaitingMode) {
    appAnalytics.track(appAnalytics.events.RI_NO_BOOKING_SITES, {
      accommodationId: props.accommodationId,
    })
  }
})
</script>
