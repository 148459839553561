<template>
  <Table
    :columns="displayedColumns"
    :data="tableData"
    :kpi-highlight="kpiHighlight"
    :have-extra-info="haveExtraInfo"
    :currency="currency"
    :getSelectedPeriodCampaignType="selectedPeriodCampaignType"
    :getSelectedAggregation="selectedAggregation"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import Table from '@/components/rateConnect/performance/table/Table.vue'
import { useStats, useStartUp } from '@/components/rateConnect/queries'
import { useRateConnectPerformance } from './useRateConnectPerformance'
import { CAMPAIGN_MODES, campaignsAggregateType, inferCampaignAggregateType } from '@/utils/rateConnectUtils'
import { selectedAccommodationId } from '@/layouts/queries'

const { selectedMarket, formattedStartDate, formattedEndDate, selectedAggregation } = useRateConnectPerformance()
const store = useStore()
const currency = computed(() => store.state.currency)
const { currentCampaignType } = useStartUp(selectedAccommodationId)

const { statsData } = useStats(
  selectedAccommodationId,
  currency,
  formattedStartDate,
  formattedEndDate,
  selectedMarket,
  selectedAggregation
)

defineProps({
  kpiHighlight: {
    type: String,
    default: '',
  },
})

const columns = {
  startDateColumn: {
    header: 'rc_table_date',
    id: 'date',
    colType: 'date',
    additional: false,
    className: 'col--date',
  },
  clicksColumn: {
    header: 'rc_table_clicks',
    hint: 'rc_def_clicks',
    id: 'clicks',
    colType: 'number',
    additional: false,
    className: 'col--clicks',
  },
  bookingsColumn: {
    header: 'rc_total_bookings_pay_per_stay',
    hint: 'rc_def_bookings',
    id: 'bookings',
    colType: 'number',
    additional: false,
    className: 'col--bookings',
  },
  revenueColumn: {
    header: 'rc_table_revenue',
    hint: 'rc_def_revenue',
    id: 'revenue',
    colType: 'currency',
    additional: false,
    className: 'col--revenue',
  },
  commissionCostsColumn: {
    header: 'rc_table_commissions',
    hint: 'rc_def_commissions',
    id: 'commissionCost',
    colType: 'currency',
    additional: false,
    className: 'col--commissionCost',
  },
  estimatedCPAColumn: {
    header: 'rc_table_ecpa',
    hint: 'rc_def_ecpa',
    id: 'ecpa',
    colType: 'percentage',
    additional: false,
    className: 'col--cpa',
  },
  budgetSpentColumn: {
    header: 'rc_table_costs',
    hint: 'rc_def_costs',
    id: 'costs',
    colType: 'currency',
    additional: false,
    className: 'col--costs',
  },
  averageBookingValueColumn: {
    header: 'rc_table_abv',
    hint: 'rc_def_abv',
    id: 'abv',
    colType: 'currency',
    additional: false,
    className: 'col--abv',
  },
  cpcColumn: {
    header: 'rc_table_cpc',
    hint: 'rc_def_cpc',
    id: 'cpc',
    colType: 'currency',
    className: 'col--cpc',
  },
  unavailabilityColumn: {
    header: 'rc_table_unavailability',
    hint: 'rc_def_unavailability',
    id: 'unavailability',
    colType: 'percentage',
    additional: false,
    className: 'col--unavailability',
  },
  cancelledBookingsColumn: {
    header: 'rc_cancellations_pay_per_stay',
    id: 'cancelledBookings',
    colType: 'number',
    additional: true,
    className: 'col--cancelledBookings',
  },
  bookingRefundedRevenueColumn: {
    header: 'rc_refunded_revenue_pay_per_stay',
    id: 'bookingRefundedRevenue',
    colType: 'currency',
    additional: true,
    className: 'col--bookingRefundedRevenue',
  },
  additionalActionColumn: {
    header: ' ',
    id: 'showMore',
    colType: 'action',
    className: 'col--additional',
  },
}

const getBothTypeColumns = [
  columns.startDateColumn,
  columns.clicksColumn,
  columns.bookingsColumn,
  columns.cancelledBookingsColumn,
  columns.bookingRefundedRevenueColumn,
  columns.revenueColumn,
  columns.commissionCostsColumn,
  columns.averageBookingValueColumn,
  columns.budgetSpentColumn,
  columns.estimatedCPAColumn,
  columns.cpcColumn,
  columns.unavailabilityColumn,
]
const getCpaTypeColumns = [
  columns.startDateColumn,
  columns.clicksColumn,
  columns.bookingsColumn,
  columns.cancelledBookingsColumn,
  columns.bookingRefundedRevenueColumn,
  columns.revenueColumn,
  columns.commissionCostsColumn,
  columns.averageBookingValueColumn,
  columns.unavailabilityColumn,
]

const getCpcTypeColumns = [
  columns.startDateColumn,
  columns.clicksColumn,
  columns.bookingsColumn,
  columns.cancelledBookingsColumn,
  columns.bookingRefundedRevenueColumn,
  columns.revenueColumn,
  columns.estimatedCPAColumn,
  columns.budgetSpentColumn,
  columns.averageBookingValueColumn,
  columns.cpcColumn,
  columns.unavailabilityColumn,
]

const selectedPeriodCampaignType = computed(() => {
  if (!statsData.value || !currentCampaignType.value) return null
  return campaignsAggregateType(statsData.value, currentCampaignType.value)
})

const displayedColumns = computed(() => {
  if (!selectedPeriodCampaignType.value) return
  switch (selectedPeriodCampaignType.value) {
    case CAMPAIGN_MODES.BOTH:
      return getBothTypeColumns
    case CAMPAIGN_MODES.CPA:
      return getCpaTypeColumns
    case CAMPAIGN_MODES.CPC:
      return getCpcTypeColumns
    default:
      return getBothTypeColumns
  }
})

const tableData = computed(() => {
  if (!statsData.value || !Array.isArray(statsData.value)) return null

  const newData = statsData.value.map(row => {
    const aggregateCampaignType = inferCampaignAggregateType(row)
    const hasExtraInfo = row.cancelledBookings || row.bookingRefundedRevenue
    const bookingRefundedRevenue = row.bookingRefundedRevenue >= 0 ? row.bookingRefundedRevenue / 100 : undefined
    return {
      date: {
        start: row.startDate,
        end: row.endDate,
      },
      clicks: row.partnerClicks,
      bookings: row.bookings,
      revenue: row.bookingRevenue / 100,
      ecpa: row.cpa / 100,
      costs: row.costs / 100,
      abv: row.avgBookingRevenue / 100,
      cpc: row.cpc / 100,
      unavailability: row.unavailabilityScore,
      commissionCost: row.commissionCost / 100,
      aggregateCampaignType,
      cancelledBookings: row.cancelledBookings,
      bookingRefundedRevenue,
      extraInfo: hasExtraInfo
        ? {
            cancelledBookings: row.cancelledBookings,
            bookingRefundedRevenue,
          }
        : undefined,
    }
  })

  newData.sort(function (a, b) {
    var c = new Date(a.date.start)
    var d = new Date(b.date.start)
    return d - c
  })
  return newData
})

const haveExtraInfo = computed(() => tableData.value?.filter(item => item.extraInfo).length > 0)
</script>
