<template>
  <div class="tw-flex tw-flex-col">
    <ul class="tw-flex tw-gap-2" :class="{ 'tw-mb-1': withText }" aria-hidden="true">
      <li
        v-for="(step, i) in steps"
        :key="i"
        :class="type === 'v2' ? 'step-circle-v2 tw-bg-gray-300' : 'step-circle tw-bg-blue-200'"
        @click="emit('onClick', i)"
        @keydown.enter="emit('onClick', i)"
      >
        <span class="visually-hidden">{{ i }}</span>
        <span aria-hidden="true" class="step-circle fade-effect-cover" :class="getClass(i)"></span>
      </li>
    </ul>
    <p v-if="withText" class="tw-text-sm tw-font-normal tw-color-gray-300">
      <span class="tw-text-blue-800 tw-font-bold">Step {{ value + 1 }}</span> of {{ steps }}
    </p>
  </div>
</template>

<script setup>
const props = defineProps({
  steps: {
    type: Number,
    required: true,
    default: 3,
  },
  value: {
    type: Number,
    required: true,
    default: 0,
  },
  withText: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    required: false,
    default: 'v1',
  },
})
const emit = defineEmits(['onClick'])
const getClass = i => {
  if (props.type === 'v2') {
    if (props.value === i) {
      return 'step-circle-v2 fade-effect-cover-v2 fade-effect-trigger '
    } else {
      return 'step-circle-v2 fade-effect-cover-v2'
    }
  } else {
    if (props.value === i) {
      return 'step-circle fade-effect-cover fade-effect-trigger'
    } else {
      return 'step-circle fade-effect-cover'
    }
  }
}
</script>

<style scoped>
ul > li {
  cursor: pointer;
  position: relative;
}

.step-circle {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.step-circle-v2 {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 10px;
}

.step-circle-v2:last-child {
  margin-right: 0px;
}

.fade-effect-cover {
  position: absolute;
  background-color: theme('colors.blue.700');
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.face-effect-cover-v2 {
  position: absolute;
  background-color: theme('colors.blue.800');
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.fade-effect-trigger {
  opacity: 1;
}

.visually-hidden {
  /* visually hidding the spans */
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
</style>
