import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { convertYYYYMMDDtoObject } from '@/utils/Utility'
import DashboardService from '@/services/DashboardService'

const formatPerformanceDataForChart = ({ visits, date_tags, views, click_through_rate }) => {
  let newVisits = []
  let newViews = []
  let newClickThroughRate = []

  Object.entries(date_tags).forEach(([key, value]) => {
    let date = convertYYYYMMDDtoObject(value)
    newVisits.push([Date.UTC(date.y, date.m, date.d), visits[key]])
    newViews.push([Date.UTC(date.y, date.m, date.d), views[key]])
    newClickThroughRate.push([Date.UTC(date.y, date.m, date.d), click_through_rate[key]])
  })

  return {
    views: newViews,
    visits: newVisits,
    clickThroughRate: newClickThroughRate,
  }
}

const formatVueDateFormatToYYYYMMDD = stringDate => {
  return stringDate.replaceAll('-', '')
}

export function usePerformanceData({ accommodationId, aggregationType, startDate, endDate }) {
  const { data, isError, isLoading, isFetching } = useQuery({
    queryKey: ['performanceData', accommodationId, aggregationType, startDate, endDate],
    queryFn: ({ queryKey }) =>
      DashboardService.fetchPerformance({
        accommodationId: queryKey[1],
        aggregationType: queryKey[2],
        startDate: formatVueDateFormatToYYYYMMDD(queryKey[3]),
        endDate: formatVueDateFormatToYYYYMMDD(queryKey[4]),
      }),
  })

  const initialGraph = {
    click_through_rate: null,
    date_tags: null,
    views: null,
    visits: null,
  }

  const performanceDataFormattedForChart = computed(() => {
    if (data?.value === undefined) {
      return initialGraph
    } else {
      const graph = data?.value?.data?.graph

      return Object.values(graph).includes(null) ? graph : formatPerformanceDataForChart(graph)
    }
  })

  return {
    performanceDataFormattedForChart,
    isError,
    isLoading,
    isFetching,
  }
}
