<template>
  <g id="Final-Homepage-Dashboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
    ><g id="tBS---Home-FL---Desktop---Dots" transform="translate(-94.000000, -634.000000)"
      ><g id="Group-8" transform="translate(94.000000, 627.000000)"
        ><g id="Icons/Content/Statistics-24x24-Line" transform="translate(0.000000, 7.000000)"
          ><rect id="Rectangle-4-Copy-109" x="0" y="0" width="24" height="24"></rect
          ><g
            id="Page-1"
            transform="translate(12.000000, 12.000300) scale(1, -1) translate(-12.000000, -12.000300) translate(2.000000, 3.000300)"
            fill="#37454D"
            ><path
              id="Fill-1"
              d="M15.5,5.0003 L5,5.0003 L5,13.5003 C5,13.7763 4.776,14.0003 4.5,14.0003 C4.224,14.0003 4,13.7763 4,13.5003 L4,4.5003 C4,4.2243 4.224,4.0003 4.5,4.0003 L15.5,4.0003 C15.776,4.0003 16,4.2243 16,4.5003 C16,4.7763 15.776,5.0003 15.5,5.0003"
            ></path
            ><path
              id="Fill-3"
              d="M13.6465,10.3538 L11.5005,8.2078 L9.8535,9.8538 C9.6585,10.0488 9.3415,10.0488 9.1465,9.8538 L6.1465,6.8538 C5.9515,6.6588 5.9515,6.3418 6.1465,6.1468 C6.2445,6.0488 6.3725,5.9998 6.5005,5.9998 C6.6275,5.9998 6.7555,6.0488 6.8535,6.1468 L9.5005,8.7928 L11.1465,7.1468 C11.3415,6.9518 11.6585,6.9518 11.8535,7.1468 L14.3535,9.6468 L16.0005,7.9998 L16.0005,11.9998 L12.0005,11.9998 L13.6465,10.3538 Z"
            ></path
            ><path
              id="Fill-5"
              d="M19,2.118 C19,1.502 18.551,1 18,1 L2,1 C1.449,1 1,1.502 1,2.118 L1,15.883 C1,16.499 1.449,17 2,17 L18,17 C18.551,17 19,16.499 19,15.883 L19,2.118 Z M18,18 L2,18 C0.896,18 0,17.052 0,15.883 L0,2.118 C0,0.949 0.896,0 2,0 L18,0 C19.104,0 20,0.949 20,2.118 L20,15.883 C20,17.052 19.104,18 18,18 L18,18 Z"
            ></path></g></g></g></g
  ></g>
</template>
