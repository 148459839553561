import { reactive, toRefs } from 'vue'

const state = reactive({
  editSection: null,
})

export function usePropertyFeatures() {
  const setEditObject = fieldsGroup => {
    state.editSection = fieldsGroup
  }

  const updateFeatures = ({ checked, fieldId, groupId }) => {
    let valuesToSend = state.editSection !== null ? [...state.editSection] : []
    let itemIndex = valuesToSend.findIndex(item => item.featureId === fieldId)
    if (checked !== undefined) {
      if (itemIndex >= 0) {
        valuesToSend.splice(itemIndex, 1)
      } else {
        valuesToSend.push({
          featureId: fieldId,
          studioGroupId: groupId,
        })
      }
    }
    state.editSection = valuesToSend
  }

  const clearEditObject = () => {
    state.editSection = null
  }

  return {
    ...toRefs(state),
    setEditObject,
    updateFeatures,
    clearEditObject,
  }
}
