<template>
  <div
    :class="{ 'tw-hidden': selectedSlide !== slidePosition }"
    class="carousel-item tw-transition-all tw-duration-700 tw-ease-in-out tw-bg-transparent tw-w-full"
  >
    <div class="tw-h-full">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    selectedSlide: {
      type: Number,
      required: true,
      defaut: 1,
    },
    slidePosition: {
      type: Number,
      required: true,
      default: 1,
    },
  },
}
</script>
