<template>
  <div class="tw-mt-10 tw-px-2.5">
    <div class="tw-flex tw-flex-row tw-gap-4 tw-flex-wrap tw-px-3">
      <div v-for="competitor in sortedCompetitors" :key="competitor.id" class="tw-flex tw-flex-row tw-items-center">
        <span
          class="tw-w-[10px] tw-h-[10px] tw-rounded-full tw-flex tw-mr-1"
          :style="`background-color: ${competitor.color}`"
        />
        {{ competitor.name }}
      </div>
    </div>
    <div class="tw-px-3 tw-mt-10">
      <div class="tw-flex tw-items-center tw-py-2">
        <p class="tw-text-xl tw-font-bold">{{ $t('rate_comparison_booking_sites') }}</p>
        <UiTooltip tooltip-position="top" :label="$t('rate_comparsion_tooltip')" class="tw-cursor-pointer tw-ml-1">
          <BaseIcon
            icon-name="information-outline"
            :height="16"
            :width="16"
            class="tw-fill-none tw-stroke-gray-800 tw-h-[22px] tw-w-[22px] tw-ml-1"
          >
            <InformationOutline />
          </BaseIcon>
        </UiTooltip>
      </div>
      <div class="tw-w-full tw-justify-between md:tw-items-center tw-flex tw-flex-col md:tw-flex-row">
        <p class="tw-font-base tw-font-normal">{{ $t('rate_comparison_booking_sites_info_text') }}</p>
        <div class="tw-flex tw-items-center">
          <span class="tw-bg-blue-200 tw-border tw-border-gray-200 tw-h-[27px] tw-w-[15px] tw-mr-2" />
          <p>{{ $t('lowest_rates_indicator') }}</p>
        </div>
      </div>
    </div>
    <div class="table-wrapper">
      <table
        class="tw-mt-5 tw-rounded tw-border-0 tw-border-spacing-0 tw-border-separate tw-w-full md:tw-table tw-block md:tw-max-w-full tw-max-w-fit tw-overflow-x-auto tw-whitespace-nowrap"
      >
        <thead class="tw-rounded-t tw-border-0 tw-bg-gray-100">
          <tr class="tw-rounded-t tw-border-0 tw-text-left">
            <th :colspan="columns.length" class="tw-border-gray-300 tw-rounded-t tw-font-semibold tw-min-h-[45px]">
              {{ $t('home_rate_per_partner') }}{{ ': ' }}<span class="tw-font-normal">{{ formattedDate }}</span>
            </th>
          </tr>
          <tr class="tw-border-0">
            <th
              class="tw-border-gray-300 tw-font-normal tw-border-t-0 tw-border-l-0 first:tw-border-l tw-text-center tw-min-h-[45px]"
              v-for="channel in columns"
              :key="channel.id"
            >
              <span class="tw-flex tw-items-center tw-justify-center tw-flex-wrap">
                <img
                  v-if="channel?.id !== 'property'"
                  class="tw-max-w-[24px] tw-max-h-[24px] tw-mr-2"
                  :src="`https://imgcy.trivago.com/e_trim/f_auto,dpr_2,bo_1px_solid_transparent,c_pad,h_24,q_auto,w_24${channel?.avatar}`"
                />
                {{ channel.fullName }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody class="tw-border-0 tw-rounded-b" ref="channels-table">
          <tr v-for="row in rows" :key="row.rowId" class="tw-border-0 tw-rounded-b tw-border-0 first:tw-border-l">
            <td
              v-for="(col, i) in columns"
              :key="i"
              class="tw-text-center tw-min-h-[45px] tw-p-[9px] tw-border tw-border-t-0 tw-border-l-0 first:tw-border-l"
              :class="{ 'tw-bg-blue-200 tw-text-blue-700 tw-font-bold': row.columns[col.id]?.highlight }"
            >
              <div v-if="row.columns[col.id]?.color">
                <UiTooltip
                  theme="material"
                  tooltip-position="top"
                  :label="row.columns[col.id].value.length > 32 ? row.columns[col.id].value : ''"
                >
                  <div class="tw-flex tw-items-center tw-justify-center">
                    <div
                      class="tw-w-[10px] tw-h-[10px] tw-rounded-full tw-flex tw-mr-1"
                      :style="`background-color: ${row.columns[col.id].color}`"
                    />

                    <span
                      :class="selectedChannelsArr.length > 4 ? 'tw-max-w-[250px]' : 'tw-max-w-full'"
                      class="tw-truncate"
                    >
                      {{ row.columns[col.id].value }}
                    </span>
                  </div>
                </UiTooltip>
              </div>

              <div v-else class="tw-whitespace-nowrap">
                {{
                  row.columns[col.id]?.value !== 0 && row.columns[col.id]?.value !== null
                    ? row.columns[col.id]?.value
                    : '-'
                }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script setup>
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'

import { computed } from 'vue'
import { useStore } from 'vuex'
import minBy from 'lodash/minBy'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const props = defineProps({
  channels: Array,
  competitors: Array,
  filters: Object,
  rates: Object,
  formattedDate: String,
})

const selectedChannelsArr = computed(() => props.filters?.channelIds.split(','))
const currency = computed(() => store.state.currency)
const locale = computed(() => store.state.locale.language)
const columns = computed(() => {
  let channelsArr = props.channels.filter(item => selectedChannelsArr.value.includes(item.id.toString()))
  channelsArr = channelsArr.map(channel => {
    return {
      ...channel,
      avatar: getPartnerImage(channel),
    }
  })
  return [{ id: 'property', name: 'hotels', fullName: '' }].concat(channelsArr)
})

const sortedCompetitors = computed(() => {
  const sorted = [...props.competitors]?.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1
    }
    return 0
  })
  return sorted
})

const getPartnerImage = channel => {
  if (channel.id == 3250) {
    return '/v1699964222/partnerlogos-squared/3250.svg'
  } else {
    return channel.logo?.localizedFaviconUrlTail !== null
      ? channel.logo?.localizedFaviconUrlTail
      : channel.logo.localizedUrlTail
  }
}

const getChannelsOverviewFromRatesData = allRates => {
  const channelIds = selectedChannelsArr.value
  const breakfast = props.filters.breakfast
  const roomType = props.filters.roomType
  let data = []
  const ret = () => ({
    channels: channelIds,
    data,
  })

  if (!Object.keys(allRates)) {
    return ret()
  }

  // Sorting the competitors for the table: first own hotel, then competitors ordered by name
  const allRatesCopy = JSON.parse(JSON.stringify(allRates))
  const myHotelRates = allRatesCopy[selectedAccommodationId.value]
  delete allRatesCopy[selectedAccommodationId.value]
  const newAllRates = new Map([[selectedAccommodationId.value, myHotelRates], ...Object.entries(allRatesCopy)])
  const allRatesKeys = Array.from(newAllRates.keys())

  const allRatesKeysWithNames = allRatesKeys.map(item => {
    return {
      id: item,
      name: props.competitors.find(competitor => {
        return competitor.id === Number(item)
      })?.name,
    }
  })

  const myPropertyId = allRatesKeysWithNames.shift().id
  const sorted = allRatesKeysWithNames.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1
    }
    return 0
  })

  let allSortedKeys = sorted.map(item => item.id)
  allSortedKeys.unshift(myPropertyId)

  allSortedKeys.map(competitorId => {
    competitorId = parseInt(competitorId)
    if (allRates[competitorId] === undefined) return
    const competitor = allRates[competitorId]
    const competitorChannelRates = []
    channelIds.map(channelId => {
      channelId = parseInt(channelId)

      if (channelIds.indexOf(channelId.toString()) !== -1) {
        const rate = `${roomType}${breakfast === 'included' ? '_breakfast' : ''}`
        competitorChannelRates.push({
          colName: channelId,
          value: competitor[channelId] ? Math.round(competitor[channelId][rate] / 100) : null,
        })
      }
    })

    const competitorData = [competitorId].concat(competitorChannelRates)
    data.push(competitorData)
  })

  return ret()
}

const rows = computed(() => {
  const { channels, data } = getChannelsOverviewFromRatesData(props.rates)

  data.map((row, index) => {
    const competitorId = data[index][0]
    const competitor = props.competitors.find(item => item.id === competitorId)
    data[index][0] = {
      colName: 'property',
      id: competitorId,
      index: props.competitors.indexOf(competitor) + 1,
      color: competitor.color,
      value: competitor.name,
    }
  })

  channels.map((channelId, index) => {
    const ratesOfChannel = []
    data.map((row, rowIndex) => {
      if (row && row.length) {
        row.map((column, columnIndex) => {
          if (columnIndex === index + 1) {
            ratesOfChannel.push({
              colName: column?.colName,
              value: column?.value !== null && column?.value !== 0 ? column?.value : null,
              rowIndex,
              columnIndex,
            })
          }
        })
      }
    })
    const rateValue = rate => rate.value
    const { rowIndex, columnIndex, value } = minBy(ratesOfChannel, rateValue) || {}
    if (data[rowIndex] && data[rowIndex][columnIndex]) {
      ratesOfChannel.forEach(item => {
        if (item.value === value) {
          data[item.rowIndex][item.columnIndex] = {
            colName: data[rowIndex][columnIndex].colName,
            value: data[rowIndex][columnIndex].value,
            rate: true,
            highlight: true,
          }
        }
      })
    }

    const channelsLength = channels.length
    data.map((items, index) => {
      const fillMissingValuesCount = channelsLength - items.slice(1, items.length).length
      if (fillMissingValuesCount > 0) {
        for (var i = 0; i < fillMissingValuesCount; i++) {
          data[index].push(null)
        }
      }
    })
  })
  const table = []
  data.forEach((row, rowIndex) => {
    const columns = {}
    row.forEach(cell => {
      columns[cell.colName] = {
        ...cell,
        value:
          cell.value !== 0 && cell.value !== null
            ? cell.value?.toLocaleString(locale.value, {
                style: 'currency',
                currency: currency.value,
                minimumFractionDigits: 0,
              })
            : 0,
      }
    })
    table.push({ rowId: +rowIndex, columns })
  })
  return table
})
</script>
<style scoped>
.table-wrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  overflow: auto;
  white-space: nowrap;
}
</style>
