<template>
  <div
    class="tw-mx-auto tw-max-w-sm tw-py-4 tw-px-6 tw-shadow tw-bg-white tw-border-t-8 tw-border-solid tw-border-red-700 tw-rounded-lg"
  >
    <h4 class="tw-py-4 tw-text-2xl">
      {{ $t('admin_error_message_header') }}
    </h4>
    <p class="tw-text-md tw-pb-4">
      {{ $t('admin_error_message_text') }}
    </p>
    <MainButton class="tw-w-full" @click.prevent="redirectToHome">
      {{ $t('appStore.emptyState.backButton') }}
    </MainButton>
  </div>
</template>
<script setup>
import MainButton from '@/components/base/buttons/MainButton.vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const redirectToHome = () => {
  router.push({ name: 'home' })
}
</script>
