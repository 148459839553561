<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 12H7V20H5V12ZM7 11H5C4.448 11 4 11.448 4 12V20C4 20.552 4.448 21 5 21H7C7.552 21 8 20.552 8 20V12C8 11.448 7.552 11 7 11Z"
      fill="#171717"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 4H13V20H11V4ZM13 3H11C10.448 3 10 3.448 10 4V20C10 20.552 10.448 21 11 21H13C13.552 21 14 20.552 14 20V4C14 3.448 13.552 3 13 3Z"
      fill="#171717"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 8H19V20H17V8ZM19 7H17C16.448 7 16 7.448 16 8V20C16 20.552 16.448 21 17 21H19C19.552 21 20 20.552 20 20V8C20 7.448 19.552 7 19 7Z"
      fill="#171717"
    />
  </svg>
</template>
