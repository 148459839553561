<template>
  <span class="tw-flex tw-justify-center tw-mt-10">
    <BaseCard class="tw-text-center md:tw-p-10">
      <BaseIcon :height="135" :width="150" icon-name="tea-pot" class="tw-mb-6 no-accommodation-icon tw-mx-auto">
        <TeaPot />
      </BaseIcon>
      <h3 class="tw-font-bold">{{ $t('emptyStatePage.noAccommodation.title') }}</h3>
      <div>{{ $t('emptyStatePage.noAccommodation.subtitle') }}</div>
      <MainButton class="tw-mt-2" @click="onFindPropertyClick">
        {{ $t('emptyStatePage.noAccommodation.cta') }}
      </MainButton>
    </BaseCard>
  </span>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import TeaPot from '@/components/icons/TeaPot.vue'

export default {
  components: {
    BaseCard,
    MainButton,
    BaseIcon,
    TeaPot,
  },
  computed: {
    ...mapState('apps', ['apps']),
  },
  methods: {
    onFindPropertyClick() {
      this.$router.push('/property-assignment/add-new')
    },
    ...mapActions('app', ['setApp']),
  },
}
</script>

<style scoped>
.no-accommodation-icon {
  width: 150px;
  height: 135px;
}
</style>
