<template>
  <BaseButton
    :size="size"
    :data-testid="dataTestId"
    class="tw-font-bold tw-border hover:tw-bg-blue-700 tw-bg-white tw-border-blue-700 hover:tw-text-white tw-text-blue-700"
    v-bind="$attrs"
  >
    <slot />
  </BaseButton>
</template>

<script>
import BaseButton from './BaseButton.vue'
export default {
  components: {
    BaseButton,
  },
  props: {
    size: {
      type: String,
      default: 'big',
    },
    dataTestId: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
button:disabled {
  @apply tw-bg-white tw-border-blue-300 tw-text-blue-300;
}
@media not all and (hover: none) and (pointer: fine) {
  button:hover {
    background-color: theme('colors.blue.800');
    border-color: theme('colors.blue.800');
  }
}
</style>
