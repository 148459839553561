<template>
  <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-w-full md:tw-justify-between tw-gap-8 md:tw-gap-10">
    <div class="tw-grow">
      <h3 class="tw-text-xl tw-font-bold tw-mb-4 md:tw-mb-2">{{ $t('rc_budget_status_header') }}</h3>
      <p class="tw-mb-3 md:tw-mb-4 tw-text-center tw-text-sm">
        <i18n-t keypath="notification_good_budget_heading_message">
          <template #budgetLeftPercentage>
            <span class="tw-font-bold tw-text-blue-800">
              {{ $n(remainingBudgetPercentage / 100, 'percent') }}
            </span>
          </template>
          <template #month>
            {{ $d(new Date(), 'longMonth') }}
          </template>
          <template #budgetleft>
            <span class="tw-font-bold tw-text-blue-800">
              {{ currency ? `${currency} ${$n(remainingBudget, 'currencyCode')}` : remainingBudget }}
            </span>
          </template>
        </i18n-t>
      </p>
      <Slider :value="remainingBudget" :min="0" :max="maxBudget" hideThumb> </Slider>
    </div>
    <div class="md:tw-max-w-xs">
      <div class="tw-flex tw-mb-3 tw-items-center">
        <h3 class="tw-text-xl tw-font-bold">{{ $t('rc_budget_outlook_header') }}</h3>
        <UiTooltip
          tooltip-position="top"
          :label="$t('/snippets/hgw/rc_budget_outlook_tooltip.inc')"
          class="tw-cursor-pointer tw-text-blue-800"
        >
          <BaseIcon
            icon-name="information-outline"
            :height="16"
            :width="16"
            class="tw-fill-none tw-stroke-blue-800 tw-h-[22px] tw-w-[22px] tw-ml-1"
          >
            <InformationOutline />
          </BaseIcon>
        </UiTooltip>
      </div>
      <p class="tw-text-sm outlook-subheader" v-html="$t('rc_budget_outlook_subheader')"></p>
      <p v-if="remainingDays >= 0" class="tw-font-bold tw-text-blue-800 tw-text-xl tw-mt-2">{{ remainingDays }}</p>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import Slider from '@/components/base/inputs/Slider.vue'

const props = defineProps({
  budget: {
    type: Object,
    default: null,
  },
  CPCLevel: {
    type: String,
    default: '',
  },
  expectedConsumptions: {
    type: Object,
    default: null,
  },
  currency: {
    type: String,
    default: '',
  },
})

const remainingBudget = computed(() => (props.budget?.left ? props.budget.left / 100 : 0))
const maxBudget = computed(() => {
  // The default number is a very high one, because otherwise the remainingBudget may get updated before the maxBudget
  // and if then remaining is larger than max (even temporarily), vue-slider will throw an error.
  return props.budget?.limit ? Math.round(props.budget.limit / 100) : 99999999
})
const remainingBudgetPercentage = computed(() => {
  if (maxBudget.value <= 0 || remainingBudget.value > maxBudget.value) return 0
  return Math.round((remainingBudget.value / maxBudget.value) * 100)
})
const remainingDays = computed(() => {
  const consumptionForCpc = props.expectedConsumptions?.[props.CPCLevel] || 1
  const remainingDaysBudget = Math.floor(props.budget?.left / consumptionForCpc)
  const now = new Date()
  const daysInMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate()
  const remainingDaysInMonth = daysInMonth - now.getDate()
  return remainingDaysBudget > remainingDaysInMonth ? remainingDaysInMonth : remainingDaysBudget
})
</script>

<style scoped>
.outlook-subheader :deep(span) {
  font-weight: bold;
}
</style>
