export const platformsLanguages = {
  'en-US': 'en-US',
  'en-GB': 'en-US',
  'pl-PL': 'pl',
  'de-DE': 'de',
  'el-GR': 'el',
  'fr-FR': 'fr',
  'it-IT': 'it',
  'es-ES': 'es',
  'pt-BR': 'pt',
  'pt-PT': 'pt',
  'ja-JP': 'ja',
  'es-MX': 'es',
  'nl-NL': 'nl',
  'tr-TR': 'tr',
  'ru-RU': 'en-US',
}
