<template>
  <div>
    <GuestRatingsHeader />
    <BaseCard v-if="isLoading">
      <GuestRatingsLoadingSkeleton />
    </BaseCard>
    <BaseCard v-else class="tw-gap-3 xl:tw-gap-12 gr-card tw-flex tw-flex-col lg:tw-flex-row">
      <div class="tw-flex tw-flex-col md:tw-flex-row tw-grow tw-justify-start">
        <div class="tw-flex md:tw-flex-row tw-flex-col tw-items-center">
          <GuestRatingsScore
            :score="accommodationRating"
            class="md:tw-mr-6 tw-h-56 tw-w-56 md:tw-h-24 md:tw-w-24 tw-shrink-0"
          />
          <div v-if="propertyHasData" class="xl:tw-shrink-0 tw-text-center md:tw-text-left">
            <p class="tw-text-2xl">
              <span class="tw-mr-1">{{ $t('based_on') }}</span>
              <strong class="tw-text-3xl">{{ numberOfReviews }}</strong
              ><span>{{ ` ${$t('reviews')}` }}</span>
            </p>
            <GuestRatingsSources v-if="propertyHasData" :ratingBreakdown="advertiserRatings" />
          </div>
        </div>
        <div v-if="!propertyHasData">
          <p class="tw-py-2 tw-text-center md:tw-text-left">{{ $t('no_data_available') }}</p>
        </div>
      </div>
      <div class="lg:tw-max-w-[50%] tw-bg-blue-200 tw-rounded-xl tw-flex tw-items-center tw-text-sm md:tw-text-base">
        <div class="tw-px-4 tw-py-2 sm:tw-px-8 sm:tw-py-4 tw-leading-22">
          <i18n-t keypath="tri_home_text" tag="p" scope="global">
            <template #here>
              <a
                :href="`https://www.trivago.com/${platformsLanguages[language]}/srl?search=100-${selectedAccommodationId}`"
                rel="noopener noreferrer"
                target="_blank"
                class="tw-underline tw-text-blue-700"
                >{{ $t('tri_home.link') }}</a
              >
            </template>
          </i18n-t>
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import useGuestRatings from './queries'
import BaseCard from '../dashboard/BaseCard.vue'
import GuestRatingsHeader from './GuestRatingsHeader.vue'
import GuestRatingsScore from './GuestRatingsScore.vue'
import GuestRatingsSources from './GuestRatingsSources.vue'
import GuestRatingsLoadingSkeleton from '@/components/loadingSkeletons/GuestRatingsLoadingSkeleton.vue'
import { platformsLanguages } from '@/constants/platformLanguages.js'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const language = computed(() => store.state.locale.language)

const { isLoading, accommodationRating, numberOfReviews, propertyHasData, advertiserRatings } =
  useGuestRatings(selectedAccommodationId)
</script>

<style scoped>
.gr-card {
  padding: 10px;

  @media (min-width: theme('screens.lg')) {
    padding-left: 20px;
  }
}
</style>
