<template>
  <BaseCard class="tw-mt-4">
    <CardLoadingSkeleton v-if="initialDataLoading" height="300px" />
    <div v-else-if="requiredDataHasErrors">
      <p class="tw-text-center tw-text-xl">{{ $t('onboarding.appLaunchError.header') }}</p>
    </div>
    <div v-else>
      <div class="tw-flex md:tw-justify-between tw-gap-4 tw-flex-col md:tw-flex-row">
        <div class="tw-max-w-3xl">
          <h2 class="tw-text-xl tw-font-bold">{{ $t('app_description_header') }}</h2>
          <p class="tw-text-sm">
            {{ $t('app_description_intro_advantage') }}
          </p>
        </div>
        <div>
          <MainButton size="medium" class="tw-whitespace-nowrap tw-w-full" @click="openEditDescription()">
            {{
              hasAtLeastOneExistingDescription
                ? $t('app_hotel_details_add_language')
                : $t('app_hotel_details_create_description')
            }}
          </MainButton>
        </div>
      </div>
      <ul class="tw-mt-8 tw-flex tw-flex-col tw-gap-4">
        <PropertyDescription
          v-for="(description, i) in sortedDescriptionsByLocale"
          :key="i"
          :description="description"
          @onClick="openViewDescription"
        />
      </ul>
      <ViewDescriptionModal
        :is-open="isViewDescriptionOpen"
        :close="closeViewDescription"
        :description="selectedViewDescription"
        @onEditClick="openEditDescription"
      />
      <EditDescriptionModal
        :is-open="isEditDescriptionOpen"
        :close="closeEditDescription"
        :description="selectedEditDescription"
        @onDescriptionSubmitSuccess="closeEditDescription"
      />
    </div>
    <FullScreenLoader v-show="requiredDataIsBeingRefetched" />
  </BaseCard>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import PropertyDescription from '@/components/propertyDetails/propertyDescription/PropertyDescription.vue'
import EditDescriptionModal from '@/components/propertyDetails/propertyDescription/EditDescriptionModal.vue'
import ViewDescriptionModal from '@/components/propertyDetails/propertyDescription/ViewDescriptionModal.vue'
import CardLoadingSkeleton from '@/components/loadingSkeletons/CardLoadingSkeleton.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import { usePropertyDescriptions } from '@/components/propertyDetails/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const isViewDescriptionOpen = ref(false)
const selectedViewDescription = ref(null)
const isEditDescriptionOpen = ref(false)
const selectedEditDescription = ref(null)

const { t } = useI18n()

const {
  propertyDescriptions,
  isPropertyDescriptionsFetching,
  isPropertyDescriptionsLoading,
  isPropertyDescriptionsError,
} = usePropertyDescriptions(selectedAccommodationId)

const initialDataLoading = computed(() => isPropertyDescriptionsLoading.value)
const requiredDataHasErrors = computed(() => isPropertyDescriptionsError.value)
const requiredDataIsBeingRefetched = computed(() => !initialDataLoading.value && isPropertyDescriptionsFetching.value)

const hasAtLeastOneExistingDescription = computed(() => propertyDescriptions.value?.length > 0)

const closeViewDescription = () => {
  isViewDescriptionOpen.value = false
  selectedViewDescription.value = null
}
const openViewDescription = description => {
  selectedViewDescription.value = description
  isViewDescriptionOpen.value = true
}
const closeEditDescription = () => {
  isEditDescriptionOpen.value = false
  selectedEditDescription.value = null
}
const openEditDescription = (description = null) => {
  closeViewDescription()
  selectedEditDescription.value = description
  isEditDescriptionOpen.value = true
}

const sortedDescriptionsByLocale = computed(() => {
  if (!propertyDescriptions.value) return []
  const copiedList = Array.isArray(propertyDescriptions.value) ? [...propertyDescriptions.value] : []
  copiedList.sort((a, b) =>
    t(`lang_${a.language_tag}`).toLowerCase() > t(`lang_${b.language_tag}`).toLowerCase()
      ? 1
      : t(`lang_${b.language_tag}`).toLowerCase() > t(`lang_${a.language_tag}`).toLowerCase()
      ? -1
      : 0
  )
  return copiedList
})
</script>
