<template>
  <router-link
    :to="route"
    class="tw-w-auto tw-text-sm tw-flex tw-mt-4 lg:tw-inline-block tw-items-center lg:tw-mt-0 tw-text-gray-800 tw-pb-3 hover:tw-text-blue-700 tw-px-0 tw-mx-4 tw-py-2 tw-border-b-4 hover:tw-border-blue-700 tw-mr-2"
    :class="isLinkActive ? 'tw-font-bold tw-border-blue-700 tw-text-blue-700' : 'tw-border-transparent'"
  >
    <slot />
  </router-link>
</template>
<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()
const props = defineProps({
  route: {
    type: String,
    required: true,
  },
})

const isLinkActive = computed(() => {
  let currentRoute
  if (router.currentRoute.value.path === '/settings/subscriptions/details') {
    currentRoute = '/settings/subscriptions'
  } else if (router.currentRoute.value.path === '/settings/subscriptions/edit-payment') {
    currentRoute = '/settings/payment-methods'
  } else {
    currentRoute = router.currentRoute.value.path
  }

  return props.route.replace('/home', '') === currentRoute
})
</script>
<style scoped>
a:hover {
  text-decoration: none;
}
</style>
