<template>
  <div v-if="!isLoading" class="md:tw-max-w-screen-lg lg:tw-max-w-screen-xl md:tw-px-4">
    <CompareFeaturesHero :currency="currency" :monthlyPrice="monthlyPrice" :annualPrice="annualPrice" />
    <div class="tw-mx-9">
      <h2 class="tw-text-2xl tw-font-bold tw-text-center tw-mb-8">{{ $t('studio.plus.compare.features.headline') }}</h2>

      <CompareFeaturesCards :currency="currency" :monthlyPrice="monthlyPrice" :annualPrice="annualPrice" />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useStudioPlusPrice } from '@/components/payment/queries'
import appAnalytics from '@/utils/tracking'
import { selectedAccommodationId } from '@/layouts/queries'

import CompareFeaturesCards from '@/components/studioPlus/CompareFeaturesCards.vue'
import CompareFeaturesHero from '@/components/studioPlus/CompareFeaturesHero.vue'

const store = useStore()
const route = useRoute()
const currency = computed(() => store.state.currency)

const { isLoading, studioPlusPriceMonthly, studioPlusPriceAnnual } = useStudioPlusPrice(selectedAccommodationId)
const annualPrice = computed(() => studioPlusPriceAnnual.value?.price ?? 0)
const monthlyPrice = computed(() => studioPlusPriceMonthly.value?.price ?? 0)

onMounted(() => {
  const trafficSource = window.sessionStorage?.getItem?.('traffic_source')
  appAnalytics.track(appAnalytics.events.SP_COMPARE_FEATURES_VISITED, {
    item_id: selectedAccommodationId.value,
    monthly_price: monthlyPrice.value,
    annual_price: annualPrice.value,
    source: route.query ? route.query : '',
    traffic_source: trafficSource ?? '',
  })
})
</script>
