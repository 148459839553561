<template>
  <header ref="page-header" class="tw-flex tw-justify-between tw-bg-white tw-sticky tw-shadow-lg tw-items-center">
    <Logo class="tw-ml-4" />
    <LanguageSelector size="big" class="tw-mr-4" with-image version="transparent" />
  </header>
</template>

<script>
import LanguageSelector from '@/components/locale/LanguageSelector.vue'
import Logo from '@/components/dashboard/Logo.vue'

export default {
  components: {
    Logo,
    LanguageSelector,
  },
}
</script>
