<template>
  <g id="Product-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Single-Hotel-Owner-eligible" transform="translate(-347.000000, -386.000000)">
      <g id="Group-11" transform="translate(215.000000, 385.000000)">
        <g id="Group-8" transform="translate(0.000000, 1.000000)">
          <g id="eye-icon" transform="translate(132.000000, 0.000000)">
            <rect id="Rectangle-4-Copy-93" x="0" y="0" width="56" height="56"></rect>
            <path
              id="Fill-1"
              d="M28.3339167,18.3333333 C23.1795833,18.3333333 19.0005833,22.5123333 19.0005833,27.6666667 C19.0005833,32.821 23.1795833,37 28.3339167,37 C33.48825,37 37.66725,32.821 37.66725,27.6666667 C37.66725,22.5123333 33.48825,18.3333333 28.3339167,18.3333333 M51.5225833,28.4763333 C49.15425,34.8696667 39.6179167,39.3333333 28.3339167,39.3333333 C17.0499167,39.3333333 7.51358333,34.8696667 5.14525,28.4763333 C4.95158333,27.9536667 4.95158333,27.3796667 5.14525,26.857 C7.51358333,20.4636667 17.0499167,16 28.3339167,16 C39.6179167,16 49.15425,20.4636667 51.5225833,26.857 C51.71625,27.3796667 51.71625,27.9536667 51.5225833,28.4763333"
              fill="#007CC2"
            ></path>
            <path
              id="Fill-3"
              d="M27.6666667,32.3333333 C25.093,32.3333333 23,30.2403333 23,27.6666667 C23,25.093 25.093,23 27.6666667,23 C30.2403333,23 32.3333333,25.093 32.3333333,27.6666667 C32.3333333,30.2403333 30.2403333,32.3333333 27.6666667,32.3333333"
              fill="#007CC2"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </g>
</template>
