<template>
  <CustomModal
    class="edit-language-modal"
    :is-modal-open="isOpen"
    @toggleModal="closeEditing"
    @onClickAway="closeEditing"
    :overlay="'dark'"
    :with-header="true"
    :with-footer="true"
    :custom-classes="'md:tw-max-w-[500px] md:tw-w-[500px] md:tw-h-auto tw-h-[100vh] tw-w-[100vw] md:tw-rounded-md tw-rounded-none'"
  >
    <template #modalHeader>
      <p class="tw-text-xl tw-text-gray-700">
        {{ $t('hotel_details_edit_language', { PropertyLanguage: $t(fullLanguage) }) }}
      </p>
    </template>

    <div v-if="editItem !== null" class="tw-flex tw-w-full tw-flex-col">
      <div class="tw-mt-2 tw-flex tw-w-full">
        <BaseTextField
          :label="nameLabel"
          name="name"
          aria-label="name"
          type="text"
          class="tw-w-full tw-mb-4 md:tw-mb-auto"
          size="medium"
          :value="editItem.name"
          :error="errors['name'] ? $t(errors['name']) : false"
          @onChange="e => updateValue('name', e)"
        />
      </div>
      <div class="tw-mt-2 tw-flex tw-w-full">
        <BaseTextField
          :label="addressLabel"
          name="address"
          aria-label="address"
          type="text"
          class="tw-w-full tw-mb-4 md:tw-mb-auto"
          size="medium"
          :error="errors['streetAddress'] ? $t(errors['streetAddress']) : false"
          :value="editItem.streetAddress"
          @onChange="e => updateValue('streetAddress', e)"
        />
      </div>
      <div class="tw-mt-2 tw-flex tw-w-full">
        <BaseTextField
          :label="webLabel"
          name="web"
          aria-label="web"
          type="text"
          class="tw-w-full tw-mb-4 md:tw-mb-auto"
          size="medium"
          :error="errors['web'] ? $t(errors['web']) : false"
          :value="editItem.web"
          @onChange="e => updateValue('web', e)"
        />
      </div>
    </div>

    <template #modalFooter>
      <div class="tw-w-full tw-flex tw-justify-end">
        <MainButton size="medium" class="tw-px-9" :disabled="!isFormValid" @click="() => saveNewValues(language)">
          <span v-if="!isUpdating">{{ $t('save') }}</span>
          <span v-if="isUpdating">
            <RCInlineLoader color="#fff" />
          </span>
        </MainButton>
      </div>
    </template>
  </CustomModal>
</template>
<script setup>
import { validate, required, url } from '@/utils/propertyDetailsHelpers.js'
import CustomModal from '@/components/base/CustomModal.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseTextField from '@/components/base/inputs/BaseTextField.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import { usePropertyLanguages } from '@/components/propertyDetails/propertyLanguages/usePropertyLanguages.js'

const { setValidation, setNewValue, editItem, errors, isFormValid } = usePropertyLanguages()
defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  language: {
    type: String,
    default: 'en',
  },
  fullLanguage: String,
  nameLabel: {
    type: String,
    default: '',
  },
  addressLabel: {
    type: String,
    default: '',
  },
  webLabel: {
    type: String,
    default: '',
  },
  isUpdating: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['closeEditing', 'save'])
const closeEditing = () => emit('closeEditing')
const saveNewValues = language => {
  let fields = ['name', 'streetAddress', 'web']
  fields.forEach(item => {
    const value = editItem.value[item] === undefined ? '' : editItem.value[item]
    const validations = getValidations(item)
    const error = validate(value, validations)
    setValidation({ name: item, value: error !== undefined ? error : false })
  })
  if (isFormValid.value) {
    emit('save', language)
  }
}
const getValidations = name => {
  if (name === 'name' || name === 'streetAddress') {
    return [required]
  } else if (name === 'web') {
    return [url]
  } else {
    return []
  }
}
const updateValue = (name, value) => {
  const validationFunctions = getValidations(name)
  const error = validate(value, validationFunctions)
  setValidation({ name, value: error !== undefined ? error : false })
  setNewValue({ name, value })
}
</script>
