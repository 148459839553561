import orderBy from 'lodash/orderBy'

const TRAVELERS_DEMAND = 'travelersDemand'
const RATES = 'rates'
export const TYPES = {
  TRAVELERS_DEMAND,
  RATES,
}

const convertTimestampToDate = value => {
  const date = new Date(value)

  const monthNumber = date.getMonth() + 1
  const month = monthNumber < 10 ? '0' + monthNumber : monthNumber
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  return `${date.getFullYear()}${month}${day}`
}

export function calculateDates({ amount, unit }) {
  let startDate, endDate, timestamp
  const today = new Date().setUTCHours(0, 0, 0, 0)
  if (unit.unit === 'days') {
    if (unit.past) {
      startDate = today - amount * 24 * 60 * 60 * 1000
      timestamp = startDate
      endDate = today - 1 * 24 * 60 * 60 * 1000
    } else {
      startDate = today + 1 * 24 * 60 * 60 * 1000
      timestamp = startDate
      endDate = today + amount * 24 * 60 * 60 * 1000
    }
  } else if (unit.unit === 'months') {
    if (unit.past) {
      startDate = new Date(today).setMonth(new Date().getMonth() - amount)
      endDate = today - 1 * 24 * 60 * 60 * 1000
      timestamp = startDate
    } else {
      endDate = new Date(today).setMonth(new Date().getMonth() + amount)
      startDate = today + 1 * 24 * 60 * 60 * 1000
      timestamp = startDate
    }
  }
  return {
    from: convertTimestampToDate(startDate),
    to: convertTimestampToDate(endDate),
    timestamp: timestamp / 1000,
  }
}

export function convertDateToUTC(date) {
  if (date === void 0) {
    date = new Date()
  }

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}

export function createDateFromTimestamp(timestamp) {
  if (timestamp === void 0) {
    timestamp = new Date().getTime()
  }

  return new Date(timestamp * 1000)
}

export function getDateRange({ days = [] }) {
  const createDate = timestamp => convertDateToUTC(createDateFromTimestamp(timestamp))

  return days.map(timestamp => {
    if (timestamp.match('-')) {
      // week
      const [from, to] = timestamp.split('-')

      return {
        from: createDate(from),
        to: createDate(to),
        timestamp,
      }
    } else {
      // day
      return {
        date: createDate(timestamp),
        timestamp,
      }
    }
  })
}

export function formatDate(value, locale) {
  let langCode = locale
  if (locale === 'pt-PT') {
    langCode = 'pt'
  }
  const f = date => {
    return new Date(date).toLocaleDateString(langCode, {
      day: 'numeric',
      month: 'short',
    })
  }

  if (value && value.from && value.to) {
    return f(value.from) + ' - ' + f(value.to)
  } else {
    return f(value.date)
  }
}

export function formatCurrency(value, currency, locale) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: 0,
  }).format(value)
}

export function getTooltip({ key, series, params }) {
  const { chart } = series
  const myMoney = val => formatCurrency(val, params.currency, params.locale)
  const myDate = date =>
    new Date(date).toLocaleDateString(params.locale, {
      day: 'numeric',
      month: 'long',
    })

  if (!params.competitors) {
    return null
  }

  const views = {
    rates: filteredSeries => {
      const roomType = params.filterValues.roomType ? params.filterValues.roomType : 'double'
      const breakfast = params.filterValues.breakfast ? params.filterValues.breakfast : 'not_included'
      let selection = []

      filteredSeries.map(item => selection.push(item.data[params.point.index]))
      selection = orderBy(selection, 'y', 'desc')

      const ratesCompetitorsList = () => {
        return selection
          .map(item => {
            const competitorId = item.series.userOptions.competitorId
            const competitor = params.competitors.filter(competitor => competitor.id === competitorId)[0]

            const rate = item.y || 0
            const itemColorIndex = params.colors.indexOf(item.color)

            if (!rate) {
              return ''
            }

            return `
            <li class="tw-flex tw-justify-between tw-items-center tw-w-full tw-px-2.5 tw-py-1.5 tw-color-black ${
              itemColorIndex === params.point.colorIndex ? 'tw-bg-blue-100 tw-rounded-md' : ''
            }">
            <div class="tw-flex tw-items-center tw-w-full tw-max-w-[250px]">
              <span class="tw-truncate tw-max-w-[200px] tw-items-center tw-leading-none tw-order-1">${
                competitor.name
              }</span>
              <span class="tw-w-[10px] tw-h-[10px] tw-mr-2 tw-rounded-full tw-flex tw-order-0" style="background-color: ${
                competitor.color
              }" />
            </div>

              <span>
                ${myMoney(rate)}
              </span>
            </li>
          `
          })
          .join('')
      }

      return `
        <div class="tw-min-w-[300px] tw-bg-white tw-rounded-none tw-rounded-xl tw-p-4 tw-text-normal" style="box-shadow: 0 2px 4px 0 rgba(63, 87, 189, 0.1), 0 2px 10px 0 rgba(108, 112, 122, 0.15)">
          <p>
            ${params.translationKeys.averageRate} ${
        key.from && key.to ? myDate(key.from) + ' - ' + myDate(key.to) : myDate(key.date)
      }
          </p>
          <p class="tw-font-bold tw-pb-2">
            ${params.translationKeys[`room_type_${roomType}`]} & ${
        params.translationKeys[`${breakfast === 'included' ? 'incl' : 'excl'}_breakfast`]
      }
          </p>
          <ul class="tw-mt-2 tw-w-full">
            ${ratesCompetitorsList()}
          </ul>
        </div>
      `
    },
  }

  let name = TYPES.RATES

  if (views[name]) {
    return views[name](chart.series)
  }
}
