<template>
  <section class="tw-h-screen tw-w-screen tw-flex tw-justify-center tw-items-center">Redirecting...</section>
</template>

<script setup>
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const language = computed(() => store.state?.locale?.language)

onBeforeMount(() => {
  store.dispatch('session/authorize', { type: 'signup', locale: language.value })
})
</script>
