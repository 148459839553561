<template>
  <div class="tw-z-20">
    <ul class="tw-flex tw-flex-row tw-h-full tw-grow tw-justify-start lg:tw-pr-6">
      <li v-for="i in slideCount" :key="i" class="tw-flex tw-justify-center tw-pb-4 tw-items-center tw-mr-2.5">
        <button
          :class="selectedSlide === i ? 'tw-bg-blue-700' : 'tw-bg-gray-500 tw-border-gray-500'"
          class="tw-text-base md:tw-text-xl tw-rounded-full tw-w-2.5 tw-h-2.5 md:tw-w-2.5 md:tw-h-2.5 tw-outline-none focus:tw-outline-none tw-relative tw-transition-all tw-duration-300 tw-ease-in-out"
          @click="onCarouselNavClick(i)"
          @keydown.enter="onCarouselNavClick(i)"
        ></button>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'CarouselNav',
  props: {
    slideCount: {
      type: Number,
      default: 0,
    },
    selectedSlide: {
      type: Number,
      default: 1,
    },
    onCarouselNavClick: {
      type: Function,
    },
  },
}
</script>
