<template>
  <div class="tw-flex tw-flex-col">
    <div v-if="loading">
      <div class="tw-animate-pulse tw-bg-gray-300 tw-rounded tw-w-full tw-h-80" aria-hidden="true" />
    </div>
    <div v-else>
      <div class="tw-mt-4" v-if="isOnboarding">
        <h1 class="tw-text-gray-800 tw-font-bold tw-text-[32px]">{{ $t('tbs_registration_stepper_2_header') }}</h1>
        <span class="required-info tw-text-xs tw-text-gray-700" v-html="$t('required_information')" />
      </div>

      <form class="form" @submit.prevent="handleSubmit">
        <div class="tw-flex tw-my-4">
          <BaseSelect
            class="tw-w-full"
            :label="$t('form.labels.title')"
            name="salutation"
            required
            :display-text-key="'label'"
            :value-key="'value'"
            :options="formsOfAddress"
            :value="form.salutation"
            :error="errors['salutation'] ? $t(errors['salutation']) : false"
            version="light"
            size="big"
            @onChange="e => onSelect('salutation', e)"
            @onBlur="e => validate('salutation', e)"
            :dataTestId="`onboarding-title-select-component`"
            :selectTestId="`onboarding-title-select`"
            :errorTestId="`onboarding-title-select-error`"
          />
        </div>
        <div class="tw-flex tw-flex-col md:tw-flex-row tw-my-4">
          <BaseTextField
            :label="$t('form.labels.first_name')"
            name="firstName"
            required
            aria-label="firstName"
            type="text"
            class="tw-w-full tw-mb-4 md:tw-mb-auto md:tw-mr-2"
            size="big"
            :error="errors['firstName'] ? $t(errors['firstName']) : false"
            :value="form.firstName"
            @onBlur="e => validate('firstName', e)"
            @onInput="e => (form.firstName = e)"
            :dataTestId="`onboarding-first-name-field`"
            :errorTestId="`onboarding-first-name-field-error`"
          />
          <BaseTextField
            :label="$t('form.labels.last_name')"
            name="lastName"
            required
            aria-label="lastName"
            type="text"
            class="tw-w-full"
            size="big"
            :error="errors['lastName'] ? $t(errors['lastName']) : false"
            :value="form.lastName"
            @onBlur="e => validate('lastName', e)"
            @onInput="e => (form.lastName = e)"
            :dataTestId="`onboarding-last-name-field`"
            :errorTestId="`onboarding-last-name-field-error`"
          />
        </div>
        <div class="tw-flex tw-my-4">
          <BaseSelect
            :label="$t('form.labels.job_position')"
            name="jobPosition"
            :display-text-key="'label'"
            required
            :value-key="'value'"
            :options="jobs"
            :value="form.jobPosition"
            version="light"
            class="tw-w-full"
            size="big"
            :error="errors['jobPosition'] ? $t(errors['jobPosition']) : false"
            @onChange="e => onSelect('jobPosition', e)"
            @onBlur="e => validate('jobPosition', e)"
            :dataTestId="`onboarding-job-position-select-component`"
            :selectTestId="`onboarding-job-position-select`"
            :errorTestId="`onboarding-job-position-select-error`"
          />
        </div>
        <div class="tw-flex tw-gap-2 tw-mt-4 tw-mb-3">
          <div class="tw-basis-1/3">
            <BaseSelect
              :label="$t('form.labels.phone')"
              name="phoneRegionCode"
              required
              :display-text-key="'label'"
              :value-key="'value'"
              :options="phoneCodes"
              :value="form.phoneRegionCode"
              version="light"
              size="big"
              @onChange="e => onSelect('phoneRegionCode', e)"
              :dataTestId="`onboarding-phone-code-select-component`"
              :selectTestId="`onboarding-phone-code-select`"
            />
          </div>
          <div class="tw-basis-2/3 tw-mt-[27px]">
            <BaseTextField
              name="phoneNationalNumber"
              aria-label="phoneNationalNumber"
              type="tel"
              size="big"
              :error="errors['phoneNationalNumber'] ? `${$t(getPhoneNumberError)} ${examplePhoneNumber}` : false"
              :value="form.phoneNationalNumber"
              @onBlur="e => validate('phoneNationalNumber', e)"
              @onInput="e => (form.phoneNationalNumber = e)"
              :dataTestId="`onboarding-phone-number-field`"
              :errorTestId="`onboarding-phone-number-field-error`"
            />
          </div>
        </div>
      </form>
      <div class="tw-flex">
        <span class="tw-text-xs tw-text-gray-700">{{ $t('form.labels.phone_privacy_note') }}</span>
      </div>
      <div v-if="isOnboarding" class="tw-flex tw-my-8">
        <span class="tw-text-sm tw-text-gray-800">{{ $t('tbs_newsletter_notification') }}</span>
      </div>
      <div v-if="isOnboarding" class="tw-flex tw-text-sm">
        <Checkbox
          name="agreedToTerms"
          :label="$t('form.labels.terms_and_conditions_checkbox')"
          :value="form.agreedToTerms"
          :error="errors['agreedToTerms'] ? $t(errors['agreedToTerms']) : false"
          @onChange="e => acceptTerms(e)"
          :dataTestId="`onboarding-terms-checkbox`"
        />
      </div>
      <div class="tw-flex tw-justify-center md:tw-justify-end md:tw-mt-10 tw-mt-8">
        <MainButton
          class="tw-w-full md:tw-w-auto"
          :disabled="disableSubmit"
          @click="handleSubmit()"
          :dataTestId="'onboarding-personal-info-save-btn'"
        >
          <span v-if="loading"><RCInlineLoader color="#fff" /></span>
          <span v-else>{{ $t(buttonLabel) }}</span>
        </MainButton>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { global } from '@/plugins/i18n'
import * as Yup from 'yup'

import BaseTextField from '@/components/base/inputs/BaseTextField.vue'
import BaseSelect from '@/components/BaseSelect.vue'
import Checkbox from '@/components/base/inputs/Checkbox.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import OrganisationManagementService from '@/services/OrganisationManagementService.js'

import { useUpdateUserProfile } from '@/components/settings/profileSettings/queries'

const BROWSER_LANGUAGE = navigator.language && navigator.language.includes('-') ? navigator.language : 'en-US'
const BROWSER_COUNTRY = BROWSER_LANGUAGE.split('-')[1]
const NAMES_REGEX = /^(?!\d+$)(?:[a-zA-ZÀ-ÿ][a-zA-ZÀ-ÿ '@&$-]*)?$/
const store = useStore()

const props = defineProps({
  isOnboarding: Boolean,
  profile: Object,
  loading: Object,
})

const emit = defineEmits(['onCreateProfileSubmit'])

const { updateUserProfile } = useUpdateUserProfile()
const language = computed(() => store.state.locale?.language ?? BROWSER_LANGUAGE)
const languageInLocalStorage = window.localStorage.getItem('stdio_locale_language')
const currency = computed(() => store.state.currency)

const form = reactive({
  salutation: props.profile?.salutation ?? '',
  firstName: props.profile?.firstName ?? '',
  lastName: props.profile?.lastName ?? '',
  jobPosition: props.profile?.jobPosition ?? '',
  phoneNationalNumber: props.profile?.phoneNationalNumber ?? '',
  phoneRegionCode: props.profile?.phoneRegionCode ?? BROWSER_COUNTRY,
  agreedToTerms: props.profile?.agreedToTerms ?? false,
  preferredLanguage: languageInLocalStorage ?? language?.value,
  currency: props.profile?.currency ?? currency.value,
  agreedToNewsletter: true,
})

const isValidatingPhoneNumber = ref(false)
const errors = reactive({
  salutation: false,
  firstName: false,
  lastName: false,
  jobPosition: false,
  phoneNationalNumber: false,
  phoneRegionCode: false,
  agreedToTerms: false,
})
const PersonalInfoSchema = Yup.object().shape({
  salutation: Yup.string().required('error_field_required'),
  firstName: Yup.string().matches(NAMES_REGEX, 'error_latin_chars').required('error_field_required'),
  lastName: Yup.string().matches(NAMES_REGEX, 'error_latin_chars').required('error_field_required'),
  jobPosition: Yup.string().required('error_field_required'),
  phoneRegionCode: Yup.string()
    .required('error_field_required')
    .test('form.errors.phoneNationalNumber', async function handlePhoneNumberValidation(value, ctx) {
      if (form.phoneNationalNumber.length > 3) {
        isValidatingPhoneNumber.value = true
        try {
          const response = await OrganisationManagementService.validatePhoneNumber({
            nationalNumber: form.phoneNationalNumber,
            regionCode: value,
          })
          if (response?.data?.acceptedNumber) form.phoneNationalNumber = response.data.acceptedNumber
          isValidatingPhoneNumber.value = false
          return true
        } catch (err) {
          isValidatingPhoneNumber.value = false
          if (err.response?.data?.exampleNumber) {
            return ctx.createError({
              path: 'phoneNationalNumber',
              message: `'form.errors.phone_number', ${err.response.data.exampleNumber}`,
            })
          }
        }
      } else return true
    }),
  phoneNationalNumber: Yup.string()
    .min(3, 'error_field_required')
    .required('error_field_required')
    .test('form.errors.phoneNationalNumber', async function handlePhoneNumberValidation(value, ctx) {
      if (value.length > 3) {
        isValidatingPhoneNumber.value = true
        try {
          const response = await OrganisationManagementService.validatePhoneNumber({
            nationalNumber: value,
            regionCode: form.phoneRegionCode,
          })
          if (response?.data?.acceptedNumber) form.phoneNationalNumber = response.data.acceptedNumber
          isValidatingPhoneNumber.value = false
          return true
        } catch (err) {
          isValidatingPhoneNumber.value = false
          if (err.response?.data?.exampleNumber) {
            return ctx.createError({
              path: 'phoneNationalNumber',
              message: `'form.errors.phone_number', ${err.response.data.exampleNumber}`,
            })
          }
        }
      } else return true
    }),
  agreedToTerms: Yup.boolean().required('error_field_required'),
})

const validate = field => {
  PersonalInfoSchema.validateAt(field, form)
    .then(() => (errors[field] = false))
    .catch(err => {
      if (field === 'phoneRegionCode') {
        errors['phoneNationalNumber'] = err.message
      } else {
        errors[field] = err.message
      }
    })
}

const acceptTerms = e => {
  form.agreedToTerms = e
  validate('agreedToTerms')
  validateAll()
}

const onSelect = (field, e) => {
  if (field === 'phoneRegionCode') form.phoneRegionCode = e
  else form[field] = e
  validate(field)
}

const getPhoneNumberError = computed(() => {
  const error = errors['phoneNationalNumber']
  if (error?.includes(',')) {
    return 'form.errors.phone_number'
  } else {
    return error
  }
})
const examplePhoneNumber = computed(() => {
  if (errors['phoneNationalNumber'].includes(',')) {
    return errors['phoneNationalNumber'].split(',')[1]
  } else return ''
})

const buttonLabel = computed(() => (props.isOnboarding ? 'form.actions.save_and_continue' : 'form.actions.save'))
const disableSubmit = computed(() => {
  const allErrors = Object.values(errors)
  const disabled = props.loading || isValidatingPhoneNumber.value || !allErrors.every(item => item === false)

  return props.isOnboarding ? disabled || !form.agreedToTerms : disabled
})

const formsOfAddress = computed(() => {
  const titles = global.getLocaleMessage(language.value).form?.titles ?? {}
  const defaultOptionLabel = global.getLocaleMessage(language.value).form?.options?.default
  const titlesObj = Object.keys(titles).map(title => ({
    value: title,
    label: titles[title],
  }))
  return [...[{ value: '', label: defaultOptionLabel }], ...titlesObj]
})

const jobs = computed(() => {
  const jobs = global.getLocaleMessage(language.value).form?.jobs ?? {}
  const defaultOptionLabel = global.getLocaleMessage(language.value).form?.options?.default
  const jobsObj = Object.keys(jobs).map(job => ({
    value: job,
    label: jobs[job],
  }))
  return [...[{ value: '', label: defaultOptionLabel }], ...jobsObj]
})
const phoneCodes = computed(() => {
  const phoneCodes = global.getLocaleMessage(language.value).form?.phone_codes ?? {}
  const phoneCodesFormattedForSelect = Object.keys(phoneCodes)?.map(code => ({
    value: code,
    label: phoneCodes[code],
  }))
  const collator = new Intl.Collator('en', { sensitivity: 'base' })
  phoneCodesFormattedForSelect?.sort((a, b) => collator.compare(a.label, b.label))
  return phoneCodesFormattedForSelect
})

const { displayNotification } = useToastNotifications()

const validateAll = () => {
  const fieldsToValidate = [
    'salutation',
    'firstName',
    'lastName',
    'phoneNationalNumber',
    'phoneRegionCode',
    'jobPosition',
    'agreedToTerms',
  ]
  for (let i = 0; i < fieldsToValidate.length; i++) {
    const field = fieldsToValidate[i]
    validate(field)
  }
}

const handleSubmit = async () => {
  validateAll()

  // perform a POST within the onboarding step or for legacy accounts which
  // do not have profile information yet
  if (props.isOnboarding) {
    emit('onCreateProfileSubmit', form)
  } else {
    form.agreedToNewsletter = null
    updateUserProfile(
      { userId: props.profile.id, body: form },
      {
        onSuccess: () => {
          displayNotification({
            message: 'form.alerts.save_success',
            isTranslationKey: true,
            isHtml: false,
            type: 'success',
          })
        },
        onError: () => {
          displayNotification({
            message: 'something_went_wrong',
            isTranslationKey: true,
            isHtml: false,
            type: 'error',
          })
        },
      }
    )
  }
}
</script>
<style scoped>
.required-info :deep(span) {
  @apply tw-text-red-700;
}
</style>
