<template>
  <h4 class="tw-font-bold tw-mt-7 tw-mb-1">{{ $t('paymentMethods.billingAddress.heading') }}</h4>
  <div v-if="isFetching">
    <div class="tw-animate-pulse tw-bg-gray-300 tw-rounded tw-w-full tw-h-24" aria-hidden="true"></div>
  </div>
  <div v-else-if="isError" class="tw-text-red-700" v-html="$t('errors.wrong_data_from_app')" />
  <div v-else>
    <div v-if="hasBillingInfo">
      <div>{{ accountName }}</div>
      <div>{{ billEmail }}</div>
      <div>
        <span>{{ billFirstName }}{{ ' ' }}</span>
        <span>{{ billLastName }}</span>
      </div>
      <div>
        <span v-if="billAddress1">{{ billAddress1 }}, </span>
        <span v-if="billAddress2">{{ billAddress2 }}, </span>
        <span v-if="billCity">{{ billCity }}, </span>
        <span v-if="billState">{{ billState }}, </span>
        <span v-if="billZipCode">{{ billZipCode }}, </span>
        <span v-if="billCountry">{{ billCountry }}</span>
      </div>
      <div v-if="taxVATId">{{ taxVATId }}</div>
    </div>
    <div v-else>-</div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  accountData: Object,
  hasBillingInfo: Boolean,
})

const accountName = computed(() => props.accountData?.basicInfo?.name)
const billEmail = computed(() => props.accountData?.billToContact?.workEmail)
const billFirstName = computed(() => props.accountData?.billToContact?.firstName)
const billLastName = computed(() => props.accountData?.billToContact?.lastName)
const billAddress1 = computed(() => props.accountData?.billToContact?.address1)
const billAddress2 = computed(() => props.accountData?.billToContact?.address2)
const billCity = computed(() => props.accountData?.billToContact?.city)
const billState = computed(() => props.accountData?.billToContact?.state)
const billZipCode = computed(() => props.accountData?.billToContact?.zipCode)
const billCountry = computed(() => props.accountData?.billToContact?.country)
const taxVATId = computed(() => props.accountData?.taxInfo?.VATId)
</script>
