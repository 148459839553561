<template>
  <div class="tw-mt-6" v-if="isPositive">
    <p v-if="reasonId === 9" class="tw-mb-6" v-html="$t('rc_pause_flow_paused_hotel_closed_info')" />
    <p v-else-if="reasonId === 10" class="tw-mb-6" v-html="$t('rc_pause_flow_paused_ownership_info')" />
    <p v-else class="tw-mb-6" v-html="$t('rc_pause_flow_paused_info')" />
    <div>
      <p class="tw-font-bold">{{ $t('rc_pause_flow_feedback_header') }}</p>
      <p class="tw-text-sm tw-mb-1 tw-text-gray-700" v-html="$t('rc_pause_flow_feedback_subtext')" />
      <textarea
        class="tw-outline-none tw-w-full tw-border tw-border-gray-500 tw-rounded-md tw-p-2 tw-max-h-[150px]"
        :placeholder="$t('rc_pause_flow_feedback_text_prompt')"
        @input="setFeedback($event.target.value)"
        :ref="feedback"
        style="height: 56px"
      />
    </div>
  </div>
  <div class="tw-mt-6" v-else-if="!isPositive && retries <= 2">
    <p class="tw-mb-6" v-html="$t('rc_pause_flow_pause_error_info')" />
    <p class="tw-mb-3">{{ $t('cancellation_fail_tip_header') }}</p>
    <ul class="tw-ml-6">
      <li
        class="tw-list-disc tw-py-1 first:tw-pt-0 last:tw-pb-0 tw-leading-5"
        v-for="(item, index) in solutions"
        :key="index"
      >
        {{ $t(item) }}
      </li>
    </ul>
    <p class="tw-mt-6" v-html="$t('cancellation_fail_help')" />
  </div>
  <div class="tw-mt-6" v-else-if="!isPositive && retries > 2">
    <p v-html="$t('rc_pause_flow_pause_error_message')" />
  </div>
</template>
<script setup>
defineProps({
  reasonId: Number,
  isPositive: Boolean,
  feedback: String,
  retries: Number,
})

const emit = defineEmits(['setFeedback'])
const setFeedback = text => emit('setFeedback', text)
const solutions = ['cancellation_fail_tip_1', 'cancellation_fail_tip_2', 'cancellation_fail_tip_3']
</script>
<style scoped>
p :deep(a) {
  @apply tw-text-blue-700 tw-underline;
}
p :deep(a):hover {
  @apply tw-text-blue-800 tw-underline;
}
</style>
