<template>
  <div class="tw-w-full">
    <p class="tw-font-bold tw-text-xl tw-text-gray-800 tw-my-2">{{ $t('app_hotel_details') }}</p>
    <div class="tw-flex tw-flex-col">
      <BaseTextField
        :label="$t('app_hotel_details_name')"
        name="name"
        aria-label="name"
        maxlength="150"
        required
        type="text"
        class="tw-w-full tw-mb-4 md:tw-mb-auto"
        size="medium"
        :error="errors['name'] ? $t(errors['name']) : false"
        :value="name"
        @onChange="e => setValue('name', e)"
      />
      <AutocompleteField
        id="hotelChainId"
        :label="$t('property_details_hotel_chain')"
        name="hotelChainId"
        aria-label="hotelChainId"
        type="text"
        class="tw-w-full tw-mb-4 md:tw-mb-auto tw-relative"
        size="medium"
        :options="allHotelChains"
        :value="hotelChainValue.withTranslation ? $t(hotelChainValue.name) : hotelChainValue.name"
        @onSelect="e => setValue('chainId', e)"
      />
      <BaseSelect
        id="propertyType"
        :label="$t('app_hotel_details_property_type')"
        name="propertyType"
        required
        aria-label="propertyType"
        :display-text-key="'name'"
        v-if="lodgingTypes.length > 0"
        :value-key="'id'"
        size="medium"
        :error="errors['accommodationTypeId'] ? $t(errors['accommodationTypeId']) : false"
        :placeholder="$t('locale.currencySelector.enabledTitle')"
        :options="lodgingTypes.length > 0 ? lodgingTypes : []"
        :value="lodgingTypeValue === null ? null : lodgingTypeValue?.id"
        version="light"
        class="tw-w-full tw-mb-4 md:tw-mb-auto tw-relative"
        @onChange="e => setValue('accommodationTypeId', e)"
      />
      <div class="tw-flex tw-gap-2">
        <div class="tw-basis-full lg:tw-basis-1/2">
          <HourSelectField
            :label="$t('app_hotel_details_check_in')"
            name="checkIn"
            aria-label="checkIn"
            size="medium"
            :error="errors['checkIn'] ? $t(errors['checkIn']) : false"
            :value="checkIn"
            @onChange="e => setValue('checkIn', e)"
          />
        </div>
        <div class="tw-basis-full lg:tw-basis-1/2">
          <HourSelectField
            :label="$t('app_hotel_details_check_out')"
            name="checkOut"
            aria-label="checkOut"
            size="medium"
            :error="errors['checkOut'] ? $t(errors['checkOut']) : false"
            :value="checkOut"
            @onChange="e => setValue('checkOut', e)"
          />
        </div>
      </div>
      <BaseStarsRatingEdit :grade="grade" @setNewValue="e => setValue('starRating', e)" />
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted } from 'vue'
import { usePropertyDetailsBasicInfo } from '@/components/propertyDetails/propertyBasicInfo/usePropertyDetailsBasicInfo.js'
import { validate, required, latinChars, noSpecialChars, url } from '@/utils/propertyDetailsHelpers.js'
import BaseTextField from '@/components/base/inputs/BaseTextField.vue'
import HourSelectField from '@/components/base/inputs/HourSelectField.vue'
import AutocompleteField from '@/components/base/inputs/AutocompleteField.vue'
import BaseStarsRatingEdit from '@/components/BaseStarsRatingEdit.vue'
import BaseSelect from '@/components/BaseSelect.vue'

const { setNewValue, setValidation } = usePropertyDetailsBasicInfo()
const props = defineProps({
  errors: Object,
  name: String,
  hotelChain: Number,
  accommodationType: { type: [Number, String] },
  checkIn: {
    type: String,
    default: '0:00',
  },
  checkOut: {
    type: String,
    default: '0:00',
  },
  expressCheckInOut: Boolean,
  grade: Number,
  translations: Object,
  lodgingTypes: Array,
  hotelChains: Array,
})

const allHotelChains = computed(() => {
  const noHotelChain = {
    name: 'no_hotel_chain_menu_option',
    value: null,
    withTranslation: true,
  }
  const newChains = [noHotelChain].concat([...props.hotelChains])
  return newChains
})

onMounted(() => {
  const fieldsToValidate = [
    { name: 'name', value: props.name },
    { name: 'accommodationTypeId', value: props.accommodationType },
  ]
  fieldsToValidate.forEach(item => {
    const validationFunctions = getValidations(item.name)
    const error = validate(item.value, validationFunctions)
    setValidation({
      name: item.name,
      value: error !== undefined ? error : false,
    })
  })
})

const hotelChainValue = computed(() => {
  const noHotelChain = {
    name: 'no_hotel_chain_menu_option',
    value: null,
    withTranslation: true,
  }
  let hotelChainObj = props.hotelChains.filter(item => {
    if (item.value === props.hotelChain) {
      return item
    }
  })
  return hotelChainObj.length > 0 ? hotelChainObj[0] : noHotelChain
})
const lodgingTypeValue = computed(() => {
  let lodgingTypeObj = props.lodgingTypes.find(item => {
    if (Number(item.id) == Number(props.accommodationType)) {
      return item
    }
  })
  return lodgingTypeObj ? lodgingTypeObj : null
})

const getValidations = name => {
  if (name === 'accommodationTypeId') {
    return [required]
  } else if (name === 'name') {
    return [required, latinChars, noSpecialChars]
  } else if (name === 'web') {
    return [url]
  } else {
    return []
  }
}
const setValue = (name, value) => {
  const validationFunctions = getValidations(name)
  const error = validate(value, validationFunctions)
  setValidation({ name, value: error !== undefined ? error : false, isFormValid: error === undefined })
  if (name === 'chainId') {
    setNewValue({ name, value: value.value })
  } else {
    setNewValue({ name, value })
  }
}
</script>
