import axios from 'axios'

import BaseService from './BaseService.js'

const rateConnectApiBaseUrl = `${import.meta.env.VITE_RATE_CONNECT_API_URL}`
export const rateConnectApiBaseUrlNoSubpath = import.meta.env.VITE_RATE_CONNECT_API_URL?.replace('/rate_connect', '')

class RateConnectService extends BaseService {
  constructor() {
    super({
      baseUrl: rateConnectApiBaseUrl,
    })
  }

  fetchHoteliersData({ accommodationId }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/{accommodationId}/start_up: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/start_up`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchNotifications({ accommodationId }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/{accommodationId}/notifications: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/notifications`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  dismissNotification({ accommodationId, notificationName }) {
    if (!this.studioToken)
      throw new Error(
        'Error in GET accommodation/{accommodationId}/notifications/${notificationName}/dismiss: No studioToken provided'
      )
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/notifications/${notificationName}/dismiss`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchCampaigns({ accommodationId }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/${accommodationId}/campaigns: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/campaigns`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  updateCampaigns({ accommodationId, body }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/${accommodationId}/campaigns: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/campaigns`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchMarkets({ accommodationId, partnerId, language = 'en-US' }) {
    if (!this.studioToken)
      throw new Error(
        'Error in GET accommodation/${accommodationId}/partner/${partnerId}/markets: No studioToken provided'
      )
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/partner/${partnerId}/markets`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
        'X-Trv-Localization': language,
      },
    })
  }

  fetchKPIS({ accommodationId, currencyCode, marketCodes, startDate, endDate }) {
    if (!this.studioToken)
      throw new Error(
        'Error in GET accommodation/${accommodationId}/performance/kpis?currencyCode=${currencyCode}&marketCodes=${marketCodes}&startDate=${startDate}&endDate=${endDate}&aggregation=day: No studioToken provided'
      )
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/performance/kpis?currencyCode=${currencyCode}&marketCodes=${marketCodes}&startDate=${startDate}&endDate=${endDate}&aggregation=day`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchPartners({ accommodationId }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/${accommodationId}/partners: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/partners`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchStats({ accommodationId, currencyCode, marketCodes, startDate, endDate, aggregation }) {
    if (!this.studioToken)
      throw new Error(
        'Error in GET accommodation/${accommodationId}/performance/statistics?currencyCode=${currencyCode}&marketCodes=${marketCodes}&startDate=${startDate}&endDate=${endDate}&aggregation=${aggregation}: No studioToken provided'
      )
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/performance/statistics?currencyCode=${currencyCode}&marketCodes=${marketCodes}&startDate=${startDate}&endDate=${endDate}&aggregation=${aggregation}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchBookingLog(params) {
    if (!this.studioToken)
      throw new Error(
        'Error in GET accommodation/${params.accommodationId}/booking_log/${params.requestId}?currencyCode=${params.currencyCode}&marketCodes=${params.marketCodes}: No studioToken provided'
      )
    return axios({
      url: params.requestId
        ? `${this.baseUrl}/accommodation/${params.accommodationId}/booking_log/${params.requestId}?currencyCode=${params.currencyCode}&marketCodes=${params.marketCodes}`
        : `${this.baseUrl}/accommodation/${params.accommodationId}/booking_log?currencyCode=${params.currencyCode}&startDate=${params.startDate}&endDate=${params.endDate}&marketCodes=${params.marketCodes}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  updateCampaignType({ accommodationId, campaignType, body }) {
    if (!this.studioToken)
      throw new Error(
        'Error in GET accommodation/${accommodationId}/campaigns/type/${campaignType.toLowerCase()}: No studioToken provided'
      )
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/campaigns/type/${campaignType.toLowerCase()}`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  updateCommissionLevel({ accommodationId, body }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/${accommodationId}/campaigns: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/campaigns`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchRecommendation({ accommodationId, currencyCode, partnerId }) {
    if (!this.studioToken)
      throw new Error(
        'Error in GET accommodation/${accommodationId}/budget_recommendation?currencyCode=${currencyCode}&partnerId=${partnerId}: No studioToken provided'
      )
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/budget_recommendation?currencyCode=${currencyCode}&partnerId=${partnerId}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  updatePartner({ accommodationId, body }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/${accommodationId}/partners: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/partners`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  toggleCampaignStatus({ accommodationId, body }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/${accommodationId}/campaigns: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/campaigns`,
      method: 'PUT',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  sendCampaign({ accommodationId, options }) {
    if (!this.studioToken)
      throw new Error('Error in GET accommodation/${accommodationId}/campaigns: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/accommodation/${accommodationId}/campaigns`,
      method: 'POST',
      data: options.body,
      headers: {
        ...options.headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  fetchAllPartners() {
    if (!this.studioToken) throw new Error('Error in GET /partners: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/partners`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  sendFreeLinksOptIn({ body }) {
    if (!this.studioToken) throw new Error('Error in GET free-links/opt-in: No studioToken provided')
    return axios({
      url: `${this.baseUrl}/free-links/opt-in`,
      method: 'POST',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Authorization': 'Basic d333cb33a013eeac2016ceb725bf74aa',
        'X-Studio-Authorization': this.studioToken,
      },
    })
  }

  sendFreeLinksOptInWithToken({ body }) {
    return axios({
      url: `${rateConnectApiBaseUrlNoSubpath}/free-links/opt-in`,
      method: 'POST',
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  }
}
export default new RateConnectService()
