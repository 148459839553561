<template>
  <div class="tw-animate-pulse tw-w-full">
    <div class="tw-h-8 tw-rounded tw-bg-gray-300 tw-w-full" />
    <div class="tw-h-8 tw-rounded tw-bg-gray-300 tw-w-1/2 tw-my-2" />
    <div class="skeleton-graph tw-rounded tw-bg-gray-300 tw-w-full" />
  </div>
</template>

<style scoped>
.skeleton-graph {
  height: 137px;
}
</style>
