<template>
  <g id="Final-Homepage-Dashboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
    ><g id="tBS---Home-FL---Desktop---Dots" transform="translate(-92.000000, -1684.000000)"
      ><g id="Group-41" transform="translate(92.000000, 1682.000000)"
        ><g id="Icons/Vote-&amp;-Rewards/Thumb-Up-24x24-Line" transform="translate(0.000000, 2.000000)"
          ><rect id="Rectangle-4-Copy-84" x="0" y="0" width="24" height="24"></rect
          ><path
            id="Page-1"
            d="M18.95,12.32 L16.74,19.32 C16.61,19.72 16.25,20 15.84,20 L8.95,19 C8.42,19 8,18.55 8,18 L8,10.59 C8.36,10.43 8.69,10.23 9,10 C9.38,9.71 9.71,9.38 10,9 C10.63,8.17 11,7.13 11,6 L11,4 L12,4 C12.55,4 13,4.45 13,5 L13,11 L18.05,11 C18.7,11 19.16,11.67 18.95,12.32 L18.95,12.32 Z M5,20 L7,20 L7,10 L5,10 L5,20 Z M19.61,10.8 C19.24,10.29 18.67,10 18.05,10 L14,10 L14,5 C14,3.9 13.1,3 12,3 L11,3 C10.7,3 10.43,3.14 10.24,3.35 C10.09,3.53 10,3.75 10,4 L10,6 C10,7.53 9.15,8.85 7.88,9.53 C7.71,9.21 7.38,9 7,9 L5,9 C4.62,9 4.29,9.21 4.12,9.53 C4.04,9.67 4,9.83 4,10 L4,20 C4,20.55 4.45,21 5,21 L7,21 C7.55,21 8,20.55 8,20 L8,19.74 C8.26,19.89 8.55,19.98 8.86,20 L15.7,20.99 C15.75,21 15.79,21 15.84,21 C16.69,21 17.43,20.44 17.69,19.62 L19.9,12.62 C20.1,12 19.99,11.32 19.61,10.8 L19.61,10.8 Z"
            fill="#37454D"
          ></path></g></g></g
  ></g>
</template>
