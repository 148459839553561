<template>
  <CustomModal
    class="delete-assignment-modal"
    :is-modal-open="isOpen"
    @toggleModal="closeModal"
    @onClickAway="closeModal"
    :overlay="'dark'"
    :with-header="false"
    :with-footer="false"
    custom-classes="tw-h-screen md:tw-h-auto tw-w-screen md:tw-max-w-[636px] md:tw-w-[636px] md:tw-rounded-md tw-rounded-none"
  >
    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full">
      <div class="tw-mt-2 tw-flex tw-pt-6 tw-pb-4 tw-text-center">
        {{ 'Are you sure you want to delete the assignment?' }}
      </div>

      <div class="tw-w-full tw-flex tw-justify-center tw-py-6">
        <MainButton size="medium" class="tw-px-9 tw-mr-2" @click="deleteAssignment">
          <span v-if="!isDeleting">{{ 'Continue' }}</span>
          <span v-if="isDeleting">
            <RCInlineLoader color="#fff" />
          </span>
        </MainButton>
        <GhostButton size="medium" class="tw-px-9" @click="closeModal">{{ 'Cancel' }}</GhostButton>
      </div>
    </div>
  </CustomModal>
</template>

<script setup>
import CustomModal from '@/components/base/CustomModal.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'

defineProps({
  isOpen: Boolean,
  isDeleting: Boolean,
})

const emit = defineEmits(['toggleModal', 'deleteAssignment'])
const toggleModal = value => emit('toggleModal', value)
const deleteAssignment = () => emit('deleteAssignment')

const closeModal = () => {
  toggleModal(false)
}
</script>
