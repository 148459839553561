<template>
  <mask id="mask0_1500_588" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 3H21V20.9998H3V3Z" fill="white" />
  </mask>
  <g mask="url(#mask0_1500_588)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.914 8.27776C19.614 9.64576 18.438 10.7478 17.054 10.9578C16.87 10.9858 16.683 10.9998 16.5 10.9998C16.177 10.9998 15.864 10.9408 15.559 10.8548C15.214 10.7578 14.888 10.6088 14.588 10.4118L13.86 11.1398L5.00001 19.9998L4.00001 18.9998L12.86 10.1398L13.59 9.40976C13.392 9.11076 13.226 8.79576 13.13 8.45576C12.995 7.97676 12.963 7.46576 13.042 6.94576C13.252 5.56176 14.354 4.38576 15.722 4.08576C15.982 4.02876 16.244 3.99976 16.5 3.99976C16.816 3.99976 17.129 4.04376 17.446 4.13976L14.793 6.79276C14.445 7.14076 14.284 7.60176 14.299 8.05776C14.313 8.47576 14.475 8.88876 14.793 9.20676C15.102 9.51576 15.508 9.68676 15.942 9.70076C15.961 9.70176 15.98 9.70676 16 9.70676C16.456 9.70676 16.885 9.52876 17.207 9.20676L19.87 6.56776C20.027 7.11976 20.042 7.69476 19.914 8.27776M20.832 6.29476C20.699 5.82576 20.281 5.55376 19.854 5.55376C19.606 5.55376 19.354 5.64576 19.153 5.84676L16.5 8.49976C16.362 8.63776 16.181 8.70676 16 8.70676C15.819 8.70676 15.638 8.63776 15.5 8.49976C15.224 8.22376 15.224 7.77676 15.5 7.49976L18.153 4.84676C18.698 4.30176 18.447 3.37876 17.706 3.16776C17.322 3.05876 16.918 2.99976 16.5 2.99976C16.177 2.99976 15.846 3.03476 15.508 3.10876C13.73 3.49876 12.326 4.99676 12.054 6.79576C11.921 7.66876 12.04 8.50276 12.348 9.23776L3.29301 18.2928C2.90201 18.6838 2.90201 19.3168 3.29301 19.7068L4.29301 20.7068C4.48801 20.9028 4.74401 20.9998 5.00001 20.9998C5.25601 20.9998 5.51201 20.9028 5.70701 20.7068L14.767 11.6478C15.301 11.8708 15.885 11.9998 16.5 11.9998C16.731 11.9998 16.966 11.9828 17.204 11.9458C19.003 11.6738 20.501 10.2698 20.891 8.49176C21.061 7.71676 21.025 6.97276 20.832 6.29476"
      fill="#0A1121"
    />
  </g>
</template>
