<template>
  <g id="Product-Page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Single-Hotel-Owner-eligible" transform="translate(-685.000000, -386.000000)">
      <g id="Group-11" transform="translate(215.000000, 385.000000)">
        <g id="Group-5" transform="translate(350.000000, 1.000000)">
          <g id="arrow-icon" transform="translate(120.000000, 0.000000)">
            <g id="icn_click2_fill_dark" transform="translate(4.666667, 4.666667)" fill="#007CC2" fill-rule="nonzero">
              <path
                id="Shape"
                d="M18.6666667,20.93 L18.6666667,39.55 C18.6666667,41.6266667 21.1866667,42.6766667 22.6566667,41.2066667 L25.9233333,37.94 L33.95,45.9666667 C34.86,46.8766667 36.33,46.8766667 37.24,45.9666667 L44.8233333,38.3833333 C45.2601989,37.9473626 45.5057027,37.3555221 45.5057027,36.7383333 C45.5057027,36.1211446 45.2601989,35.5293041 44.8233333,35.0933333 L36.7966667,27.0666667 L41.2766667,22.5866667 C42.7466667,21.1166667 41.6966667,18.5966667 39.62,18.5966667 L21,18.5966667 C19.7166667,18.5966667 18.6666667,19.6466667 18.6666667,20.93 L18.6666667,20.93 Z"
              ></path>
              <path
                id="Shape"
                d="M16.4033333,32.6666667 C13.5566667,30.5433333 11.6666667,27.16 11.6666667,23.3333333 C11.6666667,16.8933333 16.8933333,11.6666667 23.3333322,11.6666667 C27.09,11.6666667 30.4033333,13.4866667 32.55,16.2633333 L35.3966667,16.2633333 C32.8995044,11.9669687 28.3026912,9.32624627 23.3333322,9.3333191 C15.61,9.3333191 9.33333215,15.61 9.33333215,23.3333333 C9.3312704,28.3624061 12.031194,33.0048491 16.4033333,35.49 L16.4033333,32.6666667 Z"
              ></path>
              <path
                id="Shape"
                d="M28.4433333,43.68 C26.81,44.1 25.1066667,44.3333333 23.3333333,44.3333333 C11.76,44.3333333 2.33333333,34.9066667 2.33333333,23.3333333 C2.33333333,11.76 11.76,2.33333333 23.3333333,2.33333333 C34.9066667,2.33333333 44.3333333,11.76 44.3333333,23.3333333 C44.3333333,25.69 43.9133333,27.9533333 43.19,30.0766667 L45.01,31.8966667 C46.06,29.26 46.6666667,26.3666667 46.6666667,23.3333333 C46.6666667,10.4533333 36.2133333,0 23.3333333,0 C10.4533333,0 0,10.4533333 0,23.3333333 C0,36.2133333 10.4533333,46.6666667 23.3333333,46.6666667 C25.7833333,46.6666667 28.14,46.2933333 30.3566667,45.5933333 L28.4433333,43.68 Z"
              ></path>
            </g>
            <rect id="Rectangle-Copy-54" x="0" y="0" width="56" height="56"></rect>
          </g>
        </g>
      </g>
    </g>
  </g>
</template>
