<template>
  <div class="tw-bg-blue-100 tw-p-4 tw-rounded-xl tw-border-blue-700 tw-border">
    <div class="tw-flex tw-items-center tw-mb-3">
      <BaseIcon :height="20" :width="12" icon-name="bulb">
        <Bulb />
      </BaseIcon>
      <h4 class="tw-font-bold tw-ml-2">{{ $t(header) }}</h4>
    </div>
    <div>
      <p v-html="$t(smartTipsText)" class="tw-mb-3" />
      <ul>
        <div v-for="(item, index) in smartTipsList" :key="index">
          <li
            class="tw-mb-0"
            :class="{ 'tw-list-disc tw-ml-6': props.id !== 3 }"
            v-show="item === 'rc_pause_flow_clarity_tip_1'"
          >
            <i18n-t keypath="rc_pause_flow_clarity_tip_1" tag="p" scope="global">
              <template #guide>
                <a
                  download="RateConnectGuide.pdf"
                  :href="pdfUrl"
                  class="hover:tw-cursor-pointer tw-text-blue-700 tw-underline tw-text-base"
                >
                  {{ $t('rc_guide_download') }}
                </a>
              </template>
            </i18n-t>
          </li>
          <li
            v-show="item !== 'rc_pause_flow_clarity_tip_1'"
            v-html="$t(item)"
            class="tw-mb-3 last:tw-mb-0"
            :class="{ 'tw-list-disc tw-ml-6': props.id !== 3 }"
          />
        </div>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import BaseIcon from '@/components/BaseIcon.vue'
import Bulb from '@/components/icons/Bulb.vue'

const props = defineProps({
  id: Number,
})

const store = useStore()
const language = computed(() => store.state.locale.language)

const header = computed(() => {
  if ([1, 3, 4, 5, 6, 7, 8].includes(props.id)) return 'rc_pause_flow_smart_tip_header'
  if ([9, 10].includes(props.id)) return 'rc_pause_flow_hotel_closed_header'
  else return ''
})
const smartTipsText = computed(() => {
  if (props.id === 3) return 'rc_pause_flow_tips_subtext_connectivity'
  if (props.id === 1 || props.id === 4 || props.id === 6 || props.id === 7) return 'rc_pause_flow_tips_subtext_trivago'
  if (props.id === 5) return 'rc_pause_flow_rate_visibility_subtext'
  if (props.id === 8) return 'rc_pause_flow_satisfaction_subtext'
  if (props.id === 9) return 'rc_pause_flow_hotel_closed_subtext'
  if (props.id === 10) return 'rc_pause_flow_ownership_subtext'
  return ''
})

const smartTipsList = computed(() => {
  if (props.id === 1) {
    return ['rc_pause_flow_marketing_tip_1', 'rc_pause_flow_marketing_tip_2', 'rc_pause_flow_marketing_tip_3']
  } else if (props.id === 3) {
    return ['rc_pause_flow_connectivity_tip_1', 'rc_pause_flow_connectivity_tip_2', 'rc_pause_flow_connectivity_tip_3']
  } else if (props.id === 4 || props.id === 6) {
    return [
      'rc_pause_flow_traffic_bookings_tip_1',
      'rc_pause_flow_traffic_bookings_tip_2',
      'rc_pause_flow_traffic_bookings_tip_3',
    ]
  } else if (props.id === 5) {
    return [
      'rc_pause_flow_rate_visibility_tip_1',
      'rc_pause_flow_rate_visibility_tip_2',
      'rc_pause_flow_rate_visibility_tip_3',
      'rc_pause_flow_rate_visibility_tip_4',
    ]
  } else if (props.id === 7) {
    return ['rc_pause_flow_clarity_tip_1', 'rc_pause_flow_clarity_tip_2', 'rc_pause_flow_clarity_tip_3']
  } else if (props.id === 8) {
    return ['rc_pause_flow_satisfaction_tip_1', 'rc_pause_flow_satisfaction_tip_2', 'rc_pause_satisfaction_tip_3']
  } else if (props.id === 9) {
    return ['rc_pause_flow_hotel_closed_tip_1', 'rc_pause_flow_hotel_closed_tip_2']
  } else if (props.id === 10) {
    return ['rc_pause_flow_ownership_tip_1', 'rc_pause_flow_ownership_tip_2']
  } else {
    return []
  }
})

const fileLanguage = computed(() => {
  const availableLanguages = ['de-DE', 'el-GR', 'es-ES', 'fr-FR', 'it-IT']
  if (availableLanguages.includes(language.value)) return language.value.replace('-', '_')
  else return 'EN'
})

const pdfUrl = computed(() => `${window.location.origin}/home/RateConnect_SetupGuide_${fileLanguage.value}.pdf`)
</script>
<style scoped>
li :deep(a) {
  @apply tw-text-blue-700 tw-underline tw-text-base;
}
li :deep(a):hover {
  @apply tw-text-blue-800 tw-underline tw-text-base;
}
</style>
